<template>
  <el-dialog :visible.sync="info.show" width="30%" @close="closeDialog" :close-on-click-modal="false" :modal="false" class="thisblack-bgc" :class="{showGroup:true}">
    <div slot="title">
      <span class="el-icon-warning"></span>
      <span>
        {{receiverConflictErrorCode=='82301008'?$t('receiverConflictTips.outPutTitle'):$t('receiverConflictTips.inPutTitle')}}
      </span>
    </div>
    <div class="content">
      <p class="currentTime">
        {{$t('receiverConflictTips.eventTime')}}
        <span
          >{{ info.data.startTime | FormatTime('YYYY-MM-DD HH:mm') }} ~
          {{ info.data.endTime | FormatTime('YYYY-MM-DD HH:mm') }}</span
        >.
      </p>
      <p v-for="val in info.data.conflicts" :key="val.index" id="receiverInfo">
        {{$t('receiverConflictTips.receiver')}}
        <span class="receiverName">{{val.receiverName}}</span>
        {{$t('receiverConflictTips.scheduled')}}
        <span v-for="item in val.events" :key="item.index" id="receiverInfoChild">
          {{$t('receiverConflictTips.by')}}
          <span class="eventName">{{item.eventName}}</span>
          {{$t('receiverConflictTips.from')}}
          <span class="eventTime">
            {{item.startTime | FormatTime('YYYY-MM-DD HH:mm')}} ~
            {{item.endTime | FormatTime('YYYY-MM-DD HH:mm')}}.
          </span>
          <div v-if="receiverConflictErrorCode=='82301017'" class="coordinate">
            <p>
              {{$t('receiverConflictTips.coordinateTime')}}<span class="coordinateEmail">{{item.userEmail}}.</span>
            </p>
          </div>
        </span>
      </p>
      <p  v-if="receiverConflictErrorCode=='82301008'" class="coordinates">
        {{$t('receiverConflictTips.coordinateTimeInput')}}
      </p>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="closeDialog">{{$t('receiverConflictTips.goIt')}}</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  props: {
    info: {
      type: Object,
      default: () => {}
    },
    receiverConflictErrorCode: String
  },
  data () {
    return {
    }
  },
  created () {

  },
  mounted () {
    this.$nextTick(() => {
      const receiverInfo = document.querySelectorAll('#receiverInfo')
      for (let i = 0; i < receiverInfo.length; i++) {
        if (receiverInfo[i].querySelectorAll('.eventName').length > 1) {
          receiverInfo[i].lastElementChild.insertBefore(document.createTextNode('and '), receiverInfo[i].lastElementChild.firstChild)
        }
      }
    })
  },
  methods: {
    closeDialog () {
      this.info.show = false
      this.$emit('hideCheckedReceiver', this.info.data.conflicts)
    }
  }
}
</script>

<style scoped lang="less">
.thisblack-bgc{
  background-color: rgba(0, 0, 0, 0.5);
}
/deep/ .el-dialog {
  width: 480px;
  min-height: 236px;
  background-color: #28282e;
  .el-dialog__header {
    .el-dialog__headerbtn {
      .el-icon-close {
        color: #909399;
        &::before {
          font-size: 18px;
        }
      }
    }
    .el-icon-warning {
      color: #ffb524;
      &::before {
        font-size: 18px;
      }
    }
  }
  .el-dialog__body {
    padding: 0px 20px;
    .content {
      p {
        color: #f1f1f1;
        font-size: 14px;
        padding-left: 20px;
        padding-right: 14px;
        word-break: break-word;
        .receiverName, .eventName,.eventTime  {
            color: #03897b;
        }
      }
      #receiverInfo {
          padding-bottom: 12px;
      }
      .currentTime {
        margin-bottom: 15px;
        span {
            color: #03897b;
        }
      }
      .coordinate {
        margin-top: 15px;
        margin-left:-20px;
        .coordinateEmail{
        color: #03897b;
        }
      }
      .coordinates{
        margin-top: 15px;
      }
    }
  }
  .el-dialog__footer {
    .el-button {
      width: 80px;
      height: 32px;
      line-height: 3px;
      background-color: #03897b;
      border: none;
      font-size: 14px;
    }
  }
}
</style>
