<template>
  <el-dialog
    :title="$t('audioSettings.title')"
    :visible.sync="info.show"
    :close-on-click-modal="false"
    width="720px"
    :modal="false"
    class="thisblack-bgc"
  >
  <div class="middles">
    <div class="content">
      <el-checkbox @change="(val) => { saveOperation('checkedAec', val) }" v-model="State.checkedAec">{{ $t('coordinationParticipant.openAEC') }}</el-checkbox>
      <span class="contentDetail">{{ $t('coordinationParticipant.aecDetail') }}</span>
    </div>
    <div class="content">
      <el-checkbox @change="(val) => { saveOperation('checkedAns', val) }" v-model="State.checkedAns">{{ $t('coordinationParticipant.openANS') }}</el-checkbox>
      <span class="contentDetail">{{ $t('coordinationParticipant.ansDetail') }}</span>
    </div>
    <div  class="content">
      <el-checkbox @change="(val) => { saveOperation('checkedAgc', val) }" v-model="State.checkedAgc">{{ $t('coordinationParticipant.openAGC') }}</el-checkbox>
      <span class="contentDetail">{{ $t('coordinationParticipant.agcDetail') }}</span>
    </div>
  </div>
  <span  slot="title" class="el-dialog__header">
  <i style="font-size:20px;">{{$t('audioSettings.title')}}</i>
  </span>
  <span slot="footer" class="dialog-footer">
    <a  class="cancel" @click="cancelAdvanced()">{{$t('audioSettings.cancel')}}</a>
    <el-button  class="save" @click="closeAdvanced()">{{$t('audioSettings.save')}}</el-button>
  </span>
  </el-dialog>
</template>
<script>
import { mapState } from 'vuex'
export default {
  props: {
    info: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  computed: {
    ...mapState({
      State: (state) => state
    })
  },
  data () {
    return {
      checkedAec: '',
      checkedAns: '',
      checkedAgc: ''
    }
  },
  created () {
    this.checkedAec = this.State.checkedAec
    this.checkedAns = this.State.checkedAns
    this.checkedAgc = this.State.checkedAgc
  },
  methods: {
    saveOperation (key, val) {
      localStorage.setItem(`${key}`, val)
    },
    cancelAdvanced () {
      this.info.show = false
      this.State.checkedAec = this.checkedAec
      this.State.checkedAns = this.checkedAns
      this.State.checkedAgc = this.checkedAgc
      localStorage.setItem('checkedAec', this.checkedAec)
      localStorage.setItem('checkedAns', this.checkedAns)
      localStorage.setItem('checkedAgc', this.checkedAgc)
    },
    closeAdvanced () {
      this.info.show = false
      window.location.reload()
    }
  }
}
</script>
<style lang="less" scoped>
.thisblack-bgc{
  background-color: rgba(0, 0, 0, 0.5);
  overflow-y: hidden;
}
/deep/.el-dialog {
 .el-icon-close{
  display:none;
 }
  .el-dialog__header{
    padding:32px 20px 10px 19px;
    }
  .dialog-footer {
    a {
      color: #999;
      margin-left: 30px;
      cursor: pointer;
    }
    .save {
      font-size:14px !important;
      color: #fff;
      padding: 3px 17px 5px;
      background: #03897b;
      border-radius: 5px;
      cursor: pointer;
    }
    .el-button.save {
      border: none;
      padding: 8px 8px;
      margin-left: 30px;
    }
  }
  .el-dialog__footer{
    height:60px;
  }
  background: #28282e;
  .el-dialog__title {
    color: #fff;
  }
  .el-button {
    background: #03897b;
    border: #03897b;
    color: #999;
    font-size: 16px;
  }
  .el-button--primary {
    color: #fff;
  }
  .el-icon-close {
    color: #fff;
  }
  .el-dialog__body {
    color: #fff;
    font-size: 16px;
    padding-top:30px;
    padding-bottom: 15px;
    padding-left: 32px;
    padding-right: 32px;
    .middles{
      display: flex;
      flex-direction: column;
      margin-left: 8px;
      .content{
        display: flex;
        align-items: center;
        margin-top: 16px;
        .contentDetail{
          margin-left: 12px;
          color: #909090;
        }
      }
      .el-checkbox {
          color:#fff !important;
          &.is-checked {
            .el-checkbox__label {
              color: #03897b;
            }
          }
          &.is-disabled {
            .el-checkbox__label {
              color: #909090;
            }
          }
          .el-checkbox__label {
            padding-left: 5px;
          }
          .el-checkbox__input {
            &.is-checked {
              .el-checkbox__inner {
                background: #03897b;
                border: 2px solid #03897b;
                &::after {
                  left: 3px;
                }
              }
            }
            &.is-disabled {
              .el-checkbox__inner {
                background: #909090;
                border: 2px solid #909090;
                &::after {
                  left: 3px;
                }
              }
            }
            .el-checkbox__inner {
              background: none;
              border: 2px solid #666;
            }
          }
        }
    }
  }
}

</style>
