<template>
  <div class="preview">
    <div class="head">
      <div class="logoBox">
        <el-tooltip class="item" effect="dark" placement="bottom">
          <div slot="content">
            <div>Commentator(ENV: {{ Config.applicationName }}):<br />Frontend: {{ fontVersion }}<br />Backend: {{ backendVersion }}</div>
          </div>
          <!-- <img :src="logo" /> -->
          <div class="logoContent">
            <svg class="logoSvg" aria-hidden="true">
              <use :xlink:href="logoSvg"></use>
            </svg>
          </div>
        </el-tooltip>
        <span class="logoText">{{ State.logoTitle }}</span>
      </div>
      <!-- <div class="noLoginLang">
        <div class="pointer common" @click="setTheme(!isDark ? 'dark' : 'light')">
          <span class="theme">
            <i class="iconfont icon-Frame2" v-if="!isDark"></i>
            <i class="iconfont icon-a-Frame11" v-else></i>
          </span>
          <span class="fontTheme">
            <span v-if="!isDark">{{ $t('common.light') }}</span>
            <span v-else>{{ $t('common.dark') }}</span>
          </span>
        </div> -->
      <div class="common langBox" @click="changeLanguage" style="cursor: pointer;">
        <span class="iconLang">
          <i class="iconfont icon-a-V2chinese" v-if="$i18n.locale === 'zh'"></i>
          <i class="iconfont icon-a-V2English" v-else></i>
        </span>
        <span class="fontLang">
          <p v-if="$i18n.locale === 'zh'">中文</p>
          <p v-else>English</p>
        </span>
      </div>
      <!-- </div> -->
    </div>
    <!-- 左侧视频区域 -->
    <div class="contentLeft">
      <div class="videoBox">
        <div class="content">
          <div id="local_stream"></div>
          <div class="notNeedVideo" v-if="checkInfo.owner && (checkInfo.channelModel == 1 || checkInfo.channelModel == 13)">{{ $t('partyInfo.notNeedVideo', { partyModule: checkInfo.channelModel == 1 ? 'Gallery view' : 'Kiosk' }) }}</div>
        </div>
        <div class="mediaControl">
          <!-- 摄像头  Start-->
          <div class="deviceIconBox m_r_70">
            <el-tooltip class="item" effect="dark" :content="turnTips(muteCamera, $t('operationBtn.camera'))" placement="top">
              <i v-if="cameraOn" class="iconfont icon-a-V2cameraon" @click="setCameraOrMic('muteVideo')"></i>
              <i v-if="cameraOff" class="iconfont icon-a-V2cameraoff" :class="cameraOffClass" @click="setCameraOrMic('muteVideo')"></i>
            </el-tooltip>
            <device-select ref="cameraDevice" v-if="devicesInfo.cameraList" :title="$t('operationBtn.selectCamera')" @changeDevice="changeDevice" type="video" :deviceSelectId="devicesInfo.cameraId" :data="devicesInfo.cameraList"/>
          </div>
          <!-- 摄像头  End-->
          <!-- 麦克风  Start-->
          <div class="deviceIconBox m_r_70">
            <el-tooltip class="item" effect="dark" :content="turnTips(muteMic, $t('operationBtn.microphone'))" placement="top">
              <i v-if="!muteMic && devicesInfo.microphoneList" class="iconfont icon-a-V2micon" @click="setCameraOrMic('muteAudio')" ></i>
              <i v-if="!devicesInfo.microphoneList" class="iconfont icon-a-V2micoff" @click="setCameraOrMic('muteAudio')"></i>
              <i v-if="muteMic && devicesInfo.microphoneList" class="iconfont icon-a-V2micoff" @click="setCameraOrMic('muteAudio')"></i>
            </el-tooltip>
            <device-select v-if="devicesInfo.microphoneList" :title="$t('operationBtn.selectMicrophone')" @changeDevice="changeDevice" type="audio" :deviceSelectId="devicesInfo.microphoneId" :data="devicesInfo.microphoneList"/>
          </div>
          <!-- 听筒  Start-->
          <div class="deviceIconBox">
            <el-tooltip class="item" effect="dark" :content="turnTips(isMute, $t('operationBtn.speaker'))" placement="top">
              <i v-if="!isMute" class="iconfont icon-a-V2speakeron" @click="changeMute(true)"></i>
              <i v-else class="iconfont icon-a-V2speakeroff" @click="changeMute(false)"></i>
            </el-tooltip>
            <device-select v-if="devicesInfo.speakerList" :title="$t('operationBtn.selectSpeaker')" @changeDevice="changeDevice" type="speaker" :deviceSelectId="devicesInfo.speakerId" :data="devicesInfo.speakerList"/>
          </div>
        </div>
        <div class="volume">
          <canvas class="volumeLeft"></canvas>
          <canvas class="volumeRight"></canvas>
        </div>
        <p class="initCameraTips" v-if="((!localVideoTrack && !State.showInitCameraTips) || State.initCamera) && devicesInfo.cameraId">{{ $t('HostCommon.initVideoTrack') }}</p>
        <p class="initCameraTips" v-if="State.showInitCameraTips">{{ $t('HostCommon.initCameraTips', { name: State.cameraName }) }}</p>
      </div>
      <div class="testMic">
        <span @click="openTestMic" >{{ $t('HostCommon.testMic') }}</span>
      </div>
    </div>
    <!-- 右侧设置区域 -->
    <div class="contentRight">
      <div class="title">{{ $t('preview.settingTitle') }}</div>
      <!-- 密码 -->
      <div class="passCode" v-if="checkInfo.inviteMode == 3 && !checkInfo.owner">
        <i class="iconfont icon-required red"></i>
        <span class="commonSpan">{{ $t('invite.passCode') }}</span>
        <el-input v-model="password" class="commonInput"></el-input>
      </div>
      <!-- nickName -->
      <div class="nickName">
        <i class="iconfont icon-required red"></i>
        <span class="commonSpan">{{ $t('HostCommon.nickName') }}</span>
        <el-input v-model="splitEmail" class="commonInput"></el-input>
      </div>
      <el-divider class="settingDivider"></el-divider>
      <!-- 会议名称 -->
      <div class="partyName">
        <span class="commonSpan">{{ $t('eventList.eventName') }}</span>
        <el-tooltip class="item" effect="dark" :content="returnPartyID" placement="top">
          <span class="eventName f_14">{{ eventInfo.title }}</span>
        </el-tooltip>
      </div>
      <!-- 会议时间 -->
      <div class="partyName">
        <span class="commonSpan">{{ $t('coordinationRoom.eventTimeInfo') }}</span>
        <el-tooltip class="item" effect="dark" :content="returnPartyID" placement="top">
          <span>
            <span class="eventStartTime f_14">{{ moment(eventInfo.startTime).format('YYYY/MM/DD HH:mm:ss') }}</span> -
            <span class="eventEndTime f_14">{{ moment(eventInfo.endTime).format('YYYY/MM/DD HH:mm:ss') }}</span>
          </span>
        </el-tooltip>
      </div>
      <!-- 组名称 -->
      <div class="partyName">
        <span class="commonSpan">{{ $t('HostCommon.partyName') }}</span>
        <el-tooltip class="item" effect="dark" :content="returnPartyID" placement="top">
          <span class="groupName f_14">{{ groupName }}</span>
        </el-tooltip>
      </div>
      <el-button class="join" @click="jumpPage">{{ $t('preview.joinNow') }}</el-button>
      <div v-if="!checkInfo.owner" class="pointer anywhereBtn hide" @click="dealAnywhereInstall">{{ $t('HostCommon.installAnyWhereText') }}</div>
    </div>
    <!--测试麦克风-->
    <el-dialog
      :title="$t('HostCommon.MicTest')"
      :visible.sync="testMicVisible"
      width="484px"
      @close="closeMic"
      @closed="closedMic"
      :close-on-click-modal="false"
      append-to-body
      custom-class="MicTest"
      >
      <div class="MicContent">
        <span class="title">{{ $t('HostCommon.MicrophoneSelection') }}</span>
        <div class="micSelect">
          <el-select v-model="deviceId"  @change="handleAudio">
            <el-option
              v-for="item in devicesInfo.microphoneList"
              :key="item.deviceId"
              :label="item.label"
              :value="item.deviceId"
              :popper-append-to-body="true">
            </el-option>
          </el-select>
          <button @click="startTesting" :class="{start:!startOrStop,stop:startOrStop}" class="micButton">{{startOrStop ? $t('HostCommon.stopTesting'):$t('HostCommon.startTesting')}}</button>
        </div>
        <div class="volumeContent">
          <span>{{ $t('HostCommon.inputLevel') }}</span>
          <i class="iconfont icon-universal_micon"></i>
          <div class="volume">
            <canvas class="volumeLeft"></canvas>
          </div>
        </div>
        <p class="note">{{ $t('HostCommon.note')}}</p>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Cookie from 'js-cookie'
import { mapState } from 'vuex'
import AgoraClient from '@/assets/js/rtc-client-ng'
import { audioContextObj } from '@/assets/js/audioContext'
import { getMediaDevice, playVideoTrack, createCameraVideoTrack, changeDeviceAndSaveId } from '@/assets/js/agoraFeature.js'
import deviceSelect from '@/components/common/deviceSelect'
import Tool from '@/assets/js/utils.js'

export default {
  data () {
    return {
      memberId: '',
      id: '',
      startOrStop: false,
      deviceId: '',
      fontVersion: '',
      backendVersion: '',
      splitEmail: '',
      testMicVisible: false,
      rtc: null,
      devicesInfo: [],
      muteCamera: false,
      muteMic: false,
      isMute: false,
      nickName: '',
      localVideoTrack: null,
      localAudioTrack: null,
      partyCode: '',
      checkInfo: {},
      commonPartyInfo: {},
      setInfo: {
        speaker: false,
        camera: false,
        mic: false,
        useCamera: false
      },
      organization: '',
      updateTitleUrl: {
        0: '/partyline-backend/commonParty/updateTitle',
        1: '/partyline-backend/group/updateTitle',
        2: '/partyline-backend/conference/updateTitle',
        13: '/partyline-backend/kiosk/updateTitle'
      },
      Parser: require('@/assets/js/us.js'),
      isWindows: true,
      isMac: false,
      openAnywhereTimer: null,
      ontouch: 'ontouchend' in document,
      logoSvg: '',
      eventInfo: {},
      groupName: '',
      moment: require('moment'),
      password: ''
    }
  },
  components: {
    deviceSelect
  },
  computed: {
    ...mapState({
      State: state => state,
      userInfo: (state) => state.loginInfo,
      Config: (state) => state.config
    }),
    returnPartyID () {
      if (!this.partyCode) return
      return this.$t('preview.partyId') + ':  ' + this.$options.filters.returnStrByInfo(this.partyCode, this.partyCode.length === 9 ? 3 : 4)
    },
    cameraOn () {
      let cameraOn = true
      if (this.checkInfo.owner) {
        // 特殊处理 gallery view 和 kiosk
        if (this.checkInfo.channelModel == 1 || this.checkInfo.channelModel == 13) {
          cameraOn = false
          this.muteCamera = true
        } else {
          if (this.muteCamera || !this.devicesInfo.cameraList || !this.devicesInfo.cameraList.length) {
            cameraOn = false
          }
        }
      } else {
        if (this.muteCamera || !this.devicesInfo.cameraList || !this.devicesInfo.cameraList.length) {
          cameraOn = false
        }
      }
      return cameraOn
    },
    cameraOff () {
      let cameraOff = false
      if (this.checkInfo.owner) {
        if (this.checkInfo.channelModel == 1 || this.checkInfo.channelModel == 13) {
          cameraOff = true
        } else {
          if (this.muteCamera || !this.devicesInfo.cameraList || !this.devicesInfo.cameraList.length) {
            cameraOff = true
          }
        }
      } else {
        if (this.muteCamera || !this.devicesInfo.cameraList || !this.devicesInfo.cameraList.length) {
          cameraOff = true
        }
      }
      return cameraOff
    },
    cameraOffClass () {
      let _class = ''
      if (this.checkInfo.owner && (this.checkInfo.channelModel == 1 || this.checkInfo.channelModel == 13)) _class = 'disabled'
      return _class
    }
  },
  watch: {
    deviceSelectId (val) {
      // this.handleDefault()
      this.deviceId = this.devicesInfo.microphoneId
    },
    startOrStop (val) {
      if (val == true && this.localAudioTrack) {
        this.localAudioTrack.play()
      } else if (val == false) {
        if (!this.localAudioTrack) return
        this.localAudioTrack.setEnabled(false)
        this.localAudioTrack.stop()
      }
    }
  },
  async created () {
    document.title = this.$t('HostCommon.productTitle')
    const myUrl = new URL(location.href, location.origin)
    this.partyCode = myUrl.searchParams.get('id') || myUrl.searchParams.get('join_id') || ''
    // this.getMediaDeviceStatus()
    this.rtc = new AgoraClient(this.agoraCallback)

    // 获取前端版本号
    let fontEndVersionStr = localStorage.getItem('partylineCommentatorVersion')
    if (fontEndVersionStr.includes('_')) {
      fontEndVersionStr = fontEndVersionStr.substring(0, fontEndVersionStr.indexOf('_'))
    }
    this.fontVersion = 'Front' + fontEndVersionStr
    // 获取后台版本号
    await this.$store.dispatch('getBackEndVersion')
    let backEndVersionStr = localStorage.getItem('backendVersion')
    if (backEndVersionStr.includes('_')) {
      backEndVersionStr = backEndVersionStr.substring(0, backEndVersionStr.indexOf('_'))
    }
    this.backendVersion = 'Backend' + backEndVersionStr
    const parserObj = new this.Parser()
    this.isMac = parserObj.getOS().name === 'Mac OS'
    this.isWindows = parserObj.getOS().name === 'Windows'
    this.getEeventInfo()
  },
  async mounted () {
    this.logoSvg = `#${this.State.commonLogo}`
    await this.checkIsHost()
    this.State.newPageLoading = false
    this.devicesInfo = await getMediaDevice(this.rtc)
    this.deviceId = this.devicesInfo.microphoneId
    // if (!this.checkInfo.owner || (this.checkInfo.owner && this.checkInfo.channelModel != 1 && this.checkInfo.channelModel != 13)) {
    if (this.devicesInfo.cameraId && this.rtc) {
      try {
        this.devicesInfo && this.devicesInfo.cameraList.forEach(item => {
          if (item.deviceId == this.devicesInfo.cameraId) {
            this.State.cameraName = item.label
          }
        })
        this.localVideoTrack = await this.rtc._AgoraRTC.createCameraVideoTrack({
          cameraId: this.devicesInfo.cameraId, // 指定摄像头
          encoderConfig: {
            // 视频分辨率
            width: 640,
            height: 360
          },
          AgoraRTC: this.rtc._AgoraRTC
        })
      } catch (error) {
        this.State.initCamera = false
        this.State.showInitCameraTips = true
      }
      if (this.localVideoTrack) {
        this.State.showInitCameraTips = false
        await playVideoTrack(this.localVideoTrack)
        this.devicesInfo.cameraId = this.localVideoTrack._config.cameraId
      }
    }
    // }
    // 初始化麦克风声音
    if (this.deviceId) {
      this.localAudioTrack = await this.rtc._AgoraRTC.createMicrophoneAudioTrack({
        AEC: true, // 回声消除
        ANS: true, // 噪声抑制
        microphoneId: this.devicesInfo.microphoneId // 指定麦克风
      })
      if (this.localAudioTrack) {
        const media = new MediaStream()
        media.addTrack(this.localAudioTrack._mediaStreamTrack)
        audioContextObj.audioTrack(media, 'volume')
        setTimeout(() => {
          audioContextObj.resumeAudio('volume')
        }, 1000)
      }
    }
  },
  methods: {
    changeLanguage () {
      if (this.$i18n.locale === 'zh' || this.$i18n.locale === 'zh-CN') {
        Cookie.set('userLanguage', 'en', { domain: '.tvunetworks.com' })
        this.$i18n.locale = 'en'
        this.State.browserLanguage = 'en'
      } else {
        Cookie.set('userLanguage', 'zh', { domain: '.tvunetworks.com' })
        this.$i18n.locale = 'zh'
        this.State.browserLanguage = 'zh'
      }
    },
    getEeventInfo () {
      this.$http
        .post(`/commentator-backend/event/party/noLogin/${this.partyCode}`, {}, { errorTips: false })
        .then(res => {
          if (res.data.errorCode === '0x0') {
            this.eventInfo = res.data.result
            this.eventInfo.eventParties && this.eventInfo.eventParties.forEach(partyInfo => {
              if (partyInfo.partyCode == this.partyCode) {
                this.groupName = partyInfo.title
              }
            })
          } else if (
            res.data.errorCode === '82201504' ||
            res.data.errorCode === '82201505' ||
            res.data.errorCode === '82201506'
          ) {
            this.$router.push({
              name: 'remove',
              query: {
                type: 'disableParty'
              }
            })
          }
        })
    },
    dealAnywhereInstall () {
      this.$router.push({
        name: 'joinParty',
        query: {
          join_id: this.partyCode
        }
      })
    },
    closeMic () {
      this.startOrStop = false
    },
    // 处理关闭弹窗打开音量柱
    async closedMic () {
      if (this.localAudioTrack) {
        await this.localAudioTrack.setEnabled(true)
        const media = new MediaStream()
        media.addTrack(this.localAudioTrack._mediaStreamTrack)
        audioContextObj.audioTrack(media, 'volume')
        setTimeout(() => {
          audioContextObj.resumeAudio('volume')
        }, 1000)
      }
    },
    async startTesting () {
      this.startOrStop = !this.startOrStop
      if (this.startOrStop == true) {
        if (this.localAudioTrack) {
          await this.localAudioTrack.setEnabled(true)
          const media = new MediaStream()
          media.addTrack(this.localAudioTrack._mediaStreamTrack)
          audioContextObj.audioTrack(media, 'volume')
          setTimeout(() => {
            audioContextObj.resumeAudio('volume')
          }, 1000)
        }
      }
    },
    async handleAudio (val) {
      this.startOrStop = false
      this.id = val
      // 同步视频流上mic设备
      this.devicesInfo.microphoneId = val
      // const key = 'microphoneId'
      // localStorage.setItem(key, val)
      // 初始化麦克风声音
      this.localAudioTrack = await this.rtc._AgoraRTC.createMicrophoneAudioTrack({
        AEC: true, // 回声消除
        ANS: true, // 噪声抑制
        microphoneId: val // 指定麦克风
      })
      await this.localAudioTrack.setDevice(val)
    },
    openTestMic () {
      this.testMicVisible = true
      if (this.localAudioTrack) {
      // 不显示音量柱
        this.localAudioTrack.setEnabled(false)
        this.localAudioTrack.stop()
      }
    },
    async checkIsHost () {
      this.State.newPageLoading = false
      await this.$http
        .get(`${this.Config.urlInfo.partyline}/partyline-backend/party/noLogin/check`, {
          params: { partyCode: this.partyCode, clientType: 0 },
          timeout: 10000,
          errorTips: false
        })
        .then(res => {
          if (res.data.errorCode === '0x0') {
            const result = res.data.result
            this.checkInfo = result
            // 判断当前环境是不是和check接口给的一致,默认设置需要更新环境
            result.updateEnv = true
            // 获取当前域名下的所有cookie
            const cookieList = Cookie.get()
            Object.keys(cookieList).forEach(key => {
              if (result.commentatorTagKey == key && result.commentatorTagValue == cookieList[key]) {
                result.updateEnv = false
              }
            })

            if (!result.commentatorTagKey || !result.commentatorTagValue) {
              result.updateEnv = false
            }
            // 判断如果当前isLogin为false，则清除session
            if (!result.login) {
              Cookie.remove('SID', { domain })
            }
            // remove tag cookies
            result.commentatorTagList && result.commentatorTagList.forEach(v => {
              if (v.tagKey != result.tagKey || v.tagValue != result.tagValue) {
                Tool.setTagCookie(v.tagKey, v.tagValue, -1)
              }
            })
            result.tagList && result.tagList.forEach(v => {
              if (v.tagKey != result.tagKey || v.tagValue != result.tagValue) {
                Tool.setTagCookie(v.tagKey, v.tagValue, -1)
              }
            })
            // set Tag Cookies
            Tool.setTagCookie(result.tagKey, result.tagValue)
            Tool.setTagCookie(result.commentatorTagKey, result.commentatorTagValue)
            if (result.updateEnv) {
              window.location.reload()
            }
          } else if (res.data.errorCode == '80520103') {
            const userServerUrl = this.Config.urlInfo.userservice
            window.location.href = `${userServerUrl}/?url=${location.protocol}//${location.host}/commentator?join_id=${this.partyCode}&module=commentator&byLogin=true&serviceName=Commentator`
          }
        })
    },
    getMediaDeviceStatus () {
      if (!this.partyCode) return
      const params = {
        originRtilCode: this.userInfo.rtilCode,
        annotation: this.partyCode || this.State.partyCode,
        keys: [
          'nickName',
          'tagName',
          'talkBack',
          'camera',
          'mic',
          'blockAudio',
          'blockVideo',
          'voipRid',
          'voipSourceId',
          'flipVideo'
        ],
        marks: [id]
      }
      this.$http
        .get('/partyline-backend/behavior/noLogin/getList', { params }, { errorTips: false })
        .then(res => {})
    },
    turnTips (flag, tag) {
      // 无摄像头
      let msg = ''
      if ((tag === 'camera' && !this.devicesInfo.cameraId) || (tag === 'microphone' && !this.devicesInfo.microphoneId)) {
        msg = 'No access'
      } else {
        const onOrOff = flag ? this.$t('operationBtn.on') : this.$t('operationBtn.off')
        msg = `${onOrOff} ${tag}`
      }
      return msg
    },
    async setCameraOrMic (type) {
      if (type === 'muteVideo') {
        if (this.muteCamera && (this.checkInfo.channelModel == 1 || this.checkInfo.channelModel == 13) && this.checkInfo.owner) {
          return
        }
        this.muteCamera = !this.muteCamera
        if (this.localVideoTrack) {
          this.muteCamera ? this.localVideoTrack.stop() : this.localVideoTrack.play('local_stream', { mirror: true })
        }
      } else {
        this.muteMic = !this.muteMic
        if (this.muteMic && !this.isMute) {
          this.localAudioTrack && this.localAudioTrack.setEnabled(false)
        } else if (!this.muteMic && !this.isMute) {
          await this.localAudioTrack.setEnabled(true)
          if (this.localAudioTrack) {
            const media = new MediaStream()
            media.addTrack(this.localAudioTrack._mediaStreamTrack)
            audioContextObj.audioTrack(media, 'volume')
            setTimeout(() => {
              audioContextObj.resumeAudio('volume')
            }, 1000)
          }
        }
      }
    },
    async changeDevice (val) {
      if (val.type === 'video') {
        this.State.initCamera = true
        this.State.showInitCameraTips = false
      }
      const params = {
        type: val.type,
        deviceId: val.deviceId,
        track: val.type === 'audio' ? this.localAudioTrack : val.type === 'video' ? this.localVideoTrack : '',
        devicesInfo: this.devicesInfo
      }
      if (val.type === 'video') {
        if (this.localVideoTrack) {
          const lastDeviceId = this.localVideoTrack._config.cameraId
          changeDeviceAndSaveId(params, (result) => {
            const { type } = result
            if (type !== 'success') {
              this.$refs.cameraDevice.changeDeviceId(lastDeviceId)
              this.$message.warning({
                message: this.$t('HostCommon.switchFailed'),
                type: 'warning',
                offset: 50,
                showClose: true
              })
            }
            this.State.showInitCameraTips = false
            this.State.initCamera = false
          })
        } else {
          this.localVideoTrack = await createCameraVideoTrack({
            cameraId: val.deviceId,
            config: {},
            AgoraRTC: this.rtc._AgoraRTC
          })
          if (this.localVideoTrack) {
            playVideoTrack(this.localVideoTrack)
          } else {
            this.devicesInfo && this.devicesInfo.cameraList.forEach(item => {
              if (item.deviceId == val.deviceId) {
                this.State.cameraName = item.label
                this.State.showInitCameraTips = true
              }
            })
          }
          this.State.initCamera = false
        }
      } else {
        if (this.localAudioTrack) {
          changeDeviceAndSaveId(params, (result) => {
            const { type } = result
            if (type === 'success') {
              const media = new MediaStream()
              media.addTrack(this.localAudioTrack._mediaStreamTrack)
              audioContextObj.audioTrack(media, 'volume')
              setTimeout(() => {
                audioContextObj.resumeAudio('volume')
              }, 1000)
            }
          })
        } else {
          this.localAudioTrack = await createMicrophoneAudioTrack({
            rtc: this.rtc,
            config: {
              AEC: true, // 回声消除
              ANS: true, // 噪声抑制
              microphoneId: val.deviceId
            }
          })
        }
      }
    },
    changeMute () {
      this.isMute = !this.isMute
    },
    async agoraCallback ({ action, payload }) {
      const { changeDevice } = payload
      switch (action) {
        case 'cameraChange':
          if (!this.rtc) return
          this.devicesInfo = await this.rtc.getDevice()
          if (this.localVideoTrack && changeDevice.device.label === this.localVideoTrack.getTrackLabel()) {
            const oldCameras = await this.rtc._AgoraRTC.getCameras()
            oldCameras[0] && this.rtc.setDevice(oldCameras[0].deviceId, 'video', (returnType) => {
              this.devicesInfo.cameraId = oldCameras[0].deviceId
              returnType === 'success' ? this.$message.success(this.$t('common.autoSwitchNewCameraSuccess')) : this.$message.error(this.$t('common.autoSwitchNewCameraFailed'))
            }, this.localVideoTrack)
          }
          break
        case 'micChange':
          if (!this.rtc) return
          this.startOrStop = false
          this.devicesInfo = await this.rtc.getDevice()
          if (this.localAudioTrack &&
              (
                (this.localAudioTrack._deviceName.includes(changeDevice.device.label)) ||
                (changeDevice.state === 'INACTIVE' && this.localAudioTrack._config.microphoneId == 'default')
              )) {
            const oldMic = await this.rtc._AgoraRTC.getMicrophones()
            oldMic[0] && this.rtc.setDevice(oldMic[0].deviceId, 'audio', (returnType) => {
              returnType === 'success' ? this.$message.success(this.$t('common.autoSwitchNewMicSuccess')) : this.$message.error(this.$t('common.autoSwitchNewMicFailed'))
              this.devicesInfo.microphoneId = oldMic[0].deviceId
              this.deviceId = oldMic[0].deviceId
              this.startOrStop = false
            }, this.localAudioTrack)
          } else if (changeDevice.state === 'ACTIVE' &&
            this.localAudioTrack && !this.localAudioTrack._deviceName.includes(changeDevice.device.label) &&
            this.localAudioTrack._config.microphoneId == 'default') {
            // 插入mic
            this.localAudioTrack && this.localAudioTrack.setEnabled(false)
            this.localAudioTrack && this.localAudioTrack.setEnabled(true)
            if (this.devicesInfo.microphoneList[0].label != this.localAudioTrack._deviceName) { this.$message.success(this.$t('common.autoSwitchNewMicSuccess')) }
          }
          break
      }
    },
    async jumpPage () {
      if (!this.judgeCanJump()) return
      const saveSuceess = await this.saveNickName()
      if (!saveSuceess) return
      this.$router.push({
        name: 'coordinationParticipate',
        query: {
          id: this.partyCode
        },
        params: {
          password: this.password
        }
      })
    },
    judgeCanJump () {
      let flag = true
      if (!this.partyCode) {
        this.$message.error(this.$t('preview.noPartyCode'))
        flag = false
      }
      if (this.checkInfo.owner) {
        if (((this.checkInfo.channelModel == 0 || this.checkInfo.channelModel == 2) && !this.splitEmail)) {
          this.$message.error(this.$t('preview.nameCannotEmpty'))
          flag = false
        }
      } else {
        if (!this.splitEmail) {
          this.$message.error(this.$t('preview.nameCannotEmpty'))
          flag = false
        }
      }
      return flag
    },
    async saveNickName () {
      this.splitEmail = this.splitEmail.replace(/(^\s*)|(\s*$)/g, '')
      let data = []
      let flag = true
      if (this.devicesInfo.cameraId && !this.muteCamera) {
        this.setInfo.camera = true
      } else {
        this.setInfo.camera = false
      }
      if (this.devicesInfo.microphoneId && !this.muteMic) {
        this.setInfo.mic = true
      } else {
        this.setInfo.mic = false
      }
      this.setInfo.speaker = !this.isMute
      this.setInfo.noHaveCamera = !this.devicesInfo.cameraId
      data = [
        {
          mark: '',
          annotation: this.partyCode,
          key: 'nickName',
          value: this.splitEmail,
          notify: true,
          initiator: ''
        },
        {
          mark: '',
          annotation: this.partyCode,
          key: 'speaker',
          value: this.setInfo.speaker,
          notify: true,
          initiator: ''
        },
        {
          mark: '',
          annotation: this.partyCode,
          key: 'camera',
          value: this.setInfo.camera,
          notify: true,
          initiator: ''
        },
        {
          mark: '',
          annotation: this.partyCode,
          key: 'useCamera',
          value: this.setInfo.useCamera,
          notify: true,
          initiator: ''
        },
        {
          mark: '',
          annotation: this.partyCode,
          key: 'mic',
          value: this.setInfo.mic,
          notify: true,
          initiator: ''
        },
        {
          mark: '',
          annotation: this.partyCode,
          key: 'noHaveCamera',
          value: this.setInfo.noHaveCamera,
          notify: true,
          initiator: ''
        }
      ]
      const sid = Tool.getSession();
      if (sid) {
        this.memberId = localStorage.getItem('particiapntMemberId') || new Date().getTime();
      } else {
        this.memberId = 'commentator_' + new Date().getTime();
      }
      const params = {
        memberId: this.memberId,
        partyCode: this.partyCode,
        role: 0,
        type: 3,
        host: this.checkInfo.owner,
        behaviorKeyList: [
          'hearSelf',
          'speaker',
          'nickName',
          'mic',
          'camera',
          'audioOnlyType',
          'checkAudioMainWindow',
          'checkAudioShare',
          'enableControlMedia',
          'flipVideo'
        ],
        behaviors: data,
        invitePassCode: this.password
      }
      await this.$http
        .post(`${this.State.config.urlInfo.partyline}/partyline-backend/party/noLogin/join`, params, { errorTips: false })
        .then(async (res) => {
          if (res.data.errorCode != '0x0') {
            flag = false
            if (res.data.errorCode === '80520113') {
              this.$message.error(this.$t('preview.partyDisabled'))
            } else if (res.data.errorCode === '82201504') {
              this.$message.warning(this.$t('preview.partyIdNotExist'))
            } else if (
              res.data.errorCode === '82201504' ||
              res.data.errorCode === '82201505' ||
              res.data.errorCode === '82201506'
            ) {
              this.$router.push({
                name: 'remove',
                query: {
                  type: 'disableParty'
                }
              })
            } else {
              this.$message.error(res.data.errorInfo)
            }
          }
          localStorage.setItem('particiapntMemberId', params.memberId)
        })
      return flag
    }
  },
  async beforeRouteLeave (to, from, next) {
    this.localVideoTrack && await this.localVideoTrack.setEnabled(false)
    this.localAudioTrack && await this.localAudioTrack.setEnabled(false)
    setTimeout(() => {
      next()
    }, 1000)
  }
}
</script>
<style lang="less" scoped>
/deep/.MicTest {
  margin:0 !important;
  position:absolute;
  top:50%;
  left:50%;
  transform:translate(-50%,-50%);
  background: #FFFFFF;
  border-radius: 8px;
  .el-dialog__title{
    font-weight: 700;
    color: #28282E;
  }
  .el-dialog__body{
      border:0px;
    .MicContent{
      border-top: 2px solid #f5f5f5;
      margin-top: -18px;
      .note{
        word-break: normal;
      }
      .title {
          color: #28282E;
          font-size: 16px;
          margin-top: 12px;
          display: block;
      }
      .micSelect {
        margin-top: 19px;
        display: flex;
        justify-content: space-between;
        .micButton {
          width: 96px;
          height: 32px;
          padding: 0px !important;
          cursor: pointer;
          &.start{
            color: #33AB4F;
            background: #FFFFFF;
            border: 1px solid #33AB4F;
            border-radius: 4px;
          }
          &.stop{
          color: #F6445A;
          background: #FFFFFF;
          border: 1px solid #F6445A;
          border-radius: 4px;
          }
        }
        .el-popper {
          background: #28282e;
        }
        .el-select .el-input .el-select__caret {
          color :#454549;
        }
        .el-input__inner {
          text-overflow: ellipsis;
          background: #F0F0F0;
          color : #555661;
          font-weight: 500;
        }
          .el-select{
            width:322px;
            height: 32px;
          .el-select-dropdown__item {
            text-indent: 20px;
            &:hover {
              color: #00cc66;
            }
            &.selected {
              color: #00cc66;
              font-weight: 700;
            }
          }
        }
      }
      .volumeContent {
        margin-bottom: 24px;
        margin-top: 19px;
        display:flex;
        position:relative;
        span{
          display: inline-block;
        }
        .icon-universal_micon{
          display: inline-block;
          margin-top: 2px;
          margin-left: 8px;
        }
        .volume {
          display: flex;
          height: 40%;
          position: absolute;
          bottom: 3px;
          left: 312px;
          transform:rotate(90deg);
          .volumeLeft  {
            height:230px;
            width: 9px;
            background: #D8D8D8;
            margin-right: 2px;
            border-radius: 2px;
          }
        }
      }
    }
  }
  .el-dialog__headerbtn:focus .el-dialog__close, .el-dialog__headerbtn:hover .el-dialog__close{
    color:#33AB4F !important;
  }
}
.preview {
  display: flex;
  justify-content: center;
  padding-top: 12%;
  background: #28282e;
  height: 100%;
  .head {
    position: fixed;
    top: 48px;
    color: #32a04b;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    .common {
      display: flex;
      align-items: center;
    }
    .theme {
      margin-right: 5px;
    }
    .fontTheme {
      height: 25px;
      margin-right: 25px;
    }
    .iconLang {
      display: inline-block;
    }
    .fontLang {
      display: inline-block;
      margin-left: 5px;
      height: 25px;
    }
    i {
      font-size: 24px;
    }
    .logoBox {
      display: flex;
      align-items: center;
      margin-left: 48px;
      .logoContent{
        .logoSvg {
          width: 32px;
          height: 32px;
          margin-right: 16px;
        }
      }
      .logoText {
        font-weight: 700;
        font-size: 16px;
        line-height: 18px;
        color: var(--font-color);
      }
    }
    .langBox {
      margin-right: 48px;
    }
  }
  .contentLeft {
    width: 42%;
    .videoBox {
      width: 100%;
      height: 0;
      padding-bottom: 56.25%;
      position: relative;
      .content {
        width: calc(100% - 2px);
        height: 100%;
        position: absolute;
        #local_stream {
          height: 100%;
          background: #000;
          border-radius: 22px;
        }
        .notNeedVideo {
          position: absolute;
          color: var(--font-color);
          width: 100%;
          height: 100%;
          top: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: 500;
          font-size: 16px;
        }
      }
      .previewVideo {
        border-radius: 22px;
        width: 100%;
        height: 100%;
      }
      .mediaControl {
        position: absolute;
        bottom: 28px;
        width: 100%;
        height: 40px;
        display: flex;
        justify-content: center;
        .iconfont {
          display: inline-block;
          width: 36px;
          height: 36px;
          vertical-align: middle;
          font-size: 38px;
          line-height: 36px;
          border: 2px solid #fff;
          border-radius: 50%;
          cursor: pointer;
        }
        .icon-a-V2micoff:before,.icon-a-V2micon:before{
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .icon-a-V2cameraoff, .icon-a-V2micoff, .icon-a-V2speakeroff {
          background: #F6445A;
          border-color: #F6445A;
          &.disabled {
            cursor: not-allowed;
            opacity: .7;
          }
        }
        .deviceIconBox {
          /deep/.el-input .el-input__suffix-inner {
            height: 40px;
            margin-top: 0;
          }
          /deep/ .el-select-dropdown {
            bottom: 32px!important;
          }
          /deep/.popper__arrow {
            display: none!important;
          }
          /deep/.el-select-dropdown {
            background: #28282e;
            border: none;
            text-align: left;
            left: 0 !important;
            position: absolute !important;
            top: 20px;
            .titleBox {
              line-height: 40px;
            }
            .el-select-dropdown__item {
              color: #fff;
              font-weight: 700;
              &.selected {
                color: #00cc66;
                background: none;
                background: rgba(0, 0, 0, 0.3);
              }
              &.hover {
                background: rgba(0, 0, 0, 0.3);
              }
              &.is-disabled {
                color: #999;
                &:hover {
                  background: none;
                }
              }
            }
            .el-select-group__wrap {
              &:not(:last-of-type) {
                &::after {
                  background: #28282b;
                }
              }
            }
          }
          /deep/ .el-popper .popper__arrow {
            display: none;
          }
          /deep/ .el-input__icon {
            line-height: 30px;
          }
        }
      }
      .volume {
        position: absolute;
        bottom: 28px;
        left: 24px;
        display: flex;
        height: 40%;
        .volumeLeft, .volumeRight  {
          width: 3px;
          background: rgba(255, 255, 255, .3);
          margin-right: 2px;
          border-radius: 2px;
        }
      }
      .initCameraTips {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
    .testMic{
      margin-top: 16px;
      span {
        cursor:pointer;
        font-weight: 700;
        font-size: 16px;
        font-size: 16px;
        color: #33AB4F;
      }
    }
    .userGuide {
      font-weight: 500;
      font-size: 16px;
      margin-top: 12px;
      .guide {
        display: flex;
        text-decoration: none;
        .icon-a-Vector1 {
          margin-right: 5px;
          width: 20px;
          height: 20px;
        }
      }
    }
  }
  .contentRight {
    color: var(--font-color);
    margin-left: 4%;
    .title {
      font-size: 28px;
      line-height: 34px;
      font-weight: bold;
      text-align: center;
      margin-bottom: 64px;
    }
    .partyName, .passCode, .nickName {
      display: flex;
      align-items: center;
      margin-bottom: 24px;
      position: relative;
      .icon-required {
        position: absolute;
        left: -15px;
        font-size: 12px;
      }
      .commonSpan {
        display: inline-block;
        width: 120px;
        font-size: 16px;
        font-weight: bold;
      }
      .el-input.commonInput {
        /deep/.el-input__inner {
          height: 56px;
          line-height: 56px;
          background: #454549;
          border-radius: 28px;
          border: 0;
          color: #f1f1f1;
        }
      }
    }
    .settingDivider {
      background: #555454;
    }
    .foot {
      .nameBox {
        margin-bottom: 24px;
        .nameCommonSpan {
          display: inline-block;
          width: 100px;
        }
      }
      .leftMode {
          display: inline-block;
          width: 100px;
      }
      span {
        font-size: 16px;
      }
      span:nth-child(1) {
        font-weight: bold;
        margin-right: 5px;
        &.grayColor {
          font-size: 12px;
          font-weight: 400;
        }
      }
      span:nth-child(2) {
        font-weight: 400;
        // margin-right: 15px;
      }
      span:nth-child(3) {
        font-weight: 400px;
        color: #6d6d6d;
        margin-right: 5px;
      }
      span:nth-child(4) {
        font-weight: 400;
      }
    }
    .join {
      width: 100%;
      height: 48px;
      background: #33AB4F;
      border-radius: 4px;
      color: #fff;
      border: none;
      &:focus, &:hover {
        background-color: #32a04b;
      }
    }
    .anywhereBtn {
      font-size: 14px;
      font-weight: 500;
      color: #33AB4F;
      text-align: center;
      margin-top: 24px;
    }
  }
}
</style>
