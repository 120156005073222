<template>
  <div class="eventList">
    <!-- 新增需求---下拉选择框 -->
    <div class="eventListAll">
      <div class="type-select-box-eventList">
        <el-select
          v-model="value"
          placeholder="please select"
          @change="showSelected"
          :popper-append-to-body = "false"
          popper-class="testSelect"
        >
          <el-option
            v-for="item in options"
            :key="item.index"
            :label="item.label | showSelectedItems"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
    </div>
    <!-- 新增分页需求 -->
    <div class="eventListContent">
      <div class="new_pagination">
        <el-pagination
          popper-class="edit_scroll"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-sizes="[10, 15, 20, 30]"
          :page-size="params.pageSize"
          :current-page="params.pageNum"
          layout="prev, pager, next, slot, sizes"
          :total="params.total"
        >
        <span class="PageSize">{{$t('eventList.pageSize')}} </span>
        </el-pagination>
      </div>
      <div class="create">
        <el-button class="right record_icon" type="primary" size="small"
          ><a :href="downloadHref()" target="_blank">{{$t('eventList.cloudRecord')}}</a></el-button
        >
        <el-button v-show="showCentral && initConfigShow" @click="jumpToNewAudioMixer" class="right record_icon" type="primary" size="small">
          <a>{{$t('eventList.central')}}</a>
        </el-button>
        <el-button class="right" type="primary" size="small" @click="createFn"
          >{{$t('eventList.create')}}</el-button
        >
      </div>
    </div>
    <div class="list" v-loadings="scrollS.loading">
      <el-table
        :data="eventList"
        :row-class-name="addClass"
        :empty-text="$t('eventList.noData')"
        style="width: 100%;"
      >
        <el-table-column prop="title" :label="$t('eventList.eventName')" min-width="11%" >
          <template slot-scope="scope">
           <el-tooltip
              class="item"
              effect="dark"
              placement="top"
            >
              <div slot="content" @click="changeSnCopy(scope.row.id)">
                <span style="color:#909090;">{{ $t('eventList.eventId')}}</span>
                <span style="margin-left:3px;">{{scope.row.id}}</span>
              </div>
              <div class="overflow">{{ scope.row.title }}</div>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column prop="time" :label="$t('eventList.time')" min-width="20%" show-overflow-tooltip>
          <template slot-scope="scope">
            {{ scope.row.startTime | FormatTime('YYYY-MM-DD HH:mm') }} -
            {{ scope.row.endTime | FormatTime('YYYY-MM-DD HH:mm') }}
          </template>
        </el-table-column>
         <el-table-column prop="" :label="$t('eventList.status')" min-width="12%" show-overflow-tooltip>
            <template slot-scope="scope">
               <!--倒计时-->
              <div class="countDown" v-if="(scope.row.status === 4) && (myCountDown[scope.row.id] ? myCountDown[scope.row.id].show : false)" >
                <span class="triangle"></span>
                <span>{{$t('eventList.countDown')}}</span>&nbsp;
                <span class="mins">{{myCountDown[scope.row.id] ? myCountDown[scope.row.id].mins : ''}}</span>
                <span class="dots">:</span>
                <span class="seconds">{{myCountDown[scope.row.id] ? myCountDown[scope.row.id].seconds : ''}}</span>
              </div>
              <div v-else>
                {{ (statusInfo[scope.row.status] && statusInfo[scope.row.status].text) | showSelectedItems}}
                <el-tooltip
                  v-show="(scope.row.status===4 && scope.row.errorLogs) || scope.row.status===7 || (scope.row.status===10 && scope.row.manualStopTime)"
                   effect="dark" placement="right" :popper-class="addClass(scope)">
                  <div slot="content" style="max-width: 303px;">
                    <p v-show="scope.row.status===4||scope.row.status===7" v-for="(val, key) in scope.row.errorLogs" :key="key">
                      - {{val}}
                    </p>
                    <p v-show="scope.row.status===10">
                      <span>{{$t('eventList.stoppedTime')}}</span>
                      <span>{{scope.row.manualStopTime | FormatTime('YYYY-MM-DD HH:mm')}}</span>
                    </p>
                  </div>
                  <i class="iconfont icon-help2"></i>
                </el-tooltip>
              </div>
            </template>
        </el-table-column>
        <!-- 新增input -->
        <el-table-column prop="" :label="$t('eventList.input')" min-width="8%" show-overflow-tooltip>
          <template slot-scope="scope">
           <!-- <el-tooltip
              class="item"
              effect="dark"
              :content="scope.row | chooseMainType"
              placement="top"
            >-->
              <div class="overflow">{{ scope.row | chooseMainType }}</div>
           <!-- </el-tooltip>-->
          </template>
        </el-table-column>
        <!-- 新增output列 -->
        <el-table-column prop="" :label="$t('eventList.output')" min-width="8%" show-overflow-tooltip >
          <template slot-scope="scope">
            {{
             (!scope.row.eventOutputList ? 0 : scope.row.eventOutputList.length) ||(scope.row.platform == 1 ? 1: 0)
            }}
          </template>
        </el-table-column>
        <el-table-column prop="" :label="$t('eventList.groupNum')" min-width="8%" show-overflow-tooltip >
          <template slot-scope="scope">
            <span class="groupNum pointer" @click="showGroupList(scope.row.eventParties,scope.row.status,scope.row)">{{ scope.row.eventParties ? scope.row.eventParties.length : 0 }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="address" min-width="16%" :label="$t('eventList.operate')" class="operate" show-overflow-tooltip>
          <template slot-scope="scope" >
          <span style="margin-left:15px;">
            <span
              class="btn"
              @click.stop="editFn(scope, 'edit')"
              v-if="
                scope.row.status != 7 &&
                  scope.row.status != 8 &&
                  scope.row.status != 10
              "
              >{{$t('leftPaneOutPut.edit')}}</span
            >
            <span
              class="btn"
              @click.stop="editFn(scope, 'view')"
              v-if="
                scope.row.status == 7 ||
                  scope.row.status == 8 ||
                  scope.row.status == 10
              "
              >{{$t('eventList.view')}}</span
            >
            <span
              class="btn"
              @click.stop="stopEvent(scope)"
              v-if="
                scope.row.status == 4 ||
                  scope.row.status == 5 ||
                  scope.row.status == 6
              "
              >{{$t('eventList.stop')}}</span
            >
            <!-- <span class="btn" v-repeat-click="{event:deleteFn, params:[scope]}" v-if="scope.row.status != 4 && scope.row.status != 5 && scope.row.status != 6">Delete</span> -->
            <span
              class="btn"
              @click="deleteEvent(scope)"
              v-if="
                scope.row.status != 4 &&
                  scope.row.status != 5 &&
                  scope.row.status != 6
              "
              >{{$t('leftPane.del')}}</span
            >
            </span>
          </template>
        </el-table-column>
        <el-table-column label-class-name="serviceEntry" prop="service" min-width="15%" :label="$t('eventList.serviceEntry')">
          <template slot-scope="scope">
            <div class="buttons">
              <el-tooltip
                class="item"
                effect="dark"
                :content="$t('producer')"
                placement="top"
                v-if="scope.row.productionId && scope.row.status == 4"
                >
                <a :href="Config.urlInfo.producerStudioUrl + scope.row.productionId" target="_blank">
                  <i class="iconfont icon-PP"></i><br/>
                </a>
              </el-tooltip>
              <el-tooltip
                class="item"
                effect="dark"
                :content="$t('mixer')"
                placement="top"
                v-if="scope.row.audioMixTaskId && scope.row.status == 4"
              >
                <a :href="Config.urlInfo.audioMixerUrl + '/' + scope.row.audioMixTaskId" target="_blank">
                  <i class="iconfont icon-a-Audiomixer"></i><br/>
                </a>
              </el-tooltip>
              <el-tooltip
                class="item"
                effect="dark"
                :content="$t('coordiRoom')"
                placement="top"
                v-if="scope.row.status <= 4"
              >
                <a  href="javascript:void(0);" @click="checkHostJoin(scope.row)">
                  <i class="iconfont icon-a-coordinationroom"></i><br/>
                </a>
              </el-tooltip>
             <!--<el-tooltip
                class="item"
                effect="dark"
                :content="$t('tudn')"
                placement="top"
                v-if="scope.row.productionId && scope.row.status == 4"
              >
                <a :href="Config.urlInfo.tudnUrl + '?productionId=' + scope.row.productionId + '&amTaskId=' + scope.row.audioMixTaskId + '&commentatorEventId=' + scope.row.id" target="_blank">
                  <i class="iconfont icon-Union"></i><br/>
                </a>
              </el-tooltip>-->
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <CreateEvent
      v-if="eventInfo.show"
      :info="eventInfo"
      :selectInput="selectInput"
      :selectOutput="selectOutput"
      @callback="eventOperate"
      @closeDialog="closeDialog"
    />
    <StopEvent
      v-if="stopEventInfo.show"
      :info="stopEventInfo"
      @callback="eventOperate"
    ></StopEvent>
    <DeleteEvent
      v-if="deleteEventInfo.show"
      :info="deleteEventInfo"
      @delete="deleteFn"
    ></DeleteEvent>
    <GroupList :info="partyListInfo" v-if="partyListInfo.show"></GroupList>
    <living-tips-dialog
    v-for="receiverInfo in occupiedReceivers"
    :key="receiverInfo.eventId"
    v-show="showReceiverLivingTips[receiverInfo.eventId]"
    :occupiedReceiverInfo="receiverInfo"
    @closeReceiverDialog="closeReceiverDialog"
    @callBack="callBack">
    </living-tips-dialog>
  </div>
</template>
<script>
import CreateEvent from './dialog/createEvent'
import StopEvent from './dialog/stopEvent'
import GroupList from './dialog/groupList.vue'
import { mapState } from 'vuex'
import i18n from '@/lang/index.js'
import Bus from '@/assets/js/bus.js'
import Tools from '@/assets/js/utils.js'
import LivingTipsDialog from '@/components/livingTipsDialog.vue'
import DeleteEvent from './dialog/deleteEvent'
// import { myCommentatorAnalyze } from '@/assets/js/googleAnalyze/commentatorAnalyze.js'
export default {
  components: {
    CreateEvent,
    StopEvent,
    GroupList,
    LivingTipsDialog,
    DeleteEvent
  },
  props: {
    selectInput: Object,
    selectOutput: Array
  },
  computed: {
    ...mapState({
      statusInfo: (state) => state.statusInfo,
      socket: (state) => state.socket,
      Config: (state) => state.config
    }),
    coordinationUrl () {
      const obj = {}
      return function (id) {
        obj[id] = `${window.location.protocol}//${document.domain}/commentator/coordination?id=${id}`
        return obj
      }
    }
  },
  watch: {
    eventList: {
      deep: true,
      handler: function (list) {
        let hasRunning = false
        list && list.forEach(info => {
          if (info.status === 4) {
            hasRunning = true
          }
        })
        hasRunning ? this.showCentral = true : this.showCentral = false
      }
    }
  },
  data () {
    return {
      initConfigShow: false,
      eventIdCopy: false,
      showAavanceds: false,
      scrollS: {
        loading: true
      },
      loading: false,
      userId: '',
      eventInfo: {
        show: false
      },
      stopEventInfo: {
        show: false
      },
      deleteEventInfo: {
        show: false
      },
      eventList: [],
      params: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
        sid: '',
        rid: '',
        startTime: '',
        endTime: '',
        title: '',
        description: '',
        partyCode: '',
        status: [],
        timer: null,
        userId: '',
        needCheckReceiverOccupied: false
      },
      // 新增需求---下拉数据
      options: [
        {
          value: '',
          label: 'All'
        },
        {
          value: 1,
          label: 'To Do'
        },
        {
          value: 6,
          label: 'Preparing'
        },
        {
          value: 4,
          label: 'In progress'
        },
        {
          value: 10,
          label: 'Stopped'
        },

        {
          value: 7,
          label: 'Failed'
        },
        {
          value: 8,
          label: 'Done'
        }
      ],
      value: '',
      // 下拉选择数据
      selectedStatus: [],
      partyListInfo: {
        show: false,
        partyList: [],
        joinCode: '',
        invitedInfo: {}
      },
      myCountDown: {},
      countDownTimer: {},
      startCountDownStamp: 20 * 60 * 1000,
      hasNextPage: true,
      showReceiverLivingTips: {},
      occupiedReceivers: [],
      showCentral: false
    }
  },
  async created () {
    this.initConfigShow = this.Config.enableAudioMixerCentral
    this.userId = await this.$store.dispatch('userInfo')
    this.params.userId = this.userId
    this.createWebSocket()
    this.getEventList()
    this.params.needCheckReceiverOccupied = true
    Bus.$off('eventPageUpdate')
    Bus.$on('eventPageUpdate', (data) => {
      // 在此更新用户在coordinationRoom的编辑事件
      data.total = Number(data.total)
      this.handleEventPage(data)
    })
    Bus.$on('receiverOccupied', (result) => {
      this.$set(this.showReceiverLivingTips, result.eventId, true)
      if (!this.occupiedReceivers.some(item => item.eventId === result.eventId)) {
        this.occupiedReceivers.push(result)
      }
    })
  },
  filters: {
    chooseMainType: function (rows) {
      if (!rows.eventInputList) return
      const chooseItem = rows.eventInputList.find(
        (item) => item.purpose === rows.mainWindowType
      )
      if (!chooseItem) return ''
      if (chooseItem.value && chooseItem.value.changeName) {
        return chooseItem.value.changeName
      } else if (chooseItem.value && chooseItem.value.name) {
        return chooseItem.value.name
      } else if (chooseItem.type === 'pgm') {
        return 'PGM'
      } else {
        return ''
      }
      // }
    },
    showSelectedItems (name) {
      if (!name) return ''
      if (name === 'All') {
        return i18n.t('leftPaneOutPut.all')
      } else if (name === 'To Do') {
        return i18n.t('leftPaneOutPut.todo')
      } else if (name === 'Preparing') {
        return i18n.t('leftPaneOutPut.preparing')
      } else if (name === 'In progress') {
        return i18n.t('leftPaneOutPut.inprogress')
      } else if (name === 'Stopped') {
        return i18n.t('leftPaneOutPut.stop')
      } else if (name === 'Failed') {
        return i18n.t('leftPaneOutPut.failed')
      } else if (name === 'Done') {
        return i18n.t('leftPaneOutPut.done')
      } else if (name === 'Preparing(5 min)') {
        return i18n.t('leftPaneOutPut.preparingFiveMin')
      } else if (name === 'Stopping') {
        return i18n.t('leftPaneOutPut.stopping')
      } else {
        return name
      }
    }
  },
  methods: {
    changeSnCopy (row) {
      const url = row
      const oInput = document.createElement('input')
      oInput.value = url
      document.body.appendChild(oInput)
      oInput.select() // 选择对象;

      document.execCommand('Copy') // 执行浏览器复制命令
      this.$message({
        message: this.$t('eventList.copy'),
        type: 'success'
      })
      oInput.remove()
    },
    urlText (scope) {
      let _str = this.$t('eventList.eventId') + ':'
      _str += scope.row.id
      return _str
    },
    renderHeader (h, { column, $index }) {
      let realWidth = 0
      const span = document.createElement('span')

      span.innerText = column.label
      document.body.appendChild(span)

      realWidth = span.getBoundingClientRect().width
      column.minWidth = realWidth // 可能还有边距/边框等值，需要根据实际情况加上

      document.body.removeChild(span)
      return h('span', column.label)
    },
    // showAavanced () {
    //   this.showAavanceds = true
    // },
    jumpToNewAudioMixer () {
      this.$http.get('/commentator-backend/am/getRunningTaskIds')
        .then(res => {
          if (res.data.errorCode === '0x0') {
            const _url = this.Config.urlInfo.centralAudioMixerUrl + '/' + res.data.result.join()
            window.open(_url, '_blank')
          }
        })
    },
    checkHostJoin (row) {
      this.$http.get(`${this.Config.urlInfo.partyline}/partyline-backend/dictionary?key=hostUuidRelation&partyCode=${row.parentPartyCode}`).then(res => {
        if (res.data.errorCode == '0x0') {
          // const result = res.data.result ? res.data.result.split('_')[1] : ''
          // const hostUuid = localStorage.getItem(row.id)
          // if (result && result != hostUuid) {
          //   window.open(`${window.location.protocol}//${document.domain}/commentator/leaveParty?type=accountAccessTips&eventId=${row.id}&partyCode=${row.parentPartyCode}`, '_blank')
          // } else {
          window.open(`${window.location.protocol}//${document.domain}/commentator/coordination?id=${row.id}`, '_blank')
          // }
        }
      })
    },
    async getEventList () {
      this.$store.state.newPageLoading = false
      this.scrollS.loading = true
      try {
        const { data } = await this.$http.post('/commentator-backend/event/v2/page', this.params)
        this.handleEventPage(data.result)
        this.scrollS.loading = false
      } catch (err) {
        console.log(err)
      }
    },
    sendWebsocketMessage (once) {
      this.$store.state.newPageLoading = false
      this.scrollS.loading = true
      this.socket && this.socket.send({
        key: 'eventPage',
        time: 10000,
        once: once || false,
        message: {
          serverName: JSON.parse(
            localStorage.getItem('partylineCommentatorConfig')
          ).wsInvokeServerName,
          data: JSON.stringify({
            module: 'eventPage',
            data: JSON.stringify(this.params)
          })
        },
        success: (data) => {
          const res = data.data
          this.handleEventPage(res)
          if (data) {
            this.scrollS.loading = false
          }
        }
      })
    },
    createWebSocket () {
      this.sendWebsocketMessage()
    },
    send () {
      this.sendWebsocketMessage(true)
    },
    downloadHref () {
      const protocol = window.location.protocol
      if (this.$store.state.config.urlInfo) {
        const href = `${protocol}//${this.$store.state.config.urlInfo.record}`
        return href
      }
    },
    stopEvent (scope) {
      this.stopEventInfo.show = true
      this.stopEventInfo.id = scope.row.id
      this.stopEventInfo.status = scope.row.status
    },
    addClass ({ row, rowIndex }) {
      return this.statusInfo[row.status] && this.statusInfo[row.status].color
    },
    eventOperate (e) {
      // this.getMessage()
      if (e.type === 'create' || e.type === 'update' || e.type === 'stop') {
        this.getEventList()
        this.params.needCheckReceiverOccupied = false
      } else if (e.type === 'pagination') {
        this.params.pageNum = 1
        this.getEventList()
        this.params.needCheckReceiverOccupied = false
        this.sendWebsocketMessage(true)
      }
    },
    createFn () {
      // myCommentatorAnalyze('create')
      this.eventInfo = {
        show: true,
        type: 'creat'
      }
    },
    editFn (scope, type) {
      this.eventInfo = { id: scope.row.id, show: true, type }
    },
    deleteEvent (scope) {
      this.deleteEventInfo.show = true
      this.deleteEventInfo.scope = scope
      // this.deleteEventInfo.status = scope.row.status
    },
    deleteFn (scopes) {
      this.$http
        .post(`/commentator-backend/event/v2/delete/${scopes.scope.row.id}`, {}, { errorTips: false })
        .then((res) => {
          if (res.data.errorCode == '0x0') {
            this.eventList.splice(scopes.scope.$index, 1)
            // 删除最后一页的最后一条数据之后，页码切换至上一页数据
            const totalPage = Math.ceil((this.params.total - 1) / this.params.pageSize)
            this.params.pageNum = this.params.pageNum > totalPage ? totalPage : this.params.pageNum
            this.params.pageNum = this.params.pageNum < 1 ? 1 : this.params.pageNum
            this.getEventList()
            this.params.needCheckReceiverOccupied = false
            this.send(true)
            this.$message.success(this.$t('eventList.delTips'))
          } else if (res.data.errorCode == '82301002') {
            this.$message.error(res.data.errorInfo)
          } else {
            this.$message.error(res.data.errorInfo)
          }
        })
    },
    showSelected (value) {
      if (typeof value === 'string') {
        this.selectedStatus = []
      } else if (value === 6) {
        this.selectedStatus = [2, value]
      } else {
        this.selectedStatus = [value]
      }
      this.params.status = this.selectedStatus
      this.params.pageNum = 1
      this.getEventList()
      this.params.needCheckReceiverOccupied = false
      this.sendWebsocketMessage(true)
      // 重置一下数组
      // this.selectedStatus.splice(0, this.selectedStatus.length);
    },
    // 分页逻辑，处理每页显示多少条
    handleSizeChange (newSize) {
      this.params.pageSize = newSize
      this.getEventList()
      this.params.needCheckReceiverOccupied = false
      this.send()
    },
    // 分页逻辑，处理当前显示第几页
    handleCurrentChange (newPage) {
      this.params.pageNum = newPage
      this.getEventList()
      this.params.needCheckReceiverOccupied = false
      this.send()
    },
    // 处理事件页面
    handleEventPage (res) {
      this.eventList = res.list
      this.params.total = res.total
      this.hasNextPage = res.pageNum != res.pages
      this.getCountDown(this.eventList)
    },
    showGroupList (partyList, status, data) {
      this.partyListInfo.show = true
      this.partyListInfo.partyList = partyList || []
      this.partyListInfo.copyStatus = status <= 4
      this.partyListInfo.joinCode = data.parentPartyCode
      this.$http
        .get(`${this.Config.urlInfo.partyline}/partyline-backend/partyExtendInfo/${data.parentPartyCode}`)
        .then(res => {
          if (res.data.errorCode === '0x0') {
            this.partyListInfo.invitedInfo = res.data.result || {}
          }
        })
    },
    getCountDown (data) {
      /*
      1. 事件为准备中或者进行中开启定时器
      2. 当前时间距离事件结束时间<= 20min时，开始倒计时，并显示到页面
      */
      if (!data) return
      const selectedData = data.filter(v => (v.status === 4 || v.status === 2))
      let currentTime
      if (selectedData.length > 0) {
        selectedData.forEach(item => {
          if (this.countDownTimer[item.id]) {
            clearInterval(this.countDownTimer[item.id])
          }
          this.countDownTimer[item.id] = setInterval(() => {
            currentTime = new Date().getTime()
            if (item.endTime - currentTime <= this.startCountDownStamp) {
              const { mins, seconds } = Tools.countDown(item.endTime)
              this.$set(this.myCountDown, item.id, {
                mins,
                seconds,
                show: true
              })
              if (typeof this.myCountDown[item.id].seconds === 'string') {
                if (this.myCountDown[item.id].seconds.indexOf('-') > -1) {
                  this.myCountDown[item.id].show = false
                }
              }
            } else {
              this.$set(this.myCountDown, item.id, {
                show: false
              })
            }
          }, 1000)
        })
      }
    },
    closeDialog () {
      this.eventInfo.show = false
      this.$store.state.mediaInitParams.selectedOutput = []
    },
    closeReceiverDialog (id) {
      this.$set(this.showReceiverLivingTips, id, false)
    },
    callBack (id, value, type) {
      this.occupiedReceivers.forEach(event => {
        if (event.eventId === id) {
          event.receivers.forEach(item => {
            if (item.receiverId === value.receiverId) {
              this.$set(item, 'status', type)
            }
          })
        }
      })
    }
  },
  beforeDestroy () {
    // this.socket.off();
    if (this.countDownTimer) {
      Object.keys(this.countDownTimer).forEach(item => {
        clearInterval(this.countDownTimer[item])
      })
    }
    this.socket && this.socket.stop('eventPage')
  }
}
</script>
<style lang="less" scoped>
.eventList {
  position: relative;
  padding: 0 10px;
  .create {
    /deep/.el-button {
      margin: 0 10px;
      &.record_icon {
        background: none;
      }
      a {
        color: #03897b;
      }
    }
  }
  .el-button {
    border-color: #03897b;
    background: #03897b;
  }
  /deep/.el-table {
    font-size: 16px;
    color: #fff;
    background: none;
    &::before {
      background: #28282e;
    }
    .el-table__body-wrapper {
      max-height: calc(100vh - 200px);
      overflow-y: auto;
      &::-webkit-scrollbar {
        /*滚动条整体样式*/
        width: 6px; /*高宽分别对应横竖滚动条的尺寸*/
        height: 6px;
      }
      &::-webkit-scrollbar-thumb {
        /*滚动条里面小方块*/
        border-radius: 10px;
        background: rgba(255, 255, 255, 0.3);
      }
      &::-webkit-scrollbar-track {
        /*滚动条里面轨道*/
        border-radius: 10px;
        background: none;
      }
    }
    thead {
      color: #999;
    }
    th,
    tr,
    td {
      background: none;
      border-bottom: 1px solid #28282e;
      &.is-leaf {
        border-bottom: 1px solid #28282e;
      }
    }
    tbody {
      tr {
        &:hover {
          // background: rgba(3, 137, 123, 0.25);
          background: #212124;
          td {
            background: none;
          }
        }
      }
    }
    .btn {
      margin-right: 15px;
      cursor: pointer;
      color: #fff;
      a {
        color: #fff;
      }
    }

    .recordBtn {
      color: #03897b;
      img {
        vertical-align: middle;
        margin: 0 5px;
      }
    }
    .white {
      color: #fff;
    }
    .red {
      color: #f6445a;
    }
    .blue {
      color: #03897b;
    }
    .gray {
      color: #454549;
    }
    .overflow {
      display: inline-block;
      max-width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .countDown {
      position: relative;
      height: 30px;
      line-height: 30px;
      background-color: rgba(246, 68, 90, 0.1);
      border-radius: 4px;
      min-width: 125px;
      max-width: 140px;
      padding-left: 4px;
      span {
        color: #F6445A;
        font-size: 14px;
        font-weight: 700;
      }
      .mins,.seconds,.dots {
        font-weight: 700;
      }
      .triangle {
      position: absolute;
      top: 50%;
      left: -10px;
      transform: translate(-50%, -50%);
      display: inline-block;
      width: 0px;
      height: 0px;
      border-width: 10px 10px 10px 10px;
      border-radius: 4px;
      border-style: solid;
      border-color: transparent rgba(246, 68, 90, 0.1) transparent transparent;
    }
    }

    .groupNum {
      padding: 2px 6px;
      border-radius: 5px;
      background: #454549;
      color: #fff;
      &.pointer {
        cursor: pointer;
      }
    }
    .serviceEntry {
      text-align: center;
    }
    .buttons {
      text-align: center;
      display: flex;
      flex: 1;
      justify-content: center;
      a {
        text-align: center;
        padding: 0 15px;
        position: relative;
        &:after {
          content: '';
          display: block;
          position: absolute;
          top: 5px;
          right: 0px;
          height: 15px;
          border-left: 1px solid #454549;
        }
        &:last-child {
          &:after {
            border-left: none;
          }
        }
        i {
          font-size: 24px;
          color: #03897b;
          &.icon-Union {
            font-size: 20px;
          }
        }
        span {
          font-size: 14px;
          color: #454549;
        }
      }
    }
  }
  .list {
    /deep/ .lds-roller {
      top: 184px;
    }
   /deep/  .el-table .el-table__cell{
      text-align:center !important;
    }
    /deep/ .el-table .cell{
      word-break: break-word !important;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  // 新增下拉选择样式
  .type-select-box-eventList {
    width: 110px;
    /deep/.el-select {
     ::-webkit-scrollbar {
        /*滚动条整体样式*/
        width: 6px; /*高宽分别对应横竖滚动条的尺寸*/
        height: 16px;
      }
      ::-webkit-scrollbar-thumb {
        /*滚动条里面小方块*/
        border-radius: 10px;
        background: rgba(255, 255, 255, 0.3);
      }
      ::-webkit-scrollbar-track {
        /*滚动条里面轨道*/
        border-radius: 10px;
        background: none;
      }
      width: 110px;
      .el-input__inner {
        background-color: #28282e;
        border: 1px solid #010101;
        color: #fff;
        height: 30px;
        line-height: 30px;
      }
    }
    /deep/ .el-select-dropdown {
      border: none;
      text-align: left;
      left: 0 !important;
      position: absolute !important;
      top: 20px;
    }
    /deep/ .el-input__icon {
      line-height: 30px;
    }
  }
  .eventListContent {
    display: flex;
    float: right;
    // 新增分页需求
    .new_pagination {
      width: max-content;
      /deep/.el-pagination {
        width: max-content;
        .PageSize {
          margin-left: 20px;
          margin-right: 25px;
          font-weight: 400;
          font-size: 16px;
          color: #f1f1f1;
        }
        .btn-prev {
          background-color: #010101;
          .el-icon {
            color: #fff;
          }
        }
        .el-pager li {
          background-color: #010101;
          color: #fff;
        }
        .el-pager li.active {
          color: #e6a23c !important;
          font-weight: 900 !important;
        }
        .btn-next {
          background-color: #010101;
          .el-icon {
            color: #fff;
          }
        }
        .el-select {
          .el-input {
            width: 82px;
            .el-input__inner {
              background-color: #28282e;
              color: #fff;
              border: none;
            }
          }
        }
      }
    }
  }
}
</style>
