<template>
  <div class="inputSource">
    <div class="mediaList">
      <div class="top clearfix" :class="{ seachStatus: searchFlag }">
        <span class="left" v-if="!searchFlag">{{$t('leftPane.input')}}</span>
        <div class="select_type left" :class="`${selectType}`">
          <select-menu
            v-if="!searchFlag"
            :data="sourceType"
            type="group"
            :callback="getSource"
            :selectVal="selectType"
          ></select-menu>
          <el-input
            v-else
            ref="seachObj"
            autofocus
            class="black"
            v-model="searchKey"
            :placeholder="selectType | typeText"
            @keyup.enter.native="getSource('search')"
          >
            <i
              class="el-icon-search"
              slot="prefix"
              @click="getSource('search')"
            >
            </i>
          </el-input>
        </div>
        <upload-file
          v-show="selectType == 'file' && !searchFlag"
          type="icon"
        ></upload-file>
        <i
          v-if="!searchFlag && (selectType == 'vfb' || selectType == 'file')"
          class="right el-icon-search"
          @click="switchSearch"
        ></i>
        <i
          v-if="searchFlag"
          class="right cancelIcon"
          @click="getSource('cancel')"
          >{{$t('leftPane.cancel')}}</i
        >
        <i class="add" v-if="selectType == 'ext'" @click="addExt">+ {{$t('leftPane.add')}}</i>
      </div>

      <div class="list scrollbar" v-if="selectType == 'pgm'">
        <div class="text">{{$t('leftPane.inputSelectInputSource')}}</div>
      </div>
      <div
        class="list scrollbar"
        v-else-if="selectType == 'file'"
        v-scroll="scrollF"
        v-loadings="scrollF.loading"
      >
        <ul v-if="fileList.length > 0">
          <file-item
            v-for="(val, index) in fileList"
            :key="val.id"
            :data="val"
            :index="index"
            @selectSource="selectSource"
          >
          </file-item>
        </ul>
        <div v-if="!fileList.length && !scrollF.loading">
          <no-data-slot type="noFile"></no-data-slot>
        </div>
      </div>
      <div
        class="list scrollbar"
        ref="scrollBox"
        v-else
        v-scroll="scrollS"
        :class="{ scrollHeight: selectType == 'slot' }"
        v-loadings="scrollS.loading"
      >
        <ul>
          <li
            v-for="(val, index) in sourceList"
            :key="val.peerId"
            @click="selectSource(val)"
            :class="{ activeLine: val.selected }"
            @mouseenter="showHoverStyle($event,val)"
            @mouseleave="cancelHoverStyle($event, val)"
          >
          <div class="showList" v-if="selectType === 'pack'">
            <span class="dot" :class="{noSource: val.status === '3', live: val.status === '2', offline: val.status === '0', available: val.status === '1'}"></span>
            <p class="overflow packName">{{val.name}}</p>
            <p class="peerId" v-if="selectType === 'pack'">{{val.peerId}}</p>
          </div>
          <span class="overflow name" v-else>{{val.name}}</span>
            <span @click.stop>
            <el-popover
              v-if="
                selectType == 'ext' ||
                  selectType == 'anywhere' ||
                  selectType == 'grid'
              "
              placement="bottom-start"
              title=""
              :popper-class="
                `inputOperate ${showDeleteConfirm ? 'deleteConfim' : ''}`
              "
              width="150"
              @hide="hideOperate"
              ref="operatePopover"
              trigger="click"
            >
              <div
                class="btn"
                v-if="!showDeleteConfirm"
                @click="deleteFn(val, index)"
              >
                <span slot="reference">{{$t('leftPane.del')}}</span>
              </div>
              <div class="confirm" v-if="showDeleteConfirm">
                <p class="content">{{$t('leftPane.delTips')}}</p>
                <span class="button red" @click="confirmDelete(val, index)"
                  >{{$t('leftPane.del')}}</span
                >
                <span class="button gray" @click="cancelDelete(index)"
                  >{{$t('leftPane.cancel')}}</span
                >
              </div>
              <span class="operate" slot="reference"
                ><i class="el-icon-more"></i
              ></span>
            </el-popover>
            </span>
          </li>
        </ul>
        <div class="no-data" v-if="!sourceList.length && !scrollS.loading">
          <p class="gray999">{{$t('leftPaneOutPut.noResult')}}</p>
          <p class="gray666">{{$t('leftPaneOutPut.changeKeyWords')}}</p>
        </div>
      </div>
      <div class="slotMessage" v-if="selectType == 'slot'">
        {{$t('leftPane.note')}}
        <p>
          {{$t('leftPane.noteWords')}}
        </p>
      </div>
    </div>
    <AddExt
      :info="addExtInfo"
      v-if="addExtInfo.show"
      @callback="operateCallbackFn"
    />
  </div>
</template>
<script>
import Bus from '@/assets/js/bus.js'
import SelectMenu from '@/components/selectMenu'
import NoDataSlot from '@/components/noDataSlot'
import UploadFile from '@/components/uploadFile'
import FileItem from './fileItem'
import AddExt from '../dialog/addExt'
import { mapState } from 'vuex'
import i18n from '@/lang/index.js'
let _this = this
export default {
  components: {
    SelectMenu,
    NoDataSlot,
    UploadFile,
    FileItem,
    AddExt
  },
  computed: {
    ...mapState({
      State: (state) => state,
      sourceType: (state) => state.sourceTypeTemplate
    })
  },
  data () {
    return {
      sourceList: [],
      recevierId: '',
      sourceId: '',
      fileId: '',
      scrollS: {
        onScroll: this.getNextPageSource,
        loading: true,
        noMore: false
      },
      scrollF: {
        onScroll: this.getNextPageFile,
        loading: true,
        noMore: false
      },
      fileParams: {
        pageIndex: 1,
        pageSize: 20,
        nameLike: '',
        isDirectory: false,
        region: '',
        service: 'pp',
        type: 2,
        directoryName: '/Videos/Commentator'
      },
      fileList: [],
      searchKey: '',
      searchFlag: false,
      selectType: 'vfb',
      requestSource: {
        slot: {
          url: '/commentator-backend/production/slots'
        },
        pp: {
          url: '/commentator-backend/user/source/input/pp/page',
          params: {
            pageNum: 1,
            pageSize: 20,
            sourceType: null
          }
        },
        cc: {
          // url: '/commentator-backend/user/source/input/cc/page',
          // params: {
          //   pageNum: 1,
          //   pageSize: 20,
          //   sourceType: null
          // },
          url: '/commentator-backend/user/source/output/receiver/page',
          params: {
            condition: '',
            living: true,
            noCamera: true,
            offline: true,
            online: true,
            pack: true,
            pageNum: 1,
            pageSize: 20
          }
        },
        vfb: {
          url: '/commentator-backend/device/pageDevice',
          params: {
            pageNum: 1,
            pageSize: 20,
            condition: '',
            living: true,
            online: true,
            offline: true,
            noCamera: true,
            gridSdi: true,
            gridPack: true
          }
        }
      },
      sourceTypePamrs: {
        ext: 2,
        pack: 4,
        anywhere: 6,
        grid: 5
      },
      requestInfo: null,
      addExtInfo: {
        show: false
      },
      recordSelect: {},
      showDeleteConfirm: false
    }
  },
  created () {
    _this = this
    this.requestInfo = this.requestSource.vfb
    this.getSourceList()
    Bus.$on('refreshFileList', () => {
      this.getFileList(1)
    })
  },
  methods: {
    operateCallbackFn (data) {
      const { type } = data
      if (type == 'addExt') {
        this.requestInfo.params.pageNum = 1
        this.getSourceList()
      }
    },
    addExt () {
      this.addExtInfo.show = true
    },
    cancelDelete (index) {
      this.$refs.operatePopover[index].doClose()
      this.showDeleteConfirm = false
    },
    confirmDelete (val, index) {
      this.$refs.operatePopover[index].doClose()
      this.showDeleteConfirm = false
      let url = null
      if (this.selectType == 'ext') {
        url = `/commentator-backend/user/source/input/pp/delete/${val.id}`
      }
      this.$http.post(url).then((res) => {
        if (res.data.errorCode == '0x0') {
          this.sourceList.splice(index, 1)
          this.$message.success(this.$t('leftPane.delSuccessTips'))
        }
      })
    },
    deleteFn (val, index) {
      this.showDeleteConfirm = true
    },
    hideOperate () {
      this.showDeleteConfirm = false
    },
    switchSearch () {
      this.searchFlag = true
      this.$nextTick(() => {
        this.$refs.seachObj.focus()
      })
    },
    async getSource (val) {
      if (val != 'search' && val != 'cancel') {
        this.selectType = val
      }
      if (val == 'cancel') {
        this.searchFlag = false
        this.searchKey = ''
      }

      this.getRequest()
      if (this.selectType === 'file') {
        this.sourceId = ''
        this.fileList = []
        // 回到第一页
        await this.getFileList(1)
        this.fileList.forEach((v, index) => {
          if (v.id == this.recordSelect[this.selectType]) {
            this.$set(this.fileList, index, { ...v, selected: true })
          } else {
            this.$set(this.fileList, index, { ...v, selected: false })
          }
        })
      } else if (this.selectType != 'pgm') {
        console.info(258, this.$refs.scrollBox)
        this.$refs.scrollBox ? (this.$refs.scrollBox.scrollTop = 0) : null
        this.fileId = ''
        this.scrollS.noMore = false
        this.scrollS.loading = false
        this.sourceList = []
        if (this.requestInfo.params) {
          this.requestInfo.params.pageNum = 1
          this.requestInfo.params.condition = this.searchKey
        }
        // 回到第一页
        await this.getSourceList()
        this.sourceList.forEach((v, index) => {
          if (v.peerId == this.recordSelect[this.selectType]) {
            this.$set(this.sourceList, index, { ...v, selected: true })
          } else {
            this.$set(this.sourceList, index, { ...v, selected: false })
          }
        })
      }
      this.$emit('callback', {
        type: this.selectType,
        id: this.recordSelect[this.selectType]
      })
    },
    selectSource (val) {
      if (!Object.values(this.recordSelect).indexOf(val.peerId) || !Object.values(this.recordSelect).indexOf(val.id)) {
        this.$delete(this.recordSelect, this.selectType)
      } else {
        this.$set(this.recordSelect, this.selectType, val.peerId || val.id)
      }
      let selectName = null

      if (this.selectType === 'file') {
        this.fileList.forEach((v, index) => {
          if (v.id == val.id && !Object.values(this.recordSelect).indexOf(val.id)) {
            this.fileId = v.id
            selectName = v.name
            this.$set(this.fileList, index, { ...v, selected: true })
          } else {
            this.$set(this.fileList, index, { ...v, selected: false })
          }
        })
      } else {
        this.sourceList.forEach((v, index) => {
          if (v.peerId == val.peerId && !Object.values(this.recordSelect).indexOf(val.peerId)) {
            this.sourceId = v.peerId
            selectName = v.name || v.sourceName
            this.$set(this.sourceList, index, { ...v, selected: true })
          } else {
            this.$set(this.sourceList, index, { ...v, selected: false })
          }
        })
      }
      this.$emit('callback', {
        type: this.selectType,
        id: this.recordSelect[this.selectType],
        name: selectName
      })
    },
    async getFileList (val) {
      if (val) {
        this.fileParams.pageIndex = val
      }
      this.fileParams.nameLike = this.searchKey
      this.fileParams.region = localStorage.getItem('region')
      this.scrollF.loading = true
      await this.$http
        .post('/file/query-user-files', this.fileParams, {
          requestBase: 'GATEWAY'
        })
        .then((res) => {
          this.scrollF.loading = false
          if (res.data.errorCode == '0x0') {
            if (
              this.fileParams.pageIndex >=
              Math.ceil(res.data.result.total / this.fileParams.pageSize)
            ) {
              this.scrollF.noMore = true
            }
            const arr = res.data.result.records.map((v) => {
              return { ...v, selected: v.id == this.fileId }
            })
            if (this.fileParams.pageIndex > 1) {
              this.fileList = this.fileList.concat(arr)
            } else {
              this.fileList = arr
            }
          }
        })
    },
    getNextPageFile () {
      if (this.scrollF.loading || this.scrollF.noMore) return
      this.fileParams.pageNum += 1
      this.getNextPageFile()
    },
    async getSourceList () {
      if (!this.requestInfo) return
      if (this.sourceTypePamrs[this.selectType] && this.requestInfo.params) {
        this.requestInfo.params.sourceType = this.sourceTypePamrs[
          this.selectType
        ]
      }
      this.scrollS.loading = true
      await this.$http
        .post(this.requestInfo.url, this.requestInfo.params)
        .then((res) => {
          this.scrollS.loading = false
          if (res.data.errorCode == '0x0') {
            if (this.selectType == 'slot') {
              this.sourceList = res.data.result.map((v, index) => {
                return {
                  name: `Slot${index + 1}`,
                  peerId: v
                }
              })
            } else {
              if (this.requestInfo.params.pageNum >= res.data.result.pages) {
                this.scrollS.noMore = true
              }
              const list = res.data.result.list || []
              const arr = list.map((v) => {
                v.name = v.name || v.sourceName
                v.peerId = v.peerId || v.id
                return { ...v, selected: v.peerId == this.sourceId }
              })
              if (this.requestInfo.params.pageNum > 1) {
                this.sourceList = this.sourceList.concat(arr)
              } else {
                this.sourceList = arr
              }
            }
          }
        })
    },
    getRequest () {
      // 获取请求url和参数
      let request = null
      if (this.selectType == 'vfb') {
        request = this.requestSource.vfb
      } else if (this.selectType == 'ext' || this.selectType == 'grid') {
        request = this.requestSource.pp
      } else if (this.selectType == 'pack' || this.selectType == 'anywhere') {
        request = this.requestSource.cc
      } else if (this.selectType == 'slot') {
        request = this.requestSource.slot
      }
      this.requestInfo = request
    },
    getNextPageSource () {
      if (this.scrollS.loading || this.scrollS.noMore) return
      this.requestInfo.params.pageNum += 1
      this.getSourceList()
    },
    showHoverStyle (event, value) {
      if (!value.selected) {
        event.currentTarget.className = 'bgColorHover'
      }
    },
    cancelHoverStyle (event, value) {
      if (!value.selected) {
        event.currentTarget.className = null
      }
    }
  },
  filters: {
    typeText (val) {
      let text = null
      _this.sourceType.forEach((v) => {
        v.options.forEach((item) => {
          if (item.value == val) {
            if (item.label === 'PP PGM') {
              text = i18n.t('leftPane.ppPgm')
            } else if (item.label === 'PP Slot') {
              text = i18n.t('leftPane.ppSlot')
            } else if (item.label === 'TVU Transceiver - Grid') {
              text = i18n.t('leftPane.transGrid')
            } else if (item.label === 'Upload files') {
              text = i18n.t('leftPane.upLoadFile')
            } else if (item.label === 'EXT IP Source') {
              text = i18n.t('leftPane.upLoadFile')
            } else if (item.label === 'Pack') {
              text = i18n.t('leftPane.pack')
            } else if (item.label === 'SDI IN') {
              text = i18n.t('leftPane.sdiIn')
            } else {
              text = item.label
            }
          }
        })
      })
      return text
    }
  }
}
</script>
<style lang="less" scoped>
.inputSource {
  width: 100%;
  position: relative;
  background: #28282e;
  height: calc(50% - 13px);
  padding: 10px 0px 0;
  margin-bottom: 5px;
  .mediaList {
    height: calc(100% - 30px);
    position: relative;
    &:after {
      content: '';
      display: block;
      width: 4px;
      height: 20px;
      background: #f1f1f1;
      position: absolute;
      left: 1px;
      top: 3px;
    }
    .top {
      padding: 0 0 0 10px;
      > span {
        font-size: 18px;
        font-weight: bold;
        margin-right: 3px;
      }
      &.seachStatus {
        .select_type {
          width: 215px !important;
        }
      }
      /deep/.el-input {
        width: 100%;
        .el-input__inner {
          height: 30px;
          line-height: 30px;
        }
      }
      i {
        margin: 10px 10px 0 0;
        cursor: pointer;
        &.el-icon-search {
          font-size: 16px;
          font-weight: bolder;
          margin: 7px 10px 0 0;
        }
      }
      .add {
        color: #33ab4f;
        cursor: pointer;
        margin-left: 3px;
        font-weight: bold;
        margin-right: 0;
        font-size: 14px;
      }
      .select_type {
        width: 190px;
        display: inline-block;
        vertical-align: bottom;
        .black {
          /deep/ .el-input__inner {
            color: #fff;
          }
        }
        &.file {
          width: 157px;
        }
        &.ext {
          width: 165px;
        }
        &.anywhere,
        &.pack,
        &.grid {
          width: 205px;
        }
      }
      .cancelIcon {
        margin-top: 6px;
        font-size: 13px;
        font-weight: bold;
      }
    }
    .list {
      height: calc(100% - 30px);
      overflow-y: auto;
      overflow-x: hidden;
      &.scrollHeight {
        height: calc(100% - 100px);
      }
      .text {
        text-align: center;
        padding: 0 40px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
      }
      ul {
        padding-top: 10px;
      }
      .no-data {
        position: absolute;
        top: 30%;
        left: 50%;
        transform: translateX(-50%);
        width: 200px;
        text-align: center;
        .gray999 {
          color: #999;
        }
        .gray666 {
          color: #666;
        }
      }
    }
    .slotMessage {
      padding: 0 10px;
      color: #999;
      font-size: 14px;
    }
  }
  li {
    line-height: 50px;
    color: #fff;
    padding: 0 10px;
    text-align: left;
    cursor: pointer;
    position: relative;
    .showList {
      height: 50px;
      margin-left: 14px;
      .dot {
        position: absolute;
        top: 10px;
        left: 10px;
        height: 5px;
        width: 5px;
        border-radius: 5px;
      }
      .available {
        background-color: #03897B;
      }
      .noSource {
        background-color: #F5A623;
      }
      .live {
        background-color: #F6445A;
      }
      .offline {
        background-color: #6D6D6D;
      }
      .packName {
        height: 20px;
        line-height: 20px;
        color: #F1F1F1;
        font-size: 16px;
        padding-bottom: 8px;
      }
      .peerId {
        height: 14px;
        line-height: 14px;
        font-size: 12px;
        color: #FFFFFF;
        opacity: 0.5;
      }
    }
    .name {
      position: relative;
      z-index: 1;
      vertical-align: middle;
      font-size: 16px;
      max-width: 230px;
      display: inline-block;
    }
    .operate {
      float: right;
    }
    span {
      position: relative;
      z-index: 1;
      vertical-align: middle;
    }
    &.activeLine {
      background-color: #0ca789;
    }
    &:hover {
      // background: #010101;
      .editIcon {
        display: block;
      }
    }
  }
  .bgColorHover {
    background-color: #010101;
  }
}
</style>
