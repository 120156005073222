<template>
  <div class="coordination clearfix">
    <div class="box" ref="mainBox">
      <div class="left source" ref="leftSource" :style="{ minWidth: '60vw'}">
        <newMainWindow
          ref="mainwindow"
          v-if="extraParty"
          :partyCode="extraParty + ''"
          :eventInfo="eventInfo"
          :coordinationInfo="coordinationInfo"
          @upDateOnAir="upDateOnAir"
          :changeMainWindowLayout="changeMainWindowLayout"
          :talkAllGroup="talkAllGroup"
          :devicesInfo="devicesInfo"
          :userInfo="userInfo"
          :rtc="rtc"
          :groups="groups"
          :channels="channels"
          :vfbSpeakerInfo="vfbSpeakerInfo"
          :allSourceLists="allSourceLists"
          :outputRtilCode="outputRtilCode"
          @changeTalkAllGroups="changeTalkAllGroups"
          @callback="dealMainWindowCallback"
          @changeMainBoxLayout="changeMainBoxLayout"
        />
      </div>
      <div class="left group scrollbar">
        <i
          v-if="!hideLeft"
          @click="clickLeft"
          class="move-btn left-icon iconfont icon-arrow-left"
        ></i>
        <i
          v-if="!hideRight"
          @click="clickRight"
          class="move-btn right-icon  iconfont icon-arrow-right"
        ></i>
        <div class="group-list clearfix">
          <Group v-for="val in groups" :key="val.partyCode"
            v-show="GroupDisplayList.includes(val.title)"
            :muteAudioSelf="muteAudioSelf"
            :ref="`group${val.partyCode}`"
            :party="val"
            :partyListInfo="partyListInfo[val.partyCode]"
            :width="groupWidth"
            :clickTalkAllGroups="clickTalkAllGroups"
            :groupInfo="groupInfo[val.partyCode] || {}"
            :extraParty="extraParty"
            :realPartyListInfo="realPartyListInfo[val.partyCode]"
            :eventInfo="eventInfo"
            :allUserInfo="userInfo"
            :amStatusInfo="amStatusInfo"
            :allPairStatusInfo="allPairStatusInfo"
            @getNewAmStatusInfo ="getPairList"
            @micChange="micChangeCallBack"
            @playbackChange="playbackCallback"
            :talkAllGroup="talkAllGroup"
            :userNewFullScreen="true"
            @callback="groupCallback"
          />
          <ManageGroup
            ref="mangeGroup"
            v-show="GroupDisplayList.includes('Multi-Hosts')"
            :width="groupWidth"
            :channels="coordChannel"
            :userInfo="userInfo"
            :rtc="rtc"
            :muteAudioSelf="muteAudioSelf"
            :nickNameList="nickNameList"
            :managePrivate="managePrivate"
            :extraParty="extraParty"
            @callback="manageGroupCallback"
            :groupInfo="groupInfo[extraParty] || {}"
          />
        </div>
      </div>
    </div>
    <div class="foot" v-show="State.showFootBar">
      <div class="optionBox">
        <!-- 麦克风区域 -->
        <el-tooltip
          class="item"
          effect="dark"
          :content="micTitle"
          placement="top"
          v-if="!muteAudioSelf">
          <i
            class="iconfont icon-universal_micon pointer"
            :class="{
              m_r_0:
                devicesInfo.microphoneList &&
                devicesInfo.microphoneList.length &&
                State.rtcLoaded
            }"
              @click="setOperate({ type: 'muteAudio', id: partyListInfo.rtilCode })"
          >
          </i>
        </el-tooltip>
        <el-tooltip
          class="item"
          effect="dark"
          :content="micTitle"
          placement="top"
          v-else>
          <i
            class="iconfont pointer icon-Michover"
            :class="{
              m_r_0:
                devicesInfo.microphoneList &&
                devicesInfo.microphoneList.length &&
                State.rtcLoaded,
                active: muteAudioSelf
            }"
              @click="setOperate({ type: 'muteAudio', id: partyListInfo.rtilCode })"
          >
          </i>
        </el-tooltip>
        <device-select
          class="m_r_48"
          v-if="
            devicesInfo.microphoneList &&
              devicesInfo.microphoneList.length &&
              State.rtcLoaded
          "
          title="Select a Microphone"
          @updateDeviceId="updateDeviceId"
          @changeDevice="changeDevice"
          type="audio"
          :deviceSelectId="devicesInfo.microphoneId"
          :data="devicesInfo.microphoneList"
        />
        <!-- 控制网页声音区域 -->
        <el-tooltip
          class="item"
          effect="dark"
          :content="speakerTitle"
          placement="top"
          v-if="isMute"
          >
          <i :class="{active: isMute}"
            class="pointer iconfont icon-speakeroff"
            @click="changeMute(isMute)">
           <!-- <svg v-if="!isMute" aria-hidden="true">
              <use xlink:href="#icon-toolbarspeakeron"></use>
            </svg>
            <svg v-else aria-hidden="true">
              <use xlink:href="#icon-toolbarspeakeroff"></use>
            </svg>-->
          </i>
        </el-tooltip>
          <el-tooltip
          class="item"
          effect="dark"
          :content="speakerTitle"
          placement="top"
          v-else
          >
          <i
            class="pointer iconfont icon-speakeron"
            @click="changeMute(isMute)">
          </i>
        </el-tooltip>
        <device-select
          class="m_r_48"
          v-if="
            devicesInfo.speakerList &&
              devicesInfo.speakerList.length &&
              State.rtcLoaded
          "
          title="Select a Speaker"
          @updateDeviceId="updateDeviceId"
          @changeDevice="changeDevice"
          type="speaker"
          :deviceSelectId="devicesInfo.speakerId"
          :data="devicesInfo.speakerList"
        />
      </div>
    </div>
    <!-- 重复登入 -->
    <tipsDailog class="repeatJoin" width="550px" :info="repeatInfo" @save="repeatInfo.show = false">
      <div class="email">
        <p>{{ $t('coordinationRoom.repeatContent') }}</p>
      </div>
    </tipsDailog>
    <!-- r冲突的提示框 -->
    <living-tips-dialog
      v-show="showReceiverLivingTips"
      :occupiedReceiverInfo="occupiedReceiverInfo"
      @closeReceiverDialog="closeReceiverDialog"
      @callBack="callBack"
    >
    </living-tips-dialog>
  </div>
</template>
<script>
import DeviceSelect from '@/components/common/deviceSelect'
import newMainWindow from './newMainWindow.vue'
import Group from './group'
import { mapState } from 'vuex'
import Utils, { showVolume } from '@/assets/js/utils.js'
import Websocket from '@/assets/js/websocket.js'
import $ from 'jquery'
import Bus from '@/assets/js/bus.js'
import AgoraClient from '@/assets/js/rtc-client-ng'
import LivingTipsDialog from '@/components/livingTipsDialog.vue'
import TipsDailog from '@/components/common/tipsDailog.vue'
import ManageGroup from './managerGroup.vue'
import subscribeTrack from '@/assets/js/subscribeTrack.js'
import { getMicAndSpeakerDevices } from '@/assets/js/agoraFeature.js'
export default {
  components: {
    DeviceSelect,
    Group,
    LivingTipsDialog,
    newMainWindow,
    TipsDailog,
    ManageGroup
  },
  data () {
    return {
      isFirstTime: true,
      initJoin: true,
      amStatusInfo: {},
      allPairStatusInfo: {},
      pairNumber: 0,
      lastDisableMic: 0,
      initAudioPopup: true,
      muteAudioObj: {},
      muteAudioSelf: false,
      devicesInfo: {},
      hadPushTalk: true,
      groups: [],
      ws: null,
      eventId: '',
      eventInfo: {},
      extraParty: '',
      marginLeft: 0,
      hideLeft: true,
      hideRight: true,
      styles: {
        display: 'flex',
        'align-items': 'center',
        'justify-content': 'center'
      },
      repeatInfo: {
        show: false,
        title: 'coordinationRoom.repeatJoin',
        save: 'coordinationRoom.ok'
      },
      flex: {},
      leftWidth: '570px',
      groupWidth: '',
      talkAllGroup: false,
      clickTalkAllGroups: 0,
      coordinationInfo: {},
      groupInfo: {},
      partyListInfo: {},
      realPartyListInfo: {},
      hostUuid: '',
      showReceiverLivingTips: false,
      occupiedReceiverInfo: {},
      changeMainWindowLayout: 1,
      userInfo: {},
      outputRtilCode: '',
      allSourceLists: {},
      channels: {}, // 记录 pgm, mainIn 视频列表
      coordChannel: {},
      nickNameList: {},
      previewList: [], // 用来记录视频预览
      rtc: {},
      managePrivate: {}, // 用来记录 host 和其他 host 的 pushtalk 的状态
      otherHostPrivate: {}, // 用来记录其他 host 和当前 host 的 pushtalk 状态
      marginLeftNumber: 0,
      localInfo: null, // 用来记录需要再本地记录的信息
      vfbSpeakerInfo: {}
    }
  },
  computed: {
    ...mapState({
      State: state => state,
      Config: (state) => state.config,
      isMute: (state) => state.isMute,
      loginInfo: (state) => state.userInfo,
      GroupDisplayList: state => state.groupDisplayList
    }),
    micTitle () {
      let _str = this.$t('coordinationParticipant.turnOffHostMicPhone')
      if (this.muteAudioSelf) {
        _str = this.$t('coordinationParticipant.turnOnHostMicPhone')
      }
      return _str
    },
    speakerTitle () {
      let _str = this.$t('coordinationParticipant.turnOffSpeaker')
      if (this.isMute) {
        _str = this.$t('coordinationParticipant.turnOnSpeaker')
      }
      return _str
    }
  },
  async created () {
    this.State.newPageLoading = true
    document.getElementsByClassName('contentBox')[0].style.height = 'calc(100vh - 65px)'
    this.rtc = new AgoraClient(this.callBacks)
    this.devicesInfo = await this.rtc.getDevice()
    if (!localStorage.getItem('speakerId')) {
      localStorage.setItem('speakerId', this.devicesInfo.speakerId)
    }
    this.$nextTick(() => {
      this.State.leftSource = this.$refs.leftSource.offsetHeight
    })
    this.eventId = this.$route.query.id
    this.hostUuid = localStorage.getItem(this.eventId) || Utils.getRandomStr()
    localStorage.setItem(this.eventId, this.hostUuid)
    const localEventId = localStorage.getItem('eventId', this.eventId)
    localStorage.removeItem('videoKeyAudioVal')
    localStorage.removeItem('backupVideoKeyAudioVal')
    localStorage.removeItem('pgm')
    localStorage.removeItem('backupPgm')
    if (!localEventId || localEventId != this.eventId) {
      localStorage.removeItem('settingInfo')
      localStorage.removeItem('localInfo')
      localStorage.removeItem('vfbInfo')
      localStorage.setItem('eventId', this.eventId)
    }
    this.flex = this.styles

    await this.getEventInfo()
    window.addEventListener('resize', this.getArrow)
    navigator.mediaDevices.addEventListener('devicechange', async (info) => {
      // 设备发生变化时执行的操作
      const { microphoneId, microphoneArray, speakerId, speakerArray } = await getMicAndSpeakerDevices(this.rtc)
      // 只有在设备列表没有变化只是更新 default 设备时执行
      if (microphoneArray.length == this.devicesInfo.microphoneList.length && microphoneArray.length > 0) {
        if (microphoneId) {
          this.devicesInfo.microphoneId = microphoneId
          this.rtc.setDevice(microphoneId, 'audio')
        }
        this.devicesInfo.microphoneList = microphoneArray
      }
      // 只有在设备列表没有变化只是更新 default 设备时执行
      if (speakerArray.length == this.devicesInfo.speakerList.length && speakerArray.length > 0) {
        if (speakerId) {
          this.devicesInfo.speakerId = speakerId
          const array = Object.keys(this.channels).filter(rtilCode => {
            return rtilCode != this.userInfo.rtilCode
          })
          array.forEach(rtilCode => {
            if (this.channels[rtilCode].audioTrack && this.channels[rtilCode].audioTrack.setPlaybackDevice) {
              this.channels[rtilCode].audioTrack.setPlaybackDevice(localStorage.getItem('speakerId'))
            }
          })
        }
        this.devicesInfo.speakerList = speakerArray
      }
    })
    // 新增cloudProxy按钮设置
    Bus.$off('setLoginScreenButton');
    Bus.$on('setLoginScreenButton', (val) => {
      const { buttonType, operationType } = val.buttonStatus;
      if (buttonType === 7) {
        if (operationType) {
          this.join();
        } else {
          this.join()
        }
      } else if (buttonType === 11) {
        if (!operationType) {
          this.$router.push({ name: 'remove', query: { type: 'disableParty' } })
        }
      }
    })
    // Math.random().toString(36).substr(2)
    Bus.$off('eventInfoUpdate')
    Bus.$on('eventInfoUpdate', (data) => {
      const { id } = data
      // 启动多个事件时推送消息会有退错的情况
      if (id != this.eventInfo.id) return
      // 更新mainWindowInfo
      this.eventInfo = data
      this.startCountDown(data.endTime)
      this.previewList = []
      // 非首次触发时执行getPairList,为了解决pp事件提前加入可能会拿不到正确的PairList
      // 即首次需要等待addPip回调再获取PairList
      if (!this.isFirstTime) {
        // 为了解决添加或删除组等事件的变更后ONAir等状态的恢复
        this.getPairList()
      }
      // 首次触发后，将标志位设置为false，表示后续触发不再是首次
      this.isFirstTime = false; // 更新标志位
      this.dealPreviewAndAudioList(data.previewSetting)
      const hasChanged = this.judgePartyHadChange(data)
      if (hasChanged) {
        this.groups = data.eventParties
        this.join()
      }
    })
    Bus.$on('addPipSuccess', (result) => {
      this.eventInfo.audioMixTaskId = result.audioMixTaskId
      this.eventInfo.id = result.eventId
      this.getPairList()
      // 取消事件监听以防止再次触发
      Bus.$off('addPipSuccess');
    })
    // 更新事件的状态
    Bus.$on('eventInfoStatus', async (status) => {
      this.eventInfo.status = status

      // if (status == '4') {
      //   await this.getEventInfo()
      // }
    })
    Bus.$on('receiverOccupied', (result) => {
      if (this.eventId == result.eventId) {
        this.showReceiverLivingTips = true
        this.occupiedReceiverInfo = result
      }
    })
    Bus.$on('setOutput', (v) => {
      this.outputRtilCode = v.rtilCode
      if (this.rtc._remotePublishUser[this.outputRtilCode]) {
        this.rtc.subscribe(v.rtilCode, 'all')
      }
    })
    Bus.$on('inputChanged', data => {
      this.previewList = []
      this.dealPreviewAndAudioList(data)
    })
    Bus.$on('cancelOutput', (v) => {
      this.outputRtilCode = ''
    })
    Bus.$on('setBehaviors', v => {
      const behaviors = v.behaviors
      behaviors.forEach((val) => {
        if (val.key == 'nickName') {
          this.$set(this.nickNameList, val.mark, val.value)
        } else if (val.key == 'talkToCoordGroup') {
          const { mark, value } = val
          if (mark == this.userInfo.rtilCode) {
            this.userInfo.behavior.talkToCoordGroup = value
            if (Number(value) == 1) {
              Object.keys(this.managePrivate).forEach(rtilCode => {
                this.$set(this.managePrivate, rtilCode, 1)
              })
            } else {
              Object.keys(this.managePrivate).forEach(rtilCode => {
                this.$set(this.managePrivate, rtilCode, 0)
              })
            }
          } else {
            if (Number(value) == 1) {
              this.$set(this.otherHostPrivate, mark, true)
              this.rtc && this.rtc.subscribe(mark, 'audio')
            } else {
              this.$set(this.otherHostPrivate, mark, false)
              this.rtc && this.rtc.unsubscribe(mark, 'audio')
            }
          }
        }
      })
    })
    // 需要实时更新 manage rtilcode 列表
    Bus.$on('managerOnline', v => {
      const { rtilCode } = v
      if (this.userInfo.managerRtils && !this.userInfo.managerRtils.includes(rtilCode)) {
        this.userInfo.managerRtils.push(rtilCode)
      }
      // 因为存在 ws 和 Agora 先后顺序的问题,如果 manageprivte 里面没有当前的这个 rtilcode 说明 Agora 回调快于 ws
      if (!this.coordChannel[rtilCode]) {
        this.$set(this.coordChannel, rtilCode, { id: rtilCode, channel: this.userInfo.realPartyCode })
      }
      if (!this.managePrivate[rtilCode]) {
        if (
          this.userInfo.behavior &&
          this.userInfo.behavior.talkToCoordGroup &&
          this.userInfo.behavior.talkToCoordGroup == '1'
        ) {
          this.$set(this.managePrivate, rtilCode, true)
        } else {
          this.$set(this.managePrivate, rtilCode, false)
        }
        this.getNickName(rtilCode)
      }
    })
    Bus.$on('coordination', (v) => {
      const { enable, type, subPartyCode, rtilCode, starterRtilCode } = v
      // 保存 host pushtalk 的状态
      if (type == 'member' && subPartyCode == this.extraParty && starterRtilCode == this.userInfo.rtilCode) {
        this.$set(this.managePrivate, rtilCode, enable == 1)
      }
      // 保存其他 host 和当前 host 的 pushtalk 状态
      if (type == 'member' && subPartyCode == this.extraParty && rtilCode == this.userInfo.rtilCode) {
        this.$set(this.otherHostPrivate, starterRtilCode, enable == 1)
        if (enable) {
          this.rtc && this.rtc.subscribe(starterRtilCode, 'audio')
        } else {
          this.rtc && this.rtc.unsubscribe(starterRtilCode, 'audio')
        }
      }
      if (type === 'onAir') {
        this.coordinationInfo.onAir = enable
      }
    })
  },
  watch: {
    // 监听groups变化，更新coordinationRoom里面的group布局
    groups: {
      handler (newValue) {
        this.flex = this.styles
        this.getPageLayout()
        this.$nextTick(() => {
          this.getArrow('init')
        })
        this.changeMainWindowLayout = Math.random() + 1
      }
    },
    GroupDisplayList () {
      this.$nextTick(() => {
        this.getArrow('init')
      })
    }
  },
  methods: {
    getPairList (reJoinRtilCode) {
      if (!this.eventInfo.audioMixTaskId || !this.eventInfo.id) return
      // 初始化记录4个pair状态
      this.$http.post('/commentator-backend/am/noLogin/getPairList', {
        taskId: this.eventInfo.audioMixTaskId,
        eventId: this.eventInfo.id
      })
        .then(res => {
          if (res.data.errorCode === '0x0') {
            if (res.data.result) {
              this.pairNumber = res.data.result.length
              if (this.initJoin && res.data.result.length != 0) {
                this.initAMWebsocket()
                this.initJoin = false
              }
              res.data.result.forEach((item) => {
                item.inputDatas && item.inputDatas.forEach((itemInput) => {
                  if (itemInput.ID.includes('Commentator')) {
                    const parts = itemInput.ID.split('_');
                    const rtilCode = parts[2];
                    if (itemInput.audioStreams.length != 0) {
                      itemInput.audioStreams[0].channelDatas && itemInput.audioStreams[0].channelDatas.forEach((itemChannel) => {
                        if (itemChannel.ID == '0') {
                          this.amStatusInfo[rtilCode] = {
                            ...itemChannel
                          }
                          this.$set(this.allPairStatusInfo, item.ID, { ...this.amStatusInfo })
                        }
                      })
                    }
                  }
                })
              })
              this.$nextTick(() => {
                this.groups.forEach(item => {
                  if (this.$refs[`group${item.partyCode}`]) {
                    this.$refs[`group${item.partyCode}`][0].dealStatus(true, '', reJoinRtilCode)
                  }
                })
              })
            }
          }
        })
    },
    groupCallback ({ type, val }) {
      if (type === 'fullscreen') {
        this.State.fullScreen = val
        if (val) {
          document.documentElement.requestFullscreen()
        } else {
          if (document.fullscreenElement) {
            document.exitFullscreen()
          }
        }
      }
    },
    changeMainBoxLayout (val) {
      this.$refs.mainBox.style.justifyContent = val
    },
    dealPreviewAndAudioList (data) {
      const settingInfo = localStorage.getItem('settingInfo') ? JSON.parse(localStorage.getItem('settingInfo')) : {}
      const localInfo = localStorage.getItem('localInfo') ? JSON.parse(localStorage.getItem('localInfo')) : null
      if (localInfo) this.localInfo = localInfo
      let mainwindowInfo = {}
      Object.keys(data).forEach(key => {
        // 有值才根据 local 里面的值更改 enable
        if (data[key] && typeof data[key] === 'object' && settingInfo[key]) {
          data[key].enable = settingInfo[key].enable
          // 初始化视频预览
          if (settingInfo[key].enable && data[key].rtilCode) {
            this.previewList.push(data[key].rtilCode)
          }
          if (key === 'pgm' || key === 'backupPgm') {
            subscribeTrack.addReplaceRtilCode(data[key].rtilCode, localInfo[key].audioValue)
          }
        } else if (data[key] && !settingInfo[key]) {
          if (key === 'pgm' || key === 'backupPgm') {
            this.localInfo[key] = {}
            this.localInfo[key].selectAudioPairIndex = 1
            if (data.result[key].rtilCode) {
              this.localInfo[key].audioValue = data.result[key].rtilCode
              subscribeTrack.addReplaceRtilCode(data.result[key].rtilCode, data.result[key].rtilCode)
            }
          }
        }
        // 初始化 vfb speaker 数据
        const vfbInfo = localStorage.getItem('vfbInfo')
        if (!vfbInfo) {
          if (data[key] && data[key].rtilCode) {
            mainwindowInfo[data[key].rtilCode] = true
          }
        } else {
          mainwindowInfo = JSON.parse(vfbInfo)
          this.vfbSpeakerInfo = JSON.parse(vfbInfo)
        }
      })
      this.allSourceLists = data
      localStorage.setItem('settingInfo', JSON.stringify(data))
      localStorage.setItem('localInfo', JSON.stringify(this.localInfo))
      if (JSON.stringify(mainwindowInfo) != '{}') {
        localStorage.setItem('vfbInfo', JSON.stringify(mainwindowInfo))
      }
    },
    manageGroupCallback (info) {
      const { type, val, rtilCode } = info
      if (type === 'member') {
        this.$set(this.managePrivate, rtilCode, val)
      } else if (type === 'group') {
        Object.keys(this.managePrivate).forEach(rtilCode => {
          this.$set(this.managePrivate, rtilCode, val)
        })
      }
    },
    async getMainWindowInfo () {
      this.previewList = []
      try {
        const { data } = await this.$http.get(`/commentator-backend/eventPreview/${this.eventInfo.id}`)
        // 改为多 host 后需要结合接口和 localstorage 的数据一起使用
        const localInfo = localStorage.getItem('localInfo')
        if (localInfo) {
          this.dealPreviewAndAudioList(data.result)
        } else {
          const mainwindowInfo = {}
          Object.keys(data.result).forEach(key => {
            if (data.result[key] && JSON.stringify(data.result[key]) != '{}') {
              if (key === 'pgm' || key === 'backupPgm') {
                // 从本地获取数据
                if (!this.localInfo) this.localInfo = {}
                this.localInfo[key] = {}
                this.localInfo[key].selectAudioPairIndex = 1
                if (data.result[key].rtilCode) {
                  this.localInfo[key].audioValue = data.result[key].rtilCode
                  subscribeTrack.addReplaceRtilCode(data.result[key].rtilCode, data.result[key].rtilCode)
                }
              }
              // 初始化视频预览
              if (data.result[key] && data.result[key].enable && data.result[key].rtilCode) {
                this.previewList.push(data.result[key].rtilCode)
              }
              const vfbInfo = localStorage.getItem('vfbInfo')
              if (!vfbInfo && data.result[key].rtilCode) {
                mainwindowInfo[data.result[key].rtilCode] = true
              }
            }
          })
          this.vfbSpeakerInfo = mainwindowInfo
          this.allSourceLists = data.result
          if (JSON.stringify(mainwindowInfo) != '{}') {
            localStorage.setItem('vfbInfo', JSON.stringify(mainwindowInfo))
          }
          localStorage.setItem('localInfo', JSON.stringify(this.localInfo))
          localStorage.setItem('settingInfo', JSON.stringify(data.result))
        }
      } catch (err) {
        console.log(err);
      }
    },
    dealMainWindowCallback (info) {
      const { type, vfbType, val } = info
      if (type === 'changeVfbAudioTrack') {
        Object.keys(this.allSourceLists).forEach(async key => {
          if (key === vfbType) {
            const changeToRtilCode = this.allSourceLists[key].allAudioTrackRtilCodes[val - 1]
            // 改变之前保存的值
            this.localInfo[vfbType].audioValue = changeToRtilCode
            this.localInfo[vfbType].selectAudioPairIndex = val
            localStorage.setItem('localInfo', JSON.stringify(this.localInfo))
            subscribeTrack.addReplaceRtilCode(this.allSourceLists[vfbType].rtilCode, changeToRtilCode)
            // 取消之前的订阅
            try {
              if (this.channels[this.allSourceLists[key].rtilCode].audioTrack) {
                this.rtc.stop(this.channels[this.allSourceLists[key].rtilCode].audioTrack)
                await this.rtc.unsubscribe(this.channels[this.allSourceLists[key].rtilCode].audioTrack._userId, 'audio')
              }
            } catch (err) {
              console.log(err)
            }
            // 订阅选择的 rtilCode
            this.rtc && this.rtc.subscribe(changeToRtilCode, 'audio')
          }
        })
      } else if (type === 'changeVfbInfo') {
        const { id } = info
        if (this.vfbSpeakerInfo[id]) {
          val ? this.vfbSpeakerInfo[id] = true : this.vfbSpeakerInfo[id] = false
        }
      }
    },
    changeMute (val) {
      this.$store.commit('isMute', !this.isMute)
      if (val) {
        this.$message({
          type: 'success',
          message: this.$t('coordinationParticipant.websiteSoundOn')
        })
      } else {
        this.$message({
          type: 'success',
          message: this.$t('coordinationParticipant.websiteSoundOff')
        })
      }
      this.changeBehaviorStatus('speaker', !this.isMute)
    },
    changeBehaviorStatus (key, value) {
      // const _this = this
      const params = [
        {
          mark: this.partyListInfo.rtilCode + '',
          annotation: this.partyListInfo.externalJoinInfo.join,
          key,
          value
        }
      ]
      this.$http.post(`${this.State.config.urlInfo.partyline}/partyline-backend/behavior/noLogin/setList`, params)
    },
    async setOperate ({ type, id }) {
      // 防止点击过快
      const currentTime = Date.now()
      if (currentTime - this.lastDisableMic < 1000) {
        if (this.initAudioPopup) {
          this.initAudioPopup = !this.initAudioPopup
          this.$message.warning(this.$t('coordinationParticipant.notOperateFrequery'))
        }
        return
      } else {
        this.initAudioPopup = true
      }
      this.lastDisableMic = currentTime
      this.muteAudioSelf = !this.muteAudioSelf
      this.changeBehaviorStatus('mic', !this.muteAudioSelf)
    },
    changeDevice (obj) {
      if (!this.devicesInfo.cameraId || !obj) return
      if (obj.type == 'audio') {
        this.rtc.setDevice(obj.deviceId, obj.type, obj.type === 'audio' ? 'needLocalAudioTrack' : '')
        this.groups.forEach(item => {
          if (this.$refs[`group${item.partyCode}`]) {
            this.$refs[`group${item.partyCode}`][0].callBackChangeDevice(obj)
          }
        })
      } else if (obj.type == 'speaker') {
        localStorage.setItem('speakerId', obj.deviceId)
        this.$refs.mainwindow.setSpeaker()
        this.$refs.mangeGroup.setSpeaker()
      }
    },
    // 更新设备id
    updateDeviceId (type, info) {
      if (type == 'video') {
        this.devicesInfo.cameraId = info
      } else if (type == 'audio') {
        this.devicesInfo.microphoneId = info
      } else {
        this.devicesInfo.speakerId = info
      }
    },
    async callBacks ({ action, payload }) {
      if (action != 'volumeList' && action != 'receiveMetadata' && action != 'unlinkQuality') {
        console.info(139, action, payload)
      }
      switch (action) {
        case 'user-joined':
          this.userJoin(payload)
          break
        case 'unlinkQuality':
          Bus.$emit('setMainWindowUnlinkQuality', payload)
          break
        case 'user-published':
          this.publishTrack(payload)
          break
        case 'user-unpublished':
          if (payload.type == 'audio') {
            if (this.otherHostPrivate[payload.id] || this.otherHostPrivate[payload.id] == false) {
              this.$set(this.coordChannel, payload.id, {
                ...this.coordChannel[payload.id],
                audioTrack: null
              })
            }
          }
          break
        case 'subscribe-track':
          this.subscribeTrack(payload)
          break
        case 'user-left':
          this.userLeft(payload)
          break
        case 'unsubscribe-track':
          if (payload.type == 'audio') {
            if (this.otherHostPrivate[payload.id] || this.otherHostPrivate[payload.id] == false) {
              this.$set(this.coordChannel, payload.id, {
                ...this.coordChannel[payload.id],
                audioTrack: null
              })
            } else {
              this.$set(this.channels, payload.id, {
                ...this.channels[payload.id],
                audioTrack: null
              })
            }
          }
          break
        case 'autoPlayFailed':
          this.$alert(this.$t('coordinationParticipant.autoPlayFailed'), {
            confirmButtonText: this.$t('coordinationRoom.ok'),
            customClass: 'autoplayFailed',
            confirmButtonClass: 'joinBtn',
            showClose: false,
            showCancelButton: false,
            closeOnClickModal: false,
            closeOnPressEscape: false
          }).then(() => {})
          break
      }
    },
    async micChangeCallBack (changeDevice) {
      this.devicesInfo = await this.rtc.getDevice()
      if (this.rtc._localAudioTrack &&
      (
        (this.rtc._localAudioTrack._deviceName.includes(changeDevice.device.label)) ||
       (changeDevice.state === 'INACTIVE' && this.rtc._localAudioTrack._config.microphoneId == 'default')
      )) {
        const oldMic = await this.rtc._AgoraRTC.getMicrophones()
        oldMic[0] && this.rtc.setDevice(oldMic[0].deviceId, 'audio', 'needLocalAudioTrack', (type) => {
          type === 'success' ? this.$message.success(this.$t('coordinationParticipant.autoSwitchNewMicSuccess')) : this.$message.error(this.$t('coordinationParticipant.autoSwitchNewMicFailed'))
          this.devicesInfo.microphoneId = oldMic[0].deviceId
        })
        // 同时更改extraParty的设备
        this.groups.forEach(item => {
          if (this.$refs[`group${item.partyCode}`]) {
            this.$refs[`group${item.partyCode}`][0].callBackChangeDevice(oldMic[0])
          }
        })
      } else if (changeDevice.state === 'ACTIVE' &&
       this.rtc._localAudioTrack && !this.rtc._localAudioTrack._deviceName.includes(changeDevice.device.label) &&
       this.rtc._localAudioTrack._config.microphoneId == 'default' &&
       this.devicesInfo.microphoneList[0].label != this.rtc._localAudioTrack._deviceName
      ) {
        // 插入mic
        changeDevice.device && changeDevice.device.deviceId && this.rtc.setDevice(changeDevice.device.deviceId, 'audio', 'needLocalAudioTrack', (type) => {
          if (type == 'success') {
            this.$message.success(this.$t('coordinationParticipant.autoSwitchNewMicSuccess'))
          } else if (type != 'success') {
            this.$message.error(this.$t('coordinationParticipant.autoSwitchNewMicFailed'))
          }
        })
        // 同时更改extraParty的设备
        this.groups.forEach(item => {
          if (this.$refs[`group${item.partyCode}`]) {
            this.$refs[`group${item.partyCode}`][0].callBackChangeDevice(changeDevice.device)
          }
        })
      }
      this.$emit('callback', { type: 'saveDeviceList', val: this.devicesInfo })
    },
    async playbackCallback (changeDevice) {
      if (!this.rtc) return
      this.devicesInfo = this.rtc && await this.rtc.getDevice()
      if (changeDevice.state === 'ACTIVE' && this.rtc._localAudioTrack) {
        localStorage.setItem('speakerId', this.devicesInfo.speakerList[0].deviceId)
        this.devicesInfo.speakerId = this.devicesInfo.speakerList[0].deviceId
      } else {
        try {
          const oldSpeaker = await this.rtc._AgoraRTC.getPlaybackDevices()
          localStorage.setItem('speakerId', oldSpeaker[0].deviceId)
          this.devicesInfo.speakerId = oldSpeaker[0].deviceId
        } catch (e) {
          console.log('get mic list failed')
        }
      }
    },
    userJoin (payload) {
      if (!this.previewList.includes(payload.id) && this.userInfo.managerRtils.includes(payload.id)) {
        if (!this.managePrivate[payload.id] && this.managePrivate[payload.id] != false && this.managePrivate[payload.id] != '0') {
          if (this.userInfo.behavior.talkToCoordGroup && this.userInfo.behavior.talkToCoordGroup == '1') {
            this.$set(this.managePrivate, payload.id, true)
          } else {
            this.$set(this.managePrivate, payload.id, false)
          }
        }
        if (!this.coordChannel[payload.id]) {
          this.$set(this.coordChannel, payload.id, payload)
        }
        if (this.coordChannel[this.userInfo.rtilCode]) this.$delete(this.coordChannel, this.userInfo.rtilCode)
        this.getNickName(payload.id)
      }
    },
    getNickName (rtilCode) {
      if (!this.userInfo.realPartyCode) return
      this.$http
        .post(`${this.State.config.urlInfo.partyline}/partyline-backend/behavior/noLogin/getList`, {
          originRtilCode: this.userInfo.rtilCode,
          annotation: this.userInfo.realPartyCode,
          keys: [
            'nickName',
            'camera',
            'mic',
            'blockAudio',
            'blockVideo',
            'tagName',
            'talkback',
            'mixAudio'
          ],
          marks: [rtilCode]
        })
        .then((res) => {
          if (res.data.errorCode === '0x0') {
            const result = res.data.result[rtilCode]
            this.$set(this.nickNameList, rtilCode, result.nickName)
          }
        })
    },
    userLeft (payload) {
      const { id } = payload
      this.$delete(this.channels, id)
      this.$delete(this.coordChannel, id)
      // 删除已经离开的 host
      if (this.userInfo.managerRtils.includes(id)) {
        this.userInfo.managerRtils = this.userInfo.managerRtils.filter(rtilCode => {
          return rtilCode != id
        })
      }
    },
    publishTrack (payload) {
      const { id, type } = payload
      // 首先判断是否为 host 的 rtilcode 如果是则需要判断当前的 host 是否有和自己 pushtalk 然后才去订阅
      if (this.userInfo.managerRtils.includes(id)) {
        if (this.otherHostPrivate[id]) {
          this.rtc && this.rtc.subscribe(id, type)
        }
      } else {
        if (type === 'video') {
          if (this.previewList.includes(id)) this.rtc && this.rtc.subscribe(id, type)
        } else {
          this.rtc && this.rtc.subscribe(id, type)
        }
      }
    },
    // 替换 audioTrack
    replaceAudioTrack (payload, info) {
      const { rtc, replaceRtilCode, changeToRtilCode, changeVolumeBar } = info
      const hasPubTrack = rtc._remotePublishUser[changeToRtilCode] && rtc._remotePublishUser[changeToRtilCode]._audioTrack
      if (hasPubTrack) {
        const changeToTrack = rtc._remotePublishUser[changeToRtilCode]._audioTrack
        payload.audioTrack = changeToTrack
        if (changeVolumeBar) {
          // 音量柱的显示
          showVolume(changeToTrack, replaceRtilCode)
        }
      }
      return payload
    },
    subscribeTrack (payload) {
      const { audioTrack, id, type } = payload
      const pgm = this.allSourceLists.pgm
      const backupPgm = this.allSourceLists.backupPgm
      if (type === 'all') {
        // 因为 pgm 的 audio 是可以选择，所以需要选择当前的 audio
        if (pgm && pgm.rtilCode == id || backupPgm && backupPgm.rtilCode == id) {
          payload = subscribeTrack.dealTrack(this.rtc, payload)
        } else {
          if (audioTrack) {
            showVolume(audioTrack, id)
          }
        }
        this.$set(this.channels, id, payload)
      } else {
        payload = subscribeTrack.dealTrack(this.rtc, payload)
        if (pgm && subscribeTrack.replaceRtilCode[pgm.rtilCode] == id) {
          this.$set(this.channels, pgm.rtilCode, payload)
        } else if (backupPgm && subscribeTrack.replaceRtilCode[backupPgm.rtilCode] == id) {
          this.$set(this.channels, backupPgm.rtilCode, payload)
        } else {
          this.$set(this.channels, id, payload)
        }
        // 需要判断如果不一致就使用新的 audiotrack
        if (payload.audioTrack) {
          if (payload.audioTrack._userId != audioTrack._userId) {
            showVolume(payload.audioTrack, id)
          } else {
            showVolume(audioTrack, id)
          }
        } else {
          showVolume(audioTrack, id)
        }
      }
      if (!this.previewList.includes(id) && this.userInfo.managerRtils.includes(id)) this.$set(this.coordChannel, id, payload)
    },
    judgePartyHadChange (data) {
      const { eventParties } = data
      let hasChanged = false
      const newPartyList = []
      eventParties.forEach(item => {
        newPartyList.push(item.partyCode)
        if (!this.realPartyListInfo[item.partyCode]) {
          hasChanged = true
        }
        // 用户在编辑页面更改了group的title
        eventParties.forEach(item => {
          this.groups.forEach(originItem => {
            if (item.id === originItem.id && item.partyCode == originItem.partyCode && item.title !== originItem.title) {
              hasChanged = true
            }
          })
        })
      })
      if (!hasChanged) {
        Object.keys(this.realPartyListInfo).forEach(partyCode => {
          if (!newPartyList.includes(partyCode)) {
            hasChanged = true
          }
        })
      }
      return hasChanged
    },
    // 初始化websocket
    initWebsocket () {
      const row = window.location.protocol === 'http' ? 'ws' : 'wss'
      if (!this.Config || !this.Config.urlInfo) return
      const ccWebsocket = this.Config.urlInfo.ccWebsocket
      const random32Str = Utils.getRandomStr()
      this.ws = new Websocket({
        url: `${row}://${ccWebsocket}/pageSerBusRequest?taskId=${random32Str}&businessId=${this.extraParty}&notCheckUser=true`,
        sendHeart: true,
        key: 'pageSerBusRequestByPartyCode'
      })
      this.ws.initWebSocket()
    },
    async initAMWebsocket () {
      if (!this.eventInfo.audioMixTaskId) return
      await this.$http.get(`/commentator-backend/am/noLogin/getAudioMixerConfig/${this.eventInfo.id}`)
        .then(res => {
          if (res.data.errorCode === '0x0') {
            const { result } = res.data
            if (result) {
              const endpoint = result.graphql
              const extractedHostname = new URL(endpoint).hostname;
              const obj = {
                host: extractedHostname,
                'x-api-key': result.apiKey
              }
              const jsonStr = JSON.stringify(obj);
              this.encodedData = btoa(jsonStr);
              this.realtimeApiHostname = extractedHostname.replace('appsync-api', 'appsync-realtime-api');
            }
          }
        })
      const row = window.location.protocol === 'http' ? 'ws' : 'wss'
      this.ws = new Websocket({
        url: `${row}://${this.realtimeApiHostname}/graphql?header=${this.encodedData}&payload=e30=`,
        wsType: 'graphql',
        sendHeart: false,
        key: 'graphql',
        pairNumber: this.pairNumber,
        taskid: this.eventInfo.audioMixTaskId
      })
      this.ws.initWebSocket()
    },
    async getEventInfo () {
      if (!this.eventId) return
      await this.$http
        .post(`/commentator-backend/event/v2/${this.eventId}`, {}, { errorTips: false })
        .then((res) => {
          if (res.data.errorCode == '0x0') {
            const result = res.data.result
            this.checkHostJoin(result.partyCode)
            this.groups = result.eventParties || []
            this.eventInfo = result
            this.$store.commit('saveEventInfo', result)
            if (this.eventInfo.audioMixTaskId) {
              this.getPairList()
            }
            this.startCountDown(result.endTime)
            this.getPageLayout()
          } else {
            if (res.data.errorCode == '82301001') {
              this.$router.push({
                name: 'remove',
                query: {
                  type: 'disableParty'
                }
              })
            } else {
              this.$message.error(res.data.errorInfo)
            }
          }
        })
    },
    async getCoordinationInfo () {
      await this.$http.post(`${this.Config.urlInfo.partyline}/partyline-backend/coordination/noLogin/group/${this.eventId}`).then(res => {
        if (res.data.errorCode == '0x0') {
          const result = res.data.result
          const newGroupSettings = result.newGroupSettings || []
          newGroupSettings.forEach(v => {
            this.$set(this.groupInfo, v.partyCode, v)
          })
          // this.talkAllGroup = result.allGroupTalk == 1
          this.coordinationInfo = result
        }
      })
    },
    checkHostJoin (parentPartyCode) {
      this.$http
        .get(`${this.Config.urlInfo.partyline}/partyline-backend/dictionary?key=hostUuidRelation&partyCode=${parentPartyCode}`)
        .then(async res => {
          if (res.data.errorCode == '0x0') {
            this.extraParty = parentPartyCode
            this.initWebsocket()
            this.State.newPageLoading = false
            // this.$alert(this.$t('coordinationParticipant.autoPlayFailed'), {
            //   confirmButtonText: this.$t('coordinationRoom.ok'),
            //   customClass: 'autoplayFailed',
            //   confirmButtonClass: 'joinBtn',
            //   showClose: false,
            //   showCancelButton: false,
            //   closeOnClickModal: false,
            //   closeOnPressEscape: false
            // }).then(async () => {
            await this.getMainWindowInfo()
            this.join()
            // })
          }
        })
    },
    getPageLayout () {
      if (this.groups.length == 1) {
        this.leftWidth = '43vw'
        this.groupWidth = '18.5vw'
      } else {
        this.leftWidth = '570px'
        this.groupWidth = ''
      }
    },
    getArrow (key) {
      // 如果是更改组或者隐藏组时，任务重新加载这块 UI
      if (key === 'init') {
        this.hideLeft = true
        this.hideRight = true
        this.marginLeft = 0
        this.marginLeftNumber = 0
        $('.group-list').css({
          'margin-left': 0
        })
        const itemWidth = $('.group-box').width()
        const groupShowList = $('.group-list .group-box').filter((index, element) => {
          return window.getComputedStyle(element).display !== 'none'
        })
        $('.group-list').css({ width: groupShowList.length * (itemWidth + 12) })
        const groupListWidth = $('.group-list').width()
        const groupWidth = $('.group').width()
        if (groupListWidth <= groupWidth) {
          this.hideRight = true
        } else {
          this.hideRight = false
        }
      } else {
        const itemWidth = $('.group-box').width()
        $('.group-list').css({
          'margin-left': 0
        })
        if (itemWidth) {
          this.marginLeft = 0
          this.marginLeft -= this.marginLeftNumber * (itemWidth + 12)
        }
        // 不用过度不然一直拖拽会卡顿
        $('.group-list').css({
          'margin-left': this.marginLeft
        })
        const groupShowList = $('.group-list .group-box').filter((index, element) => {
          return window.getComputedStyle(element).display !== 'none'
        })
        $('.group-list').css({ width: groupShowList.length * (itemWidth + 12) })
        const groupListWidth = $('.group-list').width()
        const groupWidth = $('.group').width()
        if (this.marginLeft < 0) {
          // 第二个判断条件是在拖拽浏览器时会有一定的误差，而导致向右滑动的按钮出现
          const notScrollNumber = groupShowList.length - this.marginLeftNumber
          const groupCanHaveNumber = parseInt(groupWidth / (itemWidth + 12))
          if (notScrollNumber <= groupCanHaveNumber) {
            this.hideRight = true
          } else {
            this.hideRight = false
          }
        } else {
          if (groupListWidth <= groupWidth) {
            this.hideRight = true
          } else {
            this.hideRight = false
          }
        }
      }
      if (!this.hideRight || !this.hideLeft) {
        this.flex = {}
      } else {
        this.flex = this.styles
      }
    },
    clickRight () {
      const itemWidth = $('.group-box').width() + 12
      const groupWidth = $('.group').width()
      const groupListWidth = $('.group-list').width()
      this.marginLeft -= itemWidth
      this.marginLeftNumber++
      $('.group-list').animate({
        'margin-left': this.marginLeft
      })
      if (this.marginLeft < groupWidth - groupListWidth) {
        this.hideRight = true
      }
      if (this.marginLeft < 0) {
        this.hideLeft = false
      }
    },
    clickLeft () {
      const itemWidth = $('.group-box').width() + 12
      const groupListWidth = $('.group-list').width()
      this.marginLeft += itemWidth
      this.marginLeftNumber--
      $('.group-list').animate({
        'margin-left': this.marginLeft
      })
      if (this.marginLeft >= 0) {
        this.hideLeft = true
        this.hideRight = false
      }
      if (-this.marginLeft < groupListWidth - 2 * itemWidth) {
        this.hideRight = false
      }
    },
    changeTalkAllGroups (talkAllGroupStatus) {
      if (talkAllGroupStatus) {
        this.talkAllGroup = false
      } else {
        this.talkAllGroup = true
      }
      // this.changeTalkGroup()
      this.clickTalkAllGroups = Math.random()
    },
    async join () {
      if (this.rtc) await this.rtc.leave()
      const haveHistoryMem = localStorage.getItem('hostMemberId')
      const params = {
        memberId: haveHistoryMem || this.loginInfo.id + '_' + new Date().getTime() + '_commentator',
        partyCode: this.extraParty + '',
        role: 0,
        type: 0,
        host: true,
        hostUuid: this.hostUuid,
        behaviorKeyList: ['speaker', 'mic', 'nickName', 'talkToAllGroup', 'talkToCoordGroup'],
        subBehaviorKeyList: ['talkToGroup']
      }
      // params.behaviors = data

      this.$http
        .post(`${this.State.config.urlInfo.partyline}/partyline-backend/party/noLogin/join`, params)
        .then(async (res) => {
          if (res.data.errorCode == '0x0') {
            localStorage.setItem('hostMemberId', params.memberId)
            const result = res.data.result
            this.partyListInfo = result
            this.userInfo = result
            this.$set(this.nickNameList, result.rtilCode, result.behavior.nickName)
            await this.getCoordinationInfo()
            this.getArrow('init')
            // 防止有时加载过慢导致还没有这个方法
            if (this.$refs.mainwindow) {
              this.$refs.mainwindow.changeInputSourceInfo(this.allSourceLists)
            } else {
              setTimeout(() => {
                this.$refs.mainwindow.changeInputSourceInfo(this.allSourceLists)
              }, 500)
            }
            if (this.partyListInfo.behavior) {
              this.State.isMute = this.partyListInfo.behavior.speaker === 'false'
              this.muteAudioSelf = this.partyListInfo.behavior.mic === 'false'
            }
            // Behavior 给 talkAllgroup 赋值
            if (result.behavior.talkToAllGroup) {
              this.talkAllGroup = result.behavior.talkToAllGroup == '1'
            }
            // 初始化当前 host pushtalk 的数据和其他 host 和当前 host pushtalk 的数据
            const groupMemberSettingsMap = this.groupInfo[result.realPartyCode].groupMemberSettingsMap
            if (groupMemberSettingsMap) {
              Object.keys(groupMemberSettingsMap).forEach(rtilCode => {
                if (rtilCode == this.userInfo.rtilCode) {
                  // host 自己的数组里面记录的是其他 host 和自己 pushtalk 的状态
                  if (groupMemberSettingsMap[rtilCode].length) {
                    groupMemberSettingsMap[rtilCode].forEach(info => {
                      this.$set(this.otherHostPrivate, info.rtilCode, info.talk == 1)
                    })
                  }
                } else {
                  if (groupMemberSettingsMap[rtilCode].length) {
                    groupMemberSettingsMap[rtilCode].forEach(info => {
                      if (info.rtilCode == this.userInfo.rtilCode) {
                        this.$set(this.managePrivate, rtilCode, info.talk == 1)
                      }
                    })
                  }
                }
              })
            }
            await this.getTalkToCoordGroup()
            this.realPartyListInfo = {}
            res.data.result.realPartyList.forEach(item => {
              item.joinCode = this.extraParty
              item.managerRtils = res.data.result.managerRtils
              item.videoQualities = res.data.result.videoQualities
              this.$set(this.realPartyListInfo, item.realPartyCode, item)
            })
            this.joinChannel(res.data.result.externalJoinInfo.externalPartyInfo[0].joinInfo, res.data.result, result.behavior)
          } else if (res.data.errorCode === '80520113') {
            this.$router.push({ name: 'remove', query: { type: 'disableParty' } })
          }
        })
    },
    // 查询哪些 host 开启了 talk to coord group
    async getTalkToCoordGroup () {
      await this.$http
        .post(`${this.State.config.urlInfo.partyline}/partyline-backend/behavior/noLogin/getList?toCoordGroup`, {
          originRtilCode: this.userInfo.rtilCode,
          annotation: this.userInfo.realPartyCode,
          keys: ['talkToCoordGroup'],
          marks: this.userInfo.managerRtils
        })
        .then(res => {
          if (res.data.errorCode === '0x0') {
            const hostMap = res.data.result
            if (hostMap) {
              Object.keys(hostMap).forEach(rtilCode => {
                if (hostMap[rtilCode].talkToCoordGroup && hostMap[rtilCode].talkToCoordGroup == '1') {
                  this.$set(this.otherHostPrivate, rtilCode, 1)
                }
              })
            }
          }
        })
    },
    async joinChannel (data, result, behavior) {
      const { appId, tokenCode, rtilCode, realPartyCode, videoQualities } = data
      const { talkToCoordGroup } = behavior
      let hasPushTalk = false
      Object.keys(this.managePrivate).forEach(rtilCode => {
        if (this.managePrivate[rtilCode]) hasPushTalk = true
      })
      if (talkToCoordGroup && talkToCoordGroup == '1') hasPushTalk = true
      const params = {
        appId,
        channel: realPartyCode + '',
        token: tokenCode,
        uid: rtilCode,
        codec: 'h264',
        videoConfig: videoQualities,
        cameraId: this.devicesInfo.cameraId,
        microphoneId: this.devicesInfo.microphoneId,
        video: false,
        audio: !!this.devicesInfo.microphoneId,
        subscribeRemote: false,
        publishAudio: hasPushTalk && !this.muteAudioSelf,
        publish: true, // 默认 publish 因为这个 channel 里面的人不会发视频，如果音频在不发无法从 user-join 中知道都有哪些人
        startProxy: result.status.cloudProxy === 1,
        getRemoteNetworks: true
      }

      await this.rtc.join(params)
    },
    startCountDown (endTime) {
      Bus.$emit('startCountDown', endTime)
    },
    upDateOnAir (status) {
      this.$set(this.coordinationInfo, 'onAir', status)
    },
    updateMainWindowType (type) {
      this.eventInfo.mainWindowType = type
    },
    closeReceiverDialog (id) {
      this.showReceiverLivingTips = false
    },
    callBack (id, value, type) {
      this.occupiedReceiverInfo.receivers.forEach(item => {
        if (item.receiverId === value.receiverId) {
          this.$set(item, 'status', type)
        }
      })
    }
  },
  beforeDestroy () {
    this.ws && this.ws.off()
    window.removeEventListener('resize', this.getArrow)
  }

}
</script>
<style lang="less" scoped>
.coordination {
  height: calc(100% - 70px);
  width: 100vw;
  .foot {
    min-width: 1375px;
    height: 64px;
    background: #000;
    width: 100%;
    position: fixed;
    bottom: 0;
    text-align: center;
    z-index: 2;
    .optionBox {
      height: 100%;
      line-height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-top: 2px solid #28282e;
      .icon-speakeroff{
        margin-left:40px;
        font-size:25px;
         &.active {
          background: #F6445A;
        }
      }
      .icon-speakeron{
        margin-left:40px;
        font-size:25px;
      }
      .icon-universal_micon{
        font-size:25px;
      }
      .icon-Michover{
        font-size:19px;
         &.active {
          background: #F6445A;
        }
      }
      i {
        width: 40px;
        height: 40px;
        background: #33AB4F;
        border-radius: 50%;
        svg {
          width: 100%;
          height: 100%;
        }
        &.cameraIcon, &.muteVideo {
          margin-right: 48px;
          &.m_r_0 {
            margin-right: 0;
          }
        }
        &.cameraIcon:before {
          font-size: 20px;
        }
        &.audioIcon  {
          margin-right: 48px;
          &.m_r_0 {
            margin-right: 0;
          }
        }
        &.muteAudio,
        &.audioIcon,
        &.pushTalk {
          border: none;
          margin-top: -20px;
          position: relative;
          width: 50px;
          div {
            position: absolute;
            border: 4px solid rgba(246, 68, 90, 0.5);
            opacity: 1;
            border-radius: 50%;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            -webkit-animation: lds-ripple 1.5s cubic-bezier(0, 0.2, 0.8, 1) infinite;
            animation: lds-ripple 1.5s cubic-bezier(0, 0.2, 0.8, 1) infinite;
            &:nth-child(2) {
              -webkit-animation-delay: -1s;
              animation-delay: -1s;
            }
          }
          @keyframes lds-ripple {
            0% {
              width: 0;
              height: 0;
              opacity: 1;
            }
            100% {
              width: 55px;
              height: 55px;
              opacity: 0;
            }
          }
        }
        span {
          position: relative;
          top: -28px;
          font-size: 12px;
        }
        .loading {
          // position: absolute;
          // right: 0;
          width: 40px;
          height: 40px;
          top: 0px;
          display: inline-block;
          animation: loading-rotate 1s linear infinite;
        }
      }
      ::v-deep .el-divider--vertical {
        height: 2em;
        margin: 0 40px;
        background-color: #454549;
      }
    }
  }
  .box {
    height: 100%;
    display: flex;
  }
  .source {
    // overflow-y: auto;
    // width: 570px;
    height: 100%;
    margin: 0 12px 0 24px;
    // min-width: 570px;
    min-width: 380px;
    // min-width: 677px;
  }
  .group {
    // max-width: calc(100vw - 606px);
    // max-width: calc(100vw - 660px);
    max-width: calc(100vw - 713px);

    height: 100%;
    overflow: hidden;
    .move-btn {
      cursor: pointer;
      position: fixed;
      top: 50%;
      transform: translateY(-50%);
      font-size: 20px;
      opacity: 0.5;
      z-index: 10;
      &.left-icon {
        left: calc(60vw + 20px);
      }
      &.right-icon {
        right: 0px;
      }
    }
    .group-list {
      height: 100%;
      white-space: nowrap;
    }
    // overflow-x: scroll;
  }
}
</style>
