<template>
  <div class="outputSetting">
    <el-dialog
      :title="$t('leftPaneOutPut.gridSetting')"
      :visible="gridInfo.type === 'G'"
      @close="closeDialog"
    >
      <div class="top">
        <span class="outPutName">{{$t('leftPaneOutPut.outputName')}}</span>
        <el-input
          autocomplete="off"
          :placeholder="$t('leftPaneOutPut.inputName')"
          popper-class="grid-input"
          v-model.trim="gridParams.name"
          :disabled="gridInfo.auth === 'view'"
        ></el-input>
      </div>

      <!-- 滑块区域 -->
      <div class="middle" :class="{ch: lang === 'zh'}">
        <div class="block">
          <div class="sliderName">{{$t('leftPaneOutPut.bitrate')}}</div>
          <el-slider
            v-model="gridParams.outputGridTemplate.vBitrate"
            :max="20"
            :min="5"
            :show-input="true"
            :show-input-controls="false"
            :disabled="gridInfo.auth === 'view'"
          >
          </el-slider>
          <!-- <i>kbps</i> -->
          <div class="mbps">Mbps</div>
        </div>
        <!-- <div class="resolution">
          <div class="select-grid-name">{{$t('leftPaneOutPut.resolution')}}</div>
          <div class="text">Follow "event Format"</div>
        </div> -->
      </div>

      <!-- 分割线 -->
      <el-divider></el-divider>
      <!-- 单选框 -->
      <div class="radioBox" :class="{radioBoxCh: lang === 'zh'}">
        <span class="radioName">{{$t('leftPaneOutPut.streamType')}}</span>
        <el-radio
          v-model="gridParams.outputGridTemplate.streamType"
          label="1"
          :disabled="gridInfo.auth === 'view'"
          >{{$t('leftPaneOutPut.programFeed')}}</el-radio
        >
        <el-radio
          v-model="gridParams.outputGridTemplate.streamType"
          label="2"
          :disabled="gridInfo.auth === 'view'"
          >{{$t('leftPaneOutPut.cleanFeed')}}</el-radio
        >
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="save()" v-if="gridInfo.auth === 'edit'" :class="{activeStyle: gridParams.name}"
          >{{$t('leftPaneOutPut.save')}}</el-button
        >
        <el-button @click="cancel()" v-else>{{$t('leftPane.cancel')}}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Cookie from 'js-cookie'
import Bus from '@/assets/js/bus.js'
export default {
  props: {
    gridInfo: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      gridParams: {
        id: '',
        name: '',
        outputGridTemplate: {
          resolution: '',
          streamType: '1',
          // vBitrate: 8192
          vBitrate: 15
        },
        type: 'G'
      },
      lang: 'en'
    }
  },
  created () {
    if (this.gridInfo.origin) {
      this.getOriginGridInfo()
    } else if (!this.gridInfo.origin && this.gridInfo.id) {
      this.getGridInfo()
    }
    this.lang = Cookie.get('userLanguage')
  },
  methods: {
    closeDialog (type) {
      this.$emit('closeDialog', type)
    },
    async getGridInfo () {
      const { data } = await this.$http.post(
        `/commentator-backend/output/template/${this.gridInfo.id}`
      )
      if (
        data.result.outputGridTemplate &&
        data.result.outputGridTemplate.vBitrate
      ) {
        data.result.outputGridTemplate.vBitrate = Number(
          data.result.outputGridTemplate.vBitrate.substring(
            0,
            data.result.outputGridTemplate.vBitrate.length - 4
          )
        ) / 1000
        this.gridParams = data.result
      }
    },
    async getOriginGridInfo () {
      const { data } = await this.$http.post(
        '/commentator-backend/output/template/output/ipOut/detail',
        {
          eventId: this.gridInfo.eventId,
          id: this.gridInfo.id,
          origin: this.gridInfo.origin
        }
      )
      if (
        data.result.outputGridTemplate &&
        data.result.outputGridTemplate.vBitrate
      ) {
        data.result.outputGridTemplate.vBitrate = Number(
          data.result.outputGridTemplate.vBitrate.substring(
            0,
            data.result.outputGridTemplate.vBitrate.length - 4
          )
        ) / 1000
        this.gridParams = data.result
      }
    },
    // 校验nickName是否重复
    async checkName () {
      try {
        const res = await this.$http.post(
          '/commentator-backend/output/template/name/check',
          {
            id: this.gridParams.id,
            name: this.gridParams.name,
            type: 'G'
          }
        )
        if (res.data.errorCode === '0x0') {
          return 'success'
        } else {
          return false
        }
      } catch (err) {
        console.log(err)
      }
    },
    async save () {
      // 校验用户输入名字不为空
      // if (!this.gridParams.name) {
      //   this.$message.warning(this.$t('leftPaneOutPut.outputNameRequired'))
      // }
      // 校验名字不能重复
      const res = await this.checkName()
      if (res === false) return
      if (this.gridParams.id) {
        // 编辑
        try {
          await this.$http.post('/commentator-backend/output/template/save', {
            id: this.gridParams.id,
            name: this.gridParams.name,
            outputGridTemplate: {
              resolution: this.gridParams.outputGridTemplate.resolution,
              streamType: this.gridParams.outputGridTemplate.streamType,
              // vBitrate: this.gridParams.outputGridTemplate.vBitrate + 'kbps'
              vBitrate: (this.gridParams.outputGridTemplate.vBitrate * 1000) + 'kbps'
            },
            type: 'G'
          })
          this.gridParams = {
            id: '',
            name: '',
            outputGridTemplate: {
              resolution: '1080P',
              streamType: '1',
              // vBitrate: 8192
              vBitrate: 15 * 1000
            },
            type: 'G'
          }
        } catch (err) {
          console.log(err)
        }
      } else {
        try {
          await this.$http.post('/commentator-backend/output/template/save', {
            id: '',
            name: this.gridParams.name,
            outputGridTemplate: {
              resolution: this.gridParams.outputGridTemplate.resolution,
              streamType: this.gridParams.outputGridTemplate.streamType,
              // vBitrate: this.gridParams.outputGridTemplate.vBitrate + 'kbps'
              vBitrate: (this.gridParams.outputGridTemplate.vBitrate * 1000) + 'kbps'
            },
            type: 'G'
          })
        } catch (err) {
          console.log(err)
        }
      }
      this.closeDialog('save')
      Bus.$emit('getIpoutList')
    },
    cancel () {
      this.closeDialog()
    }
  }
}
</script>

<style lang="less" scoped>
.outputSetting {
  /deep/ .el-dialog {
    width: 760px;
    height: 640px;
    background: #28282e;
    box-shadow: 0px 20px 15px 0px rgba(0, 0, 0, 0.19),
      0px 13px 14px 0px rgba(0, 0, 0, 0.38),
      0px 7px 12px 0px rgba(0, 0, 0, 0.32);
    border-radius: 8px;
    .el-dialog__title {
      width: 111px;
      height: 24px;
      font-size: 20px;
      font-weight: 500;
      color: #ffffff;
      line-height: 25px;
    }
    .el-dialog__headerbtn {
      i {
        color: #F1F1F1;
      }
    }
    .el-dialog__body {
      height: 440px;
      padding-right: 40px;
      .top {
      .outPutName {
         text-align: right;
         font-size: 16px;
         font-weight: 500;
         color: #f1f1f1;
         line-height: 16px;
         margin-right: 8px;
      }
       .el-input {
        width: 558px;
        height: 40px;
        background-color: #333339;
        border-radius: 4px;
        .el-input__inner {
          background-color: #333339;
          border: none;
          color: #fff;
          &::placeholder {
            font-size: 14px;
            color: #555661;
          }
        }
        }
      }
      .middle {
      margin-top: 28px;
      display: flex;
      padding-right: 35px;
      margin-left: 47px;
      justify-content: space-between;
      .block {
        display: flex;
        justify-content: flex-start;
        .sliderName {
          // width: 100px;
          height: 32px;
          line-height: 32px;
          text-align: end;
          margin-right: 16px;
          font-size: 16px;
          font-weight: 500;
          color: #f1f1f1;
        }
        .el-slider {
          width: 230px;
          .el-slider__input {
            width: 56px;
            height: 32px;
            line-height: 32px;
            background: #333339;
            border-top-left-radius: 2px;
            border-bottom-left-radius: 2px;
            .el-input {
              width: 56px;
              height: 32px;
              background: #333339;
              border-top-left-radius: 2px;
              border-bottom-left-radius: 2px;
              .el-input__inner {
                text-align: start;
                background: #333339;
                border: none;
                border-bottom-right-radius: 0;
              }
            }
          }
          .el-slider__runway {
            width: 144px;
            height: 4px;
            background: #585b5d;
            border-radius: 3px;
            .el-slider__bar {
              background-color: #03897b;
              height: 4px;
            }
            .el-slider__button {
              background: #585b5d;
              border: none;
            }
          }
        }
        .mbps {
          width: 40px;
          height: 33px;
          line-height: 32px;
          margin-top: 3px;
          text-align: left;
          background: #333339;
          color: #909090;
          font-size: 14px;
        }
      }
      .resolution {
        width: 272px;
        display: flex;
        justify-content: flex-end;
        .select-grid-name {
          text-align: right;
          width: 83px;
          height: 40px;
          line-height: 40px;
          font-size: 16px;
          margin-right: 5px;
          font-weight: 500;
          color: #f1f1f1;
        }
        .text {
          width: 184px;
          height: 40px;
          line-height: 40px;
          background: #333339;
          border-radius: 4px;
          opacity: 0.9;
          font-size: 14px;
          color: #6D6D6D;
        }
      }
     }
     .ch {
        padding-right: 66px;
        margin-left: 24px;
      }
    }
    .el-divider {
      width: 612px;
      height: 1px;
      background-color: #333339;
      margin-left: 48px;
    }
    .radioBox {
      margin-left: 12px;
      .radioName {
        // fixme
        display: inline-block;
        text-align: right;
        // fixme
        margin-right: 16px;
        font-size: 16px;
        font-weight: 500;
        color: #f1f1f1;
      }
      .el-radio__input.is-checked .el-radio__inner {
        background: #454549;
        border: none;
        width: 16px;
        height: 16px;
      }
      .el-radio__inner::after {
        width: 8px;
        height: 8px;
        background-color: #454549;
        background-color: #03897b;
      }
      .el-radio__input.is-checked + .el-radio__label {
        font-size: 14px;
        font-weight: 500;
        color: #03897b;
      }
      .el-radio__inner {
        width: 16px;
        height: 16px;
        background: #454549;
        border: none;
      }
      .el-radio__input + .el-radio__label {
        font-size: 14px;
        font-weight: 500;
        color: #03897b;
      }
    }
    .radioBoxCh {
      margin-left: 0px;
    }
    .el-dialog__footer {
      .dialog-footer {
        .el-button {
          width: 96px;
          height: 40px;
          margin-right: 32px;
          background: #454549;
          border-radius: 4px;
          border: none;
          &:hover {
            color: #909090;
          }
          span {
            width: 33px;
            height: 17px;
            font-size: 14px;
            font-weight: bold;
            color: #909090;
            line-height: 17px;
          }
        }
        .activeStyle {
          background: #03897b;
          span {
            color: #fff;
          }
        }
      }
    }
  }
}
</style>
