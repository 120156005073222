<template>
  <div class="mainwindow">
    <div class="top-title">
      <i class="iconfont icon-universal_interviewer"></i>{{$t('coordinationRoom.mainWindow')}}
    </div>
    <div class="content mainwindowContent">
      <div class="topBtn">
        <el-button v-if="talkAllGroup" @click="talkToAllGroups" type="primary" icon="iconfont icon-privatechat"
        :class="{active: talkAllGroup}"
          >{{$t('coordinationRoom.talkToAllGroups')}}</el-button
        >
        <el-button v-else @click="talkToAllGroups" type="primary"  icon="iconfont icon-privatechat"
          >{{$t('coordinationRoom.talkToAllGroups')}}</el-button
        >
      </div>
      <div class="source">
        <div class="title overflow" :title="eventName">{{$t('coordinationRoom.coordinationInputSource')}}: {{ eventName }}</div>
        <div class="videoBox clearfix">
          <div class="videoContent">
            <AttendUser
              class="videoArea"
              :singChannel="channels"
              :userInfo="userInfo"
              :rtc="rtc"
              :showOperateBox="false"
              :videoControl="channels.id ? true : false"
            >
            <div class="left-title" v-show="coordinationInfo.onAir">
                {{$t('coordinationRoom.onAir')}}
             </div>
              <div class="defaultBox" v-if="outputRtilCode && !channels.id && eventInfo.status == 4">
                <LoadingIcon />
              </div>
              <div class="defaultBox" v-if="!outputRtilCode && !channels.id && eventInfo.status < 4">
                <div class="text">
                  {{$t('coordinationParticipant.theEventTimeIs')}} {{ eventInfo.startTime | FormatTime('YYYY-MM-DD HH:mm') }} -
                  {{ eventInfo.endTime | FormatTime('YYYY-MM-DD HH:mm') }}
                  <!-- <img :src="logoGray" alt="" /> -->
                </div>
              </div>
            </AttendUser>
          </div>
          <Volume :rtilCode="channels.id" :height="volumeHeight"></Volume>
        </div>
        <el-tooltip class="item" effect="dark" :content="$t(`${coordinationInfo.onAir == 1 ? 'HideAirTag' : 'showAirTag'}`)" placement="top">
           <!-- :disabled="!outputRtilCode ? true : false" -->
          <el-button :disabled="eventInfo.status < 4" type="primary" @click="changeAir" :class="{active: coordinationInfo.onAir == 1}">{{$t('coordinationRoom.onAir')}}</el-button>
        </el-tooltip>
      </div>
    </div>
  </div>
</template>
<script>
import AgoraClient from '@/assets/js/rtc-client-ng'
import AttendUser from '@/components/common/attendUser'
import LoadingIcon from '@/components/common/loadingIcon'
import Volume from '@/components/common/volume.vue'
import { mapState } from 'vuex'
import { audioContextObj } from '@/assets/js/audioContext'
import Bus from '@/assets/js/bus.js'
import $ from 'jquery'
export default {
  components: {
    AttendUser,
    LoadingIcon,
    Volume
  },
  props: {
    partyCode: String,
    eventInfo: Object,
    talkAllGroup: Boolean,
    coordinationInfo: {
      type: Object,
      default: () => {
        return {}
      }
    },
    partyListInfo: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    ...mapState({
      State: state => state,
      loginInfo: (state) => state.userInfo,
      logoGray: (state) => state.logoGray
    })
  },
  data () {
    return {
      rtc: null,
      devicesInfo: null,
      rtilCode: null,
      outputRtilCode: null,
      meInfo: {},
      userInfo: {},
      channels: {},
      eventName: '',
      currentVideo: '',
      isOnAir: false,
      volumeHeight: 0,
      changeOnair: false,
      changeAllGroup: false,
      newExternalParty: {}
    }
  },
  async created () {
    // get event source name
    this.showInputSource()

    this.rtc = new AgoraClient(this.callBack)
    // 需要先获取设备列表，并判断之前选择过的设备列表是否还存在，不存在则选择默认的
    this.devicesInfo = await this.rtc.getDevice()

    Bus.$on('setOutput', (v) => {
      this.outputRtilCode = v.rtilCode
      if (this.rtc._remotePublishUser[this.outputRtilCode]) {
        this.rtc.subscribe(v.rtilCode, 'all')
      }
    })
    Bus.$on('cancelOutput', (v) => {
      this.outputRtilCode = ''
    })
  },
  watch: {
    async partyListInfo (newValue) {
      if (this.rtc) this.rtc.leave()
      this.userInfo = newValue
      this.rtilCode = newValue.rtilCode
      this.outputRtilCode = newValue.status.output
      this.rtc = new AgoraClient(this.callBack)
      // 需要先获取设备列表，并判断之前选择过的设备列表是否还存在，不存在则选择默认的
      this.devicesInfo = await this.rtc.getDevice()
      this.newExternalParty = newValue.externalJoinInfo.externalPartyInfo[0]
      if (!this.userInfo.realPartyCode) {
        this.$set(this.userInfo, 'realPartyCode', this.newExternalParty.joinInfo.realPartyCode)
      }
      this.joinChannel(this.newExternalParty.joinInfo)
    },
    'eventInfo.inputList': {
      handler (newValue) {
        this.showInputSource()
      }
    },
    'eventInfo.eventParties': {
      handler () {
        // 重新计算视频播放区域的大小
        this.computedWidth()
      }
    }
  },
  mounted () {
    window.addEventListener('resize', this.computedWidth)
    this.$nextTick(() => {
      this.computedWidth()
    })
  },
  methods: {
    talkToAllGroups () {
      if (this.changeAllGroup) return
      // 记录状态
      this.changeAllGroup = true
      const params = {
        enable: this.talkAllGroup == 1 ? 0 : 1,
        eventId: this.eventInfo.id,
        operateType: 'room',
        parentPartyCode: this.eventInfo.partyCode
      }
      this.$http.post(`${this.State.config.urlInfo.partyline}/partyline-backend/coordination/noLogin/setup`, params).then(res => {
        this.changeAllGroup = false
        if (res.data.errorCode == '0x0') {
          this.$emit('changeTalkAllGroups')
        }
      }).catch(() => {
        this.changeAllGroup = false
      })
    },
    computedWidth () {
      // 计算mainwindow的视频区域的大小\
      const mainwindowContent = $('.mainwindowContent').height()
      const videoMaxHeight = mainwindowContent - 145
      const videoWidth = $('.videoBox').width()
      if (videoWidth * (9 / 16) > videoMaxHeight) {
        const width = (16 / 9) * videoMaxHeight
        $('.videoContent').css({ width: width - 45 })
      } else {
        $('.videoContent').css({ width: 'calc(100% - 45px)' })
      }
      // 计算video的高度
      const height = $('.videoArea').height()
      this.volumeHeight = height
    },
    changeAir () {
      if (this.changeOnair) return
      // 记录状态
      this.changeOnair = true
      const params = {
        enable: this.coordinationInfo.onAir == 1 ? 0 : 1,
        eventId: this.eventInfo.id,
        operateType: 'onAir',
        parentPartyCode: this.eventInfo.partyCode
      }
      this.$http.post(`${this.State.config.urlInfo.partyline}/partyline-backend/coordination/noLogin/setup`, params).then(res => {
        // this.changeOnair = false
        if (res.data.errorCode == '0x0') {
          // this.coordinationInfo.onAir = params.enable
          this.$emit('upDateOnAir', params.enable)
        }
      }).catch(() => {
        this.changeOnair = false
      })
    },
    async joinChannel (data) {
      const { appId, tokenCode, rtilCode, realPartyCode, videoQualities } = data
      const params = {
        appId,
        channel: realPartyCode + '',
        token: tokenCode,
        uid: rtilCode,
        codec: 'h264',
        videoConfig: videoQualities,
        cameraId: this.devicesInfo.cameraId,
        microphoneId: this.devicesInfo.microphoneId,
        // muteVideo: this.muteSelfVideo,
        // muteAudio: this.muteSelfAudio,
        video: !!this.devicesInfo.cameraId,
        audio: !!this.devicesInfo.microphoneId,
        subscribeRemote: false,
        publish: false // 默认不publish
      }

      const uid = await this.rtc.join(params)
      if (!uid) return
      // Me设置私聊功能，使用到
      this.meInfo = {
        id: this.userInfo.rtilCode,
        channel: this.userInfo.realPartyCode,
        videoTrack: this.rtc._videoTrack,
        audioTrack: this.rtc_audioTrack
      }
    },
    callBack ({ action, payload }) {
      if (action != 'volumeList' && action != 'receiveMetadata') {
        console.info(139, action, payload)
      }
      switch (action) {
        case 'user-published':
          this.publishTrack(payload)
          break
        case 'subscribe-track':
          this.subscribeTrack(payload)
          break
        case 'user-left':
          if (payload.id == this.channels.id) {
            this.channels = {}
          }
          break
      }
    },
    publishTrack (payload) {
      const { id, type } = payload
      if (this.outputRtilCode == id) {
        this.rtc.subscribe(id, type)
      }
    },
    subscribeTrack (payload) {
      const { audioTrack, videoTrack, id } = payload

      // 音量柱的显示
      const media = new MediaStream()
      if (videoTrack) {
        media.addTrack(videoTrack._mediaStreamTrack)
      }
      if (audioTrack) {
        media.addTrack(audioTrack._mediaStreamTrack)
        audioContextObj.audioTrack(media, id)
        setTimeout(() => {
          audioContextObj.resumeAudio(id)
        }, 1000)

        // 私聊
        if (this.privateWithGroup) {
          this.$set(this.privateInfo, payload.id, true)
        }
      }
      this.channels = payload
    },
    showInputSource () {
      this.eventInfo.inputList &&
      this.eventInfo.inputList.forEach((val) => {
        if (val.purpose == this.eventInfo.mainWindowType) {
          if (val.type == 'pgm') {
            this.eventName = 'PGM'
          } else {
            this.eventName = val.value ? val.value.name : ''
          }
        }
      })
    }
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.computedWidth)
    this.rtc && this.rtc.leave()
  }
}
</script>
<style lang="less" scoped>
.mainwindow {
  height: calc(65% - 13px);
  margin-bottom: 12px;
  .top-title {
    font-size: 18px;
    font-weight: bold;
    color: #f1f1f1;
    padding: 14px 0;
    i {
      color: #999;
      margin-right: 5px;
      font-size: 20px;
      position: relative;
      top: -2px;
    }
  }
  .content {
    height: calc(100% - 75px);
    background: rgba(40, 40, 46, 0.6);
    padding: 10px;
    .topBtn {
      text-align: center;
      margin-bottom: 10px;
      /deep/.el-button {
        width: 320px;
        height: 40px;
        background: #F6445A;
        box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.5);
        border-radius: 4px;
        color: #f1f1f1;
        border: 1px solid #F6445A;
        i {
          margin-right: 5px;
        }
        &.active {
          border: 1px solid #33AB4F;
          background: #33AB4F;
        }
      }
    }
    .source {
      text-align: center;
      max-width: 610px;
      margin: 0 auto;
      .title {
        text-align: left;
        font-size: 16px;
        margin-bottom: 12px;
        max-width: 100%;
      }
      .videoBox {
        margin-bottom: 20px;
        position: relative;
        display: flex;
        justify-content: center;
        width: 100%;
        .videoContent {
          float: left;
          // width: calc(100% - 60px);
        }
        .defaultBox {
          height: 100%;
          width: 100%;
          background: #28282e;
        }
        .volumeBox {
          float: left;
          width: 22px;
          height: 100%;
        }
        canvas {
          height: 100%;
          width: 100%;
          position: absolute;
          top: 0;
          left: 0;
        }
        .icon-fold {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          font-size: 40px;
          cursor: pointer;
          z-index: 100;
        }
        .left-title {
          position: absolute;
          top: 1px;
          left: 1px;
          background: #f6445a;
          padding: 2px 3px;
          font-size: 12px;
          color: #fff;
          z-index: 3;
        }
      }
      .el-button {
        width: 320px;
        height: 40px;
        border: 1px solid #454549;
        background: #454549;
        border-radius: 4px;
        margin-bottom: 12px;
        color: #909090;
        &.active {
          border: 1px solid #f6445a;
          background: #f6445a;
          color: #fff;
        }
      }
    }
    /deep/.defaultBox {
      .text {
        position: absolute;
        width: 405px;
        color: #F1F1F1;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
}
</style>
