<template>
  <div class="select">
    <el-select
      v-model="info.id"
      :placeholder="$t('eventList.selectFile')"
      v-scroll="scroll"
      popper-class="black"
      :popper-append-to-body="false"
      filterable
      remote
      :disabled="disabled"
      @change="changeFile"
      @visible-change="getList"
      :loading="loading"
      :remote-method="filterFn"
    >
      <el-option
        v-for="item in list"
        :key="item.id"
        :label="item.name"
        :value="item.id"
      >
      </el-option>
      <p
        class="loading-txt"
        v-if="scroll.loading"
        style="text-align:center;font-size:15px;color:#909090; "
        >
        <i class="el-icon-loading" style="color:#909090;"></i>
            {{$t('eventList.loading')}}
      </p>
      <div slot="empty" v-if="!scroll.loading && !loading">
        <div class="no-data">
          <p class="gray999">{{$t('leftPaneOutPut.noResult')}}</p>
          <p class="gray666">{{$t('leftPaneOutPut.changeKeyWords')}}</p>
        </div>
      </div>
    </el-select>
    <i
      class="icon el-icon-arrow-up"
      :class="{ 'is-reverse': reverse, hoverHide: info.clear && info.id }"
    ></i>
  </div>
</template>
<script>
import { filter, findIndex } from 'lodash'
export default {
  props: {
    info: {
      type: Object,
      default: () => {
        return {}
      }
    },
    disabled: {
      type: Boolean,
      default: () => {
        return false
      }
    }
  },
  data () {
    return {
      loading: false,
      list: [],
      reverse: false,
      scroll: {
        onScroll: this.getNextPage,
        loading: false,
        noMore: false,
        directive: '.el-select-dropdown__wrap'
      },
      // loaded: false,
      params: {
        pageIndex: 1,
        pageSize: 20,
        nameLike: '',
        isDirectory: false,
        region: localStorage.getItem('region'),
        service: 'pp',
        type: 2,
        directoryName: '/Videos/Commentator'
      }
    }
  },
  created () {
    this.getList('init')
  },
  methods: {
    changeFile (val) {
      const item = this.list.filter((v) => v.id === val)
      if (item.length > 0) {
        this.$emit('callback', { id: this.info.id, name: item[0].name })
      }
    },
    getNextPage () {
      if (this.scroll.loading || this.scroll.noMore) return
      this.params.pageIndex += 1
      this.scroll.loading = true
      this.getList('', 'nextPage')
    },
    filterFn (val) {
      this.params.nameLike = val
      this.params.pageIndex = 1
      this.scroll.noMore = false
      this.getList()
    },
    async getList (val, nextPage) {
      if (val === false) {
        this.reverse = false
        return
      }
      if (!nextPage) {
        this.loading = true
      } else {
        this.loading = false
      }
      if (val === true) {
        this.params.nameLike = ''
        this.params.pageIndex = 1
        this.scroll.noMore = false
        this.list = []
        this.reverse = true
      }
      this.params.region = localStorage.getItem('region')

      await this.$http
        .post('/file/query-user-files', this.params, { requestBase: 'GATEWAY' })
        .then((res) => {
          this.scroll.loading = false
          // this.loaded = true
          if (res.data.errorCode == '0x0') {
            // if (this.params.pageIndex >= res.data.result.lastPage) {
            //   this.scroll.noMore = true
            // }
            if (res.data.result.pages == this.params.pageIndex) {
              this.scroll.noMore = true
            }
            if (this.params.pageIndex > 1) {
              if (this.info.id) {
                const index = findIndex(res.data.result.records, {
                  id: this.info.id
                })
                if (index >= 0) {
                  res.data.result.records.splice(index, 1)
                }
              }
              this.list = this.list.concat(res.data.result.records)
            } else {
              this.list = res.data.result.records
              const temp = filter(this.list, { id: this.info.id })
              if (
                !temp.length &&
                this.info.id &&
                res.data.result.records.length > 0
              ) {
                this.list.push({
                  id: this.info.id,
                  name: this.info.name
                })
              }
            }
            if (val == 'init') {
              this.changeFile(this.info.id)
            }
          }
        })
        // 添加下拉框loading
      if (!nextPage) {
        this.loading = false
      } else {
        this.loading = false
      }
    }
  }
}
</script>
<style lang="less" scoped>
.select {
  display: inline-block;
  position: relative;
  &:hover {
    .hoverHide {
      display: none;
    }
  }
  /deep/.el-select {
    width: 100%;
    .el-input {
      .el-input__inner {
        padding-right: 35px !important;
      }
    }
  }
  .icon {
    position: absolute;
    top: -7px;
    right: -319px;
    color: #c0c4cc;
    font-size: 14px;
    transition: transform 0.3s;
    transform: rotateZ(180deg);
    cursor: pointer;
    &.is-reverse {
      transform: rotateZ(0);
    }
  }
  .no-data {
    padding: 10px 0;
    text-align: center;
    font-size: 14px;
    .gray999 {
      color: #999;
    }
    .gray666 {
      color: #666;
    }
  }
}
</style>
