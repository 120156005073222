<template>
  <el-dialog
    :title="$t('mutedPop.title')"
    :visible.sync="info.speaking"
    :close-on-click-modal="false"
    width="570px"
    :modal="false"
    @close="closeAdvanced()"
    class="mutedPopContent"
  >
  <div class="middles">
    <div class="content">
      <div class="contentDetail">{{ $t('mutedPop.notes') }}</div>
      <div class="contentDetail">{{ $t('mutedPop.detailNotes') }}</div>
    </div>
  </div>
  <span  slot="title" class="el-dialog__header">
    <svg
      class="muteAudioImg"
      aria-hidden="true"
    >
      <use xlink:href="#icon-universal_micoff"></use>
    </svg>
    <i>{{$t('mutedPop.title')}}</i>
  </span>
  </el-dialog>
</template>
<script>
import { mapState } from 'vuex'
export default {
  props: {
    info: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  computed: {
    ...mapState({
      State: (state) => state
    })
  },
  data () {
    return {
    }
  },
  created () {

  },
  methods: {
    saveOperation (key, val) {
      localStorage.setItem(`${key}`, val)
    },
    closeAdvanced () {
      this.$emit('reusePop')
    }
  }
}
</script>
<style lang="less" scoped>
.mutedPopContent{
  top: unset !important;
  bottom: 76px !important;
}
/deep/.el-dialog {
  .el-dialog__header{
    display: flex;
    align-items: center;
    padding: 7px 10px 7px;
    font-size: 14px;
    .muteAudioImg{
      width: 22px;
      height: 21px;
      position: relative;
      }
  }
  .el-dialog__footer{
    height:60px;
  }
  background: #333339;
  margin: auto !important;
  .el-dialog__title {
    color: #fff;
  }
  .el-icon-close {
    color: #fff;
    display: block;
  }
  .el-dialog__body {
    color: #fff;
    font-size: 16px;
    padding-top:0px;
    padding-bottom: 8px;
    padding-left: 20px;
    padding-right: 20px;
    .middles{
      border-top: 1px solid  #FFFFFF33;
      font-size:14px;
      .content{
        margin-top: 10px;
        .contentDetail{
          color: #FFFFFF;
          text-align: left;
          margin-bottom: 7px;
        }
      }
    }
  }
}

</style>
