<template>
  <div class="recevierControl">
    <tvu-top-header
      :projectName="$t('receiverControl.title')"
      :globalFunList="['waffle','account', 'helpCenter' ]"
      :userAuthUrl="Config.urlInfo.userAuthUrl"
      theme="dark"
      :versionServiceNameList="serviceNameList"
      :serviceVesion="[{
        serviceName: 'Remote Commentator',
        frontendVersion: fontendVersion,
        backendVersion: backendVersion,
        tagName: Config.applicationName
      }]"
      @callback="topHeaderCallback">
      <!-- 自定用账号下拉的中间模块 -->
      <template #account-center-module>
        <div class="contaniner login-info">
          <div class="language" v-if="State.switchLang">
            <div class="left">
              {{$t('personalInfo.lang')}}
            </div>
            <div class="right">
              <span
                :class="{ zhColor: $i18n.locale === 'zh'}"
                @click="changeLanguage('en')"
                >EN</span
              >
              <span class="second">/</span>
              <span
                :class="{ zhColor: $i18n.locale !== 'zh' }"
                @click="changeLanguage('zh')"
                >中文</span
              >
            </div>
          </div>
        </div>
      </template>
      <!-- 自定义整个服务的私有功能（可以包括或者不包括多账号ui， 提供默认样式） -->
      <template #right-app-module v-if="$route.name != 'usage'">
         <!-- 显示网络状况 -->
        <div class="item-fun" v-if="$route.name == 'coordination'">
        <div class="netWorkTip">
          <el-popover popper-class="netWorkTips" effect="dark" placement="bottom-start" :visible-arrow="false">
            <div>
              <h6>{{$t('personalInfo.bandWidth')}}</h6>
              <p class="upLink">
                <i class="el-icon-top" :class="colorUp"></i>
                <span :class="colorUp">{{$t(`personalInfo.${netWork.upLinkNetWork}`)}}</span>
              </p>
              <p class="downLink">
                <i class="el-icon-bottom" :class="colorDown"></i>
                <span :class="colorDown">{{$t(`personalInfo.${netWork.downLinkNetWork}`)}}</span>
              </p>
            </div>
            <NetWorkQuality slot="reference" @callback="showNetWork"></NetWorkQuality>
          </el-popover>
        </div>
        </div>
      </template>
    </tvu-top-header>
    <div class="content">
      <div class="refreshTips">{{ $t('receiverControl.refreshTips') }}</div>
      <div class="eventListTitle">
        <div class="titleLi" :class="item.width" v-for="item in titleArray" :key="item.title">
          <div v-if="item.secondTips">
            <div>{{ $t(`receiverControl.${item.title}`) }}</div>
            <span class="title">{{ $t(`receiverControl.${item.secondTips}`) }}</span>
            <el-tooltip
              class="item"
              effect="dark"
              :content="$t(`receiverControl.${item.tips}`)"
              placement="top">
              <i v-if="item.tips" class="iconfont" :class="returnIconClass(item)"></i>
            </el-tooltip>
          </div>
          <div v-else>
            <span class="title">{{ $t(`receiverControl.${item.title}`) }}</span>
            <el-tooltip
              class="item"
              effect="dark"
              :content="$t(`receiverControl.${item.tips}`)"
              placement="top">
              <i v-if="item.tips" class="iconfont" :class="returnIconClass(item)"></i>
            </el-tooltip>
          </div>
        </div>
      </div>
      <div class="infoBox scrollbar">
        <el-tree
          class="eventTree"
          :data="eventList"
          default-expand-all
          @node-click="handleNodeClick"
        >
          <span class="custom-tree-node" slot-scope="{ node, data }">
            <div class="treeLi" :class="{ partyTree: node.level == 2 }">
              <div :class="{ w_51: node.level == 1, w_12: node.level == 2, m_l_13: node.level == 2 }">{{ data.title }}</div>
              <div v-if="node.level == 1" class="w_17 center">{{ retrunReceiver('mainIn', data) }}</div>
              <div v-if="node.level == 1 && retrunReceiver('backupIn', data)" class="w_17 center">{{ retrunReceiver('backupIn', data) }}</div>
              <i v-if="node.level == 1 && !retrunReceiver('backupIn', data)" class="iconfont jianhao" ></i>
              <div v-if="node.level == 2" :class="{ w_12: node.level == 2 }" :title="data.rtilCode">{{ data.rtilCode ? nickNameInfo[data.rtilCode] : '' }}</div>
              <div v-if="node.level == 2" :class="{ w_15: node.level == 2 }">
                <i class="iconfont" :class="returnStatusClass('partyLine', node, data)"></i>
              </div>
              <div v-if="node.level == 2" :class="{ w_17: node.level == 2 }">
                <i class="iconfont" :class="returnStatusClass('mainIn', node, data)"></i>
              </div>
              <div v-if="node.level == 2" :class="{ w_17: node.level == 2 }">
                <i class="iconfont" :class="returnStatusClass('backupIn', node, data)"></i>
              </div>
              <div v-if="node.level == 2" :class="{ w_13: node.level == 2 }" class="operationBox">
                <span class="pointer" @click="addToReceiver(node, data)">{{ $t('receiverControl.addToR') }}</span>
                <el-divider direction="vertical"></el-divider>
                <span class="pointer" @click="removeFromReceiver(node, data)">{{ $t('receiverControl.removeFromR') }}</span>
              </div>
            </div>
          </span>
        </el-tree>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import Cookie from 'js-cookie'
import NetWorkQuality from '@/components/common/netWorkQuality'
import AgoraClient from '@/assets/js/rtc-client-ng'
export default {
  components: {
    NetWorkQuality
  },
  data () {
    return {
      netWork: {
        upLinkNetWork: 'Good',
        downLinkNetWork: 'Good'
      },
      sendParams: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
        sid: '',
        rid: '',
        startTime: '',
        endTime: '',
        title: '',
        description: '',
        partyCode: '',
        status: [],
        timer: null,
        userId: '',
        needCheckReceiverOccupied: true
      },
      serviceNameList: [],
      partyCode: '',
      AudioMixer: {
        tagName: '',
        backendVersion: '',
        frontendVersion: ''
      },
      Producer: {
        tagName: '',
        backendVersion: '',
        frontendVersion: ''
      },
      MediaService: {
        tagName: '',
        backendVersion: '',
        frontendVersion: ''
      },
      Partyline: {
        tagName: '',
        backendVersion: '',
        frontendVersion: ''
      },
      fontendVersion: '',
      backendVersion: '',
      titleArray: [
        {
          title: 'eventName',
          tips: 'eventNameTips',
          icon: 'icon-help2',
          width: 'w_13'
        },
        {
          title: 'groupName',
          width: 'w_12'
        },
        {
          title: 'commentatorName',
          width: 'w_12'
        },
        {
          title: 'statusPartyline',
          width: 'w_15'
        },
        {
          title: 'mainInStatusReceiver',
          tips: 'mainReceiverStatusTips',
          secondTips: 'mainIn',
          icon: 'icon-help2',
          width: 'w_17'
        },
        {
          title: 'backupInStatusReceiver',
          tips: 'backupReceiverStatusTips',
          secondTips: 'backupIn',
          icon: 'icon-help2',
          width: 'w_17'
        },
        {
          title: 'operations',
          tips: 'operationsTips',
          icon: 'icon-help2',
          width: 'w_13'
        }
      ],
      eventList: [],
      rtc: {},
      partyRtilCodeList: {},
      nickNameInfo: {},
      partyKeyJoinCodeVal: {}, // 每个 partyCode 为 key 来保存主 partyCode 为了后面获取与会者的 nickname 用
      getReceiverStatusTimer: {},
      wsReceiverInfo: {} // 用来记录 ws 返回的信息
    }
  },
  computed: {
    ...mapState({
      State: (state) => state,
      Config: (state) => state.config,
      userInfo: (state) => state.userInfo,
      userTypeInfo: (state) => state.userTypeInfo,
      websocket: (state) => state.socket
    }),
    colorUp () {
      if (!this.netWork.upLinkNetWork) return ''
      if (this.netWork.upLinkNetWork === 'Good') {
        return 'colorGreen'
      } else if (this.netWork.upLinkNetWork === 'Mid') {
        return 'colorOrange'
      } else if (this.netWork.upLinkNetWork === 'Poor') {
        return 'colorRed'
      } else {
        return 'colorGreen'
      }
    },
    colorDown () {
      if (!this.netWork.downLinkNetWork) return ''
      if (this.netWork.downLinkNetWork === 'Good') {
        return 'colorGreen'
      } else if (this.netWork.downLinkNetWork === 'Mid') {
        return 'colorOrange'
      } else if (this.netWork.downLinkNetWork === 'Poor') {
        return 'colorRed'
      } else {
        return 'colorGreen'
      }
    },
    returnStatusClass: {
      get: function () {
        return function (key, node, data) {
          if (key === 'partyLine') {
            return this.returnPartylineStatus(data)
          } else if (key === 'mainIn') {
            return this.returnMainInStatus(node, data)
          } else if (key === 'backupIn') {
            return this.returnBackupInStatus(node, data)
          }
        }
      }
    }
  },
  async created () {
    const _this = this
    this.State.newPageLoading = false
    this.serviceNameList = this.Config.serviceNames
    this.$backAndFrontVersion({
      ...this.$TVU,
      params: {
        serviceNameList: this.serviceNameList
      },
      success: function (val) {
        val.forEach((vals, index, val_arr) => {
          if (val_arr[index].serviceName == 'Producer') {
            _this.Producer = val_arr[index]
          } else if (val_arr[index].serviceName == 'Partyline') {
            _this.Partyline = val_arr[index]
          } else if (val_arr[index].serviceName == 'Audio Mixer') {
            _this.AudioMixer = val_arr[index]
          } else {
            _this.MediaService = val_arr[index]
          }
        });
      },
      error: function (val) {
      }
    })
    let frontVersion = localStorage.getItem('partylineCommentatorVersion')
    if (frontVersion && frontVersion.includes('_')) {
      frontVersion = frontVersion.substring(0, frontVersion.indexOf('_'))
    }
    this.fontendVersion = 'Front ' + frontVersion
    // 获取后端版本号
    await this.$store.dispatch('getBackEndVersion')
    let backVersion = localStorage.getItem('backendVersion')
    if (backVersion && backVersion.includes('_')) {
      backVersion = backVersion.substring(0, backVersion.indexOf('_'))
    }
    this.backendVersion = 'Backend ' + backVersion
    this.getEventList()
  },
  methods: {
    returnPartylineStatus (data) {
      const partyCode = data.partyCode
      const rtilCode = data.rtilCode
      if (this.partyRtilCodeList[partyCode] && this.partyRtilCodeList[partyCode].includes(Number(rtilCode))) {
        return 'icon-xingzhuang'
      } else {
        return 'icon-a-Path2'
      }
    },
    returnMainInStatus (node, data) {
      let iconClass = 'icon-a-Path2'
      const rtilCode = data.rtilCode
      const partyCode = data.partyCode
      const eventId = node.parent.data.id
      const info = this.wsReceiverInfo[eventId]
      if (info) {
        let mianInReceiverId = ''
        this.eventList.forEach(event => {
          if (event.id === eventId) {
            event.eventInputList.forEach(receiverInfo => {
              if (receiverInfo.purpose === 1) {
                mianInReceiverId = receiverInfo.value.rid
              }
            })
          }
        })
        info[mianInReceiverId].forEach(partyInfo => {
          if (partyInfo.partyCode == partyCode && partyInfo.rtilCode == rtilCode) {
            iconClass = 'icon-xingzhuang'
          }
        })
      }
      return iconClass
    },
    returnBackupInStatus (node, data) {
      let iconClass = 'icon-a-Path2'
      const rtilCode = data.rtilCode
      const partyCode = data.partyCode
      const eventId = node.parent.data.id
      const info = this.wsReceiverInfo[eventId]
      if (info) {
        let backupInReceiverId = ''
        this.eventList.forEach(event => {
          if (event.id === eventId) {
            event.eventInputList.forEach(receiverInfo => {
              if (receiverInfo.purpose === 2) {
                backupInReceiverId = receiverInfo.value.rid
              }
            })
          }
        })
        if (backupInReceiverId) {
          info[backupInReceiverId].forEach(partyInfo => {
            if (partyInfo.partyCode == partyCode && partyInfo.rtilCode == rtilCode) {
              iconClass = 'icon-xingzhuang'
            }
          })
        } else {
          return 'icon-jianhao'
        }
      }
      return iconClass
    },
    retrunReceiver (key, data) {
      let _str = ''
      if (key === 'mainIn') {
        data.eventInputList.forEach(receiverInfo => {
          if (receiverInfo.purpose === 1) {
            _str = 'rid: ' + receiverInfo.value.rid
          }
        })
      } else {
        data.eventInputList.forEach(receiverInfo => {
          if (receiverInfo.purpose === 2) {
            _str = 'rid: ' + receiverInfo.value.rid
          }
        })
      }
      return _str
    },
    getEventList () {
      this.sendParams.userId = this.State.loginInfo.id
      this.$http
        .post('/commentator-backend/event/v2/page', this.sendParams)
        .then(res => {
          if (res.data.errorCode === '0x0') {
            const list = res.data.result.list
            this.assemblyData(list)
          }
        })
    },
    assemblyData (list) {
      if (!list || !list.length) return
      list = list.filter(item => {
        return item.status === 4
      })
      list.forEach(item => {
        this.$set(item, 'children', [])
        item.eventParties.forEach(party => {
          party.children = []
          this.partyKeyJoinCodeVal[party.partyCode] = item.parentPartyCode
        })
      });
      this.eventList = list
      this.eventList.forEach(async party => {
        await this.getReceiverStatus(party.id, 'init')
        this.joinParty(party)
        this.getReceiverStatusTimer[party.id] = setInterval(() => {
          this.getReceiverStatus(party.id)
        }, 10000)
      })
    },
    joinParty (data) {
      data.eventParties.forEach(partyInfo => {
        const params = {
          memberId: 'receiverControl' + Math.random(),
          partyCode: partyInfo.partyCode,
          role: 0,
          type: 0
        }
        this.$http
          .post(`${this.State.config.urlInfo.partyline}/partyline-backend/party/v2/noLogin/agoraTokenGenerate`, params)
          .then(async (res) => {
            if (res.data.errorCode === '0x0') {
              const result = res.data.result
              this.joinChannel(result)
            }
          })
      })
    },
    joinChannel (result) {
      const { appId, tokenCode, rtilCode, realPartyCode, videoQualities } = result
      this.rtc[realPartyCode] = new AgoraClient(this.callBack)
      const params = {
        appId,
        channel: realPartyCode + '',
        token: tokenCode,
        uid: rtilCode,
        codec: 'h264',
        video: false,
        audio: false,
        videoConfig: videoQualities,
        publish: false
      }
      this.rtc[realPartyCode].join(params)
    },
    callBack ({ action, payload }) {
      if (action != 'volumeList' && action != 'receiveMetadata') {
        console.log(action, payload)
      }
      switch (action) {
        case 'user-joined':
          this.userJoin(payload)
          break
        case 'user-left':
          this.userLeave(payload)
          break
      }
    },
    userJoin (payload, byReceiverStatus) {
      const { channel, id } = payload
      // 通过 ws 推送 r 的数据不走下面逻辑
      if (!byReceiverStatus) {
        if (!this.partyRtilCodeList[channel]) this.$set(this.partyRtilCodeList, channel, [])
        if (!this.partyRtilCodeList[channel].includes(id)) {
          this.partyRtilCodeList[channel].push(id)
          this.$set(this.partyRtilCodeList, channel, this.partyRtilCodeList[channel])
        }
      }
      // 根据当前的 partyCode 去 eventList 里面找主 partyCode
      const joinPartyCode = this.partyKeyJoinCodeVal[channel]
      if (!joinPartyCode) return
      const params = {
        annotation: joinPartyCode,
        keys: [
          'nickName'
        ],
        marks: [id]
      }
      this.$http
        .post(`${this.State.config.urlInfo.partyline}/partyline-backend/behavior/noLogin/getList`, params)
        .then((res) => {
          if (res.data.errorCode === '0x0') {
            const result = res.data.result
            if (!this.nickNameInfo[id]) {
              this.$set(this.nickNameInfo, id, result[id].nickName)
            }
            this.eventList.forEach(item => {
              let array = []
              item.eventParties.forEach(partyInfo => {
                if (partyInfo.partyCode == channel) {
                  let addToList = true
                  partyInfo.children.forEach(item => {
                    if (item.rtilCode == id) {
                      addToList = false
                    }
                  })
                  if (addToList) {
                    // 添加一条新数据
                    partyInfo.children.push({
                      partyCode: channel,
                      rtilCode: id,
                      title: partyInfo.title
                    })
                  }
                }
                array = array.concat(partyInfo.children)
              })
              this.$set(item, 'children', array)
            })
          }
        })
    },
    userLeave (payload) {
      const { channel, id } = payload
      const result = this.partyRtilCodeList[channel].filter(rtilCode => {
        return rtilCode != id
      })
      this.$set(this.partyRtilCodeList, channel, result)
    },
    leaveParty (data, node) {
      data.children.forEach(partyInfo => {
        if (this.rtc[partyInfo.partyCode]) {
          this.rtc[partyInfo.partyCode].leave()
          this.$delete(this.rtc, partyInfo.partyCode)
        }
      })
    },
    topHeaderCallback (info) {
      const { type } = info
      if (type === 'signOut') {
        this.logout()
      }
    },
    changeLanguage (lang) {
      Cookie.set('userLanguage', lang, { domain: '.tvunetworks.com' })
      this.$i18n.locale = lang
      this.State.browserLanguage = lang
    },
    showNetWork (netWork) {
      this.netWork.upLinkNetWork = netWork.upLinkNetWork
      this.netWork.downLinkNetWork = netWork.downLinkNetWork
    },
    returnIconClass (item) {
      return item.icon
    },
    handleNodeClick () {

    },
    addToReceiver (node, data) {
      const eventId = node.parent.data.id
      const params = {
        partyCode: data.partyCode,
        rtilCode: data.rtilCode
      }
      this.$http
        .post('/commentator-backend/commentator/party/addMixAudio', params)
        .then(async (res) => {
          if (res.data.errorCode === '0x0') {
            if (this.getReceiverStatusTimer[eventId]) clearInterval(this.getReceiverStatusTimer[eventId])
            this.getReceiverStatusTimer[eventId] = setInterval(() => {
              this.getReceiverStatus(eventId)
            }, 10000)
            // 需要添加 eventList 里面的数据
            this.eventList.forEach(event => {
              if (event.id == eventId) {
                const rList = this.wsReceiverInfo[eventId]
                rList && Object.keys(rList).forEach(rid => {
                  let addToList = true
                  rList[rid].forEach(partyInfo => {
                    if (partyInfo.partyCode == data.partyCode && partyInfo.rtilCode == data.rtilCode) {
                      addToList = false
                    }
                  })
                  if (addToList) {
                    rList[rid].push({
                      partyCode: data.partyCode,
                      rtilCode: data.rtilCode
                    })
                  }
                })
              }
            })
          }
        })
    },
    removeFromReceiver (node, data) {
      const eventId = node.parent.data.id
      const params = {
        partyCode: data.partyCode,
        rtilCode: data.rtilCode
      }
      this.$http
        .post('/commentator-backend/commentator/party/removeMixAudio', params)
        .then(async (res) => {
          if (res.data.errorCode === '0x0') {
            if (this.getReceiverStatusTimer[eventId]) clearInterval(this.getReceiverStatusTimer[eventId])
            this.getReceiverStatusTimer[eventId] = setInterval(() => {
              this.getReceiverStatus(eventId)
            }, 10000)
            // 需要删除 eventList 里面的数据
            this.eventList.forEach(event => {
              if (event.id == eventId) {
                event.children.forEach((partyInfo, index) => {
                  if (
                    partyInfo.partyCode == data.partyCode &&
                    partyInfo.rtilCode == data.rtilCode &&
                    this.partyRtilCodeList[partyInfo.partyCode] &&
                    !this.partyRtilCodeList[partyInfo.partyCode].includes(Number(data.rtilCode))
                  ) {
                    // 如果 partyRtilCodeList[partyCode] 里面也没有的话则移除这条数据
                    event.children.splice(index, 1)
                  }
                })
                const rList = this.wsReceiverInfo[eventId]
                Object.keys(rList).forEach(key => {
                  rList[key].forEach((item, index) => {
                    if (item.partyCode == data.partyCode && item.rtilCode == data.rtilCode) {
                      rList[key].splice(index, 1)
                    }
                  })
                })
              }
            })
          }
        })
    },
    async getReceiverStatus (eventId, init) {
      await this.$http
        .get(`/commentator-backend/receiver/getAudioMixStatus/${eventId}`)
        .then(async (res) => {
          if (res.data.errorCode === '0x0') {
            const result = res.data.result
            this.$set(this.wsReceiverInfo, eventId, result)
            if (JSON.stringify(result) === '{}') {
              this.getReceiverStatusTimer && Object.keys(this.getReceiverStatusTimer).forEach(eventId => {
                if (this.getReceiverStatusTimer[eventId]) clearInterval(this.getReceiverStatusTimer[eventId])
              })
              this.$delete(this.eventList, eventId)
            } else {
              if (init) return
              this.eventList && this.eventList.forEach(event => {
                if (event.id === eventId) {
                  // 遍历返回结果
                  result && Object.keys(result).forEach(rid => {
                    result[rid].forEach(info => {
                      event.eventParties.forEach(party => {
                        if (party.partyCode === info.partyCode) {
                          let addToList = true
                          party.children.forEach(item => {
                            if (item.rtilCode == info.rtilCode) {
                              addToList = false
                            }
                          })
                          if (addToList) {
                            this.userJoin({
                              channel: info.partyCode,
                              id: Number(info.rtilCode)
                            }, 'byReceiverStatus')
                          }
                        }
                      })
                    })
                  })
                }
              })
            }
          }
        })
    }
  }
}
</script>
<style lang="less" scoped>
/deep/.el-tree.eventTree {
  color: #f1f1f1;
  background: transparent;
  >.el-tree-node>.el-tree-node__content {
    padding-left: 2%!important;
    background: #262626;
    &:hover {
      background: #262626;
    }
  }
  .el-tree-node {
    &:focus {
      .el-tree-node__content {
        background: transparent;
      }
    }
  }
  .el-tree-node__content{
    height: 48px;
    cursor: default;
    padding-left: 0!important;
    border-bottom: 1px solid #262626;
    &:hover {
      background: transparent;
    }
    .el-tree-node__expand-icon.is-leaf {
      display: none;
    }
    .custom-tree-node {
      width: 100%;
      .treeLi {
        display: flex;
        align-items: center;
        &.partyTree {
          text-align: center;
          .icon-xingzhuang {
            font-size: 18px;
            color: #33AB4F;
          }
          .icon-a-Path2 {
            font-size: 18px;
            color:#E56464;
          }
          .icon-jianhao {
            font-size: 18px;
            color: gray;
          }
          .operationBox {
            color: #33AB4F;
            .el-divider {
              background: #454549;
            }
          }
        }
      }
    }
  }
}
.recevierControl {
  .content {
    padding: 0 24px;
    .refreshTips {
      color: #8C8C8C;
      text-align: right;
      font-size: 14px;
      height: 37px;
      line-height: 37px;
    }
    .eventListTitle {
      height: 54px;
      background: #212125;
      display: flex;
      align-items: center;
      font-size: 14px;
      text-align: center;
      color: #909090;
    }
    .infoBox {
      max-height: calc(100vh - 162px);
      overflow-y: scroll;
    }
  }
}
</style>
