<template>
  <!--rtmpPush-->
  <el-dialog
    :title="$t('eventList.rtmpPush')"
    :visible.sync="info.show"
    :close-on-click-modal="false"
    width="720px"
    :modal="false"
    class="thisblack-bgc"
    :class="{noShowGroup:true}"
    @close="closeAdvanced"
  >
  <div class="middles">
    <!--SourceName--->
    <div class="source">
      <span class="sourceLabel">{{$t('rtmpPush.name')}}</span>
      <el-input
        v-model="rtmpName"
        :placeholder="$t('rtmpPush.rtmpName')"
        :maxlength="32"
        :disabled="((changeRtmpType=='rtmp'&& eventInfo.status == 4)) ? true : false"
        class="sourceName"
      ></el-input>
    </div>

    <!--Server--->
    <span class="serverLabel">{{$t('rtmpPush.server')}}</span>
    <div class="server">
      <el-input
        v-model="serverValue"
        :disabled="true"
        :placeholder="$t('rtmpPush.serverInput')"
        :maxlength="32"
        class="serverInput"
        :class="{partialWidth:eventInfo.status == 4}"
      ></el-input>
      <el-button
        v-if="eventInfo.status == 4"
        :disabled="!serverValue"
        @click="copyServer(serverValue)"
      class="record_icon" type="primary" size="small">{{$t('rtmpPush.copy')}}</el-button>
    </div>

    <!--Key--->
    <span class="keyLabel">{{$t('rtmpPush.key')}}</span>
    <div class="key">
      <el-input
      v-model="keyValue"
      :disabled="((changeRtmpType=='rtmp'&& eventInfo.status == 4)) ? true : false"
      :placeholder="$t('rtmpPush.keyInput')"
      :maxlength="32"
      class="keyInput"
      ></el-input>
      <el-button  :disabled="((changeRtmpType=='rtmp'&& eventInfo.status == 4)) ? true : false" class="generate" @click="generateRandomChar" type="primary" size="small">{{$t('rtmpPush.generate')}}</el-button>
      <el-button class="record_icon" type="primary" size="small" :disabled="!keyValue" @click="copyServer(keyValue)">{{$t('rtmpPush.copy')}}</el-button>
    </div>
    <el-button v-if="eventInfo.status == 4" :disabled="!serverValue" class="record_icon" type="primary" size="small" @click="splitCopy(serverValue,keyValue)">{{$t('rtmpPush.copyUrl')}}</el-button>
  </div>

  <span  slot="title" class="el-dialog__header">
  <i style="margin-left:2px; font-size:20px;">{{$t('eventList.rtmpPush')}}</i>
  </span>
  <span slot="footer" class="dialog-footer">
    <!--<a v-if="(eventTypeInfo.type === 'view'||eventTypeInfo.type === 'creat')" class="cancel" @click="cancelAdvanced()">{{$t('leftPane.cancel')}}</a>-->
    <el-button v-if="eventInfo.status != 4" class="save" @click="closeAdvanced()">{{$t('leftPaneOutPut.save')}}</el-button>
    <el-button v-else class="save" @click="closeAdvanced()">{{$t('eventList.doneN')}}</el-button>
  </span>
  </el-dialog>
</template>
<script>
import { mapState } from 'vuex'
export default {
  props: {
    changeRtmpType: String,
    eventTypeInfo: {
      type: Object,
      default: () => {
        return {}
      }
    },
    info: {
      type: Object,
      default: () => {
        return {}
      }
    },
    eventInfo: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  computed: {
    ...mapState({
      State: (state) => state
    })
  },
  data () {
    return {
      charArray: [], // 保存之前生成的字符数组
      rtmpName: '',
      serverValue: '',
      keyValue: ''
    }
  },
  created () {
    this.rtmpName = this.State.rtmpName
    this.serverValue = this.State.serverValue
    this.keyValue = this.State.keyValue
    if (this.eventInfo.status != 4) {
      this.serverValue = ''
    }
  },
  methods: {
    generateRandomChar () {
      let char = ''
      do {
        // 生成8位随机字符
        char = Math.random().toString(36).substr(2, 8)
      } while (this.charArray.includes(char)) // 判断是否与之前的字符数组中重复

      this.charArray.push(char) // 添加到字符数组中
      this.keyValue = char // 更新当前随机生成的字符
      this.State.keyValue = char
    },
    splitCopy (serverValue, keyValue) {
      const url = serverValue + keyValue
      const oInput = document.createElement('input')
      oInput.value = url
      document.body.appendChild(oInput)
      oInput.select() // 选择对象;

      document.execCommand('Copy') // 执行浏览器复制命令
      this.$message({
        message: this.$t('eventList.copy'),
        type: 'success'
      })
      oInput.remove()
    },
    copyServer (val) {
      const url = val
      const oInput = document.createElement('input')
      oInput.value = url
      document.body.appendChild(oInput)
      oInput.select() // 选择对象;

      document.execCommand('Copy') // 执行浏览器复制命令
      this.$message({
        message: this.$t('eventList.copy'),
        type: 'success'
      })
      oInput.remove()
    },
    // 保存数据关闭弹窗
    closeAdvanced () {
      this.State.rtmpName = this.rtmpName
      this.State.serverValue = this.serverValue
      this.State.keyValue = this.keyValue
      this.$emit('showFootRtmp', {
        id: 'rtmp',
        name: this.rtmpName,
        server: this.serverValue,
        key: this.keyValue
      }, 'rtmp')
      this.info.show = false
    }
  }
}
</script>
<style lang="less" scoped>
/deep/.el-dialog {
  .el-dialog__header{
    padding:20px 20px 10px 10px;
    }
  .dialog-footer {
    a {
      color: #999;
      margin-left: 30px;
      cursor: pointer;
    }
    .save {
      font-size:14px !important;
      color: #fff;
      padding: 3px 17px 5px;
      background: #03897b;
      border-radius: 5px;
      cursor: pointer;
    }
    .el-button.save {
      border: none;
      padding: 8px 8px;
      margin-left: 30px;
    }
  }
  .el-dialog__footer{
    height:60px;
  }
  background: #28282e;
  .el-dialog__title {
    color: #fff;
  }
  // .el-button {
  //   background: #03897b;
  //   border: #03897b;
  //   color: #999;
  //   font-size: 16px;
  // }
  .el-button--primary {
    color: #fff;
  }
  .el-icon-close {
    color: #fff;
  }
  .el-dialog__body {
    color: #fff;
    font-size: 16px;
    // max-height:540px !important;
    .middles{
      height: 505px;
      margin-top:-5px;
      .record_icon {
          color: #03897b;
          border-color: #03897b;
          background: none;
        }
      .source{
        .sourceLabel{
          display: block;
          padding-bottom: 8px;
        }
        .sourceName{
          padding-bottom: 60px;
          width: 100%;
        .el-input__inner {
            background-color: #333339;
          }
        }
      }
        .serverLabel{
          display: block;
          padding-bottom: 8px;
        }
      .server{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 60px;
        .serverInput{
          width: 100%;
          &.partialWidth{
            width: 89%;
          }
          .el-input__inner {
            background-color: #333339;
          }
        }
      }
        .keyLabel{
          display: block;
          padding-bottom: 8px;
        }
      .key{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 30px;
        .keyInput{
          width: 73%;
          .el-input__inner {
            background-color: #333339;
          }
        }
        .generate{
          border-color: #03897b;
          background: none;
        }
      }
    }
  }
}

</style>
