<template>
  <el-dialog
    :title="$t('eventList.deleteEvent')"
    :visible.sync="info.show"
    :show-close="false"
    :lock-scroll="false"
    width="550px"
  >
    <div class="content">
     <!-- <p>
        {{$t('eventList.theEvent')}},
        {{$t('eventList.allProgramsShutDown')}}
      </p>-->
      <p>{{$t('eventList.deleteTips')}}{{info.scope.row.title+'?'}}</p>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button class="cancel" @click="info.show = false">{{$t('leftPane.cancel')}}</el-button>
      <el-button type="primary" @click="deletes">{{$t('leftPane.del')}}</el-button>
    </span>
  </el-dialog>
</template>
<script>
export default {
  props: {
    info: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {
      show: true
    }
  },
  methods: {
    deletes () {
      this.info.show = false
      this.$emit('delete', { scope: this.info.scope })
    }
  }
}
</script>
<style lang="less" scoped>
/deep/.el-dialog {
  background: #28282e;
  .el-dialog__title {
    color: #fff;
  }
  .el-button {
    background: none;
    border: none;
    color: #999;
    font-size: 16px;
  }
  .el-button--primary {
    color: #03897b;
  }
}
.content {
  p {
    margin-bottom: 10px;
    color: #b4b4b4;
  }
}
</style>
