<template>
  <div class="outputSource">
    <div class="firstTop">
      <span class="text">
        <i class="flowx" v-if="showOutPutText"></i>&nbsp;
        <i class="outPutTxt" v-if="showOutPutText">{{$t('leftPaneOutPut.output')}} </i>
      </span>
      <div class="selectBox" v-if="showOutPutText">
        <el-select
          v-model="value"
          :placeholder="$t('leftPaneOutPut.receiver')"
          popper-class="outPutSelect"
          @change="showOutPut"
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label | showItems"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <!-- Receiver 搜索 -->
      <div class="searchBox" v-if="showSearchInput">
        <el-input
          ref="seachInput"
          class="searchInput"
          v-model="receiverInfo.condition"
          @keyup.enter.native="searchReceiver"
          :placeholder="$t('leftPaneOutPut.searchReceiver')"
        >
          <i class="el-icon-search" slot="prefix" @click="searchReceiver"> </i>
        </el-input>
      </div>
      <!-- 取消搜索 -->
      <i class="cancelSearch" v-if="showSearchInput" @click="getCancel"
        >{{$t('leftPane.cancel')}}</i
      >
      <!-- 搜索 -->
      <i class="el-icon-search" v-if="showSearchIcon" @click="getSearch"></i>
      <!-- Ipout 搜索 -->
      <div class="searchBox" v-if="showIpoutSearch">
        <el-input
          ref="seachInput"
          class="searchInput"
          :placeholder="$t('leftPaneOutPut.searchOutputName')"
          v-model="ipOutInfo.name"
          @keyup.enter.native="seachIpout"
        >
          <i class="el-icon-search" slot="prefix" @click="seachIpout"> </i>
        </el-input>
      </div>
      <!-- 取消搜索 -->
      <i class="cancelSearch" v-if="showIpoutCancel" @click="cancelIpoutSearch"
        >{{$t('leftPane.cancel')}}</i
      >
      <!-- 搜索 -->
      <i
        class="el-icon-search ipOutSearch"
        @click="getIpoutSearch"
        v-if="showAddIpout"
      ></i>
      <!-- 添加 -->
      <i class="add" v-if="showAddIpout">+</i>
    </div>
    <div
      class="list scrollbar"
      id="resultScroll"
      ref="myScrollBar"
      v-loadings="scrollS.loading"
      :class="{anotherList: mediaInitParams.selectedOutput.length > 0 || selectedReItems.length > 0}"
    >
      <!-- receiver -->
      <ul v-if="showRecieverList">
        <li
          class="clearfix"
          v-for="item in recieverList"
          :key="item.id"
          :class="{
            bgColor: selectedReItems.indexOf(item.id) > -1
          }"
          @click="showReceiverSelected(item)"
          @mouseenter="changeReColor($event, item.id)"
          @mouseleave="removeReColor($event, item.id)"
        >
          <span class="left">{{ item.name }}</span>
        </li>
        <!-- 搜索无结果ui -->
        <div class="no-data" v-if="outPutSeach && recieverList <= 0">
          <p class="gray999">{{$t('leftPaneOutPut.noResult')}}</p>
          <p class="gray666">{{$t('leftPaneOutPut.changeKeyWords')}}</p>
        </div>
      </ul>
    </div>
    <div
      class="selectedNum"
      v-if="mediaInitParams.selectedOutput.length > 0 || selectedReItems.length > 0"
    >
      <span>{{ mediaInitParams.selectedOutput.length + selectedReItems.length }} {{$t('leftPaneOutPut.selected')}}</span>
      <span class="cancel" @click="cancelSelectedItems">{{$t('leftPane.cancel')}}</span>
    </div>
  </div>
</template>

<script>
import i18n from '@/lang/index.js'
import Bus from '@/assets/js/bus.js'
import { mapState } from 'vuex'
export default {
  data () {
    return {
      maxBitrate: '',
      outPutSeach: false,
      options: [
        {
          value: 0,
          label: 'Receiver'
        },
        {
          value: 1,
          label: 'IP OUT'
        }
      ],
      value: 0,
      showPover: false,
      // 下拉显示数组
      recieverList: [],
      receiverInfo: {
        pageNum: 1,
        pageSize: 20,
        condition: '',
        living: true,
        online: true,
        offline: true,
        noCamera: true,
        receiver: true
      },
      receiverPages: 0,
      ipOutInfo: {
        pageNum: 1,
        pageSize: 10,
        name: ''
      },
      ipOutPages: 0,
      searchInput: '',
      // 显示socialAccounts对话框
      socialAccounts: false,
      srtSetting: false,
      hlsSetting: false,
      proSetting: false,
      showRecieverList: true,
      showIpoutList: false,
      showSearchInput: false,
      showOutPutText: true,
      showSearchIcon: true,
      // 定义IP OUT 选中数组
      selctedIpItems: [],
      // 定义Receiver 选中的数组
      selectedReItems: [],
      // ipout选中项
      selctedIPOutItems: [],
      // reciever选中项
      selctedR: [],
      showoperateBox: true,
      ipOutList: [],
      showAddIpout: false,
      showIpoutCancel: false,
      showIpoutSearch: false,
      outPuts: [],
      // 上传数组
      uploadArr: [
        {
          type: 'Receiver',
          items: []
        },
        {
          type: 'IPout',
          items: []
        }
      ],
      // 传值数组
      gridInfo: {
        auth: 'edit',
        id: '',
        type: ''
      },
      scrollS: {
        loading: true
      }
    }
  },
  created () {
    // 获取默认outPut数据
    this.getReceiverInfo()
    Bus.$off('changeToReceiver')
    Bus.$on('changeToReceiver', () => {
      this.showOutPut(0)
      this.value = ''
    })
  },
  mounted () {
    const that = this
    document
      .getElementById('resultScroll')
      .addEventListener('scroll', that.handleScroll, true)
  },
  computed: {
    ...mapState({
      State: (state) => state,
      Config: (state) => state.config,
      mediaInitParams: (state) => state.mediaInitParams
    })
  },
  filters: {
    showItems (name) {
      if (!name) return ''
      if (name === 'Receiver') {
        return i18n.t('leftPaneOutPut.receiver')
      } else if (name === 'IP OUT') {
        return i18n.t('leftPaneOutPut.ipOut')
      } else {
        return name
      }
    }
  },
  methods: {
    async getReceiverInfo (pageNation) {
      if (this.receiverInfo.condition) {
        this.outPutSeach = true
      }
      try {
        const res = await this.$http.post(
          '/commentator-backend/user/source/output/receiver/page',
          this.receiverInfo
        )
        this.receiverPages = res.data.result.pages
        this.scrollS.loading = false
        if (pageNation) {
          // 关闭下拉loading
          this.scrollS.loading = false
          this.recieverList.push(...res.data.result.list)
        } else {
          this.recieverList = res.data.result.list
        }
      } catch (err) {
        console.log(err)
      }
    },
    showOutPut (value) {
      // 给media传递最大码率
      this.$http.get('/commentator-backend/userSetting/list')
        .then(res => {
          if (res.data.errorCode === '0x0') {
            if (res.data.result.maxBitrate) {
              this.maxBitrate = res.data.result.maxBitrate;
              this.$store.commit('setMaxBitrate', this.maxBitrate)
            }
          }
        })
      if (value === 0) {
        this.showAddIpout = false
        this.showRecieverList = true
        this.showSearchIcon = true
        this.showIpoutList = false
        this.receiverInfo.pageNum = 1
        this.getReceiverInfo()
        this.$emit('changeButton', 'hideOutputList')
      } else {
        this.showIpoutList = true
        this.showAddIpout = true
        this.showRecieverList = false
        this.showSearchIcon = false
        const obj = {
          type: 'OutputSetting',
          changeType: 'showOrHide',
          domId: 'mediaOutputList'
        }
        try {
          mediaObj.rId = ''
          mediaObj.shareToggle = true
          SM.__proto__.JumpMediaJs(obj)
        } catch (e) {
          console.log('mediaObj is not defined')
        }
        this.$emit('changeButton', 'showOutputList')
      }
    },
    // 添加ipout按钮的下拉菜单触发的事件
    handleCommand (command) {
      if (command === 'c') {
        this.gridInfo = {
          auth: 'edit',
          id: '',
          type: 'G'
        }
      }
    },
    showGridCreated () {
      this.gridInfo = {
        auth: 'edit',
        id: '',
        type: 'G'
      }
    },
    getSearch () {
      this.showSearchInput = true
      this.showOutPutText = false
      this.showSearchIcon = false
      this.$nextTick(() => {
        this.$refs.seachInput.focus()
      })
    },
    getCancel () {
      this.showSearchInput = false
      this.showOutPutText = true
      this.showSearchIcon = true
      this.receiverInfo.condition = ''
      this.receiverInfo.pageNum = 1
      this.scrollS.loading = true
      this.getReceiverInfo()
      this.$refs.myScrollBar.scrollTop = 0
      this.outPutSeach = false
    },
    // 模糊查询
    async searchReceiver () {
      if (this.receiverInfo.condition === '') {
        this.scrollS.loading = true
        this.getReceiverInfo()
      }
      if (this.receiverInfo.condition) {
        this.scrollS.loading = true
        this.getReceiverInfo()
      }
    },
    showSelectedItems (item) {
      const itemId = this.selctedIpItems.indexOf(item.id)
      if (itemId > -1) {
        this.selctedIpItems.splice(itemId, 1)
      } else {
        this.selctedIpItems.push(item.id)
        // this.uploadArr[1].id = this.selctedIpItems
      }
      const upLoads = []
      this.selctedIpItems.forEach((id) => {
        this.ipOutList.forEach((ele) => {
          if (id === ele.id) {
            upLoads.push(ele)
          }
        })
      })
      this.uploadArr[1].items = upLoads
      this.$emit('callback', this.uploadArr)
    },
    showReceiverSelected (item) {
      const itemId = this.selectedReItems.indexOf(item.id)
      if (itemId > -1) {
        this.selectedReItems.splice(itemId, 1)
      } else {
        this.selectedReItems.push(item.id)
        // this.uploadArr[0].id = this.selectedReItems
      }
      const upLoadRes = []
      this.selectedReItems.forEach((id) => {
        this.recieverList.forEach((ele) => {
          if (id === ele.id) {
            upLoadRes.push(ele)
          }
        })
      })
      this.uploadArr[0].items = upLoadRes
      this.$emit('callback', this.uploadArr)
    },
    cancelSelectedItems () {
      this.$store.state.mediaInitParams.selectedOutput = []
      try {
        mediaObj.updataSocial({
          nickName: ''
        })
      } catch (e) {
        console.log('mediaObj is not defined')
      }
      this.selectedReItems = []
      this.uploadArr.forEach((item) => {
        item.items = []
      })
    },
    closePopver (item) {
      this.$set(item, 'showPover', false)
      this.showoperateBox = false
      setTimeout(() => {
        this.showoperateBox = true
      }, 0)
    },

    closeSocialMediaDialog () {
      this.socialAccounts = false
    },
    closeSrtSettingDialog () {
      this.srtSetting = false
    },

    closeHlsSettingDialog () {
      this.hlsSetting = false
    },
    closeProMpegSettingDialog () {
      this.proSetting = false
    },
    // 编辑ipout
    editIpout (item) {
      if (item.type === 'G') {
        this.gridInfo.id = item.id
        this.gridInfo.type = 'G'
      } else if (item.type === 'SRT') {
        this.srtSetting = true
      }
      this.$set(item, 'showPover', false)
    },
    // 滚动条分页加载
    handleScroll () {
      const that = this
      // 文本内容高度
      const scrollH = that.$refs.myScrollBar.scrollHeight
      // 文本卷去高度
      const scrollT = that.$refs.myScrollBar.scrollTop
      // 包裹盒子高度
      const clientH = that.$refs.myScrollBar.clientHeight
      if (scrollT + clientH >= scrollH) {
        // 滚动条触底
        if (that.showRecieverList) {
          if (that.receiverInfo.pageNum < that.receiverPages) {
            that.receiverInfo.pageNum += 1
            // 开启下拉loading
            this.scrollS.loading = true
            that.getReceiverInfo(true)
          }
        }
      }
    },
    // ipOut 搜索相关
    getIpoutSearch () {
      this.showOutPutText = false
      this.showAddIpout = false
      this.showIpoutCancel = true
      this.showIpoutSearch = true
      this.$emit('changeButton', 'hideAdd')
    },
    cancelIpoutSearch () {
      this.showAddIpout = true
      this.showIpoutCancel = false
      this.showIpoutSearch = false
      this.showOutPutText = true
      this.ipOutInfo.name = ''
      this.$refs.myScrollBar.scrollTop = 0
      this.outPutSeach = false
      try {
        mediaObj.updataSocial({
          nickName: this.ipOutInfo.name
        })
      } catch (e) {
        console.log('mediaObj is not defined')
      }
      this.$emit('changeButton', 'showAdd')
    },
    // 模糊查询ipout
    seachIpout () {
      try {
        mediaObj.updataSocial({
          nickName: this.ipOutInfo.name
        })
      } catch (e) {
        mediaObj.updataSocial({
          nickName: this.ipOutInfo.name
        })
      }
    },
    // Receiver部分
    changeReColor (e, id) {
      if (this.selectedReItems.indexOf(id) <= -1) {
        e.currentTarget.className = 'bgColorHover'
      }
    },
    removeReColor (e, id) {
      if (this.selectedReItems.indexOf(id) <= -1) {
        e.currentTarget.className = null
      }
    },
    // IPout部分
    changeIpColor (e, id) {
      if (this.selctedIpItems.indexOf(id) <= -1) {
        e.currentTarget.className = 'bgColorHover'
      }
    },
    removeIpColor (e, id) {
      if (this.selctedIpItems.indexOf(id) <= -1) {
        e.currentTarget.className = null
      }
    },
    showPoppover (item) {
      // item.showPover = !item.showPover
      this.$set(item, 'showPover', !item.showPover)
      // this.showPover = !this.showPover
    }
  }
}
</script>

<style lang="less" scoped>
.outputSource {
  position: relative;
  padding-top: 10px;
  height: calc(47% + 13px);
  background: #28282e;
  li span {
    font-size: 16px;
  }
  .firstTop {
    display: flex;
    align-items: center;
  }
  .text {
    .flowx {
      display: inline-block;
      width: 4px;
      height: 20px;
      background-color: #f1f1f1;
    }
    .outPutTxt {
      display: inline-block;
      width: 70px;
      height: 22px;
      font-size: 18px;
      font-weight: bold;
      color: #f1f1f1;
      line-height: 23px;
    }
  }
  .selectBox {
    display: inline-block;
    width: 150px;
    /deep/.el-select {
      width: 150px;
      .el-input__inner {
        background-color: #010101;
        border-radius: 4px;
        border-color: #151314;
        color: #fff;
        height: 30px;
        line-height: 30px;
        &::placeholder {
          font-size: 14px;
          color: #F1F1F1;
        }
      }
    }
    /deep/ .el-select-dropdown {
      border: none;
      text-align: left;
      left: 0 !important;
      position: absolute !important;
      top: 20px;
    }
    /deep/ .el-input__icon {
      line-height: 30px;
    }
  }
  .noDataWords {
    margin-top: 120px;
    margin-left: 47px;
    h3 {
      text-align: center;
      width: 144px;
      height: 17px;
      margin-left: 15px;
      margin-bottom: 12px;
      font-size: 14px;
      font-weight: 500;
      color: #979da3;
      line-height: 17px;
    }
    h5 {
      width: 171px;
      height: 32px;
      font-size: 14px;
      text-align: center;
      color: #6d6d6d;
      line-height: 16px;
    }
  }
  .no-data {
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translateX(-50%);
    width: 200px;
    text-align: center;
    .gray999 {
      color: #999;
    }
    .gray666 {
      color: #666;
    }
  }
  .searchBox {
    width: 215px;
    display: inline-block;
    vertical-align: bottom;
    // 搜索输入框
    .searchInput {
      line-height: 30px;
      /deep/ .el-input__inner {
        height: 30px;
        border: none;
        color: #fff;
        background-color: #010101;
        &::placeholder {
          color: #6d6d6d;
        }
      }

      .el-icon-search {
        font-weight: bolder;
        margin: 7px 10px 0 0;
      }
    }
  }
  .list {
    height: calc(100% - 50px);
    overflow-y: scroll;
    margin-top: 7px;
  }
  .anotherList {
    height: calc(100% - 85px);
  }
  .selectedNum {
    height: 36px;
    line-height: 36px;
    padding: 0 10px;
    color: #03897b;
    background-color: #28282e;
    display: flex;
    justify-content: space-between;
    margin-top: 5px;
    .cancel {
      font-weight: bold;
      color: #03897b;
      cursor: pointer;
    }
  }
  .cancelSearch {
    margin-left: 15px;
    font-size: 13px;
    font-weight: bold;
    cursor: pointer;
  }
  .el-icon-search {
    font-size: 16px;
    cursor: pointer;
    font-weight: bolder;
    margin-left: 27px;
    width: 17px;
    height: 17px;
  }
  .add {
    display: inline-block;
    width: 20px;
    height: 20px;
    line-height: 10px;
    color: #fff;
    cursor: pointer;
    font-size: 30px;
    font-weight: bold;
    margin-left: 3px;
    margin-top: 1px;
  }
  .ipOutSearch {
    position: absolute;
    top: 17px;
    left: 233px;
  }
  .bgColor {
    background-color: #0ca789;
  }
  .bgColorHover {
    background-color: #010101;
  }
  .defaultBgColor {
    background-color: #28282e;
  }
  li {
    overflow: hidden;
    line-height: 50px;
    color: #f1f1f1;
    padding: 0 10px;
    text-align: left;
    cursor: pointer;
    position: relative;
    span {
      position: relative;
      z-index: 1;
      vertical-align: middle;
    }
    .right {
      line-height: 50px;
    }
    &.activeLine {
      background-color: #496b66 !important;
      padding: 2px;
      background-image: -webkit-linear-gradient(
        -45deg,
        #03897b 25%,
        rgba(3, 137, 123, 0) 25%,
        rgba(3, 137, 123, 0) 50%,
        #03897b 50%,
        #03897b 75%,
        rgba(3, 137, 123, 0) 75%,
        rgba(3, 137, 123, 0)
      );
      background-size: 10px 10px;
      animation: activeLine 0.8s linear infinite;
      box-sizing: content-box;
      position: relative;
      &:after {
        position: absolute;
        top: 2px;
        left: 2px;
        bottom: 2px;
        right: 2px;
        display: block;
        content: '';
        background-color: #496b66;
      }
    }
    &:hover {
      .editIcon {
        display: block;
      }
    }
  }
}
@keyframes activeLine {
  0% {
    background-position: 0px 0px;
  }
  50% {
    background-position: 0px 5px;
  }
  100% {
    background-position: 0px 10px;
  }
}
</style>
