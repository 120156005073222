<template>
  <div class="receiverSetting">
    <el-dialog
      :title="$t('leftPaneOutPut.receiverSetting')"
      class="recevierSetting"
      :visible="info.show"
      @close="close"
    >
      <div class="content">
        <div class="divCommon">
          <div class="title">{{ $t('leftPaneOutPut.receiverName') }}</div>
          <div class="receiverName commonBox">{{ info.info.name }}</div>
        </div>
        <div class="divCommon">
          <div class="title">{{ $t('leftPaneOutPut.issLive') }}</div>
          <el-select
            v-model="selectIssp"
            filterable
            popper-class="isspSelect"
            @visible-change="getIsspList"
            @change="changeInfo"
            :loading="isspLoading">
            <el-option
              v-for="item in isspList"
              :key="item.id"
              :label="item.nickName"
              :value="item.id">
            </el-option>
          </el-select>
        </div>
        <div class="divCommon">
          <div class="title">{{ $t('leftPaneOutPut.resolution') }}</div>
          <div class="resolution commonBox">{{ $t('leftPaneOutPut.followEvent') }}</div>
        </div>
        <!--比特率-->
        <div class="divCommon">
          <div class="title">{{ $t('leftPaneOutPut.bitrate') }}</div>
          <div class="silderBox">
            <el-slider v-model="bitrateVal" :min="100" :max="30720" disabled></el-slider>
            <el-input v-model="bitrateVal" class="bitrateInput" disabled></el-input>
            <span class="grayColor">kbps</span>
          </div>
        </div>
        <el-divider class="receiverDivider"></el-divider>
       <!-- 比特流类型-->
        <div class="divCommon">
          <div class="title streamType">{{ $t('leftPaneOutPut.streamType') }}</div>
          <div class="radioDiv"><el-radio v-model="streamType" disabled label="0">{{ $t('leftPaneOutPut.programFeed') }}</el-radio></div>
          <div><el-radio v-model="streamType" disabled label="1">{{ $t('leftPaneOutPut.cleanFeed') }}</el-radio></div>
        </div>
        <el-button class="saveBtn" @click="setLiveIsspUrl">{{ $t('leftPaneOutPut.save') }}</el-button>
        <p class="isspNote" v-show="selectIssp!='defaultGrid'">{{ $t('leftPaneOutPut.isspNote') }}</p>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
  data () {
    return {
      isspList: [],
      selectIssp: 'defaultGrid',
      oldSelectIssp: 'defaultGrid', // 为了当更改 issp 时清除掉之前的选项
      bitrateVal: 15360,
      streamType: '1',
      isspLoading: false
    }
  },
  props: {
    info: {
      type: Object,
      default: function () {
        return {}
      }
    },
    eventInfo: {
      type: Object,
      default: () => {
        return {}
      }
    },
    rLiveIsspInfo: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  computed: {
    ...mapState({
      mediaInitParams: state => state.mediaInitParams
    })
  },
  watch: {
    eventInfo: {
      immediate: true,
      deep: true,
      async handler (val) {
        const _this = this
        const receiverList = val.receivers
        await this.getIsspList(true)
        receiverList.forEach(item => {
          if (_this.info.info && item.id == _this.info.info.peerId) {
            this.isspList.forEach(item1 => {
              if (item.liveOutputId == item1.id) {
                _this.selectIssp = item1.id
                _this.oldSelectIssp = item1.id
                _this.bitrateVal = Number(item1.bitrate)
                _this.streamType = JSON.parse(item1.externalParameters).streamType + ''
              }
            })
          }
        })
      }
    }
  },
  methods: {
    close () {
      // const _this = this
      // _this.mediaInitParams.selectedOutput = _this.mediaInitParams.selectedOutput.filter(info => {
      //   return info.id != _this.selectIssp
      // })
      // _this.isspList.forEach(item => {
      //   if (item.id == this.oldSelectIssp) {
      //     _this.mediaInitParams.selectedOutput.push({
      //       id: item.id,
      //       name: item.nickName,
      //       type: 'ISSP',
      //       url: '',
      //       disabled: true
      //     })
      //   }
      // })
      this.$emit('closePopup')
    },
    async getIsspList (val) {
      if (!val) return
      const params = {
        pageNum: 1,
        pageSize: 1000,
        secondType: 'ISSP'
      }
      try {
        const mediaObj = await SM.__proto__.getOutputList(params)
        if (mediaObj.data.errorCode === '0x0') {
          this.isspList = mediaObj.data.result.list
          this.isspList.unshift({ id: 'defaultGrid', nickName: 'Default Grid' })
        }
      } catch (error) {
        console.log(error)
      }
    },
    changeInfo (id) {
      const _this = this
      this.isspList.forEach(item => {
        if (id == 'defaultGrid') {
          this.bitrateVal = 15360
          this.streamType = '1'
        } else if (item.id == id) {
          this.bitrateVal = Number(item.bitrate)
          this.streamType = JSON.parse(item.externalParameters).streamType + ''
          _this.mediaInitParams.selectedOutput.push({
            id,
            name: item.nickName,
            type: 'ISSP',
            url: '',
            disabled: true
          })
        }
        // 为了同步下边 ip out 的勾选, 先删除之前所选择的
        _this.mediaInitParams.selectedOutput = _this.mediaInitParams.selectedOutput.filter(info => {
          return info.id != _this.oldSelectIssp
        })
      })
      this.oldSelectIssp = id
    },
    setLiveIsspUrl () {
      this.info.show = false
      this.$emit('setIsspUrl', this.selectIssp)
    }
  }
}
</script>
<style lang="less" scoped>
  .receiverSetting {
    /deep/.el-dialog__wrapper.recevierSetting {
      .el-dialog {
        width: 330px;
        height: 665px;
        transform: translateX(530px);
        .el-dialog__header {
          border-bottom: 2px solid #444;
          padding-bottom: 20px;
          .el-dialog__title {
            font-weight: 700px;
            font-size: 16px;
          }
        }
        .el-dialog__body {
          padding: 24px 30px 30px;
          max-height: 540px;
          .content {
            .divCommon {
              margin-bottom: 16px;
              .el-select {
                .el-input {
                  width: 270px;
                  .el-input__inner {
                    background: #353535;
                  }
                }
              }
              .el-radio__input.is-checked .el-radio__inner {
                border-color: #33AB4F;
                background: #33AB4F;
              }
              .el-radio__input.is-checked+.el-radio__label {
                color: #33AB4F;
              }
              .title {
                color: #DFDFDF;
                font-size: 14px;
                &.streamType {
                  margin-bottom: 20px;
                }
              }
              .radioDiv {
                margin-bottom: 10px;
              }
              .commonBox {
                height: 36px;
                background: #353535;
                line-height: 36px;
                text-indent: 10px;
                cursor: not-allowed;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                border-radius: 4px;
              }
              .resolution {
                color: #555661;
                text-indent: 10px;
              }
              .silderBox {
                height: 32px;
                line-height: 32px;
                display: flex;
                align-items: center;
                .el-slider {
                  width: 158px;
                  display: inline-block;
                  margin-right: 12px;
                  .el-slider__bar {
                    background: #33AB4F;
                  }
                  .el-slider__button {
                    border: 0;
                  }
                }
                .el-input.bitrateInput {
                  width: 50px;
                  margin-right: 15px;
                  .el-input__inner {
                    height: 32px;
                    line-height: 32px;
                    margin-left: 14px;
                    background: #353535;
                    padding: 0 3px 0 8px;
                    border-radius: 4px 0 0 4px;
                  }
                }
                .grayColor {
                  color: #666666;
                  font-size: 12px;
                  background: #353535;
                  margin-left: -1px;
                  border-radius: 0 4px 4px 0;
                  padding-right: 8px;
                }
              }
            }
            .el-divider.receiverDivider {
              height: 2px;
              margin: 16px 0 8px 0;
            }
            .el-button.saveBtn {
              background: #33AB4F;
              border-radius: 4px;
              border-color: #33ab4f;
              color: #ffff;
              width: 250px;
              margin: 15px auto 0;
              &:hover {
                background: #2c9745;
                border-color: #2c9745;
              }
            }
            .isspNote{
              color: #6D6D6D;
              word-break: normal;
              font-size: 12px;
              margin-top: 8px;
            }
          }
        }
      }
    }
  }
</style>
