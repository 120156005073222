<template>
  <div class="commentator clearfix">
    <div class="leftBox">
      <InputSpurce @callback="changeId" />
      <OutputSpurce @callback="changeId" @changeButton="controlButton"/>
      <div id="mediaOutputList" class="scrollbar" v-if="showMediaOutputList"></div>
    </div>
    <div class="rightBox">
      <EventList :selectInput="selectInput" :selectOutput="selectOutput" />
    </div>
  </div>
</template>
<script>
import InputSpurce from './input/indexa'
import OutputSpurce from './output/myIndex'
import EventList from './eventList'
import { mapState } from 'vuex'
export default {
  components: {
    EventList,
    InputSpurce,
    OutputSpurce
  },
  data () {
    return {
      selectInput: {
        type: 'vfb',
        id: ''
      },
      selectOutput: [],
      showMediaOutputList: false
    }
  },
  created () {
    document.getElementsByClassName('contentBox')[0].style.height = 'calc(100vh - 65px)'
  },
  computed: {
    ...mapState({
      state: (state) => state,
      selectedOutput: state => state.mediaInitParams.selectedOutput
    })
  },
  watch: {
    selectedOutput: {
      handler (value) {
        const mediaOutputListDom = document.getElementById('mediaOutputList')
        if (value.length > 0) {
          if (mediaOutputListDom) {
            document.getElementById('mediaOutputList').style.height = '37%'
          }
        } else {
          if (mediaOutputListDom) {
            document.getElementById('mediaOutputList').style.height = '42%'
          }
        }
      }
    }
  },
  methods: {
    changeId (obj) {
      if (Array.isArray(obj)) {
        this.selectOutput = obj
      } else {
        const { type, id, name } = obj
        this.selectInput = { type, id, name }
      }
      if (obj.type === 'controlOutput') {
        this.showMediaOutputList = obj.value
      }
    },
    controlButton (type) {
      if (type === 'hideAdd') {
        this.$nextTick(() => {
          const add = document.getElementById('mediaOutputList').querySelector('.Add')
          add.style.display = 'none'
        })
      } else if (type === 'showAdd') {
        const add = document.getElementById('mediaOutputList').querySelector('.Add')
        add.style.display = 'block'
      } else if (type === 'hideOutputList') {
        this.showMediaOutputList = false
      } else if (type === 'showOutputList') {
        this.showMediaOutputList = true
      }
    }
  }
}
</script>
<style lang="less" scoped>
.commentator {
  height: 100%;
  width: 100wh;
  display: flex;
  .left,
  .right {
    height: 100%;
  }
  // fixme
  .leftBox {
    float: left;
    width: 285px;
  }

  .rightBox {
    width: calc(100vw - 295px);
    margin-left: 10px;
  }
  #mediaOutputList {
  position: absolute;
  top: calc(50% + 80px);
  width: 285px;
  height: 42%;
  box-sizing: border-box;
  padding: 5px 0px 0 0px;
  // padding-bottom: 50px;
  /deep/ .outPutSetting   {
    height: calc(100% - 5px);
      .scrollAuto::-webkit-scrollbar {
      /*滚动条整体样式*/
       width: 6px !important; /*高宽分别对应横竖滚动条的尺寸*/
       height: 6px !important;
      }
      .scrollAuto::-webkit-scrollbar-thumb {
       /*滚动条里面小方块*/
       border-radius: 10px !important;
       background: rgba(255, 255, 255, 0.3)  !important;
      }

      .scrollAuto::-webkit-scrollbar-track {
        /*滚动条里面轨道*/
        border-radius: 10px !important;
        background: none !important;
      }
      .outputExample{
            line-height: 239.59px;
      }
    .OutputList {
      .OutputItem {
        padding: 5px 0 0 10px;
        border: none;
          .commentatorCheckBox {
            display: none;
          }
      }
      .selectedAccount {
        background-color: #0ca789;
        .switch {
          display: none;
        }
      }
    }
  }
  /deep/ .OtherChannels {
      left: 290px  !important;
      top: 65px !important;
      background-color: #28282e;
      height: calc(100% - 65px);
      // fixme
      overflow-y: auto;
      .outputExtra {
        .outputInfoBox {
          max-height: none;
          min-height: none;
        }
      }
      &::-webkit-scrollbar {
      /*滚动条整体样式*/
       width: 6px; /*高宽分别对应横竖滚动条的尺寸*/
       height: 6px;
      }
      &::-webkit-scrollbar-thumb {
       /*滚动条里面小方块*/
       border-radius: 10px;
       background: rgba(255, 255, 255, 0.3);
      }

      &::-webkit-scrollbar-track {
        /*滚动条里面轨道*/
        border-radius: 10px;
        background: none;
      }
  }
  /deep/.AddOutput {
    position: relative;
    margin-top: 5px;
    text-align: left;
    top: calc(-100% - 40px);
    left: calc(100% - 60px);
    width: 30px;
    .Add {
      opacity: 0;
    }
    .ChannelList {
    top: 100%;
    left: 10px;
    background-color: #333339;
    box-shadow: none;
    z-index: 10001;
    &::-webkit-scrollbar {
      /*滚动条整体样式*/
       width: 6px; /*高宽分别对应横竖滚动条的尺寸*/
       height: 6px;
       background: none !important;
      }
    &::-webkit-scrollbar-thumb {
       /*滚动条里面小方块*/
       border-radius: 10px;
       background: rgba(255, 255, 255, 0.3);
      }

    &::-webkit-scrollbar-track {
        /*滚动条里面轨道*/
        border-radius: 10px;
        background: none;
      }
    hr {
      display: none;
    }
    }
  }
  }
}
</style>
