import Vue from 'vue'
import Vuex from 'vuex'
import $ from 'jquery'
import Tool from '@/assets/js/utils.js'
import cookie from 'js-cookie'
import { $http } from '@/assets/js/axiosExtend.js'
import Router from '@/router'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    backEndVersion: '',
    config: {},
    userInfo: {},
    userTypeInfo: '',
    loginInfo: {},
    statusInfo: {
      0: {
        text: 'To Do',
        color: 'white'
      },
      1: {
        text: 'To Do',
        color: 'white'
      },
      2: {
        text: 'Preparing(5 min)',
        color: 'white'
      },
      3: {
        text: 'Preparing(5 min)',
        color: 'white'
      },
      4: {
        text: 'In progress',
        color: 'blue'
      },
      5: {
        text: 'Preparing',
        color: 'red'
      },
      6: {
        text: 'Preparing',
        color: 'red',
        errorInfo: 'Please check Receiver'
      },
      7: {
        text: 'Failed',
        color: 'red'
      },
      8: {
        text: 'Done',
        color: 'gray'
      },
      9: {
        text: 'Preparing',
        color: 'red'
      },
      10: {
        text: 'Stopped',
        color: 'gray'
      },
      12: {
        text: 'Stopping',
        color: 'blue'
      }
    },
    isMute: false,
    socket: '',
    praivateInfo: {},
    eventInfo: {},
    pageLoading: false,
    newPageLoading: false, // 用于页面整体全屏
    checkEnv: {},
    sourceType: [
      {
        label: '',
        options: [
          {
            label: 'PP PGM',
            value: 'pgm'
          },
          {
            label: 'PP Slot',
            value: 'slot'
          }
        ]
      },
      {
        label: '',
        options: [
          {
            label: 'TVU Transceiver - Grid',
            value: 'vfb'
          },
          {
            label: 'Pack',
            value: 'pack'
          },
          {
            label: 'Upload files',
            value: 'file'
          }
        ]
      },
      {
        label: '',
        options: [
          {
            label: 'EXT IP Source',
            value: 'ext'
          },
          {
            label: 'RTMP Push',
            value: 'rtmp'
          }
        ]
      }
    ],
    sourceTypeTemplate: [
      {
        label: '',
        options: [
          {
            label: 'PP PGM',
            value: 'pgm'
          },
          {
            label: 'PP Slot',
            value: 'slot'
          }
        ]
      },
      {
        label: '',
        options: [
          {
            label: 'TVU Transceiver - Grid',
            value: 'vfb'
          },
          {
            label: 'Pack',
            value: 'pack'
          },
          {
            label: 'Upload files',
            value: 'file'
          }
        ]
      },
      {
        label: '',
        options: [
          {
            label: 'EXT IP Source',
            value: 'ext'
          }
        ]
      }
    ],
    logo: require(`@/${$cm.logo}`),
    logoGray: require(`@/${$cm.logoGray}`),
    homeLogo: require(`@/${$cm.homeLogo}`),
    logoTitle: `${$cm.commonLogoTitle}`,
    commonLogo: `${$cm.commonLogo}`,
    homeTitle: $cm.title,
    switchLang: $cm.switchLang,
    // media 相关的初始化数据
    mediaInitParams: {
      selectedOutput: [],
      programInfo: {
        maxBitrate: '',
        domain: ''
      },
      addAccountId: ''
    },
    browserLanguage: 'en',
    controlMutes: {},
    advanceSettingInfo: {
      bitrateValue: 2,
      mainWindowBitrate: 2000,
      mainWindowResolution: '1280x720',
      mainWindowRate: '30',
      checkedRps: 0,
      checkedPgmRecording: 1,
      checkedReplayApp: 0,
      checkedOutPutBuffer: 0,
      outPutBufferValue: 2,
      checkedInputSourceRecording: 0,
      cloudRecordChecked: []
    },
    fullScreen: false,
    showFootBar: true,
    bitrateValue: 2,
    mainWindowBitrate: 2000,
    mainWindowResolution: '1280x720',
    mainWindowRate: '30',
    clientId: '',
    mainwindowHeight: '',
    topMainWindow: '',
    leftSource: '',
    BoomMainWindow: '',
    BoomMainWindows: '',
    rtcLoaded: false,
    networkConnect: true,
    hostSpeakerIsMute: false,
    MainWindow: '',
    topTitle: '',
    autoPlayFailed: false,
    rtmpName: '',
    serverValue: '',
    keyValue: '',
    rtmpNameBackup: '',
    serverValueBackup: '',
    keyValueBackup: '',
    groupDisplayList: [],
    checkedAec: false,
    checkedAns: true,
    checkedAgc: false,
    showInitCameraTips: false,
    initCamera: false,
    cameraName: '',
    mainReceiverAbleDely: false,
    backReceiverAbleDely: false
  },
  mutations: {
    setMaxBitrate (state, val) {
      state.mediaInitParams.programInfo.maxBitrate = val
    },
    setDomain (state, val) {
      state.mediaInitParams.programInfo.domain = val
    },
    config (state, val) {
      state.config = val
      window.versionNewsObj = {
        code: state.config.versionControl.versionCode,
        pageSize: 10,
        versionUrl: 'https://' + state.config.versionControl.versionUrl,
        versionApiUrl: 'https://' + state.config.versionControl.versionApiUrl,
        session: cookie.get('SID'),
        theme: 'black'
      }
      Vue.prototype.$TVU = {
        http: $http,
        userGroup: 'https://' + state.config.versionControl.versionApiUrl,
        serverName: 'Commentator',
        userAuthUrl: state.config.urlInfo.userAuthUrl, // 后端配置的url地址
        session: cookie.get('SID')
      }
    },
    saveLoginInfo (state, val) {
      state.userInfo = val
      state.loginInfo = val
    },
    saveUserTypeInfo (state, val) {
      state.userTypeInfo = val
    },
    saveBackendVersion (state, payload) {
      state.backEndVersion = payload
    },
    setWebsocket (state, payload) {
      state.socket = payload
    },
    isMute (state, val) {
      state.isMute = val
    },
    saveEventInfo (state, val) {
      state.eventInfo = val
    },
    pageLoading (state, val) {
      state.pageLoading = val
    },
    checkEnv (state, val) {
      state.checkEnv = val
    }
  },
  actions: {
    async config ({ commit }) {
      const res = await $http.get('/commentator-backend/publicApi/v2/initConfig', { showAlert: true })
      if (res.data.errorCode === '0x0') {
        const data = res.data.result
        if (data) {
          localStorage.setItem(
            'partylineCommentatorConfig',
            JSON.stringify(data)
          )
          commit('config', data)
        }
      }
    },
    async userInfo ({ state, commit }) {
      try {
        const sid = Tool.getSession()
        if (!sid) return
        if (state.userInfo.id) {
          return state.userInfo.id
        }
        const res = await $http.get('/commentator-backend/user/v2/loginInfo', { showAlert: true })
        if (res.data.errorCode === '0x0') {
          const data = res.data.result
          if (data) {
            commit('saveLoginInfo', data)
          }
        }
        return res.data.result.id
      } catch (error) {
        console.log(error)
      }
    },
    async getUserType ({ state, commit }) {
      try {
        const res = await $http.get(`https://${state.config.urlInfo.gateway}/newppapi/user/getUserInfo`, { showAlert: true })
        if (res.data.errorCode === '0x0') {
          const data = res.data.result.userType
          commit('saveUserTypeInfo', data)
        }
      } catch (err) {
        console.log(err);
      }
    },
    // 获取commentator后端版本号
    async getBackEndVersion ({ commit }) {
      await $.ajax({
        url: '/commentator-backend/publicApi/version',
        type: 'get',
        success (data) {
          commit('saveBackendVersion', data)
          localStorage.setItem('backendVersion', data)
        },
        error (err) {
          console.log('这是错误提示', err)
        }
      })
    },
    async checkEnv ({ state, commit }, partyCode) {
      if (!partyCode || typeof (partyCode) != 'string') return
      state.newPageLoading = false
      if (partyCode) partyCode = partyCode.replace(/[^0-9]/gi, '') // 过滤掉非数字字符
      commit('pageLoading', true)
      await $http
        .get(`${state.config.urlInfo.partyline}/partyline-backend/party/noLogin/check`, {
          params: { partyCode, clientType: 0 },
          timeout: 10000,
          errorTips: false
        })
        .then(async (res) => {
          if (res.data.errorCode == '0x0') {
            commit('pageLoading', false)
            const result = res.data.result
            console.info(227, result)
            // 判断当前环境是不是和check接口给的一致,默认设置需要更新环境
            result.updateEnv = true
            // 获取当前域名下的所有cookie
            const cookieList = cookie.get()
            Object.keys(cookieList).forEach(key => {
              if (result.commentatorTagKey == key && result.commentatorTagValue == cookieList[key]) {
                result.updateEnv = false
              }
            })

            if (!result.commentatorTagKey || !result.commentatorTagValue) {
              result.updateEnv = false
            }
            // 判断如果当前isLogin为false，则清除session
            if (!result.login) {
              cookie.remove('SID', { domain })
            }

            commit('checkEnv', result)
            // remove tag cookies
            result.commentatorTagList && result.commentatorTagList.forEach(v => {
              if (v.tagKey != result.tagKey || v.tagValue != result.tagValue) {
                Tool.setTagCookie(v.tagKey, v.tagValue, -1)
              }
            })
            result.tagList && result.tagList.forEach(v => {
              if (v.tagKey != result.tagKey || v.tagValue != result.tagValue) {
                Tool.setTagCookie(v.tagKey, v.tagValue, -1)
              }
            })
            // set Tag Cookies
            Tool.setTagCookie(result.tagKey, result.tagValue)
            Tool.setTagCookie(result.commentatorTagKey, result.commentatorTagValue)
            if (result.updateEnv) {
              window.location.reload()
            }
          } else if (res.data.errorCode == '80520103') {
            const userServerUrl = state.config.urlInfo.userservice
            window.location.href = `${userServerUrl}/?url=${location.protocol}//${location.host}/commentator?join_id=${partyCode}&module=commentator&byLogin=true&serviceName=Commentator`
          } else if (res.data.errorCode == '80520104') {
            Router.push({ name: 'remove', query: { type: 'noCommonpartyPermission', partyCode } })
          } else if (res.data.errorCode == '82201504') {
            commit('pageLoading', false)
            Router.push({ name: 'leaveParty', query: { type: 'notExist' } })
          }
        })
        .catch(() => {
          state.newPageLoading = false
          Vue.prototype.$alert('Request timed out, please click refresh to reload the page', {
            confirmButtonText: 'Refresh',
            customClass: 'reloadAlert',
            confirmButtonClass: 'reloadBtn',
            showClose: false,
            showCancelButton: false,
            closeOnClickModal: false,
            closeOnPressEscape: false
          }).then(() => {
            location.reload(); // 重新加载页面
          })
          commit('pageLoading', false)
        })
    }
  },
  modules: {}
})
