<template>
  <div id="box">
    <div class="delayWarn" v-show="networkTips">
      <i class="el-icon-warning-outline"></i>{{$t('coordinationParticipant.commentatorAbnormalNet')}}<el-button
        @click="refreshPage"
        icon="el-icon-refresh-right"
        >{{$t('coordinationParticipant.refresh')}}</el-button
      >
    </div>
    <div id="content">
      <Group
        ref="mainWindowContent"
        modeKey="mainWindow"
        title="Main window"
        :metaDataTimeCode="metaDataTimeCode"
        :metaDateTimeStamp="metaDateTimeStamp"
        :meInfo="meInfo"
        :showTitle="false"
        :userInfo="userInfo"
        :rtc="rtc[externalPartyCode]"
        :channels="externalChannel"
        :nickNameInfo="nickNameInfo"
        :videoObj="videoObj"
        :amStatusInfo ="amStatusInfo"
        :allPairStatusInfo="allPairStatusInfo"
        :muteAudioObj="muteAudioObj"
        :pgmMute="pgmMute"
        :userNewFullScreen="true"
        @showMetaDataInfo="showDataInfo"
        @callback="groupCallback"
      >
      </Group>
      <Group
        ref="talentCommentator"
        title="Talent Commentator"
        :meInfo="meInfo"
        :userInfo="userInfo"
        :channels="channels"
        :privateInfo="privateInfo"
        :showTitle="false"
        :userNewFullScreen="true"
        :isPrivate="isPrivate"
        :rtc="rtc[realPartyCode]"
        :nickNameInfo="nickNameInfo"
        :videoObj="videoObj"
        :muteAudioObj="muteAudioObj"
        :videoSelf="videoSelf"
        :muteAudioSelf="muteAudioSelf"
        @callback="groupCallback"
      />
      <!-- 按照最新需求 host 为多个 -->
      <AttendUser
        v-for="val in hostInfo"
        :key="val.id"
        class="is-local hide"
        nickName=""
        :rtc="rtc[realPartyCode]"
        :singChannel="hostInfo[val.id]"
        :userInfo="userInfo"
      >
      </AttendUser>
      <div class="foot" v-show="State.showFootBar">
        <div class="optionBox">
          <!-- 离开会议区域 -->
          <el-tooltip
            class="item"
            effect="dark"
            :content="$t('coordinationParticipant.leaveParty')"
            placement="top"
          >
            <i
              class="iconfont hangup pointer m_r_40"
              @click="showDialog"
              @mouseenter="hangupHover = true"
              @mouseleave="hangupHover = false"
            >
              <!-- 挂断 -->
              <svg class="iconfont hangup" aria-hidden="true">
                <use v-if="!hangupHover" xlink:href="#icon-toolbarLeave"></use>
                <use v-if="hangupHover" xlink:href="#icon-toolbarLeave"></use>
              </svg>
            </i>
          </el-tooltip>
          <el-divider class="leaveDivider" direction="vertical"></el-divider>
          <!-- 控制摄像头的区域 -->
          <div class="m_r_40">
            <el-tooltip
              class="cameraItem"
              effect="dark"
              :content="$t('coordinationParticipant.turnOffCamera')"
              placement="top"
              v-if="videoSelf"
            >
              <i
                class="pointer iconfont icon-universal_cameraon"
                :class="{
                  m_r_0: devicesInfo.cameraList && devicesInfo.cameraList.length && rtcLoaded
                }"
                @click="setOperate({ type: 'muteVideo', id: userInfo.rtilCode })"
              >
              </i>
            </el-tooltip>
            <el-tooltip
              class="cameraItem"
              effect="dark"
              :content="$t('coordinationParticipant.turnOnCamera')"
              placement="top"
              v-if="!videoSelf"
            >
              <i
                class="iconfont pointer icon-universal_cameraoff"
                :class="{
                  m_r_0:
                    devicesInfo.cameraList &&
                    devicesInfo.cameraList.length &&
                    rtcLoaded,
                    active: !videoSelf
                }"
                @click="setOperate({ type: 'muteVideo', id: userInfo.rtilCode })"
              >
              </i>
            </el-tooltip>
            <device-select
              v-if="
                devicesInfo.cameraList &&
                  devicesInfo.cameraList.length &&
                  rtcLoaded
              "
              title="Select a Camera"
              @updateDeviceId="updateDeviceId"
              @changeDevice="changeDevice"
              type="video"
              :deviceSelectId="devicesInfo.cameraId"
              :data="devicesInfo.cameraList"
            />
          </div>
          <!-- 控制网页声音区域 -->
          <el-tooltip
            class="speakerItem"
            effect="dark"
            :content="speakerTitle"
            placement="top"
          >
            <i
              class="pointer iconfont icon-speakeron"
              @click="changeMute(isMute)"
              v-if="!isMute"
            ></i>
            <i
              :class="{active: isMute}"
              class="pointer iconfont icon-speakeroff"
              @click="changeMute(isMute)"
              v-else
            ></i>
          </el-tooltip>
          <device-select
          class="m_r_48"
          v-if="
            devicesInfo.speakerList &&
              devicesInfo.speakerList.length &&
              rtcLoaded
          "
          title="Select a Speaker"
          @updateDeviceId="updateDeviceId"
          @changeDevice="changeDevice"
          type="speaker"
          :deviceSelectId="devicesInfo.speakerId"
          :data="devicesInfo.speakerList"
        />
          <!-- 分割线 -->
          <el-divider direction="vertical"></el-divider>
          <!-- push to talk 区域 -->
          <el-tooltip
            class="micOnItem"
            effect="dark"
            :content="micTitle"
            placement="top"
          >
            <i
              class="iconfont pointer"
              :class="{
                m_r_0:
                  devicesInfo.microphoneList &&
                  devicesInfo.microphoneList.length &&
                  rtcLoaded,
                audioIcon:hadPushTalk,
                muteAudio:!hadPushTalk
              }"
              @click="setOperate({ type: 'muteAudio', id: userInfo.rtilCode })"
            >
               <span style="width:40px; height:40px; display:inline-block;">
                <i  v-if="hadPushTalk" style="font-size:28px;border:0; " :style="{'color':(talkAllBackState||talkHostBackState||talkPipBackState) ?'#6D6D6D':'#33AB4F'}"
                 :class="{'iconfont icon-micOff':(talkAllBackState||talkHostBackState||talkPipBackState),'iconfont icon-micOn':!(talkAllBackState||talkHostBackState||talkPipBackState)}">
                </i>
                <i  v-else style="font-size:28px;border:0;"  :style="{'color':(talkAllBackState||talkHostBackState||talkPipBackState) ?'#6D6D6D':'#F6445A'}" class="iconfont icon-a-mic" >
                </i>
              </span>
              <span style="top: -10px;display: block;" :style="{'color':(talkAllBackState||talkHostBackState||talkPipBackState) ?'#6D6D6D':''}">{{$t('coordinationParticipant.mic')}}</span>
            </i>
          </el-tooltip>
          <device-select
            class="m_r_40"
            v-if="
              devicesInfo.microphoneList &&
                devicesInfo.microphoneList.length &&
                rtcLoaded
            "
            title="Select a Microphone"
            @changeDevice="changeDevice"
            @updateDeviceId="updateDeviceId"
            type="audio"
            :deviceSelectId="devicesInfo.microphoneId"
            :data="devicesInfo.microphoneList"
          />
          <!-- Talkback 区域 -->
          <el-tooltip
            class="talkAll"
            effect="dark"
            :content="this.$t('coordinationParticipant.clikAndHold')"
            placement="top"
          >
            <i
              class="iconfont pointer pushTalk"
              v-if="hasTalkback"
              @mouseup="controlTalkback($event.button+'', $event.type)"
            >
              <span style="width:40px; height:40px; display:inline-block;" v-if="talkbackTimerBtn">
              <i style="font-size:28px;color:#33AB4F;border: 0;"  class="iconfont icon-a-TalkbackAll" >
              </i>
              </span>
              <span style="display:block;top:-6px;" class="loading"  v-else-if="!talkbackTimerBtn">
                <svg class="iconfont" aria-hidden="true">
                  <use xlink:href="#icon-a-BTNloading"></use>
                </svg>
              </span>
              <div v-if="talkbackTimerBtn" style="margin-top:-8px;"></div><div v-if="talkbackTimerBtn" style="margin-top:-8px;"></div>
              <span :style="{'top':'-10px','display':'block'}">{{$t('coordinationParticipant.talkBack')}}</span>
            </i>
              <!-- @mouseup="clearTalkback" -->
            <i
              class="iconfont pushTalk font_35 pointer"
              v-else-if="!hasTalkback"
              @mousedown="controlTalkback($event.button+'', $event.type)"
              @mouseup="controlTalkback($event.button+'', $event.type)"
            >
              <span style="width:40px; height:40px; display:inline-block;" v-if="talkbackTimerBtn">
                <i style="font-size:28px;border:0;"  :style="{'color':(talkHostBackState||talkPipBackState) ?'#6D6D6D':'#F6445A'}" class="iconfont icon-talkBackAll" >
                </i>
              </span>
              <span  style="display:block;top:-6px;" class="loading" v-else-if="!talkbackTimerBtn" >
                <svg class="iconfont" aria-hidden="true">
                  <use xlink:href="#icon-a-BTNloading"></use>
                </svg>
              </span>
              <span :style="{'top':'-10px','display':'block','color':(talkHostBackState||talkPipBackState) ?'#6D6D6D':''}">{{$t('coordinationParticipant.talkBack')}}</span>
            </i>
          </el-tooltip>
          <!--TalkBack host-->
          <el-tooltip
            class="talkHost"
            effect="dark"
            :content="this.$t('coordinationParticipant.clikAndHostHold')"
            placement="top"
          >
          <!--按，松开-->
            <i
              class="iconfont pointer pushTalk"
              v-if="hasHostTalkback"
              @mouseup="controlTalkback($event.button+'', $event.type,'Host')"
            >
              <span style="width:40px; height:40px; display:inline-block;" v-if="talkbackHostTimerBtn">
              <i style="font-size:28px;color:#33AB4F;border: 0;"  class="iconfont icon-talkHostOff" >
              </i>
              </span>
              <span style="display:block;top:-6px;" class="loading"  v-if="!talkbackHostTimerBtn">
                <svg class="iconfont" aria-hidden="true">
                  <use xlink:href="#icon-a-BTNloading"></use>
                </svg>
              </span>
              <div v-if="talkbackHostTimerBtn" style="margin-top:-8px;"></div><div  v-if="talkbackHostTimerBtn" style="margin-top:-8px;"></div>
              <span :style="{'top':'-10px','display':'block'}">{{$t('coordinationParticipant.talkHostBack')}}</span>
            </i>
            <!-- @mouseup="clearTalkback" -->
            <!--不按-->
            <i
              class="iconfont pushTalk font_35 pointer"
              v-else-if="!hasHostTalkback"
              @mousedown="controlTalkback($event.button+'', $event.type,'Host')"
              @mouseup="controlTalkback($event.button+'', $event.type,'Host')"
            >
              <span style="width:40px; height:40px; display:inline-block;" v-if="talkbackHostTimerBtn">
                <i style="font-size:28px;border:0;"  :style="{'color':(talkAllBackState||talkPipBackState) ?'#6D6D6D':'#F6445A'}" class="iconfont icon-talkBackCoord" >
                </i>
              </span>
              <span  style="display:block; top:-6px;" class="loading" v-if="!talkbackHostTimerBtn" >
                <svg class="iconfont" aria-hidden="true">
                  <use xlink:href="#icon-a-BTNloading"></use>
                </svg>
              </span>
              <span :style="{'top':'-10px','display':'block','color':(talkAllBackState||talkPipBackState) ?'#6D6D6D':''}">{{$t('coordinationParticipant.talkHostBack')}}</span>
            </i>
          </el-tooltip>
           <!--TalkBack pip-->
          <el-tooltip
            class="talkPip"
            effect="dark"
            :content=" this.$t('coordinationParticipant.clikAndPipHold')"
            placement="top"
          >
          <!--按，松开-->
            <i
              class="iconfont pointer pushTalk"
              v-if="hasPipTalkback"
              @mouseup="controlTalkback($event.button+'', $event.type,'pip')"
            >
              <span style="width:40px; height:40px; display:inline-block;" v-if="talkbackPipTimerBtn">
                <i style="font-size:28px;color:#33AB4F;border: 0;"  class="iconfont icon-talkPipOff">
                </i>
              </span>
              <span style="display:block;top:-6px;" class="loading" v-if="!talkbackPipTimerBtn">
                <svg class="iconfont" aria-hidden="true">
                  <use xlink:href="#icon-a-BTNloading"></use>
                </svg>
              </span>
              <div v-if="talkbackPipTimerBtn" style="margin-top:-8px;"></div><div v-if="talkbackPipTimerBtn" style="margin-top:-8px;"></div>
              <span :style="{'top':'-10px','display':'block'}">{{$t('coordinationParticipant.talkPipBack')}}</span>
            </i>
            <!--不按-->
            <i
              class="iconfont pushTalk font_35 pointer"
              v-else-if="!hasPipTalkback"
              @mousedown="controlTalkback($event.button+'', $event.type,'pip')"
              @mouseup="controlTalkback($event.button+'', $event.type,'pip')"
            >
              <span style="width:40px; height:40px; display:inline-block;" v-if="talkbackPipTimerBtn">
                <i style="font-size:28px;border:0;"  :style="{'color':(talkAllBackState||talkHostBackState) ?'#6D6D6D':'#F6445A'}" class="iconfont icon-talkBackGroup" >
                </i>
              </span>
              <span  style="display:block; top:-6px;" class="loading" v-if="!talkbackPipTimerBtn">
                <svg class="iconfont" aria-hidden="true">
                  <use xlink:href="#icon-a-BTNloading"></use>
                </svg>
              </span>
              <span :style="{'top':'-10px','display':'block','color':(talkAllBackState||talkHostBackState) ?'#6D6D6D':''}">{{$t('coordinationParticipant.talkPipBack')}}</span>
            </i>
          </el-tooltip>
        </div>
        <TipsDailog :info="leaveTips" @save="leaveParty"/>
        <!-- 重复登入 -->
        <tipsDailog class="repeatJoin" width="550px" :info="repeatInfo" @save="repeatInfo.show = false">
          <div class="email">
            <p>{{ $t('coordinationRoom.repeatContent') }}</p>
          </div>
        </tipsDailog>
        <MutedPop
          ref="muted"
          v-if="speakInfo.speaking"
          :info="speakInfo"
          @reusePop="closeMute()"
         />
        <user-setting
          @setNickName="setNickName"
          v-if="setNameInfo.show"
          :info="setNameInfo"
        ></user-setting>
      </div>
    </div>
  </div>
</template>
<script>
import Group from './group'
import Bus from '@/assets/js/bus.js'
import { mapState } from 'vuex'
import Utils from '@/assets/js/utils.js'
import AgoraClient from '@/assets/js/rtc-client-ng'
import AttendUser from '@/components/common/attendUser'
import { audioContextObj } from '@/assets/js/audioContext'
import TipsDailog from '@/components/common/tipsDailog'
import MutedPop from '@/views/commentator/dialog/mutedPop'
import DeviceSelect from '@/components/common/deviceSelect'
import Websocket from '@/assets/js/websocket.js'
import UserSetting from '@/components/common/userSetting'
import { getMicAndSpeakerDevices } from '@/assets/js/agoraFeature.js'
import axios from 'axios'
const CancelToken = axios.CancelToken

export default {
  data () {
    return {
      initJoin: true,
      encodedData: '',
      realtimeApiHostname: '',
      amStatusInfo: {},
      allPairStatusInfo: {},
      pairNumber: 0,
      isSpeaking: false,
      speakTimer: null,
      silenceTimer: null,
      manualShutdown: false,
      speakInfo: {
        speaking: false
      },
      talkBackUrl: '',
      initVideoPopup: true,
      initAudioPopup: true,
      lastDisableCamera: 0,
      lastDisableMic: 0,
      excuteTalkBack: false,
      isCommentatorParty: '',
      switchVal: '',
      remoteNetWorks: {},
      talkBackMousedown: false,
      allMousedownStatus: false,
      hostMousedownStatus: false,
      pipMousedownStatus: false,
      space: false,
      cKey: false,
      gKey: false,
      audioStatus: '',
      talkBackType: '',
      noCallBackRtilCode: '',
      privateInfo: {},
      hadOpenWebCloud: false,
      talkbackTimerBtn: true,
      talkbackHostTimerBtn: true,
      talkbackPipTimerBtn: true,
      rtc: {},
      meInfo: {},
      userInfo: {},
      rtilCode: '',
      voiceList: {},
      channels: {},
      externalChannel: {},
      devicesInfo: {},
      nickNameInfo: {},
      videoObj: {},
      muteAudioObj: {},
      realPartyCode: '',
      externalPartyCode: '',
      videoSelf: true,
      muteAudioSelf: false,
      leaveTips: {
        title: 'coordinationParticipant.leavePartyTitle',
        content: 'Are you sure to leave the party?',
        cancel: 'coordinationParticipant.cancel',
        save: 'coordinationParticipant.leave',
        show: false
      },
      repeatInfo: {
        show: false,
        title: 'coordinationRoom.repeatJoin',
        save: 'coordinationRoom.ok',
        hideCancel: true
      },
      rtcLoaded: false,
      hangupHover: false,
      carmerHover: false,
      hadPushTalk: true,
      ws: null,
      firstLoad: true,
      showDelayTimer: null,
      talkbackTimer: null,
      talkAllBackState: false,
      talkHostBackState: false,
      talkPipBackState: false,
      hasTalkback: false,
      hasHostTalkback: false,
      hasPipTalkback: false,
      pushTalkStatus: true, //  用来保存pushyalk的状态
      outputRtilCode: '',
      hostInfo: {},
      initMuteVideo: true,
      networkTips: false,
      isPrivate: false,
      maxTimeDifference: 0,
      hasAllExecuteKeyDown: false, // 用来放置重复多次执行keydown事件
      hasHostExecuteKeyDown: false, // 用来放置重复多次执行keydown事件
      hasPipExecuteKeyDown: false, // 用来放置重复多次执行keydown事件
      mousedownStatus: false,
      setNameInfo: {
        show: false
      },
      cancel: {
        removeMixAudio: null,
        addMixAudio: null,
        addTalkback: null,
        removeTalkback: null
      },
      showReceiveMeta: false,
      metaDataTimeCode: '',
      metaDateTimeStamp: '',
      groupInfo: {},
      hostPrivateInfo: {}, // 用来记录哪些 host 和当前与会者有 pusktalk
      talkToGroupList: [], // 用来维护当前哪些 host 开启 talk to group
      talkToAllGroupList: [], // 用来维护当前哪些 host 开启 talk to all group
      pgmMute: false,
      password: ''
    }
  },
  components: {
    Group,
    TipsDailog,
    DeviceSelect,
    UserSetting,
    AttendUser,
    MutedPop
  },
  computed: {
    ...mapState({
      State: (state) => state,
      isMute: (state) => state.isMute,
      // isReplyAudio: (state) => state.isReplyAudio,
      Config: (state) => state.config,
      eventInfo: state => state.eventInfo
    }),
    speakerTitle () {
      let _str = this.$t('coordinationParticipant.turnOffSpeaker')
      if (this.isMute) {
        _str = this.$t('coordinationParticipant.turnOnSpeaker')
      }
      return _str
    },
    micTitle () {
      let _str = this.$t('coordinationParticipant.turnOffMicPhone')
      if (!this.hadPushTalk) {
        _str = this.$t('coordinationParticipant.turnOnMicPhone')
      }
      return _str
    }
  },
  watch: {
    hadPushTalk (newVal) {
      if (newVal) {
        this.speakInfo.speaking = false
        this.manualShutdown = false
        this.clearTimer()
      }
    },
    hasTalkback (newVal) {
      if (newVal) {
        // 当有talkback时,将开启的弹窗关闭
        this.speakInfo.speaking = false
        // 为了重置手动关闭的状态
        this.manualShutdown = false
        this.clearTimer()
      }
    },
    hasHostTalkback (newVal) {
      if (newVal) {
        this.speakInfo.speaking = false
        this.manualShutdown = false
        this.clearTimer()
      }
    },
    hasPipTalkback (newVal) {
      if (newVal) {
        this.speakInfo.speaking = false
        this.manualShutdown = false
        this.clearTimer()
      }
    }
  },
  async created () {
    document.getElementsByClassName('contentBox')[0].style.height = 'calc(100vh - 65px)'
    navigator.mediaDevices.addEventListener('devicechange', async (info) => {
      // 设备发生变化时执行的操作
      for (const k in this.rtc) {
        const rtc = this.rtc[k]
        const { microphoneId, microphoneArray, speakerId, speakerArray } = await getMicAndSpeakerDevices(rtc)
        // 只有在设备列表没有变化只是更新 default 设备时执行
        if (microphoneArray.length == this.devicesInfo.microphoneList.length && microphoneArray.length > 0) {
          if (microphoneId) {
            this.devicesInfo.microphoneId = microphoneId
            rtc.setDevice(microphoneId, 'audio')
          }
          this.devicesInfo.microphoneList = microphoneArray
        }
        // 只有在设备列表没有变化只是更新 default 设备时执行
        if (speakerArray.length == this.devicesInfo.speakerList.length && speakerArray.length > 0) {
          if (speakerId) {
            this.devicesInfo.speakerId = speakerId
            const array = Object.keys(this.channels).filter(rtilCode => {
              return rtilCode != this.userInfo.rtilCode
            })
            array.forEach(rtilCode => {
              if (this.channels[rtilCode].audioTrack && this.channels[rtilCode].audioTrack.setPlaybackDevice) {
                this.channels[rtilCode].audioTrack.setPlaybackDevice(localStorage.getItem('speakerId'))
              }
            })
          }
          this.devicesInfo.speakerList = speakerArray
        }
      }
    })
    Bus.$on('pgmChange', (val) => {
      const arr = val.newPgmSlot.sharedMemoryName.split('_');
      if (val.newPgmSlot && val.newPgmSlot.pips) {
        const pips = val.newPgmSlot.pips
        if (pips.some(item => item.includes('Commentator'))) {
          this.isCommentatorParty = true
        } else {
          this.isCommentatorParty = false
        }
      } else {
        this.isCommentatorParty = false
      }
      if ((arr[0] == 'Commentator' && arr[1] == this.realPartyCode && arr[2] == this.userInfo.rtilCode) ||
      (val.newPgmSlot.tType == 3 && val.newPgmSlot.sourceType == 600 &&
      this.isCommentatorParty)) {
        this.switchVal = true
      } else {
        this.switchVal = false
      }
    })
    Bus.$off('setLoginScreenButton');
    // 新增cloudProxy
    Bus.$on('setLoginScreenButton', async (val) => {
      const { buttonType, operationType } = val.buttonStatus;
      if (buttonType === 7) {
        if (operationType) {
          this.hadOpenWebCloud = true
          for (const k in this.rtc) {
            this.rtc[k] && await this.rtc[k].leave()
          }
          this.joinParty();
        } else {
          this.hadOpenWebCloud = false
          for (const k in this.rtc) {
            this.rtc[k] && await this.rtc[k].leave('closeCLoudProxy')
          }
          this.joinParty()
        }
      } else if (buttonType === 11) {
        if (!operationType) {
          this.$router.push({ name: 'remove', query: { type: 'disableParty' } })
        }
      }
    });
    const { id } = this.$route.query
    const { password } = this.$route.params
    this.partyCode = id
    this.password = password
    this.rtc[id] = new AgoraClient(this.callBack)
    // 需要先获取设备列表，并判断之前选择过的设备列表是否还存在，不存在则选择默认的
    this.devicesInfo = await this.rtc[id].getDevice()
    if (!localStorage.getItem('speakerId')) {
      localStorage.setItem('speakerId', this.devicesInfo.speakerId)
    }
    this.setNameInfo.cameraId = this.devicesInfo.cameraId
    this.setNameInfo.microphoneId = this.devicesInfo.microphoneId
    await this.setNameOrJoin()
    await this.getCurrentEventInfo()
    await this.getGroupInfo()
    Bus.$on('addPipSuccess', (result) => {
      if (this.initJoin) {
        this.getPairList(result.audioMixTaskId, result.eventId)
      }
    })
    Bus.$on('setOutput', (info) => {
      const { rtilCode } = info
      this.outputRtilCode = rtilCode
      if (this.rtc[this.externalPartyCode] && this.rtc[this.externalPartyCode]._remotePublishUser[this.outputRtilCode]) {
        this.rtc[this.externalPartyCode].subscribe(rtilCode, 'all')
      }
    })
    Bus.$on('coordination', info => {
      const { enable, type, subPartyCode, rtilCode, starterRtilCode } = info
      if ((type === 'group' || type === 'room') && this.eventInfo) {
        this.$http
          .post(`${this.State.config.urlInfo.partyline}/partyline-backend/coordination/noLogin/group/${this.eventInfo.id}`)
          .then(res => {
            if (res.data.errorCode === '0x0') {
              const { result } = res.data
              result.newGroupSettings && result.newGroupSettings.forEach(item => {
                this.$set(this.groupInfo, item.partyCode, item)
              })
            }
          })
        if (enable) {
          // 先订阅
          this.rtc[this.realPartyCode] && this.rtc[this.realPartyCode].subscribe(starterRtilCode, 'audio')
          // 判断当前的speaker是否处于静音状态
          if (!this.isMute && this.rtc[this.realPartyCode]._subscribeTrack[starterRtilCode] && this.rtc[this.realPartyCode]._subscribeTrack[starterRtilCode].audioTrack) {
            this.rtc[this.realPartyCode].play(this.rtc[this.realPartyCode]._subscribeTrack[starterRtilCode].audioTrack, '', { type: 'audio' })
          }
          if (this.muteAudioSelf) {
            this.changeBehaviorStatus('mic', true)
          }
        } else {
          // 有 subPartyCode 表示部分，没有则表示取消全部 talk group
          if (!subPartyCode && this.rtc[this.realPartyCode]._subscribeTrack[starterRtilCode]) {
            this.rtc[this.realPartyCode].stop(this.rtc[this.realPartyCode]._subscribeTrack[starterRtilCode].audioTrack)
            this.rtc[this.realPartyCode] && this.rtc[this.realPartyCode].unsubscribe(starterRtilCode, 'audio')
          } else if (subPartyCode == this.realPartyCode && this.rtc[this.realPartyCode]._subscribeTrack[starterRtilCode]) {
            this.rtc[this.realPartyCode].stop(this.rtc[this.realPartyCode]._subscribeTrack[starterRtilCode].audioTrack)
            this.rtc[this.realPartyCode] && this.rtc[this.realPartyCode].unsubscribe(starterRtilCode, 'audio')
          }
        }
      } else if (type === 'member') {
        if (subPartyCode == this.userInfo.realPartyCode && rtilCode == this.userInfo.rtilCode) {
          this.$set(this.hostPrivateInfo, starterRtilCode, enable == 1)
          const rtc = this.rtc[this.realPartyCode]
          if (enable) {
            // 如果 Agora 退消息快于 ws，则我们需要在这重新订阅一次
            if (rtc._remotePublishUser[starterRtilCode] &&
              (!rtc._subscribeTrack[starterRtilCode] || !rtc._subscribeTrack[starterRtilCode].audioTrack)
            ) {
              rtc && rtc.subscribe(starterRtilCode, 'audio')
            }
          } else {
            if (rtc._subscribeTrack[starterRtilCode]) {
              rtc && rtc.unsubscribe(starterRtilCode, 'audio')
            }
          }
        }
        this.controlPrivateInfo(rtilCode, enable, starterRtilCode)
      }
    })
    // 11
    Bus.$on('setBehaviors', (v) => {
      const behaviors = v.behaviors
      behaviors.forEach((val) => {
        if (val.key == 'audioStatus') {
          this.audioStatus = val.value
        }
        if (val.key == 'nickName') {
          this.$set(this.nickNameInfo, val.mark, val.value)
        }
        if (val.key == 'tagName') {
          this.$set(this.tagNameInfo, val.mark, val.value)
        }
        // talkBack
        if (val.key == 'callAll') {
          this.talkBackType = 'callAll'
          if (val.value == 'true') {
            this.rtc[this.realPartyCode] && this.rtc[this.realPartyCode].subscribe(val.mark, 'audio')
          }
        } else if (val.key == 'callHost') {
          this.talkBackType = 'callHost'
          // callHost不订阅同组其他人audio
          if (val.value == 'true' && val.mark != this.userInfo.rtilCode) {
            this.noCallBackRtilCode = val.mark
            if (JSON.parse(this.audioStatus) || (this.channels[val.mark] && this.channels[val.mark].audioTrack)) {
              this.rtc[this.realPartyCode] && this.rtc[this.realPartyCode].unsubscribe(val.mark, 'audio')
            }
          } else {
            this.talkBackType = ''
            if (this.audioStatus && JSON.parse(this.audioStatus)) {
              this.rtc[this.realPartyCode] && this.rtc[this.realPartyCode].subscribe(val.mark, 'audio')
            }
          }
        }
        if (val.key == 'camera' && val.initiator && val.mark == this.rtilCode && this.rtc[this.realPartyCode]._localVideoTrack) {
          if (this.rtc[this.realPartyCode]._localVideoTrack) {
            this.rtc[this.realPartyCode]._localVideoTrack._originMediaStreamTrack.enabled = !(val.value === 'true')
          }
          this.videoSelf = (val.value === 'true')
        }
        if (val.key == 'talkToAllGroup') {
          this.dealTalkToAllGroup(val)
        }
        if (val.key === 'talkToGroup') {
          this.dealTalkToGroup(val)
        }
      })
    })
    Bus.$on('controlBehavior', v => {
      const { rtilCode, behavior } = v
      const { key, value } = behavior
      if (key == 'nickName' && this.nickNameInfo[rtilCode]) {
        this.nickNameInfo[rtilCode] = value
      }
    })
    Bus.$on('managerOnline', v => {
      const { rtilCode } = v
      if (this.userInfo.managerRtils && !this.userInfo.managerRtils.includes(rtilCode)) this.userInfo.managerRtils.push(rtilCode)
      // 把后进来的 host 添加到 hostprivate 数组中方便控制是否订阅次 host 的音频流
      if (!this.hostPrivateInfo[rtilCode]) {
        // 还需要判断当前的 talk to group 是否开启如果开启
        this.$set(this.hostPrivateInfo, rtilCode, false)
        // 由于存在和 ws 消息和 Agora 推送人进来的先后快慢问题所以这里边也要添加订阅逻辑
        if (
          this.rtc[this.realPartyCode] && this.rtc[this.realPartyCode]._remotePublishUser[rtilCode] &&
          (!this.rtc[this.realPartyCode]._subscribeTrack[rtilCode] || !this.rtc[this.realPartyCode]._subscribeTrack[rtilCode].audioTrack)
        ) {
          this.rtc[this.realPartyCode] && this.rtc[this.realPartyCode].subscribe(rtilCode, 'audio')
        }
      }
    })
    window.addEventListener('keydown', (event) => {
      if (!this.excuteTalkBack) return
      const keyCodes = window.event ? event.keyCode : event.which
      if (keyCodes === 32 && !this.hasHostExecuteKeyDown && !this.hasAllExecuteKeyDown && !this.hasPipExecuteKeyDown) {
        this.hasAllExecuteKeyDown = true
        this.space = true
        this.controlTalkback('spaceKeydown')
      } else if (keyCodes === 67 && !this.hasHostExecuteKeyDown && !this.hasAllExecuteKeyDown && !this.hasPipExecuteKeyDown) {
        this.cKey = true
        this.hasHostExecuteKeyDown = true
        this.controlTalkback('cKeydown', 'Host', 'Host')
      } else if (keyCodes === 71 && !this.hasHostExecuteKeyDown && !this.hasAllExecuteKeyDown && !this.hasPipExecuteKeyDown) {
        this.gKey = true
        this.hasPipExecuteKeyDown = true
        this.controlTalkback('gKeydown', 'pip', 'pip')
      }
    })
    window.addEventListener('keyup', (event) => {
      if (!this.excuteTalkBack) return
      const keyCodes = window.event ? event.keyCode : event.which
      if (keyCodes === 32 && this.space) {
        this.space = false
        this.hasAllExecuteKeyDown = false
        this.controlTalkback('spaceKeyup')
      } else if (keyCodes === 67 && this.cKey) {
        this.cKey = false
        this.hasHostExecuteKeyDown = false
        this.controlTalkback('cKeyup', 'Host', 'Host')
      } else if (keyCodes === 71 && this.gKey) {
        this.gKey = false
        this.hasPipExecuteKeyDown = false
        this.controlTalkback('gKeyup', 'pip', 'pip')
      }
    })
    window.addEventListener('mousedown', () => {
      if (!this.excuteTalkBack) return
      if (this.hasTalkback || this.talkAllBackState) {
        this.talkBackMousedown = true
      } else if (this.hasHostTalkback || this.talkHostBackState) {
        this.talkBackMousedown = true
      } else if (this.hasPipTalkback || this.talkPipBackState) {
        this.talkBackMousedown = true
      }
    })
    window.addEventListener('mouseup', () => {
      if (this.hasTalkback || this.talkAllBackState) {
        this.talkBackMousedown = false
      } else if (this.hasHostTalkback || this.talkHostBackState) {
        this.talkBackMousedown = false
      } else if (this.hasPipTalkback || this.talkPipBackState) {
        this.talkBackMousedown = false
      }
    })
    window.addEventListener('blur', () => {
      console.info(3333, '未激活')
      if (this.hasTalkback || this.talkAllBackState) {
        this.closeTalkback()
        // 如果之前是 pushTalk 的状态需恢复
        if (this.pushTalkStatus) {
          this.setPushTalk('byTalkback')
        }
        this.allMousedownStatus = false
        this.space = false
        this.hasAllExecuteKeyDown = false
        this.mousedownStatus = false
      } else if (this.hasHostTalkback || this.talkHostBackState) {
        this.closeTalkback('Host')
        // 如果之前是 pushTalk 的状态需要恢复
        if (this.pushTalkStatus) {
          this.setPushTalk('byTalkback')
        }
        this.hostMousedownStatus = false
        this.cKey = false
        this.hasHostExecuteKeyDown = false
        this.mousedownStatus = false
      } else if (this.hasPipTalkback || this.talkPipBackState) {
        this.closeTalkback('pip')
        // 如果之前是 pushTalk 的状态需要恢复
        if (this.pushTalkStatus) {
          this.setPushTalk('byTalkback')
        }
        this.pipMousedownStatus = false
        this.gKey = false
        this.hasPipExecuteKeyDown = false
        this.mousedownStatus = false
      }
    })
    // Bus.$on('endParty', v => {
    //   this.$router.push({ name: 'leaveParty' })
    // })
    // 移出party
    Bus.$off('removeParticipant')
    Bus.$on('removeParticipant', v => {
      if (v.rtilCode === this.rtilCode) {
        this.rtc[this.realPartyCode].leave()
        this.$router.push({ name: 'remove', query: { type: 'leavrParty' } })
      }
    })
    Bus.$on('controlMetaData', val => {
      this.showReceiveMeta = val
    })
  },
  methods: {
    clearTimer () {
      // 清除所有计时器
      this.isSpeaking = false;
      clearTimeout(this.speakTimer);
      clearTimeout(this.silenceTimer);
      this.speakTimer = null;
      this.silenceTimer = null;
    },
    closeMute () {
      this.speakInfo.speaking = false
      this.manualShutdown = true
      this.clearTimer()
    },
    dealTalkToAllGroup (behaviors) {
      const { mark, value } = behaviors
      this.$set(this.hostPrivateInfo, mark, value == '1')
      if (value == '1') {
        if (
          this.rtc[this.realPartyCode] &&
          this.rtc[this.realPartyCode]._remotePublishUser[mark] &&
          (!this.rtc[this.realPartyCode]._subscribeTrack[mark] || !this.rtc[this.realPartyCode]._subscribeTrack[mark].audioTrack)
        ) {
          this.rtc[this.realPartyCode] && this.rtc[this.realPartyCode].subscribe(mark, 'audio')
        }
        this.controlPrivateInfo('', 1, mark)
        if (!this.talkToAllGroupList.includes(mark)) {
          this.talkToAllGroupList.push(mark)
        }
      } else {
        this.controlPrivateInfo('', 0, mark)
        if (this.talkToAllGroupList.includes(mark)) {
          this.talkToAllGroupList = this.talkToAllGroupList && this.talkToAllGroupList.filter(rtilCode => {
            return rtilCode != mark
          })
        }
      }
    },
    dealTalkToGroup (behaviors) {
      const { mark, value } = behaviors
      this.$set(this.hostPrivateInfo, mark, value == '1')
      this.controlPrivateInfo('', value, mark)
      // 如果 val == 1 需要考虑 ws 消息和 Agora 谁想返回的问题，所以在这里加一步订阅当前 host 音频流
      if (value == '1') {
        if (
          this.rtc[this.realPartyCode] &&
          this.rtc[this.realPartyCode]._remotePublishUser[mark] &&
          (!this.rtc[this.realPartyCode]._subscribeTrack[mark] || !this.rtc[this.realPartyCode]._subscribeTrack[mark].audioTrack)
        ) {
          this.rtc[this.realPartyCode] && this.rtc[this.realPartyCode].subscribe(mark, 'audio')
        }
        if (!this.talkToGroupList.includes(mark)) {
          this.talkToGroupList.push(mark)
        }
      } else {
        this.rtc[this.realPartyCode] && this.rtc[this.realPartyCode].unsubscribe(mark, 'audio')
        if (this.talkToGroupList.includes(mark)) {
          this.talkToGroupList = this.talkToGroupList && this.talkToGroupList.filter(rtilCode => {
            return rtilCode != mark
          })
        }
      }
    },
    // 维护 pusktalk 状态 privateInfo 的方法
    controlPrivateInfo (rtilCode, value, starterRtilCode) {
      if (rtilCode) {
        // 先判断之前是否记录过此数据，没有则添加
        if (!this.privateInfo[rtilCode]) {
          this.$set(this.privateInfo, rtilCode, [starterRtilCode])
        } else {
          // 如果当前有值在根据推送的 enable 来进行处理，因为是多 host 所以才把 private 的 value 改成数组
          if (value == 1) {
            if (!this.privateInfo[rtilCode].includes(starterRtilCode)) {
              this.privateInfo[rtilCode].push(starterRtilCode)
              this.$set(this.privateInfo, rtilCode, this.privateInfo[rtilCode])
            }
          } else {
            const array = this.privateInfo[rtilCode] && this.privateInfo[rtilCode].filter(rtilCode => {
              return rtilCode != starterRtilCode
            })
            this.$set(this.privateInfo, rtilCode, array)
          }
        }
      } else {
        if (value == 1) {
          // 维护与会者自己的 pushtalk 状态
          if (!this.privateInfo[this.userInfo.rtilCode]) {
            this.$set(this.privateInfo, this.userInfo.rtilCode, [starterRtilCode])
          } else {
            if (!this.privateInfo[this.userInfo.rtilCode].includes(starterRtilCode)) {
              this.privateInfo[this.userInfo.rtilCode].push(starterRtilCode)
              this.$set(this.privateInfo, this.userInfo.rtilCode, this.privateInfo[this.userInfo.rtilCode])
            }
          }
          // 维护当前组内其他与会者的 pushtalk状态
          Object.keys(this.channels).forEach(rtilCode => {
            if (!this.privateInfo[rtilCode]) {
              this.$set(this.privateInfo, rtilCode, [starterRtilCode])
            } else {
              if (!this.privateInfo[rtilCode].includes(starterRtilCode)) {
                this.privateInfo[rtilCode].push(starterRtilCode)
                this.$set(this.privateInfo, rtilCode, this.privateInfo[rtilCode])
              }
            }
          })
        } else {
          const hostArray = this.privateInfo[this.userInfo.rtilCode] && this.privateInfo[this.userInfo.rtilCode].filter(rtilCode => {
            return rtilCode != starterRtilCode
          })
          this.$set(this.privateInfo, this.userInfo.rtilCode, hostArray)
          Object.keys(this.channels).forEach(rtilCode => {
            const otherParticipants = this.privateInfo[rtilCode] && this.privateInfo[rtilCode].filter(currentRtilCode => {
              return currentRtilCode != starterRtilCode
            })
            this.$set(this.privateInfo, rtilCode, otherParticipants)
          })
        }
      }
    },
    // 记录pp事件是否为pgm上mainWindow的状态
    eventState () {
      if (!this.eventInfo) {
        return
      }
      this.$http
        .get(`/commentator-backend/event/v2/noLogin/state/${this.eventInfo.id}`)
        .then(res => {
          if (res.data.errorCode === '0x0') {
            const { result } = res.data
            if (result && result.source) {
              const arr = result.source.pgm.shareMemoryName.split('_');
              if (result.source.slots[result.source.pgm.slot] && result.source.slots[result.source.pgm.slot].pips) {
                const pips = result.source.slots[result.source.pgm.slot].pips
                if (pips.some(item => item.includes('Commentator'))) {
                  this.isCommentatorParty = true
                } else {
                  this.isCommentatorParty = false
                }
              } else {
                this.isCommentatorParty = false
              }
              // 判断是否为将participant as PGM 或者 participant as customer source
              if ((arr[0] == 'Commentator' && arr[1] == this.realPartyCode && arr[2] == this.userInfo.rtilCode) ||
            (result.source.slots[result.source.pgm.slot].tType == 3 && result.source.slots[result.source.pgm.slot].sourceType == 600 &&
            this.isCommentatorParty)) {
                this.switchVal = true
              } else {
                this.switchVal = false
              }
            }
          }
        })
    },
    groupCallback ({ type, val, isControlMute }) {
      if (type === 'fullscreen') {
        this.State.fullScreen = val
        if (val && document.documentElement.requestFullscreen) {
          document.documentElement.requestFullscreen()
        } else {
          if (document.fullscreenElement) {
            document.exitFullscreen()
          }
        }
      } else if (type === 'savePgmMute') {
        this.pgmMute = isControlMute
        localStorage.setItem('pgmMute', isControlMute)
      }
    },
    showDataInfo () {
      this.showReceiveMeta = !this.showReceiveMeta
    },
    async recordDeviceStatus () {
      const data = [{
        mark: this.userInfo.rtilCode + '',
        annotation: this.userInfo.joinCode,
        key: 'blockVideo',
        value: !this.devicesInfo.cameraId,
        initiator: this.userInfo.rtilCode + ''
      }, {
        mark: this.userInfo.rtilCode + '',
        annotation: this.userInfo.joinCode,
        key: 'blockAudio',
        value: !this.devicesInfo.microphoneId,
        initiator: this.userInfo.rtilCode + ''
      }]
      await this.$http.post(`${this.State.config.urlInfo.partyline}/partyline-backend/behavior/noLogin/setList`, data)
    },
    // 初始化websocket
    initWebsocket () {
      const row = window.location.protocol === 'http' ? 'ws' : 'wss'
      if (!this.Config || !this.Config.urlInfo) return
      const ccWebsocket = this.Config.urlInfo.ccWebsocket
      const random32Str = Utils.getRandomStr()
      const businessId = this.userInfo.joinCode + ':' + this.realPartyCode
      this.ws = new Websocket({
        url: `${row}://${ccWebsocket}/pageSerBusRequest?taskId=${random32Str}&businessId=${businessId}&notCheckUser=true`,
        sendHeart: true,
        key: 'pageSerBusRequestByPartyCode'
      })
      this.ws.initWebSocket()
    },
    async initAMWebsocket (taskId, eventId) {
      if (!taskId || !eventId) return
      await this.$http.get(`/commentator-backend/am/noLogin/getAudioMixerConfig/${eventId}`)
        .then(res => {
          if (res.data.errorCode === '0x0') {
            const { result } = res.data
            if (result) {
              const endpoint = result.graphql
              const extractedHostname = new URL(endpoint).hostname;
              const obj = {
                host: extractedHostname,
                'x-api-key': result.apiKey
              }
              const jsonStr = JSON.stringify(obj);
              this.encodedData = btoa(jsonStr);
              this.realtimeApiHostname = extractedHostname.replace('appsync-api', 'appsync-realtime-api');
            }
          }
        })
      const row = window.location.protocol === 'http' ? 'ws' : 'wss'
      this.ws = new Websocket({
        url: `${row}://${this.realtimeApiHostname}/graphql?header=${this.encodedData}&payload=e30=`,
        wsType: 'graphql',
        sendHeart: false,
        key: 'graphql',
        pairNumber: this.pairNumber,
        taskid: taskId
      })
      this.ws.initWebSocket()
    },
    getPairList (taskId, eventId) {
      if (!taskId || !eventId) return
      // 初始化记录4个pair状态
      this.$http.post('/commentator-backend/am/noLogin/getPairList', {
        taskId: taskId,
        eventId: eventId
      })
        .then(res => {
          if (res.data.errorCode === '0x0') {
            if (res.data.result) {
              this.pairNumber = res.data.result.length
              if (this.initJoin) {
                this.initAMWebsocket(taskId, eventId)
                this.initJoin = false
              }
              res.data.result.forEach((item) => {
                item.inputDatas && item.inputDatas.forEach((itemInput) => {
                  if (itemInput.ID.includes('Commentator')) {
                    const parts = itemInput.ID.split('_');
                    const rtilCode = parts[2];
                    if (itemInput.audioStreams.length != 0) {
                      itemInput.audioStreams[0].channelDatas && itemInput.audioStreams[0].channelDatas.forEach((itemChannel) => {
                        if (itemChannel.ID == '0') {
                          this.amStatusInfo[rtilCode] = {
                            ...itemChannel
                          }
                          this.$set(this.allPairStatusInfo, item.ID, { ...this.amStatusInfo })
                        }
                      })
                    }
                  }
                })
              })
              // 检查是否加入到R
              this.$nextTick(() => {
                // if (!this.amStatusInfo[this.userInfo.rtilCode] && !initOnce) {
                //   this.$set(this.notJoinR, this.userInfo.rtilCode, true);
                // }
                // 由于是要把与会者自己的状态显示到mainWindow，所以应该调用mainWindow的结构
                this.$refs.mainWindowContent.dealStatus(true)
              })
            }
          }
        })
    },
    async getCurrentEventInfo () {
      try {
        await this.$http
          .post(`/commentator-backend/event/party/noLogin/${this.partyCode}`)
          .then((res) => {
            if (res.data.errorCode === '0x0') {
              this.$store.commit('saveEventInfo', res.data.result)
              // 记录pp事件是否为pgm上mainWindow的状态
              this.eventState()
              if (res.data.result.producerSettingsDto) {
                const { delay } = res.data.result.producerSettingsDto
                this.maxTimeDifference = delay * 1000
              }
            }
          })
      } catch (error) {
        console.error('getCurrentEventInfo error:', error)
      }
    },
    async getGroupInfo () {
      if (!this.eventInfo) return
      await this.$http
        .post(`${this.State.config.urlInfo.partyline}/partyline-backend/coordination/noLogin/group/${this.eventInfo.id}`)
        .then(res => {
          if (res.data.errorCode === '0x0') {
            const { result } = res.data
            result.newGroupSettings && result.newGroupSettings.forEach(item => {
              this.$set(this.groupInfo, item.partyCode, item)
            })
            if (this.realPartyCode) {
              // 初始化哪些 host 和当前的与会者 pushtalk, groupInfo 当刷新进入页面时如果点击 ok 过快会报错，后续优化
              const groupMemberSettingsMap = this.groupInfo[this.realPartyCode].groupMemberSettingsMap
              if (this.groupInfo[this.realPartyCode] && groupMemberSettingsMap) {
                Object.keys(groupMemberSettingsMap).forEach(rtilCode => {
                  if (rtilCode == result.rtilCode && groupMemberSettingsMap[rtilCode].length) {
                    groupMemberSettingsMap[rtilCode].forEach(item => {
                      this.$set(this.hostPrivateInfo, item.rtilCode, item.talk == 1)
                    })
                  }
                  // 初始化 pushtalk 状态显示
                  if (groupMemberSettingsMap[rtilCode]) {
                    const array = []
                    groupMemberSettingsMap[rtilCode].forEach(item => {
                      if (item.talk == 1) array.push(item.rtilCode)
                    })
                    this.$set(this.privateInfo, rtilCode, array)
                  }
                })
              }
            }
          }
        })
    },
    async setNameOrJoin () {
      if (this.rtc[this.partyCode]) this.rtc[this.partyCode].leave()
      this.rtc[this.partyCode] = new AgoraClient(this.callBack)
      // 需要先获取设备列表，并判断之前选择过的设备列表是否还存在，不存在则选择默认的
      const devices = await this.rtc[this.partyCode].getDevice()
      this.cameraList = devices.cameraList
      this.microphoneList = devices.microphoneList
      this.speakerList = devices.speakerList
      this.speakerId = devices.speakerId
      this.cameraId = devices.cameraId
      this.microphoneId = devices.microphoneId
      this.devicesInfo = {
        cameraId: this.cameraId,
        cameraList: devices.cameraList,
        microphoneId: this.microphoneId,
        microphoneList: devices.microphoneList,
        speakerId: this.speakerId,
        speakerList: devices.speakerList
      }
      const rtilCode = await this.getBehaviorsByMemberId()
      if (rtilCode) {
        if (this.nickNameInfo[rtilCode]) {
          // this.$alert(this.$t('coordinationParticipant.autoPlayFailed'), {
          //   confirmButtonText: this.$t('coordinationRoom.ok'),
          //   customClass: 'autoplayFailed',
          //   confirmButtonClass: 'joinBtn',
          //   showClose: false,
          //   showCancelButton: false,
          //   closeOnClickModal: false,
          //   closeOnPressEscape: false
          // }).then(() => {
          this.joinParty() // 有rtilCode代表设置过nickName
          // })
        } else {
          // 直接通过join接口去添加nickName（新用户）
          // this.setNameInfo.show = true
          this.$router.push({
            name: 'preview',
            query: {
              id: this.partyCode
            }
          })
        }
      } else {
        // 直接通过join接口去添加nickName（新用户）
        // this.setNameInfo.show = true
        this.$router.push({
          name: 'preview',
          query: {
            id: this.partyCode
          }
        })
      }
    },
    async getBehaviorsByMemberId () {
      const params = {
        memberId: localStorage.getItem('particiapntMemberId') || new Date().getTime(),
        annotation: this.partyCode,
        keys: ['nickName']
      }
      const res = await this.$http.post(`${this.State.config.urlInfo.partyline}/partyline-backend/behavior/noLogin/getListByMemberId`, params)
      const result = res.data.result
      const { rtilCode, behaviors } = result
      if (res.data.errorCode == '0x0' && rtilCode) {
        this.$set(this.nickNameInfo, rtilCode, behaviors[rtilCode].nickName)
      }
      return rtilCode
    },
    joinParty (behaviors) {
      const haveHistoryMem = localStorage.getItem('particiapntMemberId')
      const params = {
        memberId: haveHistoryMem || new Date().getTime(),
        partyCode: this.partyCode,
        role: 0,
        type: 0,
        password: this.password,
        behaviorKeyList: [
          'speaker',
          'nickName',
          'mic',
          'camera'
        ]
      }
      if (behaviors) params.behaviors = behaviors
      this.$http
        .post(`${this.State.config.urlInfo.partyline}/partyline-backend/party/noLogin/join`, params)
        .then(async (res) => {
          if (res.data.errorCode === '0x0') {
            this.excuteTalkBack = true
            const result = res.data.result
            const { rtilCode, realPartyCode, behavior } = result
            localStorage.setItem('particiapntMemberId', params.memberId)
            this.userInfo = result
            this.State.userInfo = result
            this.rtilCode = rtilCode
            this.realPartyCode = realPartyCode
            this.outputRtilCode = result.status.output
            this.externalPartyCode = result.externalJoinInfo.join
            const pgmMute = localStorage.getItem('pgmMute')
            if (!pgmMute) {
              localStorage.setItem('pgmMute', false)
            } else {
              this.pgmMute = pgmMute === 'true'
            }

            this.initWebsocket()
            this.recordDeviceStatus()// 用来记录是否有摄像头或者block
            await Promise
              .all([this.getTalkToAllGroup(), this.getTalkToGroup()])
              .then(result => {
                result && result.forEach((item, index) => {
                  if (index === 0) {
                    this.initTalkToAllGroup(item)
                  } else {
                    this.initTalkToGroup(item)
                  }
                })
              })
              .catch((e) => {
                console.log('Init talkToAllGroup and talkToGroup failed.')
              })
            if (JSON.stringify(this.groupInfo) != '{}') {
              const groupMemberSettingsMap = this.groupInfo[this.realPartyCode].groupMemberSettingsMap
              if (this.groupInfo[this.realPartyCode] && groupMemberSettingsMap) {
                Object.keys(groupMemberSettingsMap).forEach(rtilCode => {
                  if (rtilCode == result.rtilCode && groupMemberSettingsMap[rtilCode].length) {
                    groupMemberSettingsMap[rtilCode].forEach(item => {
                      this.$set(this.hostPrivateInfo, item.rtilCode, item.talk == 1)
                    })
                  }
                  // 初始化 pushtalk 状态显示
                  if (groupMemberSettingsMap[rtilCode]) {
                    const array = []
                    groupMemberSettingsMap[rtilCode].forEach(item => {
                      if (item.talk == 1) array.push(item.rtilCode)
                    })
                    this.$set(this.privateInfo, rtilCode, array)
                  }
                })
              }
            }
            if (behavior && behavior.nickName) {
              this.hadPushTalk = behavior.mixAudio !== 'false'
              this.pushTalkStatus = behavior.mixAudio !== 'false'
              this.joinChannel({ ...result, memberId: params.memberId }, behavior)
              this.getNickName(rtilCode)
            } else {
              this.setNameInfo.show = true
              this.setNameInfo.userInfo = {
                ...this.userInfo,
                partyCode: this.externalPartyCode
              }
            }
          } else if (res.data.errorCode === '80520113') {
            this.$router.push({ name: 'remove', query: { type: 'disableParty' } })
          } else if (res.data.errorCode === '80520107') {
            this.$router.push({ name: 'remove', query: { type: 'needPassCode' } })
          }
        })
    },
    getTalkToAllGroup () {
      return this.$http
        .post(`${this.State.config.urlInfo.partyline}/partyline-backend/behavior/noLogin/getList?allGroup`, {
          originRtilCode: this.userInfo.rtilCode,
          annotation: this.userInfo.joinCode,
          keys: ['talkToAllGroup'],
          marks: this.userInfo.managerRtils
        })
    },
    initTalkToAllGroup (res) {
      if (res.data.errorCode === '0x0') {
        const hostMap = res.data.result
        if (hostMap) {
          const array = []
          Object.keys(hostMap).forEach(rtilCode => {
            if (hostMap[rtilCode].talkToAllGroup && hostMap[rtilCode].talkToAllGroup == '1') {
              this.$set(this.hostPrivateInfo, rtilCode, 1)
              this.talkToAllGroupList.push(rtilCode)
              array.push(rtilCode)
            }
          })
          this.$set(this.privateInfo, this.userInfo.rtilCode, array)
        }
      }
    },
    getTalkToGroup () {
      return this.$http
        .post(`${this.State.config.urlInfo.partyline}/partyline-backend/behavior/noLogin/getList?toGroup`, {
          originRtilCode: this.userInfo.rtilCode,
          annotation: this.userInfo.realPartyCode,
          keys: ['talkToGroup'],
          marks: this.userInfo.managerRtils
        })
    },
    initTalkToGroup (res) {
      if (res.data.errorCode === '0x0') {
        const hostMap = res.data.result
        if (hostMap) {
          const array = []
          Object.keys(hostMap).forEach(rtilCode => {
            if (hostMap[rtilCode].talkToGroup && hostMap[rtilCode].talkToGroup == '1') {
              this.$set(this.hostPrivateInfo, rtilCode, 1)
              this.talkToGroupList.push(rtilCode)
              array.push(rtilCode)
            }
          })
          if (this.privateInfo[this.userInfo.rtilCode].length) {
            const concatArray = this.privateInfo[this.userInfo.rtilCode].concat(array)
            this.$set(this.privateInfo, this.userInfo.rtilCode, concatArray)
          } else {
            this.$set(this.privateInfo, this.userInfo.rtilCode, array)
          }
        }
      }
    },
    async joinChannel (joinInfo, behavior) {
      // 初始化状态
      this.State.isMute = behavior.speaker === 'false'
      this.videoSelf = behavior.camera !== 'false'
      this.muteAudioSelf = behavior.mic === 'false'
      const quality = this.State.eventInfo.advancedSetting && this.State.eventInfo.advancedSetting.quality
      // 为了修改初始以 mute 加入时，底部栏 mic 状态不对的问题
      if (this.muteAudioSelf) {
        this.hadPushTalk = false
        this.pushTalkStatus = false
        this.setOperate({ type: 'muteAudio', id: this.userInfo.rtilCode, notByClick: 'notByClick' })
      }
      this.State.checkedAec = localStorage.getItem('checkedAec') || false
      this.State.checkedAns = localStorage.getItem('checkedAns') || true
      this.State.checkedAgc = localStorage.getItem('checkedAgc') || false
      if (typeof (this.State.checkedAec) == 'string' && this.State.checkedAec != '') {
        this.State.checkedAec = JSON.parse(this.State.checkedAec)
      }
      if (typeof (this.State.checkedAns) == 'string' && this.State.checkedAns != '') {
        this.State.checkedAns = JSON.parse(this.State.checkedAns)
      }
      if (typeof (this.State.checkedAgc) == 'string' && this.State.checkedAgc != '') {
        this.State.checkedAgc = JSON.parse(this.State.checkedAgc)
      }
      const { appId, tokenCode, rtilCode, realPartyCode, videoQualities } = joinInfo
      const params = {
        appId,
        channel: realPartyCode + '',
        token: tokenCode,
        uid: rtilCode,
        codec: 'h264',
        videoConfig: videoQualities,
        cameraId: this.devicesInfo.cameraId,
        microphoneId: this.devicesInfo.microphoneId,
        video: !!this.devicesInfo.cameraId,
        audio: !!this.devicesInfo.microphoneId,
        publishVideo: true,
        publishAudio: !this.muteAudioSelf,
        muteAudio: this.muteAudioSelf,
        audioProfil: 'high_quality',
        sendMetadata: joinInfo.status.forwardMetadata === true,
        startProxy: joinInfo.status.cloudProxy === 1,
        checkedAec: this.State.checkedAec,
        checkedAns: this.State.checkedAns,
        checkedAgc: this.State.checkedAgc,
        getRemoteNetworks: true,
        quality: quality && quality != '360p' ? quality : ''

      }
      // this.rtc[realPartyCode] = new AgoraClient(this.callBack)
      const uid = await this.rtc[realPartyCode].join(params)
      if (!uid) return
      this.hadOpenWebCloud = true
      this.rtcLoaded = true
      console.log('Test delay join Agora success: ', Date.now(), new Date())
      // 加入过real channel之后再加入external channel不然会有冲突
      this.joinExternal(joinInfo)
    },
    joinExternal (joinInfo) {
      const externalJoinInfo =
        joinInfo.externalJoinInfo.externalPartyInfo[0].joinInfo
      const {
        appId,
        tokenCode,
        rtilCode,
        realPartyCode,
        videoQualities
      } = externalJoinInfo
      const params = {
        appId: appId,
        channel: realPartyCode + '',
        token: tokenCode,
        uid: rtilCode,
        cameraId: this.devicesInfo.cameraId,
        microphoneId: this.devicesInfo.microphoneId,
        publish: false,
        audio: false,
        videoConfig: videoQualities,
        subscribeRemote: false,
        sendMetadata: true,
        startProxy: joinInfo.status.cloudProxy === 1,
        getRemoteNetworks: true

      }
      this.rtc[realPartyCode] = new AgoraClient(this.callBack)
      this.rtc[realPartyCode].join(params)
    },
    // 1.与会者发了流没处理好 2.与会者没发流 3.host没接收到

    async callBack ({ action, payload }) {
      const { uid, channel, audioTrack, videoTrack, changeDevice } = payload
      if (action != 'volumeList' && action != 'receiveMetadata' && action != 'unlinkQuality') {
        console.info(139, action, payload)
      }
      switch (action) {
        case 'user-joined':
          this.userJoin(payload)
          break
        case 'unlinkQuality':
          Bus.$emit('setUnlinkQuality', payload)
          break
        case 'user-left':
          this.userLeave(payload)
          break
          // 自己发的流
        case 'local-track':
          if (uid == this.userInfo.rtilCode && channel == this.realPartyCode && audioTrack) {
            const mediaStream = new MediaStream()
            mediaStream.addTrack(audioTrack._mediaStreamTrack)
            audioContextObj.audioTrack(mediaStream, uid, (val1, val2, remoteKey) => {
              // 过滤自己和开启mic情况
              if (remoteKey !== uid || this.hadPushTalk || this.manualShutdown || this.hasTalkback || this.hasHostTalkback || this.hasPipTalkback) return
              // 检测到有音频判断条件

              const hasAudio = val1 > 0.2 || val2 > 0.2

              if (hasAudio) {
                // 检测到说话，重置无声监测计时器
                if (!this.isSpeaking && !this.speakTimer) {
                  // 第一次检测到说话时设置计时器
                  this.speakTimer = setTimeout(() => {
                    this.isSpeaking = true;
                    this.speakInfo.speaking = true;
                    console.log('有人说话');
                  }, 1000); // 设置在2秒后执行说话逻辑
                }
                // 如果已经在说话，重置无声状态的计时器
                clearTimeout(this.silenceTimer);
                this.silenceTimer = null;
              } else {
                clearTimeout(this.speakTimer);
                this.speakTimer = null;
                // 检测到无声，处理无声情况
                if (this.isSpeaking && !this.silenceTimer) {
                  this.silenceTimer = setTimeout(() => {
                    this.isSpeaking = false;
                    this.speakInfo.speaking = false;
                    console.log('2秒内未检测到音频');
                  }, 2000); // 设置在5秒无声后执行无声结束逻辑
                }
                // 未检测到声音且说话计时器存在时先清除说话计时器
                // clearTimeout(speakTimer);
                // speakTimer = null;
              }
            });
            setTimeout(() => {
              audioContextObj.resumeAudio(uid)
            }, 1000)
            // Me设置私聊功能，使用到
            this.meInfo = {
              id: this.userInfo.rtilCode,
              channel: this.userInfo.realPartyCode,
              videoTrack: null,
              audioTrack: null // 本地视频不需要播放audio
            }
          }
          // 自动切换摄像头时候设备列表更新
          if (this.rtc[this.realPartyCode]._localVideoTrack && !payload.notExecuteDeviceListUpdate) {
            const videoLabel = this.rtc[this.realPartyCode]._localVideoTrack.getTrackLabel()
            this.devicesInfo.cameraList.forEach(item => {
              if (item.label === videoLabel) {
                this.devicesInfo.cameraId = item.deviceId
              }
            })
          }
          // 添加如果后台返回是禁用摄像头状态，发黑帧的逻辑
          if (this.userInfo.behavior && this.userInfo.behavior.camera === 'false' && videoTrack && videoTrack._originMediaStreamTrack) {
            videoTrack._originMediaStreamTrack.enabled = false
          }
          break
          // 订阅远程的流（与会者加进来）
        case 'subscribe-track':
          this.subscribeTrack(payload)
          break
        case 'unsubscribe-track':
          if (payload.type == 'audio') {
            this.$set(this.channels, payload.id, {
              ...this.channels[payload.id],
              audioTrack: null
            })
            console.info(236, payload.audioTrack)
          }
          break
        case 'volumeList':
          payload.forEach((volume) => {
            this.$set(this.voiceList, volume.uid, volume)
          })
          break
          // 接收远程发来的流
        case 'user-published':
        case 'user-unpublished':
          this.muteAudioOrVideo(action, payload)
          break
        case 'receiveMetadata':
          this.dealMetadata(payload)
          break
        case 'changeDevice':
          if (payload.success === 'success') {
            localStorage.setItem(payload.type, payload.deviceId)
          }
          break
        case 'connection-state-change':
          // 代表有人重复加入
          if (payload.reason === 'UID_BANNED') {
            this.repeatInfo.show = true
          }
          break
        case 'cameraChange':
          this.devicesInfo = await this.rtc[this.realPartyCode].getDevice()
          if (this.rtc[this.realPartyCode]._localVideoTrack && changeDevice.device.label === this.rtc[this.realPartyCode]._localVideoTrack.getTrackLabel()) {
            const oldCameras = await this.rtc[this.realPartyCode]._AgoraRTC.getCameras()
            oldCameras[0] && this.rtc[this.realPartyCode].setDevice(oldCameras[0].deviceId, 'video', '', (type) => {
              type === 'success' ? this.$message.success(this.$t('coordinationParticipant.autoSwitchNewCameraSuccess')) : this.$message.error(this.$t('coordinationParticipant.autoSwitchNewCameraFailed'))
              this.devicesInfo.cameraId = oldCameras[0].deviceId
            })
          }
          break
        case 'micChange':
          this.devicesInfo = await this.rtc[this.realPartyCode].getDevice()
          if (this.rtc[this.realPartyCode]._localAudioTrack &&
          (
            (this.rtc[this.realPartyCode]._localAudioTrack._deviceName.includes(changeDevice.device.label)) ||
            (changeDevice.state === 'INACTIVE' && this.rtc[this.realPartyCode]._localAudioTrack._config.microphoneId == 'default')
          )) {
            const oldMic = await this.rtc[this.realPartyCode]._AgoraRTC.getMicrophones()
            oldMic[0] && this.rtc[this.realPartyCode].setDevice(oldMic[0].deviceId, 'audio', 'needLocalAudioTrack', (type) => {
              type === 'success' ? this.$message.success(this.$t('coordinationParticipant.autoSwitchNewMicSuccess')) : this.$message.error(this.$t('coordinationParticipant.autoSwitchNewMicFailed'))
              this.devicesInfo.microphoneId = oldMic[0].deviceId
            })
          } else if (changeDevice.state === 'ACTIVE' &&
            this.rtc[this.realPartyCode]._localAudioTrack &&
            !this.rtc[this.realPartyCode]._localAudioTrack._deviceName.includes(changeDevice.device.label) &&
            this.rtc[this.realPartyCode]._localAudioTrack._config.microphoneId == 'default') {
          // 插入mic
            if (this.hadPushTalk && this.rtc[this.realPartyCode]) {
              this.rtc[this.realPartyCode].setEnabled(false, 'audio')
              this.rtc[this.realPartyCode].setEnabled(true, 'audio')
            }
            if (this.devicesInfo.microphoneList[0].label != this.rtc[this.realPartyCode]._localAudioTrack._deviceName) { this.$message.success(this.$t('coordinationParticipant.autoSwitchNewMicSuccess')) }
          }
          break
        case 'playbackChange':
          if (!this.rtc[this.realPartyCode]) return
          this.devicesInfo = await this.rtc[this.realPartyCode].getDevice()
          if (changeDevice.state === 'ACTIVE' && this.rtc[this.realPartyCode]._localAudioTrack) {
            localStorage.setItem('speakerId', this.devicesInfo.speakerList[0].deviceId)
            this.devicesInfo.speakerId = this.devicesInfo.speakerList[0].deviceId
          } else {
            try {
              const oldSpeaker = await this.rtc[this.realPartyCode]._AgoraRTC.getPlaybackDevices()
              localStorage.setItem('speakerId', oldSpeaker[0].deviceId)
              this.devicesInfo.speakerId = oldSpeaker[0].deviceId
            } catch (e) {
              console.log('get mic list failed')
            }
          }
          break
        case 'autoPlayFailed':
          this.$alert(this.$t('coordinationParticipant.autoPlayFailed'), {
            confirmButtonText: this.$t('coordinationRoom.ok'),
            customClass: 'autoplayFailed',
            confirmButtonClass: 'joinBtn',
            showClose: false,
            showCancelButton: false,
            closeOnClickModal: false,
            closeOnPressEscape: false
          }).then(() => {})
          break
      }
    },
    muteAudioOrVideo (action, payload) {
      const { id, channel, mediaType } = payload
      if (action === 'user-unpublished') {
        if (payload.mediaType === 'video') {
          this.$set(this.videoObj, id, false)
        } else {
          this.$set(this.muteAudioObj, id, true)
        }
      } else {
        if (this.outputRtilCode == id && this.externalPartyCode == channel) {
          this.rtc[this.externalPartyCode] && this.rtc[this.externalPartyCode].subscribe(id, mediaType)
        }
        if (payload.mediaType === 'video') {
          this.$set(this.videoObj, id, true)
        } else {
          this.$delete(this.muteAudioObj, id)
        }
      }
    },
    async userJoin (payload) {
      if (!payload) return
      await this.$http
        .post(`${this.State.config.urlInfo.partyline}/partyline-backend/member/noLogin/queryByRtilCode/${payload.id}`)
        .then((res) => {
          if (res.data.errorCode === '0x0') {
            const result = res.data.result
            const accountId = result.accountId.toLowerCase()

            this.getNickName(payload.id)

            if (result.deviceType === 'T' || result.deviceType === 'R') {
              if (!this.nickNameInfo[payload.id]) {
                this.$set(this.nickNameInfo, payload.id, result.deviceName)
              }
            }

            if (result.deviceType !== 'R') {
              if (this.channels[payload.id] && (this.channels[payload.id].audioTrack || this.channels[payload.id].videoTrack)) return
            }
            if (this.outputRtilCode && this.outputRtilCode == payload.id && this.externalChannel.id != payload.id) {
              const obj = {
                ...payload,
                tid: accountId
              }
              this.externalChannel = obj
            }
            if (this.talkToAllGroupList.length) {
              const array = []
              this.talkToAllGroupList.forEach(rtilCode => {
                array.push(rtilCode)
              })
              this.$set(this.privateInfo, payload.id, array)
            }
            if (this.talkToGroupList.length) {
              const array = []
              this.talkToGroupList.forEach(rtilCode => {
                array.push(rtilCode)
              })
              if (this.privateInfo[payload.id] && this.privateInfo[payload.id].length) {
                const concatArray = this.privateInfo[payload.id].concat(array)
                this.$set(this.privateInfo, payload.id, concatArray)
              } else {
                this.$set(this.privateInfo, payload.id, array)
              }
            }
          }
        })
    },
    setNickName (data) {
      this.joinParty(data)
    },
    async getNickName (id) {
      await this.$http
        .post(`${this.State.config.urlInfo.partyline}/partyline-backend/behavior/noLogin/getList`, {
          originRtilCode: this.userInfo.rtilCode,
          annotation: this.userInfo.joinCode,
          keys: ['nickName', 'camera', 'mic', 'blockAudio', 'blockVideo', 'host'],
          marks: [id]
        })
        .then((res) => {
          if (res.data.errorCode === '0x0') {
            const result = res.data.result[id]
            if (!this.nickNameInfo[id]) {
              this.$set(this.nickNameInfo, id, result.nickName)
            }
            this.$set(this.muteAudioObj, id, result.mic === 'false')
            this.$set(this.videoObj, id, result.camera === 'true')
          }
        })
    },
    userLeave (payload) {
      const { id, channel } = payload
      const _this = this
      this.$delete(_this.channels, id)
      this.$delete(this.privateInfo, id)
      if (channel == this.externalPartyCode && id == this.externalChannel.id) {
        this.externalChannel = {}
      }
      if (this.userInfo.managerRtils.includes(id) && this.talkToGroupList.includes(id)) {
        this.talkToGroupList = this.talkToGroupList && this.talkToGroupList.filter(rtilCode => {
          return rtilCode != id
        })
      }
      if (this.userInfo.managerRtils.includes(id) && this.talkToAllGroupList.includes(id)) {
        this.talkToAllGroupList = this.talkToAllGroupList && this.talkToAllGroupList.filter(rtilCode => {
          return rtilCode != id
        })
      }

      this.$set(this.videoObj, payload.id, false)
      this.$set(this.muteAudioObj, payload.id, false)
    },
    subscribeTrack (payload) {
      try {
        // 创建可以播放的mediastream
        const { id, channel, videoTrack, audioTrack } = payload

        // 音量柱的显示
        const media = new MediaStream()
        if (videoTrack) {
          media.addTrack(videoTrack._mediaStreamTrack)
        }
        if (audioTrack && this.talkBackType != 'callHost' || (this.talkBackType == 'callHost' && id != this.noCallBackRtilCode)) {
          media.addTrack(audioTrack._mediaStreamTrack)
          audioContextObj.audioTrack(media, id)
          setTimeout(() => {
            audioContextObj.resumeAudio(id)
          }, 1000)
        }

        // 不接收 host 在 externalChannel 里的流
        if (this.userInfo.managerRtils.includes(id) && channel == this.externalPartyCode) return

        if (this.userInfo.managerRtils.includes(id) && channel == this.realPartyCode) {
          if (this.hostPrivateInfo[id]) {
            const obj = {}
            obj.id = id
            obj.videoTrack = videoTrack
            obj.audioTrack = audioTrack
            this.$set(this.hostInfo, id, obj)
          }
        } else if (this.externalPartyCode == channel) {
          if (payload.id == this.outputRtilCode) {
            const obj = {}
            obj.id = id
            obj.videoTrack = videoTrack
            obj.audioTrack = audioTrack
            obj.channel = channel
            this.externalChannel = obj
          }
        } else {
          const obj = Object.assign({}, this.channels[payload.id])
          obj.id = id
          obj.videoTrack = videoTrack
          obj.audioTrack = audioTrack
          obj.channel = channel
          if (this.talkBackType == 'callHost') {
            if (this.noCallBackRtilCode && id != this.noCallBackRtilCode) {
              this.$set(this.channels, payload.id, obj)
            } else if (this.noCallBackRtilCode && id == this.noCallBackRtilCode) {
              if ((this.audioStatus && JSON.parse(this.audioStatus)) || (this.channels[this.noCallBackRtilCode] && this.channels[this.noCallBackRtilCode].audioTrack)) {
                this.rtc[this.realPartyCode] && this.rtc[this.realPartyCode].unsubscribe(this.noCallBackRtilCode, 'audio')
              }
            }
          } else {
            this.$set(this.channels, payload.id, obj)
          }
        }
      } catch (error) {
        console.info(error)
      }
    },
    dealMetadata (payload) {
      // metadata首先external和realparty先各发一次，但是只有在external接收到消息的时候才去做转发
      // jira链接:https://tvunetworks.atlassian.net/browse/PL-3072
      // 切为与会者为pgm时，metadata应该发null，而不是不发；相反metadata正常发
      const { metadataInfo, channel } = payload
      if (this.externalPartyCode == channel && this.rtc[this.realPartyCode]._client && this.rtc[this.realPartyCode]._client._joinAndNotLeaveYet) {
        if (this.userInfo.status.forwardMetadata === true && !this.switchVal) {
          const info = JSON.parse(this.Uint8ArrayToString(metadataInfo))
          info.rtil = this.userInfo.rtilCode
          info.vframe_pts = Date.now()
          // console.log(new TextEncoder().encode(JSON.stringify(info)))
          try {
            this.rtc[this.realPartyCode]._client.sendMetadata(
              new TextEncoder().encode(JSON.stringify(info)),
              function (error) {
                if (error) console.log('sendMetadata', error)
              }
            )
          } catch {
            console.log('send metadat failed')
          }
        } else {
          const obj = {}
          obj.rtil = this.userInfo.rtilCode
          obj.vframe_pts = Date.now()
          try {
            this.rtc[this.realPartyCode]._client.sendMetadata(
              new TextEncoder().encode(JSON.stringify(obj)),
              function (error) {
                if (error) console.log('sendMetadata', error)
              }
            )
          } catch {
            console.log('send metadata failed')
          }
        }
        // const currentDate = Date.now()
        const info = this.Uint8ArrayToString(metadataInfo)
        const timestamp = JSON.parse(info).timestamp
        const timeCode = JSON.parse(info).timeCode
        this.metaDataTimeCode = timeCode
        this.metaDateTimeStamp = this.getFullTimestamp(timestamp)
      }
    },
    getFullTimestamp (timeStamp) {
      if (!timeStamp || !this.showReceiveMeta) return ''
      const pad = (n, s = 2) => (`${new Array(s).fill(0)}${n}`).slice(-s);
      const d = new Date(timeStamp)
      return `${pad(d.getFullYear(), 4)}-${pad(d.getMonth() + 1)}-${pad(d.getDate())} ${pad(d.getHours())}:${pad(d.getMinutes())}:${pad(d.getSeconds())}.${pad(d.getMilliseconds(), 3)}`;
    },
    Uint8ArrayToString (fileData) {
      let dataString = ''
      for (let i = 0; i < fileData.length; i++) {
        dataString += String.fromCharCode(fileData[i])
      }
      return dataString
    },
    showDialog () {
      this.leaveTips.show = true
      this.leaveTips.content = this.$t('coordinationParticipant.leavePartyContent')
    },
    async setOperate ({ type, id, notByClick }) {
      // 防止点击过快
      const currentTime = Date.now()
      if (type === 'muteVideo') {
        if (currentTime - this.lastDisableCamera < 1000) {
          if (this.initVideoPopup) {
            this.initVideoPopup = !this.initVideoPopup
            this.$message.warning(this.$t('coordinationParticipant.notOperateFrequery'))
          }
          return
        } else {
          this.initVideoPopup = true
        }
        this.lastDisableCamera = currentTime
      }
      if (type === 'muteAudio') {
        if (currentTime - this.lastDisableMic < 1000) {
          if (this.initAudioPopup) {
            this.initAudioPopup = !this.initAudioPopup
            this.$message.warning(this.$t('coordinationParticipant.notOperateFrequery'))
          }
          return
        } else {
          this.initAudioPopup = true
        }
        this.lastDisableMic = currentTime
      }
      // <!--防止频繁点击代码结束-->
      if (this.hasTalkback || this.hasHostTalkback || this.hasPipTalkback) return
      if (this.talkAllBackState || this.talkHostBackState || this.talkPipBackState) return

      const _this = this
      if (type === 'muteVideo') {
        _this.videoSelf = !_this.videoSelf
        this.$set(this.videoObj, this.userInfo.rtilCode, !this.videoSelf)
        // 添加测试 mute 但是发黑帧的逻辑
        if (this.rtc[this.realPartyCode]._localVideoTrack && this.rtc[this.realPartyCode]._localVideoTrack._originMediaStreamTrack) {
          this.rtc[this.realPartyCode]._localVideoTrack._originMediaStreamTrack.enabled = !this.rtc[this.realPartyCode]._localVideoTrack._originMediaStreamTrack.enabled
        }
        this.changeBehaviorStatus('camera', this.videoSelf)
      } else {
        if (!notByClick) {
          this.muteAudioSelf = !this.muteAudioSelf
          this.$set(this.muteAudioObj, this.userInfo.rtilCode, this.muteAudioSelf)
        }
        if (this.hadPushTalk && this.rtc[this.realPartyCode]) {
          this.rtc[this.realPartyCode].unpublish(['audio'])
          // this.rtc[this.realPartyCode].setEnabled(false, 'audio')
        } else {
          const _this = this
          if (!notByClick && this.rtc[this.realPartyCode]) {
            this.rtc[this.realPartyCode].publish(['audio'])
            // await this.rtc[this.realPartyCode].setEnabled(true, 'audio')
            if (_this.rtc[_this.realPartyCode]._localAudioTrack) {
              const mediaStream = new MediaStream()
              mediaStream.addTrack(_this.rtc[_this.realPartyCode]._localAudioTrack._mediaStreamTrack)
              audioContextObj.audioTrack(mediaStream, id)
              audioContextObj.resumeAudio(id)
            }
          }
        }
        this.changeBehaviorStatus('mic', !this.muteAudioSelf)
        // 根据最新UImuteAudio时逻辑改为发送pushToTalk
        this.setPushTalk('', notByClick, 4)
      }
    },
    changeDevice (obj) {
      // if (!this.devicesInfo.cameraId) return
      if (obj.type == 'audio') {
        this.rtc[this.realPartyCode].setDevice(obj.deviceId, obj.type, obj.type === 'audio' ? 'needLocalAudioTrack' : '')
      } else if (obj.type == 'speaker') {
        localStorage.setItem('speakerId', obj.deviceId)
        // mainWindow
        this.$refs.mainWindowContent.setSpeaker()
        // 与会者
        // this.$refs.talentCommentator.setSpeaker()
        // host
        if (this.rtc[this.realPartyCode]) {
          Object.keys(this.rtc[this.realPartyCode]._remotePublishUser).forEach(rtilCode => {
            if (this.rtc[this.realPartyCode]._remotePublishUser[rtilCode] && this.rtc[this.realPartyCode]._remotePublishUser[rtilCode].audioTrack) {
              this.rtc[this.realPartyCode]._remotePublishUser[rtilCode].audioTrack.setPlaybackDevice(localStorage.getItem('speakerId'))
              this.rtc[this.realPartyCode]._remotePublishUser[rtilCode].audioTrack.play()
            }
          })
        }
      } else {
        this.rtc[this.realPartyCode].setDevice(obj.deviceId, obj.type, obj.type === 'audio' ? 'needLocalAudioTrack' : '')
      }
    },
    // 更新设备id
    updateDeviceId (type, info) {
      if (type == 'video') {
        this.devicesInfo.cameraId = info
      } else if (type == 'audio') {
        this.devicesInfo.microphoneId = info
      } else {
        this.devicesInfo.speakerId = info
      }
    },
    changeMute (val) {
      this.$store.commit('isMute', !this.isMute)
      if (val) {
        this.$message({
          type: 'success',
          message: this.$t('coordinationParticipant.websiteSoundOn')
        })
      } else {
        this.$message({
          type: 'success',
          message: this.$t('coordinationParticipant.websiteSoundOff')
        })
      }
      this.changeBehaviorStatus('speaker', !this.isMute)
    },
    changeBehaviorStatus (key, value) {
      const params = [
        {
          mark: this.userInfo.rtilCode + '',
          annotation: this.externalPartyCode,
          key,
          value,
          notify: true
        }
      ]
      this.$http.post(`${this.State.config.urlInfo.partyline}/partyline-backend/behavior/noLogin/setList`, params)
    },
    setPushTalk (byTalkback, notByClick, privateType) {
      if (!this.rtilCode || !this.realPartyCode) return
      // 处理选择按压talkBack按键，不可点击麦克风
      // if (!this.hadPushTalk && (this.space || this.cKey || this.gKey)) { return }
      // if (!notByClick) this.hadPushTalk = !this.hadPushTalk
      if (!byTalkback && !notByClick) {
        this.pushTalkStatus = !this.pushTalkStatus
        this.hadPushTalk = !this.hadPushTalk
      }
      const params = {
        rtilCode: this.rtilCode,
        partyCode: this.realPartyCode
      }
      // const _str = this.hadPushTalk ? 'You will be pushed to Audio mixer.' : 'You will be stopped pushing to Audio mixer.'
      const self = this
      if (byTalkback) {
        this.talkBackUrl = this.pushTalkStatus ? '/commentator-backend/commentator/party/noLogin/disableTalkback' : '/commentator-backend/commentator/party/noLogin/enableTalkback'
      } else {
        this.talkBackUrl = this.hadPushTalk ? '/commentator-backend/commentator/party/noLogin/enableMic' : '/commentator-backend/commentator/party/noLogin/disableMic'
      }
      if (this.pushTalkStatus) {
        // 麦克风关闭时，cancel 不混音
        this.cancel.removeMixAudio && this.cancel.removeMixAudio()
      } else {
        // 麦克风打开时，cancel  混音
        this.cancel.addMixAudio && this.cancel.addMixAudio()
      }
      this.$http.post(this.talkBackUrl, params, {
        cancelToken: new CancelToken(function executor (c) {
          self.cancel[self.pushTalkStatus ? 'addMixAudio' : 'removeMixAudio'] = c
          // 这个参数 c 就是CancelToken构造函数里面自带的取消请求的函数，这里把该函数当参数用
        })
      }).then((res) => {
        if (res.data.errorCode == '0x0') {
          // if (!notByClick) this.$message.success(_str)
          // 状态改变
          if (!privateType) {
            this.talkbackTimerBtn = true
            if (this.pushTalkStatus) {
              this.hasTalkback = false
              // 清除diabled状态
              this.talkAllBackState = false
            } else if (!this.pushTalkStatus) {
              this.hasTalkback = true
              // 判断当前的麦克风是否开启如果没有需要创建流
              this.openMicTalkBack()
            }
          } else if (privateType == 'Host') {
            this.talkbackHostTimerBtn = true
            if (this.pushTalkStatus) {
              this.hasHostTalkback = false
              // 清除diabled状态
              this.talkHostBackState = false
            } else if (!this.pushTalkStatus) {
              this.hasHostTalkback = true
              // 判断当前的麦克风是否开启如果没有需要创建流
              this.openMicTalkBack()
            }
          } else if (privateType == 'pip') {
            this.talkbackPipTimerBtn = true
            if (this.pushTalkStatus) {
              this.hasPipTalkback = false
              // 清除diabled状态
              this.talkPipBackState = false
            } else if (!this.pushTalkStatus) {
              this.hasPipTalkback = true
              // 判断当前的麦克风是否开启如果没有需要创建流
              this.openMicTalkBack()
            }
          }
        }
      }).catch((e) => {
        // 快速点击发送unmute请求添加loading
        if (!privateType) {
          this.talkAllBackState = true
          this.talkbackTimerBtn = false
          this.hasTalkback = false
        } else if (privateType == 'Host') {
          this.talkHostBackState = true
          this.talkbackHostTimerBtn = false
          this.hasHostTalkback = false
        } else if (privateType == 'pip') {
          this.talkPipBackState = true
          this.talkbackPipTimerBtn = false
          this.hasPipTalkback = false
        }
      })
    },
    // 判断当前的麦克风是否开启如果没有需要创建流
    openMicTalkBack () {
      if (
        !this.hadPushTalk &&
        this.rtc[this.realPartyCode] &&
        this.rtc[this.realPartyCode]._localAudioTrack
      ) {
        this.rtc[this.realPartyCode].publish(['audio'])
      }
    },
    controlTalkback (byKeyCode, keyType = '', privateType) {
      if (!privateType) {
        // 三个按钮只能有一个是打开状态
        if (this.talkHostBackState || this.talkPipBackState) {
          if ((this.hasAllExecuteKeyDown && byKeyCode != 0) || (byKeyCode == 0 && this.talkBackMousedown)) {
            this.$message.error(this.$t('coordinationParticipant.talkBackConflict'))
          }
          return
        }
        // 鼠标点击事件
        if (byKeyCode == 0) {
          this.mousedownStatus = keyType == 'mousedown'
          // 记录鼠标点击talkAll状态，新增disable状态
          if (this.mousedownStatus) {
            this.allMousedownStatus = true
          }
        }

        console.info(333, `操作了按键：${byKeyCode == 0 ? '鼠标' : '空格'}`, `当前是否开启talkback：${this.hasTalkback}`, `是否按下鼠标:${this.mousedownStatus}`, `是否按下空格:${this.hasAllExecuteKeyDown}`)
        if (this.hasAllExecuteKeyDown && keyType.includes('mouse') || this.mousedownStatus && byKeyCode.includes('space')) return
        console.info(333, '进行状态更改', byKeyCode, this.hasTalkback, `click:${this.mousedownStatus}`, `space:${this.hasAllExecuteKeyDown}`)
        // 关闭私聊（松手）
        if (this.hasTalkback || (!this.mousedownStatus && byKeyCode == 0) || (!this.hasAllExecuteKeyDown && byKeyCode.includes('space'))) {
          this.closeTalkback()
          this.allMousedownStatus = false
          this.pushTalkStatus = true
          this.setPushTalk('byTalkback', '', privateType)
        } else {
        // 打开私聊（按下）
          if ((byKeyCode == 0 || byKeyCode.includes('space')) && (this.mousedownStatus || this.hasAllExecuteKeyDown)) {
            this.pushTalkStatus = false
            this.openTalkback()
          }
        }
      } else if (privateType == 'Host') {
        if (this.talkAllBackState || this.talkPipBackState) {
          if ((this.hasHostExecuteKeyDown && byKeyCode != 0) || (byKeyCode == 0 && this.talkBackMousedown)) {
            this.$message.error(this.$t('coordinationParticipant.talkBackConflict'))
          }
          return
        }
        if (byKeyCode == 0) {
          this.mousedownStatus = keyType == 'mousedown'
          // 记录鼠标点击talkHost状态，新增disable状态
          if (this.mousedownStatus) {
            this.hostMousedownStatus = true
          }
        }
        console.info(333, `操作了按键：${byKeyCode == 0 ? '鼠标' : 'c'}`, `当前是否开启talkback：${this.hasHostTalkback}`, `是否按下鼠标:${this.mousedownStatus}`, `是否按下c:${this.hasHostExecuteKeyDown}`)
        if (this.hasHostExecuteKeyDown && keyType.includes('mouse') || this.mousedownStatus && byKeyCode.includes('c')) return
        console.info(333, '进行状态更改', byKeyCode, this.hasHostTalkback, `click:${this.mousedownStatus}`, `c:${this.hasHostExecuteKeyDown}`)
        // 关闭私聊（松手）
        if (this.hasHostTalkback || (!this.mousedownStatus && byKeyCode == 0) || (!this.hasHostExecuteKeyDown && byKeyCode.includes('c'))) {
          this.closeTalkback('Host')
          this.hostMousedownStatus = false
          this.pushTalkStatus = true
          this.setPushTalk('byTalkback', '', privateType)
        } else {
        // 打开私聊（按下）
          if ((byKeyCode == 0 || byKeyCode.includes('c')) && (this.mousedownStatus || this.hasHostExecuteKeyDown)) {
            this.pushTalkStatus = false
            this.openTalkback('Host')
          }
        }
      } else if (privateType == 'pip') {
        if (this.talkAllBackState || this.talkHostBackState) {
          if ((this.hasPipExecuteKeyDown && byKeyCode != 0) || (byKeyCode == 0 && this.talkBackMousedown)) {
            this.$message.error(this.$t('coordinationParticipant.talkBackConflict'))
          }
          return
        }
        if (byKeyCode == 0) {
          this.mousedownStatus = keyType == 'mousedown'
          // 记录鼠标点击talkpip状态，新增disable状态
          if (this.mousedownStatus) {
            this.pipMousedownStatus = true
          }
        }
        console.info(333, `操作了按键：${byKeyCode == 0 ? '鼠标' : 'g'}`, `当前是否开启talkback：${this.hasPipTalkback}`, `是否按下鼠标:${this.mousedownStatus}`, `是否按下g:${this.hasPipExecuteKeyDown}`)
        if (this.hasPipExecuteKeyDown && keyType.includes('mouse') || this.mousedownStatus && byKeyCode.includes('g')) return
        console.info(333, '进行状态更改', byKeyCode, this.hasPipTalkback, `click:${this.mousedownStatus}`, `g:${this.hasPipExecuteKeyDown}`)
        // 关闭私聊（松手）
        if (this.hasPipTalkback || (!this.mousedownStatus && byKeyCode == 0) || (!this.hasPipExecuteKeyDown && byKeyCode.includes('g'))) {
          this.closeTalkback('pip')
          this.pipMousedownStatus = false
          this.pushTalkStatus = true
          this.setPushTalk('byTalkback', '', privateType)
        } else {
        // 打开私聊（按下）
          if ((byKeyCode == 0 || byKeyCode.includes('g')) && (this.mousedownStatus || this.hasPipExecuteKeyDown)) {
            this.pushTalkStatus = false
            this.openTalkback('pip')
          }
        }
      }
    },
    openTalkback (privateType) {
      if (!privateType) {
        // diabled状态
        this.talkAllBackState = true
        // 打开loading
        this.talkbackTimerBtn = false
        this.setPushTalk('byTalkback', '', privateType)
        this.changeTalkbackBehavior('callAll', true)
      } else if (privateType == 'Host') {
        this.talkHostBackState = true
        this.talkbackHostTimerBtn = false
        this.setPushTalk('byTalkback', '', privateType)
        this.changeTalkbackBehavior('callHost', true, 'Host')
      } else if (privateType == 'pip') {
        this.talkPipBackState = true
        this.talkbackPipTimerBtn = false
        this.setPushTalk('byTalkback', '', privateType)
        this.changeTalkbackBehavior('callPip', true, 'pip')
      }
    },
    closeTalkback (privateType) {
      if (!privateType) {
        // loading状态
        this.talkbackTimerBtn = false
        // 同样判断当前本地的mic状态, 如果当前的pushTalk是打开的，则不需要setEnabled为false
        if (!this.hadPushTalk && this.rtc[this.realPartyCode] && this.rtc[this.realPartyCode]._localAudioTrack && !this.pushTalkStatus) {
          this.rtc[this.realPartyCode].unpublish(['audio'])
        }
        this.changeTalkbackBehavior('callAll', false)
      } else if (privateType == 'Host') {
        this.talkbackHostTimerBtn = false
        // 同样判断当前本地的mic状态, 如果当前的pushTalk是打开的，则不需要setEnabled为false
        if (!this.hadPushTalk && this.rtc[this.realPartyCode] && this.rtc[this.realPartyCode]._localAudioTrack && !this.pushTalkStatus) {
          this.rtc[this.realPartyCode].unpublish(['audio'])
        }
        this.changeTalkbackBehavior('callHost', false, 'Host')
      } else if (privateType == 'pip') {
        this.talkbackPipTimerBtn = false
        // 同样判断当前本地的mic状态, 如果当前的pushTalk是打开的，则不需要setEnabled为false
        if (!this.hadPushTalk && this.rtc[this.realPartyCode] && this.rtc[this.realPartyCode]._localAudioTrack && !this.pushTalkStatus) {
          this.rtc[this.realPartyCode].unpublish(['audio'])
        }
        this.changeTalkbackBehavior('callPip', false, 'pip')
      }
    },
    changeTalkbackBehavior (key, value, privateType) {
      if (!this.userInfo.rtilCode || !this.eventInfo || !this.eventInfo.id || !this.realPartyCode) return
      const self = this
      if (value == 1) {
        this.cancel.removeTalkback && this.cancel.removeTalkback()
      } else {
        this.cancel.addTalkback && this.cancel.addTalkback()
      }
      const params = [
        {
          annotation: this.externalPartyCode,
          initiator: this.realPartyCode,
          key,
          mark: this.userInfo.rtilCode + '',
          notify: true,
          value
        },
        {
          annotation: this.externalPartyCode,
          initiator: this.realPartyCode,
          key: 'audioStatus',
          mark: this.userInfo.rtilCode + '',
          notify: true,
          value: this.hadPushTalk
        }
      ]
      this.$http.post(`${this.State.config.urlInfo.partyline}/partyline-backend/behavior/noLogin/setList`, params, {
        cancelToken: new CancelToken(function executor (c) {
          self.cancel[value == 1 ? 'addTalkback' : 'removeTalkback'] = c
          // 这个参数 c 就是CancelToken构造函数里面自带的取消请求的函数，这里把该函数当参数用
        })
      }).then(() => {
        if (!this.pushTalkStatus) {
          if (!privateType) {
          // setList不影响loading状态
          // this.talkbackTimerBtn = true
          // if (value) {
          //   this.hasTalkback = true
          // } else {
          //   this.hasTalkback = false
          // }
          // } else if (privateType == 'Host') {
          //   this.talkbackHostTimerBtn = true
          //   if (value) {
          //     this.hasHostTalkback = true
          //   } else {
          //     this.hasHostTalkback = false
          //   }
          // } else if (privateType == 'pip') {
          //   this.talkbackPipTimerBtn = true
          //   if (value) {
          //     this.hasPipTalkback = true
          //   } else {
          //     this.hasPipTalkback = false
          //   }
          }
        }
      }).catch(() => {
        // 快速点击发送unmute请求添加loading
        if (!privateType) {
          this.talkAllBackState = true
          this.talkbackTimerBtn = false
          this.hasTalkback = false
        } else if (privateType == 'Host') {
          this.talkHostBackState = true
          this.talkbackHostTimerBtn = false
          this.hasHostTalkback = false
        } else if (privateType == 'pip') {
          this.talkPipBackState = true
          this.talkbackPipTimerBtn = false
          this.hasPipTalkback = false
        }
      })
    },
    refreshPage () {
      window.location.reload()
    },
    leaveParty () {
      const params = {
        partyCode: this.userInfo.joinCode,
        module: 'leaveParty',
        msg: {
          rtilCode: this.userInfo.rtilCode,
          realPartyCode: this.userInfo.realPartyCode,
          externalParty: this.userInfo.externalJoinInfo.join
        }
      }
      if (!params.partyCode || !params.msg.rtilCode) return
      this.$http.post(
        `${this.State.config.urlInfo.partyline}/partyline-backend/party/noLogin/command?key=11260`,
        params
      )
      this.$router.push({ name: 'leaveParty' })
    }
  },
  beforeDestroy () {
    this.ws && this.ws.off()
    clearTimeout(this.speakTimer);
    clearTimeout(this.silenceTimer);
  },
  async beforeRouteLeave (to, from, next) {
    if (this.rtc) {
      for (const k in this.rtc) {
        if (this.hadOpenWebCloud) {
          this.rtc[k] && await this.rtc[k].leave(this.hadOpenWebCloud ? 'closeCLoudProxy' : '')
        }
      }
    }
    this.hadOpenWebCloud = false
    next()
  }
  // async beforeRouteLeave (to, from, next) {
  //   for (const k in this.rtc) {
  //     if (this.hadOpenWebCloud) {
  //       this.rtc[k] && await this.rtc[k].leave(this.hadOpenWebCloud ? 'closeCLoudProxy' : '')
  //     }
  //   }
  //   this.hadOpenWebCloud = false
  //   // next()
  // }
}
</script>
<style lang="less" scoped>
#box {
  .delayWarn {
    height: 40px;
    line-height: 40px;
    background: #03897b;
    text-indent: 25px;
    position: absolute;
    width: 100%;
    z-index: 2;
    i {
      margin-right: 5px;
    }
    ::v-deep .el-button {
      padding: 9px 10px;
      margin-left: 100px;
      background: #015249;
      color: #fff;
      border: none;
      &:hover {
        background: #02413a;
      }
    }
  }
}
#content {
  height: calc(100% - 120px);
  display: flex;
  ::v-deep .metaData {
    position: absolute;
    top: 15px;
    left: 15px;
    z-index: 2;
  }
  ::v-deep .group-box {
    &:nth-child(1) {
      width: 85%;
      margin-right: 0;
      .content-box {
        padding: 0;
        .pip {
          padding: 0 0 0 10px;
          .video-con {
            max-height: calc(100vh - 145px);
            .videoBox {
              video {
                object-fit: contain!important;
              }
            }
          }
          .volumeBox {
            max-height: calc(100vh - 145px);
          }
        }
      }
    }
    &:nth-child(2) {
      width: 15%;
      margin-right: 0;
      margin-left: -10px;
      .pip {
        padding: 0;
        li {
          >.box {
            width: calc(100% - 35px);
          }
        }
      }
    }
    .content-box {
      background: none;
      padding: 0;
      .mainWindow {
        width: 100%;
        transform: none;
        .box {
          background: #1c1c20;
        }
      }
    }
  }
  .foot {
    height: 64px;
    background: #000;
    width: 100%;
    position: fixed;
    bottom: 0;
    text-align: center;
    z-index: 2;
    .optionBox {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      border-top: 2px solid #28282e;
      .icon-universal_cameraon:before,
      .icon-universal_cameraoff:before,
      .icon-speakeron:before,
      .icon-speakeroff:before,
      .hangup{
        display: block;
        width: 40px;
        height: 40px;
      }
      .cameraItem{
        display: inline-block;
      }
      .speakerItem,.cameraItem{
        font-size: 28px;
        line-height: 40px;
        background: #33AB4F;
        border:none;
        &.active {
          background: #F6445A;
        }
    }
      .micOnItem{
        margin-top: 0px !important;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-around;
      }
      .micOffItem{
        margin-top: 0px !important;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-around;
      }
      .talkAll{
        margin-top: 0px !important;
        display: flex;
        width: 70px !important;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-around;
      }
      .talkHost{
        margin-left: 40px;
        margin-top: 0px !important;
        display: flex;
        width: 90px !important;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-around;
      }
      .talkPip{
        margin-left: 40px;
        margin-top: 0px !important;
        display: flex;
        width: 90px !important;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-around;
      }
      i {
        width: 40px;
        height: 40px;
        border: 1px solid #3b3b3e;
        border-radius: 50%;
        svg {
          width: 100%;
          height: 100%;
        }
        &.cameraIcon, &.muteVideo {
          margin-right: 48px;
          &.m_r_0 {
            margin-right: 0;
          }
        }
        &.cameraIcon:before {
          font-size: 20px;
        }
        &.audioIcon  {
          margin-right: 48px;
          &.m_r_0 {
            margin-right: 0;
          }
        }
        &.muteAudio,
        &.audioIcon,
        &.pushTalk {
          border: none;
          margin-top: -20px;
          position: relative;
          width: 50px;
          div {
            position: absolute;
            border: 4px solid #33AB4F;;
            opacity: 1;
            border-radius: 50%;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            -webkit-animation: lds-ripple 1.5s cubic-bezier(0, 0.2, 0.8, 1) infinite;
            animation: lds-ripple 1.5s cubic-bezier(0, 0.2, 0.8, 1) infinite;
            &:nth-child(2) {
              -webkit-animation-delay: -1s;
              animation-delay: -1s;
            }
          }
          @keyframes lds-ripple {
            0% {
              width: 0;
              height: 0;
              opacity: 1;
            }
            100% {
              width: 55px;
              height: 55px;
              opacity: 0;
            }
          }
        }
        span {
          position: relative;
          top: -4px;
          font-size: 12px;
        }
        .loading {
          width: 40px;
          height: 40px;
          top: 0px;
          display: inline-block;
          animation: loading-rotate 1s linear infinite;
        }
        .loadingHost{
          width: 40px;
          height: 40px;
          top: -34px;
          left: 11px;
          display: inline-block;
          animation: loading-rotate 1s linear infinite;
        }
      }
      ::v-deep .el-divider--vertical {
        height: 24px;
        margin: 0 24px;
        background-color: #444;
      }
      .leaveDivider {
        margin-left: -16px;
      }
    }
  }
}
@keyframes loading-rotate {
    to {
        transform: rotate(1turn)
    }
}
</style>
