<template>
  <div class="removePage">
    <h1><img :src="homeLogo"/><span>{{homeTitle}}</span></h1>
    <p class="title">{{ title }}</p>
    <div class="bottomContent" >
      <p v-html="text"></p>
      <el-button v-if="$route.query.type != 'notExist' && $route.query.type != 'unExitParty'" @click="reJoin()">
        {{$t('coordinationParticipant.reJoin')}}</el-button>
        <el-button v-else @click="BackHome()">
        {{$t('HostCommon.backHome')}}</el-button>
    </div>
    <div class="right">
      <el-button v-if="cancelBtn" @click="cancel" class="cancel">{{cancelText}}</el-button>
      <el-button v-if="saveBtn" @click="save" class="save">{{saveText}}</el-button>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import Utils from '@/assets/js/utils.js'
export default {
  data () {
    return {
      text: '',
      title: '',
      saveBtn: false,
      saveText: '',
      cancelBtn: false,
      cancelText: '',
      routeName: '',
      routeParams: '',
      cancelRouteParams: ''
    }
  },
  computed: {
    ...mapState({
      State: state => state,
      logo: state => state.logo,
      homeLogo: state => state.homeLogo,
      homeTitle: state => state.homeTitle,
      Config: (state) => state.config
    })
  },
  created () {
    this.State.newPageLoading = false
    document.getElementsByClassName('contentBox')[0].style.height = '100vh'
    const { type } = this.$route.query
    switch (type) {
      case 'eventOver':
        this.title = this.$t('leaveParty.alert')
        this.text = this.$t('leaveParty.eventOverText')
        break;
      case 'eventDelete':
        this.title = this.$t('leaveParty.alert')
        this.text = this.$t('leaveParty.eventDeleteText')
        break;
      case 'unExitParty':
        this.title = this.$t('leaveParty.unableToJoin')
        this.text = this.$t('leaveParty.invalidPartyIdText')
        break;
      case 'accountAccess':
        this.title = this.$t('coordinationRoom.exitCoordinationRoom')
        this.text = this.$t('coordinationRoom.accountAccessed')
        this.saveBtn = true
        this.saveText = this.$t('leftPaneOutPut.done')
        this.routeParams = {
          name: 'commentator'
        }
        break;
      case 'accountAccessTips':
        this.title = this.$t('coordinationRoom.couldNotAccessCoordination')
        this.text = this.$t('coordinationRoom.accountAccessed') + `<p class="accountTips">${this.$t('coordinationRoom.closeOtherAccount')}</p>`
        this.saveBtn = true
        this.saveText = this.$t('coordinationRoom.no')
        this.cancelBtn = true
        this.cancelText = this.$t('coordinationRoom.agree')
        this.routeParams = {
          name: 'commentator'
        }
        this.cancelRouteParams = {
          name: 'coordination',
          query: {
            id: this.$route.query.eventId
          }
        }
        break;
      case 'notExist':
        this.title = this.$t('leaveParty.unableToJoin')
        this.text = this.$t('leaveParty.notExist')
        break;
      default:
        this.title = this.$t('coordinationParticipant.leaveParty')
        this.text = this.$t('leaveParty.leavePartyText')
    }
  },
  methods: {
    BackHome () {
      this.$router.push({
        name: 'commentator'
      })
    },
    reJoin () {
      window.history.back()
    },
    save () {
      if (this.routeParams) {
        this.$router.push(this.routeParams)
      }
    },
    cancel () {
      if (this.cancelRouteParams) {
        const { type, eventId, partyCode } = this.$route.query
        if (type == 'accountAccessTips') {
          const params = {
            key: 'hostUuidRelation',
            partyCode,
            value: localStorage.getItem(eventId) || Utils.getRandomStr()
          }
          this.$http.post(`${this.Config.urlInfo.partyline}/partyline-backend/dictionary`, params).then(res => {
            if (res.data.errorCode == '0x0') {
              localStorage.setItem(eventId, params.value)
              this.$router.push(this.cancelRouteParams)
            }
          })
        } else {
          this.$router.push(this.cancelRouteParams)
        }
      }
    }
  }
}
</script>
<style lang="less" scoped>
.removePage {
  width: 440px;
  background:#FBFBFB;
  border-radius:8px;
  border:2px solid #F2F2F2;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  padding: 20px 45px;
  .bottomContent{
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;
    p {
      display: inline-block;
      margin-bottom: 0;
    }
  }
  h1 {
    text-align: center;
    margin-bottom: 40px;
    img {
      width: 28px;
      height: 28px;
      margin-right: 10px;
    }
    span {
      font-size:24px;
      color:rgba(51,171,79,1);
      line-height:28px;
      background:linear-gradient(233deg, rgba(180,236,81,1) 0%, rgba(66,147,33,1) 100%), linear-gradient(270deg, rgba(3,161,118,1) 0%, rgba(113,186,42,1) 59%, rgba(208,217,0,1) 100%);
      -webkit-background-clip:text;
      -webkit-text-fill-color:transparent;
      position: relative;
      top: -5px;
    }
  }
  p {
    font-size: 16px;
    color: #555661;
    margin-bottom: 40px;
  }
  /deep/.accountTips {
    color: #28282E;
    margin-top: 30px;
    font-size: 16px;
    font-weight: 600;
  }
  .title {
    font-weight: bold;
    font-size: 18px;
    color: #28282E;
  }
  .el-button {
      border: 0;
      background:linear-gradient(270deg,rgba(3,161,118,1) 0%,rgba(113,186,42,1) 59%,rgba(208,217,0,1) 100%);
      color: #fff;
      padding: 9px 15px;
      border-radius: 4px;
      &.save {
        background:#33AB4F;
        padding: 10px 16px;
      }
      &.cancel {
        color: #33AB4F;
        background: none;
        &:hover {
          background: none;
        }
      }
  }
  // .el-button {
  //     border: 0;
  //     color: #fff;
  //     border-radius: 4px;
  //     &.save {
  //       background:#33AB4F;
  //       padding: 10px 16px;
  //     }
  //     &.cancel {
  //       color: #33AB4F;
  //       background: none;
  //       &:hover {
  //         background: none;
  //       }
  //     }
  //   }
}
</style>
