<template>
  <div class="recevier" v-loading="loading">
    <div class="title">
      <span @click="changeToggle"
        ><i class="icon el-icon-caret-bottom" :class="{ trans: !show }"></i
        >{{ title | showOutPutTitle}}</span
      >
    </div>
    <transition name="draw">
      <ul
        class="scrollbar"
        v-scroll="scroll"
        v-show="show"
        v-loadings="scroll.loading"
      >
        <li v-for="val in list" :key="val.id" :class="{ active: val.checked && val.status !== '2'}">
          <span class="title" :class="{live: val.status === '2'}" :title="val.name">
            <span class="dot" v-if="val.status === '2'"></span>
            {{ val.name }}
          </span>
          <span
            v-if="rLiveIsspInfo[val.peerId]"
            class="copyBtn"
            :title="rLiveIsspInfo[val.peerId]"
            v-clipboard:copy="copyLink(rLiveIsspInfo[val.peerId])"
            v-clipboard:success="onCopy"
            v-clipboard:error="onError"
          >{{ rLiveIsspInfo[val.peerId] }}</span>
          <div class="opearate">
            <el-popover
              placement="bottom-start"
              title=""
              :ref="`popover${val.id}`"
              popper-class="inputOperate"
              width="150"
              trigger="click"
            >
              <div class="btn">
                <div @click="viewSettingFn(val)" class="button">
                  {{showEdit ? $t('leftPaneOutPut.edit') : $t('otherWords.viewSetting')}}
                </div>
              </div>
              <!-- <span class="operate" slot="reference"
                ><i class="el-icon-more"></i
              ></span> -->
            </el-popover>
            <el-checkbox
              :disabled="disabled"
              @change="
                (value) => {
                  selectItem(val, value)
                }
              "
              v-model="val.checked"
            ></el-checkbox>
          </div>
        </li>
        <li  v-if="scroll.loading && !loading" style="justify-content: center;">
          <span
          class="loading-txt"
          style="font-size:15px;color:#909090; "
          >
            <i class="el-icon-loading" style="color:#909090;"></i>
            {{$t('eventList.loading')}}
          </span>
        </li>
      </ul>
    </transition>
    <div class="no-data" v-if="!list.length && !scroll.loading && show">
      <p class="gray999">{{$t('leftPaneOutPut.noResult')}}</p>
    </div>
  </div>
</template>
<script>
import i18n from '@/lang/index.js'
import Bus from '@/assets/js/bus.js'
import { mapState } from 'vuex'
export default {
  props: {
    title: String,
    request: Object,
    selectVal: Array,
    disabled: Boolean,
    eventId: {
      type: String,
      default: () => {
        return ''
      }
    },
    defaultGridId: {
      type: String,
      default: () => {
        return ''
      }
    },
    eventStatus: {
      type: Number,
      default: () => {
        return null
      }
    },
    searchType: {
      type: String,
      default: () => {
        return null
      }
    },
    showEdit: {
      type: Boolean,
      default: true
    },
    cancelSelectedR: {
      type: Array,
      default: () => []
    },
    rLiveIsspInfo: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {
      loading: false,
      show: true,
      checked: false,
      list: [],
      scroll: {
        onScroll: this.getNextPage,
        loading: true,
        noMore: false
      },
      ipoutSetting: {
        show: false,
        id: '',
        type: '',
        auth: ''
      }
    }
  },
  computed: {
    ...mapState({
      State: state => state
    })
  },
  watch: {
    searchType (val) {
      if (val.indexOf(this.title) > -1) {
        this.show = true
        this.scroll.noMore = false
        this.scroll.loading = false
        this.getList()
      } else {
        this.show = false
      }
    },
    selectVal: {
      handler (oldValue, newValue) {
        if (!this.cancelSelectedR) return
        this.list.forEach(item => {
          this.cancelSelectedR.forEach(value => {
            if (item.peerId === value.id) {
              item.checked = false
            }
          })
        })
        this.$emit('callback', {
          deleteType: true
        })
      }
    }
  },
  created () {
    this.loading = true
    this.getList()
    Bus.$on('getIpoutList', () => {
      this.request.params.pageNum = 1
      this.getList()
    })
    Bus.$on('eventInfoUpdate', (data) => {
      // if (!this.State.clientId) return
      if (data.clientId != this.State.clientId) {
      // 在此更新用户在coordinationRoom的编辑事件
        this.getList()
      }
    })
  },
  filters: {
    showOutPutTitle (title) {
      if (!title) return ''
      if (title === 'Receiver') {
        return i18n.t('leftPaneOutPut.receiver')
      } else if (title === 'IP OUT') {
        return i18n.t('leftPaneOutPut.ipOut')
      } else {
        return title
      }
    }
  },
  methods: {
    hidePopup () {
      Object.keys(this.$refs).forEach((v) => {
        if (v.indexOf('popover') >= 0 && this.$refs[v].length > 0) {
          this.$refs[v][0].doClose() // 关闭popover
        }
      })
    },
    viewSettingFn (val) {
      this.$emit('changeReceiverSetting', true, val)
      this.hidePopup()
    },
    changeToggle () {
      this.show = !this.show
    },
    getList () {
      this.scroll.loading = true
      this.$http.post(this.request.url, this.request.params).then((res) => {
        this.scroll.loading = false
        this.loading = false
        if (res.data.errorCode == '0x0') {
          let list = []
          if (this.eventId && this.title == 'IP OUT') {
            // 编辑时查询的ipout数据机构与creat时不同
            res.data.result.forEach((v) => {
              const item = { ...v, ...v.value }
              if (
                item.pushLiveName != this.defaultGridId ||
                !this.defaultGridId
              ) {
                list.push({ ...item, checked: false })
              }
            })
          } else {
            list = res.data.result.list || []
          }

          list.forEach((v) => {
            if (this.request.label) {
              v.name = v[this.request.label]
            }
            v.key = v.peerId
            if (this.request.id) {
              v.key = v[this.request.id]
            }
            this.selectVal.forEach((val) => {
              if (val.id == v.key) {
                v.checked = true
              }
            })
            // if (this.selectVal.length && this.selectVal.includes(v.key)) {
            //   v.checked = true
            // }
          })
          if (
            this.request.params &&
            this.request.params.pageSize &&
            this.request.params.pageNum != 1
          ) {
            this.list = this.list.concat(list)
          } else {
            this.list = list
          }
          if (
            this.request.params &&
             !res.data.result.hasNextPage
          ) {
            this.scroll.noMore = true
          }
          this.$emit('callback', {
            type: 'sendRList',
            list
          })
        }
      })
    },
    getNextPage () {
      if (!this.request.params || !this.request.params.pageSize) return
      if (this.scroll.loading || this.scroll.noMore) return
      this.request.params.pageNum += 1
      this.getList()
    },
    selectItem (val, value) {
      const type = value ? 'add' : 'remove'
      this.$emit('callback', {
        output: this.title,
        type,
        id: val.key,
        name: val.name,
        outputType: val.type
      })
    },
    copyLink (val) {
      return val
    },
    onCopy () {
      this.hidePopup()
      this.$message({
        message: this.$t('otherWords.copySuccess'),
        type: 'success'
      })
    },
    onError () {
      this.$message({
        message: 'Copy failed',
        type: 'error'
      })
    }
  }
}
</script>
<style lang="less" scoped>
.recevier {
  position: relative;
  padding-right: 30px;
  /deep/.el-loading-mask {
    background: none;
  }
  .no-data {
    margin-left:15px;
    text-align: center;
    height: 95px;
    .gray999 {
      color: #999;
    }
    .gray666 {
      color: #666;
    }
  }
  .icon {
    margin-right: 5px;
    cursor: pointer;
    font-size: 20px;
    position: relative;
    top: 3px;
  }
  > .title {
    span {
      cursor: pointer;
      transform: rotate(0deg);
      .trans {
        transform: rotate(-90deg);
      }
    }
  }
  ul {
    padding-left: 21px;
    max-height: 175px;
    // min-height: 100px;
    overflow-y: auto;
    padding-right: 33px;
    position: relative;
    margin-bottom: 10px;
    ::v-deep.lds-roller {
      transform: translateX(-50%) scale(0.5, 0.5);
      top: 30px;
    }
    li {
      line-height: 30px;
      height: 32px;
      padding: 0 10px;
      display: flex;
      justify-content: flex-end;
      &:hover {
        background: rgba(0, 0, 0, 0.15);
      }
      .operate {
        margin-right: 42px;
        cursor: pointer;
      }
      .title {
        cursor: pointer;
        flex: 1;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        .dot {
          display: inline-block;
          width: 5px;
          height: 5px;
          background-color: #F6445A;
          border-radius: 50%;
          transform: translateY(-1px);
        }
        &.live {
          color: #F6445A;
        }
      }
      .copyBtn {
        width: 40%;
        margin-right: 40px;
        cursor: pointer;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      &.active {
        .title {
          color: #03897b;
        }
      }
    }
    /deep/.el-checkbox__input {
      &.is-checked {
        .el-checkbox__inner {
          background: #03897b;
          border: 2px solid #03897b;
          &::after {
            left: 3px;
          }
        }
        &.is-disabled {
          .el-checkbox__inner {
            background: #909090;
            border: 2px solid #909090;
            &::after {
              left: 3px;
            }
          }
        }
      }
      .el-checkbox__inner {
        background: none;
        border: 2px solid #666;
      }
    }
  }
  .draw-enter-active,
  .draw-leave-active {
    transition: all 0.5s ease;
    max-height: 175px;
  }
  .draw-enter, .draw-leave-to /* .fade-leave-active below version 2.1.8 */ {
    height: 0;
  }
}
</style>
