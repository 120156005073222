import Vue from 'vue'
import VueRouter from 'vue-router'
import Header from '@/components/header'
import Head from '@/views/coordination/participate/head'
import $store from '@/store/index.js'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'participate',
    components: {
      default: () => import('@/components/common/Participate')
    }
  },
  {
    path: '/event',
    name: 'commentator',
    components: {
      default: () => import('@/views/commentator/index'),
      head: Header
    }
  },
  {
    path: '/coordination',
    name: 'coordination',
    components: {
      default: () => import('@/views/coordination/host/index'),
      head: Header
    },
    beforeEnter: (to, from, next) => {
      if (to.query.id) {
        next()
      } else {
        next({ name: 'commentator' })
      }
    }
  },
  {
    path: '/coordinationParticipate',
    name: 'coordinationParticipate',
    components: {
      default: () => import('@/views/coordination/participate'),
      head: Head
    }
  },
  {
    path: '/leaveParty',
    name: 'leaveParty',
    components: {
      default: () => import('@/views/coordination/common/leaveParty')
    }
  },
  {
    path: '/joinParty',
    name: 'joinParty',
    components: {
      default: () => import('@/views/chooseToJoin/index.vue')
    }
  },
  {
    path: '/remove',
    name: 'remove',
    component: () => import('@/views/Remove/index.vue')
  },
  {
    path: '/usage',
    name: 'usage',
    components: {
      default: () => import('@/views/Usage/index.vue'),
      head: Head
    }
  },
  {
    path: '/preview',
    name: 'preview',
    component: () => import('@/views/Preview/preview.vue')
  },
  {
    path: '/receiverControl',
    name: 'receiverControl',
    component: () => import('@/views/ReceiverControl/receiverControl.vue')
  },
  {
    path: '/newReceiverControl',
    name: 'newReceiverControl',
    component: () => import('@/views/ReceiverControl/newReceiverControl.vue')
  }
]

const router = new VueRouter({
  base: process.env.BASE_URL,
  mode: 'history',
  routes
})

router.beforeEach(async (to, from, next) => {
  const { join_id, id } = to.query
  const partyCode = join_id || id
  const session = window.location.href.split('session=')[1]
    ? window.location.href.split('session=')[1].split('#')[0]
    : ''
  const isAuthenticated = localStorage.getItem('commentatorSession')
  if (session && isAuthenticated != session) {
    localStorage.setItem('commentatorSession', session)
  }
  await $store.dispatch('config')
  await $store.dispatch('userInfo')
  if (to.name === 'participate' || to.name === 'coordinationParticipate') {
    await $store.dispatch('checkEnv', partyCode)
  }
  next()
})

export default router
