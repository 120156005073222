<template>
  <el-dialog
    :title="$t('eventList.advancedSettings')"
    :visible.sync="info.show"
    :close-on-click-modal="false"
    width="720px"
    :modal="false"
    class="thisblack-bgc"
    :class="{noShowGroup:true}"
    @close="closeAdvanced"
  >
  <div class="top">
    <span>{{$t('advancedSettings.mainWindowSetting')}}</span><br/>
    <span>{{$t('advancedSettings.mainWindowSettingNote')}}</span>
    <div class="resolutionAndFramerate">
      <!-- 设置分辨率 -->
      <div class="resolutionContent">
        <span>{{$t('advancedSettings.resolution')}}</span>
        <div class="resolutionContentSelect">
          <el-select v-model="advanceSettingValue.mainWindowResolution"
          :disabled="eventInfo.status == 4 || eventInfo.status == 2 ? true : false"
          popper-class="black" @change="updateBitrate">
            <el-option
              v-for="item in resolutionOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <!-- 设置帧率 -->
      <div class="framerateContent">
        <span>{{$t('advancedSettings.framerate')}}</span>
        <div class="framerateSelect">
          <el-select v-model="advanceSettingValue.mainWindowRate"
          :disabled="eventInfo.status == 4 || eventInfo.status == 2 ? true : false"
          popper-class="black" @change="updateBitrate">
            <el-option
              v-for="item in ratesOptions"
              :key="item.value"
              :label="showDefault(item.value)"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
      </div>
    </div>
    <!-- 设置比特率 -->
    <div class="bitrateContent">
      <div style="width:54px;">{{$t('advancedSettings.bitrate')}}</div>
      <div class="slider">
        <el-slider
          v-model="advanceSettingValue.mainWindowBitrate"
          :min="200"
          :max="10000"
          @change="changeMainWindowBitrate"
          :disabled="eventInfo.status == 4 || eventInfo.status == 2 ? true : false"
          show-input
          :show-input-controls="false"
        >
        </el-slider>
      </div>
      <span class="grayColor">kbps</span>
    </div>
    <!-- 设置 encoder 高低分辨率 -->
    <div class="videoEncoder">
      <div>
        <div class="qualityFeature" v-if="qualityFeature">
          <el-divider class="dividers" v-if="!receiverType"></el-divider>
          <p  v-if="!receiverType" class="encoderTitle">{{ $t('advancedSettings.encoderTitle') }}</p>
          <p  v-if="!receiverType" class="encoderTips">{{ $t('advancedSettings.encoderTips') }}</p>
          <div class="encoderResolution" v-if="!receiverType">
            <span class="resolutionLeft">{{ $t('advancedSettings.resolution') }}</span>
            <el-select
              class="resolutionRight"
              v-model="advanceSettingValue.advancedSetting.quality"
              :disabled="eventInfo.status == 4 || eventInfo.status == 2 ? true : false"
              popper-class="black"
              @change="saveResolution"
            >
              <el-option
                v-for="item in encoderOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <el-divider class="dividers"></el-divider>
        <div style="display: inline-block;">{{$t('advancedSettings.OtherFeatures')}}</div><br/>
        <span v-if="receiverType != '1'" class="OtherFeaturesRequired">{{$t('advancedSettings.required')}}</span>
        <div class="OtherFeaturesRequired" v-else>
          <div>{{$t('advancedSettings.linuxRrequired')}}</div>
          <div>{{$t('advancedSettings.requiredBack')}}</div>
          <div>{{$t('advancedSettings.delayRequired')}}</div>
        </div>
        <div class="bitrateBox">
          <div style="width:54px;">{{$t('eventList.delay')}}</div>
          <div class="slider">
            <el-slider
              v-model="advanceSettingValue.bitrateValue"
              :min="0.6"
              :max="4"
              :step="0.1"
              @change="changeBitInputVal"
              :disabled="!showDelayContent"
              show-input
              :show-input-controls="false"
            >
            </el-slider>
            <!--<el-input class="bitInput" :disabled="eventInfo.status == 4 || eventInfo.status == 2 ? true : false" v-model="inputBitrate" @change="saveBitrate"></el-input>-->
          </div>
          <span class="grayColor">s</span>
          <span class="gray">{{$t('eventList.delayTips')}}</span>
        </div>
      </div>
      <div class="receiverBackupTips" v-show="showDelayMessage">
        <p><i class="el-icon-warning" style="margin-right: 8px;"></i>{{ $t('HostCommon.receiverBackupTips') }}</p>
      </div>

      <div v-show="receiverType != '1'" >
        <el-divider class="dividers" ></el-divider>
        <span>{{$t('advancedSettings.enableFeatures')}}</span><br/>
        <span class="enableFeaturesPleaseSelect">{{$t('advancedSettings.pleaseSelect')}}</span>
      </div>
    </div>
  </div>
  <div class="middles" v-if="receiverType != '1'">

    <div class="rps" v-if="advanceSettings.rps">
      <el-checkbox :disabled="eventInfo.status == 4 || eventInfo.status == 2 ? true : false" v-model="advanceSettingValue.checkedRps" @change="changeRps" :true-label=1 :false-label=0 >{{$t('advancedSettings.rps')}}</el-checkbox>
      <el-tooltip class="item" effect="dark" placement="top">
        <div slot="content">
          <p>{{ $t('advancedSettings.rpsTitle') }}</p>
          <p>1. {{ $t('advancedSettings.rpsOne') }}</p>
          <p>1. {{ $t('advancedSettings.rpsTwo') }}</p>
        </div>
        <i class="iconfont icon-help2" style="color: #909090"></i>
      </el-tooltip>
    </div>

    <div class="outPutBuffer" v-if="advanceSettings.outputDelay">
      <el-checkbox :disabled="eventInfo.status == 4 || eventInfo.status == 2 ? true : false" v-model="advanceSettingValue.checkedOutPutBuffer" @change="changeCheckedOutPutBuffer" :true-label=1 :false-label=0 >{{$t('advancedSettings.outPutBuffer')}}</el-checkbox>
      <el-tooltip class="item" effect="dark" placement="top">
        <div slot="content">
          <p>{{ $t('advancedSettings.outPutBufferTitle') }}</p>
          <p>{{ $t('advancedSettings.outPutBufferOne') }}</p>
          <p style="padding-left:7px;">{{ $t('advancedSettings.outPutBufferSecond') }}</p>
          <p>{{ $t('advancedSettings.outPutBufferThird') }}</p>
          <p style="padding-left:7px;">{{ $t('advancedSettings.outPutBufferFourth') }}</p>
        </div>
        <i class="iconfont icon-help2" style="color: #909090"></i>
      </el-tooltip>
      <div class="silderBox" v-if="advanceSettingValue.checkedOutPutBuffer">
        <div style="margin-right:25px;"><span>{{ $t('coordinationRoom.bufferSize')}}</span></div>
        <el-slider @change="changeOutPutBuffer"  show-input :disabled="eventInfo.status == 4 || eventInfo.status == 2 ? true : false"
                  :show-input-controls="false" v-model="advanceSettingValue.outPutBufferValue" :min="2" :max="300" ></el-slider>
        <span class="grayColor">s</span>
      </div>
    </div>
    <!--阶段三-->
    <div class="inputSourceRecording" v-if="advanceSettings.cloudRecord">
      <el-checkbox :disabled="eventInfo.status == 4 || eventInfo.status == 2 ? true : false" v-model="advanceSettingValue.checkedInputSourceRecording" @change="changeInputSourceRecording"  :true-label=1 :false-label=0 >{{$t('advancedSettings.inputSourceRecording')}}</el-checkbox>
      <el-tooltip class="item" effect="dark" placement="top">
        <div slot="content">
          <p>{{ $t('advancedSettings.inputSourceRecordingTitle') }}</p>
        </div>
        <i class="iconfont icon-help2" style="color: #909090"></i>
      </el-tooltip>

      <div v-if="advanceSettingValue.checkedInputSourceRecording" style="margin-top:12px;">
        <el-select
          :disabled="eventInfo.status == 4 || eventInfo.status == 2 ? true : false"
          v-model="advanceSettingValue.cloudRecordChecked"
          multiple
          class="cloudRecorCheckbox"
          placeholder=""
          @change="closeInput"
          popper-class="cloudRecorCheckboxs"
  	     :popper-append-to-body="false"
        >
         <!--@change="recordSelect"
         v-if="enableList && enableList.cloudRecord && enableList.cloudRecord.value == 1"-->
          <el-option
            v-for="(item, index) in currProgrameSlotNum"
            :key="index"
            :label="$t('advancedSettings.SourceNum', {num: item})"
            :value="`s${item}`"
          >
          </el-option>
        </el-select>
      </div>
    </div>

    <div class="pgmRecording" v-if="advanceSettings.PGMRecording">
      <el-checkbox :disabled="eventInfo.status == 4 || eventInfo.status == 2 ? true : false" v-model="advanceSettingValue.checkedPgmRecording" @change="changePgmRecording" :true-label=1 :false-label=0>{{$t('advancedSettings.pgmRecording')}}</el-checkbox>
      <el-tooltip class="item" effect="dark" placement="top">
        <div slot="content">
          <p>{{ $t('advancedSettings.pgmRecordingTitle') }}</p>
        </div>
        <i class="iconfont icon-help2" style="color: #909090"></i>
      </el-tooltip>
    </div>

    <div class="replayApp" v-if="advanceSettings.replay || advanceSettings.replayApp">
      <el-checkbox :disabled="eventInfo.status == 4 || eventInfo.status == 2 ? true : false" v-model="advanceSettingValue.checkedReplayApp" @change="changeReplayApp" :true-label=1 :false-label=0>{{$t('advancedSettings.replayApp')}}</el-checkbox>
      <el-tooltip class="item" effect="dark" placement="top">
        <div slot="content">
          <p>{{ $t('advancedSettings.replayAppTitle') }}</p>
        </div>
        <i class="iconfont icon-help2" style="color: #909090"></i>
      </el-tooltip>
    </div>

    <div class="allowCommentator" v-show="false">
      <el-checkbox v-model="checkedAllowCommentator">{{$t('advancedSettings.allowCommentator')}}</el-checkbox>
      <el-tooltip class="item" effect="dark" placement="top">
        <div slot="content">
          <p>{{ $t('coordinationRoom.listVersion') }}</p>
          <p>1. {{ $t('coordinationRoom.online') }}</p>
        </div>
        <i class="iconfont icon-help2" style="color: #909090"></i>
      </el-tooltip>
    </div>
  </div>
  <span  slot="title" class="el-dialog__header">
  <i style="font-size:20px;">{{$t('eventList.advancedSettings')}}</i>
  </span>
  <span slot="footer" class="dialog-footer">
    <a v-if="(eventTypeInfo.type === 'view'||eventTypeInfo.type === 'creat')" class="cancel" @click="cancelAdvanced()">{{$t('leftPane.cancel')}}</a>
    <el-button v-if="(eventTypeInfo.type === 'view'||eventTypeInfo.type === 'creat')" class="save" @click="closeAdvanced()">{{$t('leftPaneOutPut.save')}}</el-button>
    <el-button v-if="(eventTypeInfo.type === 'edit')" class="save" @click="closeAdvanced()">{{$t('eventList.doneN')}}</el-button>
  </span>
  </el-dialog>
</template>
<script>
import { mapState } from 'vuex'
export default {
  props: {
    qualityFeature: {
      type: Boolean,
      default () {
        return true
      }
    },
    backupReceiverPeerId: {
      type: String
    },
    receiverBackupBtnVal: {
      type: Boolean,
      default () {
        return false
      }
    },
    receiverType: {
      type: Number,
      default: 0
    },
    eventTypeInfo: {
      type: Object,
      default: () => {
        return {}
      }
    },
    info: {
      type: Object,
      default: () => {
        return {}
      }
    },
    showGroupLink: {
      type: Boolean,
      default () {
        return false
      }
    },
    eventInfo: {
      type: Object,
      default: () => {
        return {}
      }
    },
    advanceSettings: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  computed: {
    ...mapState({
      State: (state) => state
    }),
    // 1 是物理r 0是pp
    // (eventInfo.status == 4 || eventInfo.status == 2 ? true : false)
    // receiverBackupBtnVal 是否勾选备用
    showDelayContent () {
      let showDelayContent = false
      if (!this.backupReceiverPeerId && !this.State.mainReceiverAbleDely && this.receiverType == 1) {
        showDelayContent = false
      } else if (this.eventInfo.status && (this.eventInfo.status == 4 || this.eventInfo.status == 2)) {
        showDelayContent = false
      } else {
        showDelayContent = true
      }
      return showDelayContent
    },
    showDelayMessage () {
      let showDelayMes = true
      if (this.receiverType == 1 && (this.backupReceiverPeerId && this.State.mainReceiverAbleDely && this.State.backReceiverAbleDely)) {
        showDelayMes = false
      } else if (this.receiverType == 1 && !this.backupReceiverPeerId && this.State.mainReceiverAbleDely) {
        showDelayMes = false
      } else if (this.receiverType != '1') {
        showDelayMes = false
      } else {
        showDelayMes = true
      }
      return showDelayMes
    }
  },
  data () {
    return {
      resolution: '360p',
      encoderOptions: [{
        value: '360p',
	      label: '640x360 (360p)'
      }, {
	      value: '1080p',
	      label: '1920x1080 (1080p)'
      }],
      resolutionOptions: [{
	      value: '640x360',
	      label: '640x360'
      }, {
	      value: '848x480',
	      label: '848x480'
      }, {
	      value: '1280x720',
	      label: '1280x720'
      }, {
	      value: '1920x1080',
	      label: '1920x1080'
      }],
      ratesOptions: [
        {
          value: '15',
          label: '15'
        }, {
          value: '30',
          label: '30 (default)'
        }, {
          value: '60',
          label: '60'
      	}
      ],
      currProgrameSlotNum: 0,
      advanceSettingValue: {
        bitrateValue: 2,
        mainWindowBitrate: 2000,
        mainWindowResolution: '1280x720',
        mainWindowRate: '30',
        checkedRps: 0,
        checkedPgmRecording: 1,
        checkedReplayApp: 0,
        checkedOutPutBuffer: 0,
        outPutBufferValue: 2,
        checkedInputSourceRecording: 0,
        cloudRecordChecked: [],
        advancedSetting: {
          quality: '360p'
        }
      },
      checkedAllowCommentator: 0
    }
  },
  created () {
    this.getData()
  },
  methods: {
    saveResolution (val) {
      this.advanceSettingValue.advancedSetting.quality = val
      this.State.advanceSettingInfo.advancedSetting.quality = val
      this.queryConfig()
    },
    // 获取设置数据信息
    getData () {
      this.advanceSettingValue = Object.assign({}, this.advanceSettingValue, this.State.advanceSettingInfo)
      if (this.advanceSettingValue.checkedInputSourceRecording == 1) {
        this.changeSlotNumnumber()
      }
    },
    // 初始化数据
    initData () {
      this.State.advanceSettingInfo = {
        bitrateValue: 2,
        mainWindowBitrate: 2000,
        mainWindowResolution: '1280x720',
        mainWindowRate: '30',
        checkedRps: 0,
        checkedPgmRecording: 1,
        checkedReplayApp: 0,
        checkedOutPutBuffer: 0,
        outPutBufferValue: 2,
        checkedInputSourceRecording: 0,
        cloudRecordChecked: [],
        advancedSetting: {
          quality: '360p'
        }
      }
      if (this.advanceSettingValue.checkedInputSourceRecording == 1) {
        this.changeSlotNumnumber()
      }
    },
    showDefault (value) {
      if (value == '30') {
        return this.$t('advancedSettings.defaultBitrate')
      } else {
        return value
      }
    },
    // 根据选中的分辨率值来更新比特率值
    updateBitrate () {
      // 根据选中的分辨率值和帧率来更新比特率值
      const bitrates = new Map([
        ['640x360-15', 800],
        ['640x360-30', 800],
        ['640x360-60', 1000],
        ['848x480-15', 610],
        ['848x480-30', 930],
        ['848x480-60', 930],
        ['1280x720-15', 1130],
        ['1280x720-30', 2000],
        ['1280x720-60', 3000],
        ['1920x1080-15', 2080],
        ['1920x1080-30', 3150],
        ['1920x1080-60', 4780]
      ]);
      const key = `${this.advanceSettingValue.mainWindowResolution}-${this.advanceSettingValue.mainWindowRate}`;
      this.advanceSettingValue.mainWindowBitrate = bitrates.get(key);
      this.queryConfig()
      this.State.advanceSettingInfo.mainWindowBitrate = this.advanceSettingValue.mainWindowBitrate
      this.State.advanceSettingInfo.mainWindowRate = this.advanceSettingValue.mainWindowRate
      this.State.advanceSettingInfo.mainWindowResolution = this.advanceSettingValue.mainWindowResolution
    },
    changeMainWindowBitrate (val) {
      this.advanceSettingValue.mainWindowBitrate = Number(val)
      this.queryConfig()
      this.State.advanceSettingInfo.mainWindowBitrate = this.advanceSettingValue.mainWindowBitrate
    },
    changeBitInputVal (val) {
      this.advanceSettingValue.bitrateValue = Number(val)
      this.queryConfig()
      this.State.advanceSettingInfo.bitrateValue = this.advanceSettingValue.bitrateValue
    },
    // 取消数据关闭弹窗
    cancelAdvanced () {
      this.info.show = false
      this.$emit('showFoot', '', 'advancedSettings')
      this.initData()
    },
    // 保存数据关闭弹窗
    closeAdvanced () {
      this.info.show = false
      this.$emit('showFoot', '', 'advancedSettings')
    },
    // 传参
    queryConfig () {
      const dataToSend = {
        checkedRps: this.advanceSettingValue.checkedRps,
        checkedPgmRecording: this.advanceSettingValue.checkedPgmRecording,
        checkedReplayApp: this.advanceSettingValue.checkedReplayApp,
        outPutBufferValue: this.advanceSettingValue.outPutBufferValue,
        checkedOutPutBuffer: this.advanceSettingValue.checkedOutPutBuffer,
        checkedInputSourceRecording: this.advanceSettingValue.checkedInputSourceRecording,
        cloudRecordChecked: this.advanceSettingValue.cloudRecordChecked,
        bitrateValue: this.advanceSettingValue.bitrateValue,
        mainWindowBitrate: this.advanceSettingValue.mainWindowBitrate,
        mainWindowResolution: this.advanceSettingValue.mainWindowResolution,
        mainWindowRate: this.advanceSettingValue.mainWindowRate
      }
      // 只有当qualityFeature为true时，才添加quality键值对
      if (this.qualityFeature) {
        dataToSend.quality = this.advanceSettingValue.advancedSetting.quality;
      }
      this.$emit('changeConfig', dataToSend);

      // this.$emit('changeConfig', {
      //   checkedRps: this.advanceSettingValue.checkedRps,
      //   checkedPgmRecording: this.advanceSettingValue.checkedPgmRecording,
      //   checkedReplayApp: this.advanceSettingValue.checkedReplayApp,
      //   outPutBufferValue: this.advanceSettingValue.outPutBufferValue,
      //   checkedOutPutBuffer: this.advanceSettingValue.checkedOutPutBuffer,
      //   checkedInputSourceRecording: this.advanceSettingValue.checkedInputSourceRecording,
      //   cloudRecordChecked: this.advanceSettingValue.cloudRecordChecked,
      //   bitrateValue: this.advanceSettingValue.bitrateValue,
      //   mainWindowBitrate: this.advanceSettingValue.mainWindowBitrate,
      //   mainWindowResolution: this.advanceSettingValue.mainWindowResolution,
      //   mainWindowRate: this.advanceSettingValue.mainWindowRate,
      //   quality: this.advanceSettingValue.advancedSetting.quality
      // })
    },
    // 无选项关闭input
    closeInput (value) {
      if (value && value.length == 0) {
        this.advanceSettingValue.checkedInputSourceRecording = 0
      }
      this.State.advanceSettingInfo.cloudRecordChecked = value
      this.queryConfig()
    },
    changeRps () {
      this.State.advanceSettingInfo.checkedRps = this.advanceSettingValue.checkedRps
      this.queryConfig()
      this.changeSlotNumnumber()
      this.advanceSettingValue.cloudRecordChecked = ['s1']
      this.State.advanceSettingInfo.cloudRecordChecked = this.advanceSettingValue.cloudRecordChecked
    },
    changeCheckedOutPutBuffer () {
      this.State.advanceSettingInfo.checkedOutPutBuffer = this.advanceSettingValue.checkedOutPutBuffer
      this.queryConfig()
    },
    changeOutPutBuffer () {
      this.State.advanceSettingInfo.outPutBufferValue = this.advanceSettingValue.outPutBufferValue
      this.queryConfig()
    },
    // 获取slot数量
    changeSlotNumnumber (init) {
      const params = {
        rps: this.advanceSettingValue.checkedRps
      }
      this.$http
        .post(`https://${this.State.config.urlInfo.gateway}/newppapi/user/getInputSourceNum`, params)
        .then((res) => {
          if (res.data.errorCode == '0x0') {
            this.currProgrameSlotNum = res.data.result
          } else {
            this.$message.error(res.data.errorInfo)
          }
        })
    },
    // 阶段三(switch)
    changeInputSourceRecording (value) {
      this.State.advanceSettingInfo.checkedInputSourceRecording = this.advanceSettingValue.checkedInputSourceRecording
      this.queryConfig()
      this.changeSlotNumnumber()
      this.advanceSettingValue.cloudRecordChecked = ['s1']
      this.State.advanceSettingInfo.cloudRecordChecked = this.advanceSettingValue.cloudRecordChecked
    },
    changePgmRecording () {
      this.State.advanceSettingInfo.checkedPgmRecording = this.advanceSettingValue.checkedPgmRecording
      this.queryConfig()
    },
    changeReplayApp () {
      this.State.advanceSettingInfo.checkedReplayApp = this.advanceSettingValue.checkedReplayApp
      this.queryConfig()
    }
  }
}
</script>
<style lang="less" scoped>
/deep/.el-dialog {
 .el-icon-close{
  display:none;
 }
  .el-dialog__header{
    padding:32px 20px 10px 19px;
    }
  .dialog-footer {
    a {
      color: #999;
      margin-left: 30px;
      cursor: pointer;
    }
    .save {
      font-size:14px !important;
      color: #fff;
      padding: 3px 17px 5px;
      background: #03897b;
      border-radius: 5px;
      cursor: pointer;
    }
    .el-button.save {
      border: none;
      padding: 8px 8px;
      margin-left: 30px;
    }
  }
  .el-dialog__footer{
    height:60px;
  }
  background: #28282e;
  .el-dialog__title {
    color: #fff;
  }
  .el-button {
    background: #03897b;
    border: #03897b;
    color: #999;
    font-size: 16px;
  }
  .el-button--primary {
    color: #fff;
  }
  .el-icon-close {
    color: #fff;
  }
  .el-dialog__body {
    color: #fff;
    font-size: 16px;
    padding: 30px 40px;
    // max-height:540px !important;
    .top{
      .dividers{
        border: 0px solid #333339;
        background-color:#333339 !important;
      }
      .encoderTips {
        font-size: 14px;
        color: #909090;
        margin-top: 8px;
        margin-bottom: 16px;
        word-break: break-word;
      }
      .encoderResolution {
        margin-top: 30px;
        .resolutionLeft {
          color: #f1f1f1;
          margin-right: 10px;
        }
        .resolutionRight {
          .el-input__inner {
            background: #333339;
          }
        }
      }
      margin-top:-5px;
      .resolutionAndFramerate {
        display: flex;
        justify-content: space-between;
        margin-top: 30px;
        .resolutionContent,.framerateContent{
            display: flex;
            align-items: center;
          .resolutionContentSelect,.framerateSelect{
            display: inline-block;
            width: 220px;
            margin-left: 10px;
            position: relative;
             .el-select {
                display: block;
                width:100%;
                .el-input--suffix{
                  width: 220px;
                }
                .el-input {
                   width: 100%;
                  .el-input__inner {
                    background-color: #333339;
                }
               }
              .el-select-dropdown {
                position: absolute !important;
                top: 38px !important;
                left: 0 !important;
              }
            }
          }
        }
      }
      .bitrateBox,.bitrateContent {
        border-radius: 8px;
        margin-top:30px;
        height: 32px;
        line-height: 32px;
        display: flex;
        .slider{
          margin-left: 8px;
        }
        .el-slider {
          width: 158px;
          display: inline-block;
          margin-right: 12px;
          .el-slider__runway{
            background-color: #585B5D !important;
            width:250px;
          }
          .el-slider__bar {
            background:#03897B;
          }
          .el-slider__button {
            border: 0;
            background: #03897B;
          }
        }
        .el-input__inner {
          padding: 0 3px 0 8px !important;
          margin-left: 240px;
          width: 52px;
          background-color:#353535 !important;
          border-radius: 4px 0 0 4px;
        }
      }
      .receiverBackupTips{
        padding: 12px 16px;
        color: orange;
        background: rgb(255 243 219 / 10%);
        margin: 0 auto;
        font-size: 14px;
        border-radius: 4px;
        margin-top:30px;
        p {
          display: flex;
          align-items: baseline;
          word-break: keep-all;
          text-align: justify;
        }
      }
     span:nth-child(3){
        font-size: 14px !important;
        color: #909090 !important;
        word-break: break-word;
      }
      .enableFeaturesPleaseSelect{
        font-size: 14px !important;
        color: #909090 !important;
      }
      .OtherFeaturesRequired{
        font-size: 14px !important;
        color: #909090 !important;
        margin-left: 7px;
        div{
          word-break: keep-all;
        }
      }
      .grayColor {
        height:32px;
        line-height:30px;
        color: #666666;
        font-size: 12px;
        margin-top:3px;
        background: #353535;
        margin-left: 150px;
        border-radius: 0 4px 4px 0;
        padding-right: 8px;
      }
      .gray {
        color: #6d6d6d;
        font-size: 12px;
        width: 262px;
        margin-top:4px;
        margin-left:13px;
        height: 41px;
        line-height:15px;
        word-break: break-word;
      }
    }
    .middles{
      float: left;
      margin-left: 8px;
      margin-top: 16px;
      .outPutBuffer{
        margin-top:12px;
        .silderBox {
          width: 604px;
          border-radius: 8px;
          padding-top: 20px;
          padding-bottom: 20px;
          background: rgba(0, 0, 0, 0.24);
          margin-top:12px;
          padding-left:22px;
          height: 32px;
          line-height: 32px;
          display: flex;
          align-items: center;
          .el-slider {
            width: 158px;
            display: inline-block;
            margin-right: 12px;
            .el-slider__runway{
              background-color: #585B5D !important;
              width:375px;
            }
            .el-slider__bar {
              background:#03897B;
            }
            .el-slider__button {
              border: 0;
              background: #03897B;
            }
          }
         .el-input__inner {
            padding: 0 3px 0 8px !important;
            margin-left: 382px;
            width: 52px;
            background-color:#353535 !important;
            border-radius: 4px 0 0 4px;
         }
          .grayColor {
            height:32px;
            line-height:30px;
            color: #666666;
            font-size: 12px;
            background: #353535;
            margin-left: 292px;
            border-radius: 0 4px 4px 0;
            padding-right: 8px;
          }
        }
      }
     .cloudRecorCheckboxs .el-select-dropdown__item.selected{
        color:#03897B !important;
       background-color:#333339 !important;

      }
      .el-popper[x-placement^=bottom] .popper__arrow{
        border-bottom-color:#333339 !important;
      }
       .el-popper[x-placement^=bottom] .popper__arrow::after{
        border-bottom-color:#333339 !important;
      }
     .cloudRecorCheckboxs{
        background: #333339;
        border: 1px solid rgb(51, 51, 57);
        .el-select-dropdown__item{
        color:#F1F1F1 !important;
      }
      .el-select-dropdown__item.hover, .el-select-dropdown__item:hover{
        background-color: rgba(0, 0, 0, 0.1) !important;
      }

     }
      .inputSourceRecording{
        margin-top:12px;
        .cloudRecorCheckbox {
          border-radius: 8px;
          .el-tag.el-tag--info .el-tag__close{
            color:#909090 !important;
          }
          .el-input{
          width:626px;
          }
          .el-tag.el-tag--info{
          border: 1px solid #333339;
          background-color: #f4f4f5;
          color: #909090;
          background: rgba(0, 0, 0, 0.24) !important;
          }
          .el-tag.el-tag--info .el-tag__close:hover{
            background-color:aliceblue;
          }
          .el-input--suffix .el-input__inner {
            background: rgba(0, 0, 0, 0.24) !important;
          }
          ::v-deep.el-input__inner {
            // height:72px !important;
            background: #f6f7fa;
            border: none;
          }
      }
      }
      .pgmRecording{
        margin-top:12px;
      }
      .replayApp{
        margin-top:12px;
      }
      .allowCommentator{
        margin-top:40px;
      }
      .el-checkbox {
          color:#fff !important;
          &.is-checked {
            .el-checkbox__label {
              color: #03897b;
            }
          }
          &.is-disabled {
            .el-checkbox__label {
              color: #909090;
            }
          }
          .el-checkbox__label {
            padding-left: 5px;
          }
          .el-checkbox__input {
            &.is-checked {
              .el-checkbox__inner {
                background: #03897b;
                border: 2px solid #03897b;
                &::after {
                  left: 3px;
                }
              }
            }
            &.is-disabled {
              .el-checkbox__inner {
                background: #909090;
                border: 2px solid #909090;
                &::after {
                  left: 3px;
                }
              }
            }
            .el-checkbox__inner {
              background: none;
              border: 2px solid #666;
            }
          }
        }
      .item{
        margin-left:3px;
      }
    }
  }
}

</style>
