<template>
  <el-dialog
    :title="$t('eventList.stopEvent')"
    :visible.sync="info.show"
    :show-close="false"
    :lock-scroll="false"
    width="550px"
  >
    <div class="content">
      <p>
        {{$t('eventList.theEvent')}} {{ info.status == 4 ? $t('eventList.onGoing') : $t('eventList.isPreparing') }},
        {{$t('eventList.allProgramsShutDown')}}
      </p>
      <p>{{$t('eventList.stopTips')}}</p>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button class="cancel" @click="info.show = false">{{$t('leftPane.cancel')}}</el-button>
      <el-button type="primary" @click="stop">{{$t('eventList.stop')}}</el-button>
    </span>
  </el-dialog>
</template>
<script>
export default {
  props: {
    info: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {
      show: true
    }
  },
  methods: {
    stop () {
      try {
        this.$http
          .post(`/commentator-backend/event/v2/stop/${this.info.id}`)
          .then((res) => {
            if (res.data.errorCode == '0x0') {
              this.$message.success(this.$t('eventList.stopTipSuccess'))
              this.info.show = false
              this.$emit('callback', { type: 'stop' })
            // 调用父组件的websocket的方法
            // this.$parent.sendWebsocketMessage(true);
            }
          })
      } catch (error) {
        console.log(error)
      }
    }
  }
}
</script>
<style lang="less" scoped>
/deep/.el-dialog {
  background: #28282e;
  .el-dialog__title {
    color: #fff;
  }
  .el-button {
    background: none;
    border: none;
    color: #999;
    font-size: 16px;
  }
  .el-button--primary {
    color: #03897b;
  }
}
.content {
  p {
    margin-bottom: 10px;
    color: #b4b4b4;
  }
}
</style>
