<template>
  <li
    class="fileItem"
    :class="{ activeLine: data.selected }"
    @click.self="selectSource"
  >
    <span
      class="overflow"
      :title="data.name || data.nickName"
      @click="selectSource"
      v-if="!editFlag"
      >{{ data.name || data.nickName }}</span
    >
    <el-input
      v-else
      ref="inputObj"
      autofocus
      @blur="editFlag = false"
      v-model="name"
      @keyup.enter.native="editName"
      maxlength="32"
    />
    <el-popover
      placement="bottom-start"
      title=""
      popper-class="inputOperate"
      width="150"
      ref="operatePopover"
      trigger="click"
    >
      <div class="btn" @click.stop="switchStatus" v-if="showEditMore">
        {{$t('leftPane.rename')}}
      </div>
      <div class="btn" v-else>{{$t('leftPane.more')}}</div>
      <div class="btn" @click.stop="checkDelete">{{$t('leftPane.del')}}</div>
      <span class="operate" slot="reference"><i class="el-icon-more"></i></span>
    </el-popover>
  </li>
</template>
<script>
import Bus from '@/assets/js/bus.js'
export default {
  props: {
    data: Object,
    active: {
      default: false,
      type: Boolean
    },
    // 控制more的显示
    showEditMore: {
      default: true,
      type: Boolean
    }
  },
  data () {
    return {
      editFlag: false,
      operateFlag: false,
      name: '',
      fileId: ''
    }
  },
  created () {
    this.name = this.data.name
  },
  methods: {
    selectSource () {
      this.fileId = this.data.id
      this.$emit('selectSource', this.data)
    },
    editName () {
      this.editFlag = false
      // 截取后缀，看文件格式是否符合要求
      const reg = /^.+\.mp4|.mov$/i
      if (!reg.test(this.name)) {
        this.name = this.data.name
        this.$message.info('Incorrect file format, support MOV, MP4 file')
        return
      }
      const params = {
        id: this.data.id,
        newName: this.name
      }
      this.$http
        .post('/file/rename', params, { requestBase: 'GATEWAY' })
        .then((res) => {
          if (res.data.errorCode == '0x0') {
            this.upDataStepTwo()
          } else {
            this.name = this.data.name
          }
        })
    },
    upDataStepTwo () {
      this.$http
        .post(`/commentator-backend/user/file/v2/rename/${this.data.id}`, {
          name: this.name
        })
        .then((res) => {
          if (res.data.errorCode == '0x0') {
            this.$message.success(this.$t('leftPane.renameFileSuccess'))
            Bus.$emit('refreshFileList')
          }
        })
    },
    switchStatus () {
      this.editFlag = true
      this.operateFlag = false
      this.$nextTick(() => {
        this.$refs.inputObj.focus()
      })
    },
    checkDelete () {
      this.$http
        .post(`/commentator-backend/user/file/v2/check/${this.data.id}`)
        .then((res) => {
          if (res.data.errorCode == '0x0') {
            this.deleteFile()
          } else {
            // this.$message.info(
            //   'The current file is used and cannot be deleted'
            // )
          }
        })
    },
    deleteFile () {
      const params = {
        id: this.data.id
      }
      this.$http
        .post('/file/delete', params, { requestBase: 'GATEWAY' })
        .then((res) => {
          if (res.data.errorCode == '0x0') {
            this.$message.success(this.$t('eventList.delTips'))
            Bus.$emit('refreshFileList')
            this.operateFlag = false
          }
        })
    }
  }
}
</script>
<style lang="less" scoped>
.fileItem {
  line-height: 50px;
  color: #fff;
  padding: 0 10px;
  text-align: left;
  cursor: pointer;
  .operate {
    float: right;
  }
  .overflow {
    position: relative;
    z-index: 1;
    vertical-align: middle;
    font-size: 14px;
    max-width: 230px;
    display: inline-block;
  }
  .editIcon {
    cursor: pointer;
    display: none;
    font-size: 25px;
    position: absolute;
    top: -4px;
    right: 20px;
    z-index: 1;
  }
  .operateBox {
    position: absolute;
    line-height: 30px;
    width: 100px;
    background: #28282e;
    box-shadow: -2px 4px 12px #000;
    right: 0;
    top: 35px;
    z-index: 3;
    span {
      display: block;
      width: 100%;
      padding-left: 5px;
      // &:hover{
      //     background: #010101;
      // }
    }
  }
  &.activeLine {
    background-color: #000000;
    // background-color: #496b66 !important;
    // padding: 2px;
    // background-image: -webkit-linear-gradient(-45deg,#03897b 25%,rgba(3, 137, 123, 0) 25%,rgba(3, 137, 123, 0) 50%,#03897b 50%,#03897b 75%,rgba(3, 137, 123, 0) 75%,rgba(3, 137, 123, 0));
    // background-size: 10px 10px;
    // animation: activeLine 0.8s linear infinite;
    // box-sizing: content-box;
    // position: relative;
    // .el-input{
    //     z-index: 1;
    // }
    // &:after {
    //     position: absolute;
    //     top: 2px;
    //     left: 2px;
    //     bottom: 2px;
    //     right: 2px;
    //     display: block;
    //     content: '';
    //     background-color:#496b66;
    // }
    // span {
    //     left: 10px;
    // }
  }
  &:hover {
    background: #010101;
    .editIcon {
      display: block;
    }
  }
  /deep/.el-input {
    width: 250px;
    .el-input__inner {
      background: none;
      border: none;
      color: #fff;
      padding-left: 0;
    }
  }
}
// @keyframes activeLine {
//   0% {
//     background-position: 0px 0px;
//   }
//   50% {
//     background-position: 0px 5px;
//   }
//   100% {
//     background-position: 0px 10px;
//   }
// }
</style>
