<template>
  <div class="mainwindow-info" :style="{minHeight:(this.State.BoomMainWindows)}">
    <el-button class="edit" @click="editEvent">
      <img src="@/assets/img/Edit.png"/>
      <span>{{$t('leftPaneOutPut.edit')}}</span>
    </el-button>
    <ul class="envInfo">
      <li class="nameTitle">
        <span class="infoName">{{$t('coordinationRoom.eventNameInfo')}}:</span>
        {{ eventInfo.title }}
      </li>
      <li>
        <span class="infoName">{{$t('coordinationRoom.eventTimeInfo')}}:</span>
        {{ eventInfo.startTime | FormatTime('YYYY-MM-DD HH:mm') }} -
        {{ eventInfo.endTime | FormatTime('YYYY-MM-DD HH:mm') }}
        <el-radio v-model="radio" :label="true" :class="addClass">
        {{statusInfo[eventInfo.status].text | showEventStatus}}
        </el-radio>
      </li>
      <li>
        <span class="infoName">{{$t('coordinationRoom.groupNumInfo')}}:</span>
        {{ eventInfo.eventParties ? eventInfo.eventParties.length : 0 }}
      </li>
      <!-- 分割线 -->
      <el-divider></el-divider>
      <li class="inputBox" v-show="eventInfo.rid && !eventInfo.rid.includes('ffff')">
        <span>{{ $t('coordinationRoom.eventInfoInput') }}:</span>
        <span>{{ eventInfo.inputList[0].value ? eventInfo.inputList[0].value.name : '' }} / {{ eventInfo.receiverName }}</span>
      </li>
      <li class="outputBox" v-show="eventInfo.rid && !eventInfo.rid.includes('ffff')">
        <span>{{ $t('coordinationRoom.eventInfoOutPut') }}:</span>
        <span>{{ eventInfo.inputList[0].value ? eventInfo.inputList[0].value.name : '' }} / {{ eventInfo.receiverName }}</span>
      </li>
      <li class="input_backup" style="margin-left:-10px;">
        <span v-for="(val,index) in eventInfo.inputList || []" :key="index+'1'" v-show="eventInfo.rid && eventInfo.rid.includes('ffff') &&val.purpose == 1">
          <span class="infoName">{{$t('coordinationRoom.eventInfoInput')}}:</span>
          <span class="four_txt">{{ eventInfo.inputList | sourceName(val.purpose) }}</span>
        </span>
        <span v-for="(val,index) in eventInfo.inputList || []" :key="index" v-show="eventInfo.rid && eventInfo.rid.includes('ffff')&&val.purpose == 2">
          <span class="infoName">{{$t('coordinationRoom.backUpInput')}}:</span>
          <span class="four_txt">{{ eventInfo.inputList | sourceName(val.purpose) }}</span>
        </span>
      </li>
      <!-- pp Receiver backup input select -->
      <li v-if="eventInfo.inputList.length == 2"   v-show="eventInfo.rid && eventInfo.rid.includes('ffff')">
        <span class="infoName">{{$t('coordinationRoom.inputSelectInfo')}}:</span>
        <el-button class="back" @click="setInputSource(1)" :class="{ switch: activeInputType === 1}">
          {{$t('coordinationRoom.mainIn')}}
        </el-button>
        <el-button class="back" @click="setInputSource(2)" :class="{ switch: activeInputType === 2}">
          {{$t('coordinationRoom.eventBackUpIn')}}
        </el-button>
        <span class="apply" :class="{showgray: activeApply}" @click="switchMainWindow('')">{{$t('eventList.apply')}}</span>
      </li>
      <!-- 物理 Receiver backup input select -->
      <li v-if="eventInfo.previewSetting.backupPgm">
        <span class="infoName">{{$t('coordinationRoom.inputSelectInfo')}}:</span>
        <el-button class="back" @click="setInputSource(1)" :class="{ switch: activeInputType === 1}">
          {{$t('coordinationRoom.mainIn')}}
        </el-button>
        <el-button class="back" @click="setInputSource(2)" :class="{ switch: activeInputType === 2}">
          {{$t('coordinationRoom.eventBackUpIn')}}
        </el-button>
        <span class="apply" :class="{showgray: activeApply}" @click="switchMainWindow('sendPlatform')">{{$t('eventList.apply')}}</span>
      </li>
    </ul>

    <!-- 分割线 -->
    <el-divider v-show="eventInfo.rid && eventInfo.rid.includes('ffff')"></el-divider>

  <div class="outPutList">
    <div class="outPutName" v-show="eventInfo.rid && eventInfo.rid.includes('ffff')">{{$t('coordinationRoom.eventInfoOutPut')}}:</div>
    <div class="list scrollbar" v-show="eventInfo.rid && eventInfo.rid.includes('ffff')">
      <ul class="outPut">
       <!-- 用户没有选择output项，显示默认的grid推流链接 -->
        <li  v-for="(val, index) in outputList" :key="val.id">
          <div class="outPutListInfo">
            <span class="num">{{index+1}}</span>
            <el-tooltip class="item" effect="dark" placement="top" :content="val.name || val.id">
              <span class="name">{{ val.name || val.id }}</span>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" :content="val.url" placement="top">
              <span class="link">{{ val.url }}</span>
            </el-tooltip>
          </div>
            <el-dropdown
            placement="bottom-start"
            trigger="click"
            v-if="val.url"
          >
            <span class="opreation">
              <i class="el-icon-more" slot="reference"></i>
            </span>
            <el-dropdown-menu
              slot="dropdown"
              class="operate-Coodination-Select"
            >
              <el-dropdown-item >
                <span
                  class="link"
                  v-clipboard:copy="copyLink(val.url)"
                  v-clipboard:success="onCopy"
                  v-clipboard:error="onError"
                  >{{$t('eventList.copyLink')}}</span
                ></el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
        </li>
      </ul>
    </div>
    </div>
    <CreateEvent @updateEndTime="updateEndTime" v-if="event.show" :info="event" @closeDialog="closeDialog"/>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import CreateEvent from '@/views/commentator/dialog/createEvent'
import i18n from '@/lang/index.js'
import Bus from '@/assets/js/bus.js'
export default {
  props: {
    eventInfo: Object
  },
  components: {
    CreateEvent
  },
  computed: {
    ...mapState({
      State: (state) => state,
      statusInfo: (state) => state.statusInfo
    }),
    addClass () {
      if (!this.eventInfo.status || !this.eventInfo) return ''
      if (this.eventInfo.status == 7) {
        return 'color_failed'
      } else if (this.eventInfo.status == 4) {
        return 'color_progress'
      } else if (this.eventInfo.status == 8 || this.eventInfo.status == 10) {
        return 'color_done'
      } else {
        return ''
      }
    }
  },
  data () {
    return {
      endTime: '',
      radio: true,
      eventId: '',
      outputList: [],
      viewSetting: {
        show: false,
        id: '',
        type: '',
        auth: '',
        origin: ''
      },
      event: {
        show: false,
        id: '',
        type: ''
      },
      activeApply: false,
      activeInputType: 1,
      isChangeHeight: false
    }
  },
  created () {
    this.activeInputType = this.eventInfo.mainWindowType
    const receiverList = this.eventInfo.receivers || []
    const ipoutList = this.eventInfo.templates || []
    this.outputList = receiverList.concat(ipoutList)
    Bus.$off('changeMainWindowHeight')
    Bus.$on('changeMainWindowHeight', () => {
      this.isChangeHeight = true
    })
    Bus.$off('cancelChange')
    Bus.$on('cancelChange', () => {
      if (this.isChangeHeight) {
        this.isChangeHeight = false
      }
    })
  },
  watch: {
    // 侦听output项的变化，响应式更新mainWindowInfo里面的output数据
    'eventInfo.templates': {
      handler (newValue) {
        const receiverList = this.eventInfo.receivers || []
        const ipoutList = this.eventInfo.templates || []
        this.outputList = receiverList.concat(ipoutList)
      }
    },
    'eventInfo.receivers': {
      handler (newValue) {
        const receiverList = this.eventInfo.receivers || []
        const ipoutList = this.eventInfo.templates || []
        this.outputList = receiverList.concat(ipoutList)
      }
    },
    'eventInfo.mainWindowType': {
      handler (newValue) {
        this.activeInputType = newValue
      }
    }
  },
  methods: {
    updateEndTime (endTime) {
      this.eventInfo.endTime = endTime
    },
    setInputSource (val) {
      if (this.activeInputType === val) {
        return
      }
      this.activeInputType = val
      if (this.eventInfo.mainWindowType === val) {
        this.activeApply = false
      } else {
        this.activeApply = true
      }
    },
    switchMainWindow (sendPlatform) {
      if (!this.activeApply) {
        return
      }
      const url = sendPlatform ? `/commentator-backend/event/v2/input/switch/${this.eventInfo.id}/${this.activeInputType}?clientId=${this.State.clientId}&platform=1` : `/commentator-backend/event/v2/input/switch/${this.eventInfo.id}/${this.activeInputType}?clientId=${this.State.clientId}`
      this.$http
        .post(url)
        .then((res) => {
          if (res.data.errorCode == '0x0') {
            this.$emit('callback', this.activeInputType)
            this.activeApply = false
            this.$message.success(this.$t('eventList.switchInputSuccess'))
          }
        })
    },
    editEvent () {
      if (
        this.eventInfo.status == 7 ||
        this.eventInfo.status == 8 ||
        this.eventInfo.status == 10
      ) {
        this.event.type = 'view'
      } else {
        this.event.type = 'edit'
      }
      this.event.show = true
      this.event.id = this.eventInfo.id
    },
    viewSettingFn (val) {
      if (this.eventInfo.status && this.eventInfo.status >= 4) {
        this.viewSetting.auth = 'view'
      } else {
        this.viewSetting.auth = 'edit'
      }
      this.viewSetting.show = true
      this.viewSetting.id = val.id
      this.viewSetting.type = val.type
      this.viewSetting.origin = 'commentator'
      this.viewSetting.eventId = this.eventId
    },
    closeGridSettingDialog () {
      this.viewSetting.show = false
    },
    copyLink (val) {
      return val
    },
    onCopy () {
      this.$message({
        message: this.$t('otherWords.copySuccess'),
        type: 'success'
      })
    },
    onError () {
      this.$message({
        message: 'Copy failed',
        type: 'error'
      })
    },
    closeDialog () {
      this.event.show = false
    }
  },
  filters: {
    sourceName (data, type) {
      let name = null
      data = data || []
      data.forEach((val) => {
        if (val.purpose == type) {
          if (val.type == 'pgm') {
            name = 'PGM'
          } else {
            name = val.value ? val.value.name : null
          }
        }
      })
      return name
    },
    showEventStatus (name) {
      if (!name) return ''
      if (name === 'All') {
        return i18n.t('leftPaneOutPut.all')
      } else if (name === 'To Do') {
        return i18n.t('leftPaneOutPut.todo')
      } else if (name === 'Preparing') {
        return i18n.t('leftPaneOutPut.preparing')
      } else if (name === 'In progress') {
        return i18n.t('leftPaneOutPut.inprogress')
      } else if (name === 'Stopped') {
        return i18n.t('leftPaneOutPut.stop')
      } else if (name === 'Failed') {
        return i18n.t('leftPaneOutPut.failed')
      } else if (name === 'Done') {
        return i18n.t('leftPaneOutPut.done')
      } else if (name === 'Preparing(5 min)') {
        return i18n.t('leftPaneOutPut.preparingFiveMin')
      } else {
        return name
      }
    }
  },
  beforeDestroy () {
    Bus.$off('changeMainWindowHeight')
    Bus.$off('cancelChange')
  }
}
</script>

<style lang="less" scoped>
.mainwindow-info {
  margin-top:8px;
  background-color: #18181c;
  border-radius: 4px;
  &.tripleContentLayout {
    // height: 23%;
    // margin-top:10px;
  }
  /deep/ .edit {
    float: right;
    margin-right: 16px;
    margin-top: 12px;
    width: 79px;
    height: 32px;
    line-height: 32px;
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 4px;
    padding: 0;
    font-size: 14px;
    font-weight: bold;
    border: none;
    img {
      float: left;
      display: block;
      margin-left: 17px;
      margin-top: 7px;
      margin-right: 4px;
    }
    span {
      float: left;
      display: block;
      color: #03897B;
    }
  }
  .envInfo {
    padding-top: 22px;
    width: 100%;
    .nameTitle {
      width: 574px;
    }
    li {
      margin-bottom: 16px;
      border-radius: 4px;
      font-size: 14px;
      color: #f1f1f1;
      /deep/ .el-radio {
        margin-left: 20px;
        cursor: default;
        .el-radio__inner {
          border-color: #33ab4f;
          background-color: #03897b;
          cursor: default;
        }
        .el-radio__label {
          color: #F1F1F1;
          font-size: 14px;
        }
      }
       /deep/.color_done {
        .el-radio__label {
          color: #555661;
        }
        }
        /deep/.color_failed {
        .el-radio__label {
          color: #F6445A;
        }
        }
        /deep/ .color_progress {
          .radio__label {
            color: #03897B;
          }
        }
      span {
        font-size: 14px;
        font-weight: 500;
        color: #909090;
        margin-right: 8px;
        text-align: right;
      }
      .four_txt {
        color: #f1f1f1;
      }
      .el-button {
        height: 32px;
        line-height: 8px;
        border-radius: 4px;
        border: none;
        margin-right: 16px;
        /deep/ span {
        width: 92px;
        text-align: center;
        font-size: 14px;
        font-weight: bold;
        color: #03897B;
        }
      }
      /deep/ .back {
        background-color: rgba(0, 0, 0, 0.3);
        font-size: 14px;
        font-weight: bold;
        span {
          color: #454549;
        }
      }
      /deep/ .switch {
        font-size: 14px;
        font-weight: bold;
        background-color: rgba(3, 137, 123, 0.14);
        span {
          color: #03897B;
        }
      }
      .apply {
        display: inline-block;
        width: 62px;
        height: 32px;
        line-height: 32px;
        background-color: #F6445A;
        border-radius: 4px;
        color: #F1F1F1;
        text-align: center;
        margin-left: 89px;
        opacity: 0.4;
        cursor: text;
      }
      .showgray {
          opacity: 1;
          cursor: pointer;
      }
    }
    .el-divider {
      width: 80%;
      height: 1px;
      background-color: #454549;
      margin: 2% 0;
      margin-left: 18px;
    }
    .input_backup {
      display: flex;
      justify-content: space-start;
    }
  }
  .infoName {
    display: inline-block;
    width: 123px;
    text-align: right;
  }
  .el-divider {
    width: 80%;
    height: 1px;
    margin: 2% 0;
    background-color: #454549;
    margin-left: 17px;
  }
  .inputBox, .outputBox {
    margin-left: 8%;
  }
  .outPutList {
    overflow: hidden;
    width: 100%;
  .outPutName {
    float: left;
    width: 122px;
    margin-top: 7px;
    text-align: right;
    font-size: 14px;
    font-weight: 500;
    color: #909090;
  }
  .list {
    float: left;
    height: 132px;
    overflow-y: scroll;
    margin-left: 7px;
    width: calc(98% - 122px);
    .outPut {
      width: 100%;
      li {
        margin-bottom: 3px;
        height: 32px;
        line-height: 32px;
        &:hover {
          background-color: #000000;
          opacity: 0.3;
        }
        .outPutListInfo {
          float: left;
          width: 322px;
          overflow: hidden;
         .num {
          float: left;
          width: 15px;
          height: 15px;
          line-height: 15px;
          text-align: center;
          border-radius: 50%;
          background-color: #555661;
          margin-top: 9px;
          margin-right: 8px;
          font-size: 12px;
          font-weight: 500;
          color: #18181c;
          }
          .name {
          float: left;
          width: 87px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-size: 14px;
          color: #f1f1f1;
          }
         .link {
          float: right;
          width: 149px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-size: 14px;
          color: #909090;
          }
        }
        .el-dropdown {
          float: right;
        }
        .opreation {
          margin-right: 60px;
          width: 16px;
          height: 32px;
          font-weight: 700;
          line-height: 32px;
          color: #fff;
          .el-icon-more {
            color: #fff;
          }
        }
      }
    }
  }
  }
}
</style>
