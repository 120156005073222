<template>
  <div class="wrapper">
    <div class="topContent">
      <div class="selectContent">
        <!--R类型-->
        <div class="search-box">
          <span class="rType">Mode:</span>
        </div>
        <div class="search-box">
          <el-select :popper-append-to-body="false" popper-class="eventTypeContent"  v-model="eventType" placeholder="Please select">
              <el-option
                v-for="item in eventTypeArray"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
        </div>
        <!--日期select-->
        <div class="search-box">
          <el-date-picker
            v-model="dateranges"
            type="daterange"
            align="center"
            unlink-panels
            range-separator="To"
            start-placeholder="Start date"
            end-placeholder="End date"
            :picker-options="pickerOptions"
            @change="changeEndValue"
            :append-to-body="false">
          </el-date-picker>
        </div>
        <!--EventName-->
        <div class="search-box">
          <span class="eventName">Event Name:</span>
        </div>
        <div class="search-box">
          <el-input v-model.trim="search" placeholder="Please enter Event Name" @keyup.enter.native="searchList"/>
        </div>
        <div class="search-box">
          <el-button class="right" type="primary" size="small" @click="searchList"
          >Search</el-button>
        </div>
      </div>
      <div class="timeConsumption">
        <div  class="search-box">
          <div class="timeCount">
            <div class="timeContent">
              <span>Total Consumed this Month:</span>
              <div class="timeDate">
                <span class="time">{{ totalConsumedLinuxRTimeHours }}</span>
                <span class="unit">hs</span>
                <span class="time">{{ totalConsumedLinuxRTimeMin }}</span>
                <span class="unit">mins</span>
              </div>
            </div>
        </div>
        </div>
      </div>
    </div>
    <el-table
      class="usage" :data="eventUsageList"
      style="width: 100%"
      :height="tableMaxHeight" v-loadings="scrollS.loading"
    >
      <el-table-column fixed label="EventId" width="200" show-overflow-tooltip>
        <template slot-scope="scope">
          <span @click="changeSnCopy(scope.row.eventId)">{{ scope.row.eventId  }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="taskId" width="200" label="AM Task ID"  show-overflow-tooltip>
        <template slot-scope="scope">
          <span @click="changeSnCopy(scope.row.taskId)">{{ scope.row.taskId }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Event Name"  width="200" show-overflow-tooltip>
        <template slot-scope="scope">
          <span @click="changeSnCopy(scope.row.title)">{{ scope.row.title }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Time" width="200" show-overflow-tooltip >
        <template slot-scope="scope">
            {{ scope.row.startTime | FormatTime('YYYY-MM-DD HH:mm') }} -
            {{ scope.row.endTime | FormatTime('YYYY-MM-DD HH:mm') }}
        </template>
      </el-table-column>
      <el-table-column label="Duration" width="200" show-overflow-tooltip >
      <template slot-scope="scope">
          {{ scope.row.duration | millisecond(scope.row.duration)}}
      </template>
      </el-table-column>
      <el-table-column label="MainPartyCode" width="200">
        <template slot-scope="scope">
          <div @click="changeSnCopy(scope.row.partyCode)">
            {{ scope.row.partyCode}}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="CreateTime" width="200"  show-overflow-tooltip>
        <template slot-scope="scope">
          <span>{{ scope.row.createTime | FormatTime('YYYY-MM-DD HH:mm') }}</span>
        </template>
      </el-table-column>
      <el-table-column label="UpdateTime" width="200" show-overflow-tooltip>
        <template slot-scope="scope">
          <span>{{ scope.row.updateTime | FormatTime('YYYY-MM-DD HH:mm') }}</span>
        </template>
      </el-table-column>
      <el-table-column label="ProgramId" width="200" show-overflow-tooltip>
        <template slot-scope="scope">
          <span @click="changeSnCopy(scope.row.programId)">{{ scope.row.programId }}</span>
        </template>
      </el-table-column>
      <el-table-column label="ProductionId" width="200" show-overflow-tooltip>
        <template slot-scope="scope">
          <span @click="changeSnCopy(scope.row.productionId)">{{ scope.row.productionId }}</span>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      popper-class="paginationContent"
      :background="true"
      @size-change="pageSizeChange"
      @current-change="currentPageChange"
      :current-page="params.pageNum"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="params.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total">
    </el-pagination>
  </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
  data () {
    return {
      totalConsumedLinuxRTimeHours: '',
      totalConsumedLinuxRTimeMin: '',
      dateranges: [],
      pickerOptions: {
        shortcuts: [
          {
            text: 'Latest 1 week',
            onClick: (picker) => {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              start.setHours(0, 0, 0, 0);
              end.setDate(end.getDate() + 1)
              end.setHours(0, 0, 0, 0);
              end.setTime(end.getTime() - 1)
              picker.$emit('pick', [start, end]);
            }
          },
          {
            text: 'Latest 30 days',
            onClick: (picker) => {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              start.setHours(0, 0, 0, 0);
              end.setDate(end.getDate() + 1)
              end.setHours(0, 0, 0, 0);
              end.setTime(end.getTime() - 1)
              picker.$emit('pick', [start, end]);
            }
          },
          {
            text: 'This month',
            onClick: (picker) => {
              const end = new Date();
              const start = new Date();
              start.setDate(1);
              start.setHours(0, 0, 0, 0);
              end.setDate(end.getDate() + 1)
              end.setHours(0, 0, 0, 0);
              end.setTime(end.getTime() - 1)
              picker.$emit('pick', [start, end]);
            }
          },
          {
            text: 'Last month',
            onClick: (picker) => {
              const start = new Date();
              start.setDate(1);
              start.setMonth(start.getMonth() - 1);
              start.setHours(0, 0, 0, 0);
              const end = new Date(start.getFullYear(), start.getMonth() + 1, 0);
              picker.$emit('pick', [start, end]);
            }
          }
        ]
      },
      eventType: '',
      eventTypeArray: [
        {
          value: '',
          label: 'All'
        },
        {
          value: '1',
          label: 'Transceiver'
        },
        {
          value: '0',
          label: 'Cloud R'
        }],
      scrollS: {
        loading: true
      },
      slectParams: {},
      selectLabel: 'title',
      search: '',
      eventUsageList: [],
      eventList: [],
      total: 0,
      params: {
        pageSize: 20,
        pageNum: 1
      },
      loading: false,
      tableMaxHeight: 0
    }
  },
  computed: {
    ...mapState({
      statusInfo: (state) => state.statusInfo,
      State: state => state
    })
  },
  watch: {
    params: {
      handler () {
        this.fetchList()
      },
      deep: true
    }
  },
  filters: {
    millisecond (duration) {
      const hours = Math.floor(duration / (1000 * 60 * 60)).toString().padStart(2, '0');
      const minutes = Math.floor((duration % (1000 * 60 * 60)) / (1000 * 60)).toString().padStart(2, '0');
      const seconds = Math.floor((duration % (1000 * 60)) / 1000).toString().padStart(2, '0');
      return hours + ':' + minutes + ':' + seconds;
    }
  },
  created () {
    document.getElementsByClassName('contentBox')[0].style.height = 'calc(100vh - 65px)'
    this.initDateRange()
    this.getComuseHours()
    this.State.newPageLoading = false
    this.params.pageNum = 1
    this.fetchList()
  },
  methods: {
    // returnMainpage () {
    //   this.$router.push({
    //     name: 'commentator'
    //   })
    // },
    changeSnCopy (row) {
      const url = row
      const oInput = document.createElement('input')
      oInput.value = url
      document.body.appendChild(oInput)
      oInput.select() // 选择对象;

      document.execCommand('Copy') // 执行浏览器复制命令
      this.$message({
        message: 'Copy success',
        type: 'success'
      })
      oInput.remove()
    },
    convertMillisecondsToHoursAndMinutes (milliseconds) {
      let minutes = Math.floor(milliseconds / 60000);
      const hours = Math.floor(minutes / 60);
      minutes = minutes % 60;
      return [
        hours,
        minutes
      ]
    },
    handleLinuxRhours (timeData) {
      this.totalConsumedLinuxRTimeHours = this.convertMillisecondsToHoursAndMinutes(timeData.usageDuration)
        ? this.convertMillisecondsToHoursAndMinutes(timeData.usageDuration)[0]
        : ''
      this.totalConsumedLinuxRTimeMin = this.convertMillisecondsToHoursAndMinutes(timeData.usageDuration)
        ? this.convertMillisecondsToHoursAndMinutes(timeData.usageDuration)[1]
        : ''
    },
    getComuseHours () {
      this.$http
        .get(`/commentator-backend/user/usageStatistics?startTime=${new Date(this.dateranges[0]).getTime()}&endTime=${new Date(this.dateranges[1]).getTime()}&platform=${this.eventType ? Number(this.eventType) : this.eventType}&email=${this.search}`)
        .then(res => {
          this.handleLinuxRhours(res.data.result)
        })
        .catch((error) => {
          console.log(error)
        })
    },
    initDateRange () {
      const lastMonth = this.pickerOptions.shortcuts.find(shortcut => shortcut.text === 'Latest 30 days');
      if (lastMonth) {
        const picker = {
          $emit: (event, value) => {
            this.dateranges = value; // 将起始日期和结束日期赋值给dateranges
          }
        };
        lastMonth.onClick(picker);
      }
    },
    searchList () {
      this.params.pageNum = 1
      this.fetchList()
      this.getComuseHours()
    },
    changeSelectLabel () {
      this.search = ''
    },
    pageSizeChange (v) {
      this.params.pageNum = 1
      this.params.pageSize = v
    },
    currentPageChange (v) {
      this.params.pageNum = v
    },
    fetchList () {
      this.scrollS.loading = true
      const params = Object.assign({}, this.params, {
        [this.selectLabel]: this.search,
        platform: this.eventType ? Number(this.eventType) : this.eventType,
        startTime: new Date(this.dateranges[0]).getTime(),
        endTime: new Date(this.dateranges[1]).getTime()
      })
      this.url = '/commentator-backend/user/usagePage'
      this.$http
        .post(this.url, params).then(res => {
          this.scrollS.loading = false
          const { errorCode, result } = res.data
          if (errorCode === '0x0') {
            const { total, list } = result
            this.total = total
            if (this.monitorModel) {
              this.eventList = list
            } else {
              this.eventUsageList = list
            }
          } else {
            this.$message({
              type: 'error',
              message: res.data.errorInfo
            })
          }
          this.tableMaxHeight = document.getElementsByTagName('body')[0].clientHeight
        })
    },
    changeEndValue (dateArray) {
      const endTime = dateArray[1];
      if (endTime) {
        endTime.setDate(endTime.getDate() + 1)
        endTime.setHours(0, 0, 0, 0);
        this.dateranges[1] = endTime.setTime(endTime.getTime() - 1)
      }
    }
  }
}
</script>
<style lang="less" scoped>
  /deep/.el-table {
    font-size: 14px;
    color: #fff;
    background: none;
    &::before {
      background: #28282e;
    }
    .el-table__body-wrapper {
      max-height: calc(100vh - 200px);
      overflow-y: auto;
      overflow-x: auto;
      &::-webkit-scrollbar {
        /*滚动条整体样式*/
        width: 6px; /*高宽分别对应横竖滚动条的尺寸*/
        height: 10px;
      }
      &::-webkit-scrollbar-corner {
        background-color: transparent;
      }
      &::-webkit-scrollbar-thumb {
        /*滚动条里面小方块*/
        border-radius: 10px;
        background: rgba(255, 255, 255, 0.3);
      }
      &::-webkit-scrollbar-track {
        /*滚动条里面轨道*/
        border-radius: 10px;
        background: none;
      }
    }
    thead {
      color: #999;
    }
    th,
    tr,
    td {
      background: #010101;
      border-bottom: 1px solid #28282e;
      &.is-leaf {
        border-bottom: 1px solid #28282e;
      }
    }
    tbody {
      tr {
        &:hover {
          // background: rgba(3, 137, 123, 0.25);
          background: #212124;
          td {
            background: none;
          }
        }
      }
    }
     .el-table__cell{
      text-align:center !important;
    }
     .cell{
      word-break: break-word !important;
    }
  }
  /deep/.el-table__fixed-right-patch{
    width: 0px !important;
  }
  /deep/.el-table__fixed-right::before, .el-table__fixed::before{
    display: none !important;
  }
  /deep/ .el-table__fixed::before{
    display: none !important;
  }
  /deep/.el-table--enable-row-hover .el-table__body tr:hover>td.el-table__cell{
      background-color:#212124 !important;
    }
  /deep/.el-table__body tr.hover-row>td.el-table__cell{
    background-color:#212124 !important;
  }
  /deep/.el-input--suffix .el-input__inner {
    background: #28282e;
    height: auto;
    color: #f1f1f1;
    border: 0;
  }
    /* // 分页 */
  /deep/.el-pagination {
    text-align: right;
    padding: 0;
    margin-top: -8px;
    .el-pager li {
      background-color: #010101;
      color: #fff;
    }
    .el-pagination__total,.el-pagination__jump{
      color: #999;
    }
    .el-pager li.active {
      color: #e6a23c !important;
      font-weight: 900 !important;
    }
    .btn-prev {
      background-color: #010101;
      .el-icon {
        color: #fff;
      }
    }
    .btn-next {
      background-color: #010101;
      .el-icon {
        color: #fff;
      }
    }
    .el-select {
    .el-input {
      .el-input__inner {
        background-color: #28282e;
        color: #fff;
        border: none;
      }
    }
    }
    .el-pagination__editor.el-input .el-input__inner{
        background: #28282e;
        color: #fff;
        border: 0;
      }
  }
  /deep/.el-pagination.is-background .el-pager li:not(.disabled):hover{
    color:white;
  }
  /deep/.el-pager li.active {
    color: #00d000 !important;
    background-color: transparent !important;
    font-size: 13px;
    font-weight:700;
  }
  /deep/.el-range-input{
    background: #28282e;
    color: #f1f1f1;
  }
  .wrapper {
    height: 100vh;
    margin-top: -5px;
    background-color: #010101;
    padding: 20px;
    .topContent{
      display: flex;
      align-items: center;
      justify-content: space-between;
      .selectContent{
        display: flex;
        align-items: center;
      }
      .search-box {
        margin-top: 7px;
        margin-right: 20px;
        .el-button {
          margin: 0 10px;
          border-color: #03897b;
          background: #03897b;
        }
        /deep/.el-input {
          .el-input__inner {
            background-color: #28282e;
            color: #fff;
            border: none;
          }
        }
          .el-range-editor.el-input__inner{
            background: #28282e;
            color: #f1f1f1;
            border: 0;
          }
        .timeCount{
          display: flex;
          justify-content: space-around;
          .timeContent{
            display: flex;
            align-items: flex-end;
            .timeDate {
              margin-left: 13px;

              .time {
                padding: 5px;
                font-size: 22px;
              }
            }
          }
        }
      }
    }
      .el-table.usage {
        margin: 30px 0;
        background-color: transparent;
        max-height: calc(100vh - 40px - 60px - 60px - 65px);
    }
  }
</style>
