<template>
<div>
<div v-if="showAdvanceds">
  <el-dialog
    :title="dialogTitle"
    :visible.sync="info.show"
    :close-on-click-modal="false"
    width="720px"
    append-to-body
    :modal="false"
    class="thisblack-bgc"
    :class="{showGroup:showGroupLink,noShowGroup:!showGroupLink}"
    @close="done"
  >
    <div class="content" v-if="!showGroupLink" v-loadings="scrollS.loading">
      <div class="serviceUrl" v-if="info.id && eventInfo.status <= 4">
        <div class="mixer" v-if="audioMixer">
          <div class="audio">
            <p>{{$t('eventList.advancedAudioMixer')}}</p>
            <div class="url">
              <a :href="audioMixer" target="_blank">{{ audioMixer }}</a>
            </div>
            <el-tooltip
              class="item"
              effect="dark"
              :content="$t('eventList.copyLink')"
              placement="top"
            >
              <span
                class="link el-icon-connection"
                v-clipboard:copy="copyLink(audioMixer)"
                v-clipboard:success="onCopy"
                v-clipboard:error="onError"
              ></span>
            </el-tooltip>
          </div>
        </div>
        <div class="mixer" v-if="eventInfo.ppUrl && eventInfo.status == 4">
          <div class="audio">
            <p>{{$t('coordinationRoom.producer')}}</p>
            <div class="url">
              <a :href="eventInfo.ppUrl" target="_blank">{{ eventInfo.ppUrl }}</a>
            </div>
            <el-tooltip
              class="item"
              effect="dark"
              :content="$t('eventList.copyLink')"
              placement="top"
            >
              <span
                class="link el-icon-connection"
                v-clipboard:copy="copyLink(eventInfo.ppUrl)"
                v-clipboard:success="onCopy"
                v-clipboard:error="onError"
              ></span>
            </el-tooltip>
          </div>
        </div>
        <div class="mixer" v-if="info.id">
          <div class="audio">
            <p>{{$t('coordination')}}</p>
            <div class="url">
              <a :href="coordinationUrl" target="_blank">{{ coordinationUrl }}</a>
            </div>
            <el-tooltip
              class="item"
              effect="dark"
              :content="$t('eventList.copyLink')"
              placement="top"
            >
              <span
                class="link el-icon-connection"
                v-clipboard:copy="copyLink(coordinationUrl)"
                v-clipboard:success="onCopy"
                v-clipboard:error="onError"
              ></span>
            </el-tooltip>
          </div>
        </div>
      </div>
      <el-divider v-if="info.id && eventInfo.status <= 4"></el-divider>
      <!-- 显示 event name -->
      <div class="title eventName">
        <span class="label"><i class="require">*</i>{{$t('eventList.eventName')}}</span>
        <el-input
          v-model="title"
          :placeholder="$t('eventList.addTitle')"
          :maxlength="32"
          :disabled="isReuse?false:info.type == 'view' || eventInfo.status == 4"
          class="eventName"
        ></el-input>
      </div>
      <!-- 选择事件时间 -->
      <div class="title eventTime" v-if="timeControl">
        <span class="label"><i class="require">*</i>{{$t('eventList.time')}}</span>
        <el-date-picker
          v-model="startTime"
          type="datetime"
          :clearable="false"
          format="yyyy-MM-dd HH:mm"
          value-format="timestamp"
          :default-time="new Date().getTime() | FormatTime('HH:mm:ss')"
          :picker-options="startDatePickerOptions"
          align="center"
          :disabled="
            !isReuse && (info.type == 'view' ||
            (eventInfo.status && eventInfo.status != 1 && eventInfo.status != 2))
              ? true
              : false
          "
          :placeholder="$t('eventList.startTime')"
          @change="changeStartTime"
        >
        </el-date-picker>
        <span class="sign">-</span>
        <el-date-picker
          align="center"
          popper-class="endTime"
          v-model="endTime"
          type="datetime"
          :disabled="!isReuse && info.type == 'view' ? true : false"
          :clearable="false"
          @change="changeEndTime"
          format="yyyy-MM-dd HH:mm"
          value-format="timestamp"
          :picker-options="endDatePickerOptions"
          :default-time="new Date().getTime() | FormatTime('HH:mm:ss')"
          :placeholder="$t('eventList.endTime')"
        >
        </el-date-picker>
        <span style="display:inline-block; margin-left:14px;">
          <span
            class="set_now_icon"
            :class="{ notAllowed: !isReuse && (info.type === 'view' || info.type === 'edit') }"
            @click="resetTime('now')"
            >{{$t('eventList.now')}}
          </span
        >
        </span>
      </div>
      <!-- 选择 now 30 分钟的时间 -->
      <div class="title duration_box eventTime" v-else>
        <span class="label"><i class="require">*</i>{{$t('eventList.time')}}</span>
        <span>{{$t('eventList.now')}}</span>
        <span class="label duration">{{$t('eventList.duration')}}</span>
        <el-dropdown
          trigger="click"
          @command="changeDuration"
          placement="bottom"
          style="margin-left:4px; width:100px;"
        >
          <el-input
            class="el-dropdown-link duration_input"
            v-model.number="duration"
            @change="changeTime"
          ></el-input>
          <div class="unit">{{durationType.unit | showTimeUnit}}</div>
          <el-dropdown-menu slot="dropdown" class="time_dropdown">
            <el-dropdown-item v-for="(val, key) in durationTime" :command="val" :key="key">{{val.time}} {{val.unit | showTimeUnit}}</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <div style="display:inline-block; margin-left:12px;">
        <span
          class="set_now_icon"
          :class="{ notAllowed: !isReuse && (info.type === 'view' || info.type === 'edit') }"
          @click="resetTime('reset')"
          >{{$t('eventList.reset')}}</span
        >
        </div>
      </div>
      <el-divider></el-divider>
      <!-- 选择 cloudR 还是 Receiver -->
      <div class="receiverBox" :class="{ active: showActiveBackground, receiverBackup: receiverType == '1' && purpose == 1 && receiverBackupBtnVal }">
        <!-- input R type -->
        <div class="title">
          <span class="label"><i class="require">*</i>{{$t('coordinationRoom.inputRType')}}</span>
          <div class="inputType">
            <el-select
              v-model="receiverType"
              popper-class="black"
              @change="defaultSet"
              :disabled="info.type == 'view' ? true : false"
              :popper-append-to-body="false"
            >
              <el-option
                v-for="item in rTypeList"
                :key="item.val"
                :label="$t(`coordinationRoom.${item.label}`)"
                :value="item.val">
              </el-option>
            </el-select>
          </div>
          <!-- 选择物理 R -->
          <div class="receiverType" v-show="receiverType == '1'">
            <el-select
              remote
              :placeholder="$t('eventList.ReceiverSelect')"
              v-model="receiverPeerId"
              popper-class="black"
              @input="getRList(receiverPeerId, 'main')"
              v-scroll="scroll"
              :loading="loading"
              :filterable="true"
              :filter-method="(val) => {getReceiverList('filter', val)}"
              @visible-change="(val) => {getReceiverList('getList', val)}"
              :disabled="isReuse?false : info.type == 'view' || eventInfo.status == '4' ? true : false"
              :popper-append-to-body="false"
              ref="ruleSelect"
            >
              <el-option
                v-for="item in rList"
                :key="item.peerId"
                :label="item.name"
                :value="item.peerId"
                :disabled="receiverBackupBtnVal && backupReceiverPeerId === item.peerId">
                <div @click="clickReceiverPeerId(item)">{{ item.name }}</div>
              </el-option>
              <p
              class="loading-txt"
              v-if="scroll.loading"
              style="text-align:center;font-size:15px;color:#909090; "
              >
              <i class="el-icon-loading" style="color:#909090;"></i>
                  {{$t('eventList.loading')}}
              </p>
            </el-select>
          </div>
          <checkRFeature ref="receiverCheckFeature"  :receiverMainOrBack="'mainReceiver'" v-if="receiverType == '1'"></checkRFeature>
        </div>
        <!-- input 区域 -->
        <div class="title">
          <span class="label"><i class="require">*</i>{{$t('leftPane.input')}}</span>
          <!-- 没有选择物理 R 时显示 -->
          <div class="inputType" v-show="receiverType != '1'" :class="{ width330: inputType == 'pgm' }">
            <select-menu
              :data="inputTypeList"
              type="group"
              @callBack="
                (val) => {
                  changeInputType(val, '')
                }
              "
              :callback="
                (val) => {
                  changeInputType(val, '')
                }
              "
              :disabledMessage="disabledMessage"
              :selectVal="inputType"
              :disabled="isReuse?false:info.type == 'view' ? true : false"
            ></select-menu>
          </div>
          <!-- 选择物理 R 时显示 R 的 input 类型 -->
          <div class="inputType" v-show="receiverType == '1'">
            <el-select :popper-append-to-body="false" v-model="receiverInputType" popper-class="black" @change="(val) => {getSourceInfo(val, 'main')}" :disabled="isReuse?false : info.type == 'view' ? true : false">
              <el-option
                v-for="item in typeList"
                :key="item.val"
                :label="item.label"
                :value="item.val">
              </el-option>
            </el-select>
          </div>
          <!-- input 第二个输入框 -->
          <div class="inputList" v-if="inputType != 'pgm'">
            <!-- 选择类型是 file 时显示 -->
            <SelectScrollFile
              v-if="inputType == 'file'"
              :info="fileInfo"
              @callback="
                (val) => {
                  selectInputChange(val)
                }
              "
              :disabled="isReuse?false:info.type == 'view' ? true : false"
            />
            <!-- 选择类型不是 file 并且 request.url 有值时显示 -->
            <SelectScroll
              ref="selectScroll"
              v-if="inputType != 'file' && requestInfo.url"
              :eventInfo="eventInfo"
              :receiverPeerId="receiverPeerId"
              :receiverInputType='receiverInputType'
              :info="requestInfo"
              :type="inputType"
              :backupBtnVal="backupBtnVal"
              :disabledList="disableInputSlot"
              :disabledMessage="disabledInputSlotMessage"
              @openRtmp="showRtmp"
              @openRtmpBackUp="showRtmpBackUp"
              @callback="
                (val) => {
                  selectInputChange(val)
                }
              "
              :disabled="isReuse?false:info.type == 'view' ? true : false"
            />
          </div>
          <!-- 选择类型不是 pgm 并且也不是物理 R 时的 backup 勾选框 -->
          <div class="backup" v-if="inputType != 'pgm' && receiverType != '1'">
            <el-checkbox
              v-model="backupBtnVal"
              :disabled="isReuse?false:info.type == 'view' ? true : false"
              @change="changeBackupBtn"
              >{{$t('eventList.backUp')}}</el-checkbox
            >
          </div>
          <!-- 选择为物理 R 时的 backup 勾选框 -->
          <div class="receiverBackup" v-if="receiverType == '1'">
            <el-checkbox
              v-model="receiverBackupBtnVal"
              :disabled="isReuse?false:(info.type == 'view'|| info.type =='edit') ? true : false"
              @change="changeBackupBtn"
              >{{$t('eventList.backUp')}}</el-checkbox
            >
          </div>
        </div>
        <!-- output 区域 -->
        <div class="title output" v-show="receiverPeerId">
          <span class="label">{{$t('eventList.outputs')}}</span>
          <div class="sdiOutput">
            SDI OUT
          </div>
          <div class="receiverName">{{ receiverOutputName }}</div>
        </div>
      </div>
      <!-- back receiver 区域 -->
      <div class="backupReceiverBox" v-if="receiverType == '1' && receiverBackupBtnVal" :class="{ active: purpose == 2 && receiverBackupBtnVal }">
        <!-- backup 物理 R 的选择 -->
        <div class="title" v-if="receiverType == '1' && receiverBackupBtnVal">
          <span class="label">{{$t('eventList.backupReceiver')}}</span>
          <!--物理R的提示框-->
          <div class="backupReceiver">
            <el-select
              remote
              :placeholder="$t('eventList.ReceiverSelect')"
              v-model="backupReceiverPeerId"
              popper-class="black"
              @input="getRList(backupReceiverPeerId, 'backup')"
              v-scroll="backupScroll"
              :loading="backupLoading"
              :filterable="true"
              :filter-method="(val) => {getBackupReceiverList('filter', val)}"
              @visible-change="(val) => {getBackupReceiverList('getList', val)}"
              :disabled="isReuse?false : info.type == 'view' ? true : false"
              :popper-append-to-body="false"
              ref="ruleSelect"
            >
              <el-option
                v-for="item in backupRList"
                :key="item.peerId"
                :label="item.name"
                :value="item.peerId"
                :disabled="receiverPeerId === item.peerId">
                <div @click="clickBackupReceiverPeerId(item)">{{ item.name }}</div>
              </el-option>
              <p
              class="loading-txt"
              v-if="backupScroll.loading"
              style="text-align:center;font-size:15px;color:#909090; "
              >
              <i class="el-icon-loading" style="color:#909090;"></i>
                  {{$t('eventList.loading')}}
              </p>
            </el-select>
          </div>
          <!--问号的提示信息-->
          <checkRFeature ref="backupReceiverCheckFeature"  :receiverMainOrBack="'backReceiver'" :receiverType="receiverType"></checkRFeature>
        </div>
        <!-- bkp inpurt -->
        <div class="title" v-if="receiverType == '1' && receiverBackupBtnVal">
          <span class="label"><i class="require">*</i>{{$t('eventList.bkpInput')}}</span>
          <!-- backup 物理 R 输入类型的选择 -->
          <div class="inputType">
            <el-select :popper-append-to-body="false" v-model="backupReceiverInputType" popper-class="black" @change="(val) => {getSourceInfo(val, 'backup')}" :disabled="isReuse?false : info.type == 'view' ? true : false">
              <el-option
                v-for="item in typeList"
                :key="item.val"
                :label="item.label"
                :value="item.val">
              </el-option>
            </el-select>
          </div>
          <div class="inputList" v-if="inputType != 'pgm'">
            <SelectScroll
              ref="backupSelectScroll"
              v-if="inputType != 'file' && backupRequestInfo.url"
              :eventInfo="eventInfo"
              :receiverPeerId="backupReceiverPeerId"
              :receiverInputType='backupReceiverInputType'
              :info="backupRequestInfo"
              :type="inputType"
              :disabledList="disableInputSlot"
              :disabledMessage="disabledInputSlotMessage"
              @openRtmp="showRtmp"
              @openRtmpBackUp="showRtmpBackUp"
              @callback="
                (val) => {
                  selectInputChange(val, '', 'backupReceiver')
                }
              "
              :disabled="isReuse?false:info.type == 'view' ? true : false"
            />
          </div>
        </div>
        <!-- back receiver output 区域 -->
        <div class="title output" v-show="receiverType == '1' && backupReceiverPeerId && receiverBackupBtnVal">
          <span class="label">{{$t('eventList.outputs')}}</span>
          <div class="sdiOutput">
            SDI OUT
          </div>
          <div class="receiverName">{{ backupReceiverOutputName }}</div>
        </div>
      </div>
      <!-- backup input select -->
      <div class="title backupInputSelect" v-if="receiverType == '1' && receiverBackupBtnVal">
        <span class="label">{{$t('eventList.inputSelect')}}</span>
        <span
          class="backupBtn"
          :class="{ active: purpose == 1, disabled: isReuse?false : info.type == 'view' }"
          @click="selectSourceType(1)"
          >{{$t('eventList.mainIn')}}</span
        >
        <span
          class="backupBtn"
          :class="{ active: purpose == 2, disabled: isReuse? false : info.type == 'view' }"
          @click="selectSourceType(2)"
          >{{$t('eventList.backUpIn')}}</span
        >
        <span
          v-if="eventInfo.status == 4"
          class="backupBtn applyBtn backup"
          @click="applyBkInputType()"
          >{{$t('eventList.apply')}}</span
        >
      </div>
      <!-- 类型不是 pgm 时的 backup 源的选择 -->
      <div class="title ppRBackup" :class="{ active: purpose == 2 }" v-if="backupBtnVal && inputType != 'pgm'">
        <span class="label">{{$t('eventList.bkpInput')}}</span>
        <div class="inputType" :class="{ width330: backupType == 'pgm' }">
          <select-menu
            :data="backupTypeList"
            :disabledMessage="disabledBackMessage"
            type="group"
            :disabled="isReuse?false : info.type == 'view' || inputType == 'pgm' ? true : false"
            :callback="
              (val) => {
                changeInputType(val, 'backup')
              }
            "
            @callBack="
              (val) => {
                changeInputType(val, 'backup')
              }
            "
            :selectVal="backupType"
          ></select-menu>
        </div>
        <div class="inputList" v-if="backupType != 'pgm'">
          <SelectScrollFile
            v-if="backupType == 'file'"
            :info="backupFileInfo"
            @callback="
              (val) => {
                selectInputChange(val, 'backup')
              }
            "
            :disabled="isReuse?false : info.type == 'view' ? true : false"
          />
          <SelectScroll
            v-if="backupType != 'file'"
            :eventInfo="eventInfo"
            :backType="backType"
            :key="backupType"
            :backupBtnVal="backupBtnVal"
            :type="backupType"
            :info="requestBackupInfo"
            :disabledList="disableBackInputSlot"
            :disabledMessage="disabledBackInputSlotMessage"
            @openRtmp="showRtmp"
            @openRtmpBackUp="showRtmpBackUp"
            @callback="
              (val) => {
                selectInputChange(val, 'backup')
              }
            "
            :disabled="isReuse? false: info.type == 'view' ? true : false"
          />
        </div>
      </div>
      <!-- input select 输入的选择 -->
      <div class="title" v-if="backupBtnVal && inputType != 'pgm'">
        <span class="label">{{$t('eventList.inputSelect')}}</span>
        <span
          class="backupBtn"
          :class="{ active: purpose == 1, disabled: isReuse?false : info.type == 'view' }"
          @click="selectSourceType(1)"
          >{{$t('eventList.mainIn')}}</span
        >
        <span
          class="backupBtn"
          :class="{ active: purpose == 2, disabled: isReuse? false : info.type == 'view' }"
          @click="selectSourceType(2)"
          >{{$t('eventList.backUpIn')}}</span
        >
        <span
          v-if="eventInfo.status == 4"
          class="backupBtn applyBtn"
          @click="applyInputType()"
          >{{$t('eventList.apply')}}</span
        >
      </div>
      <el-divider v-if="receiverType != '1'"></el-divider>
      <!-- 输出 -->
      <div class="title outputBox" v-show="receiverType != '1'">
        <span class="label">{{$t('eventList.outputs')}}</span>
        <!-- <span class="selectedNum"
          >({{ selectOutputR.length + selectOutputIP.length }} {{$t('leftPaneOutPut.selected')}})</span
        > -->
        <span class="selectedNum"
          >({{ selectOutputR.length + mediaInitParams.selectedOutput.length}} {{$t('leftPaneOutPut.selected')}})</span
        >
        <!-- 唤醒创建grid的弹出窗 -->
        <span class="addGrid">+</span>

        <div class="search">
          <el-input
            :placeholder="$t('eventList.search')"
            v-model="searchOutput"
            class="input-with-select"
            @keyup.enter.native="searchOutputFn"
          >
            <el-select
              v-model="searchOutputType"
              slot="prepend"
              placeholder=""
              popper-class="black"
              :popper-append-to-body="false"
            >
              <el-option :label="$t('leftPaneOutPut.receiver')" :value="1"></el-option>
              <el-option :label="$t('leftPaneOutPut.ipOut')" :value="2"></el-option>
            </el-select>
            <i slot="append" class="el-icon-search" @click="searchOutputFn"></i>
          </el-input>
        </div>
        <!-- default grid -->
        <div class="grid clearfix">
          <span
            class="name left overflow"
            :title="eventInfo.defaultGrid ? eventInfo.defaultGrid.pushLiveName : ''"
          >
            {{ eventInfo.defaultGrid ? eventInfo.defaultGrid.pushLiveName : 'Default Grid' }}
          </span>
          <span
            class="url left overflow"
            :title="eventInfo.defaultGrid ? eventInfo.defaultGrid.pushLiveUrl : ''"
          >
            {{ eventInfo.defaultGrid ? eventInfo.defaultGrid.pushLiveUrl : $t('otherWords.receiverPlay') }}
          </span>
          <el-popover
            placement="bottom-start"
            title=""
            popper-class="defaultInputOperate"
            width="150"
            trigger="click"
            ref="popoverGrid"
            v-model="visible"
          >
            <div class="myBtn" v-show="eventInfo.status != 4 || !eventInfo.defaultGrid" @click="showGridSetting">{{ $t('otherWords.editSetting') }}</div>
            <div class="myBtn" v-show="eventInfo.status == 4 && eventInfo.defaultGrid">
              <span
                class="button"
                v-if="eventInfo.defaultGrid ? eventInfo.defaultGrid.pushLiveUrl : false"
                v-clipboard:copy="copyLink(eventInfo.defaultGrid ? eventInfo.defaultGrid.pushLiveUrl : '')"
                v-clipboard:success="onCopy"
                v-clipboard:error="onError"
              >
                {{$t('eventList.copyLink')}}</span
              >
            </div>
            <div class="myBtn" v-show="eventInfo.status == 4 && eventInfo.defaultGrid">
              <div class="button" @click="showEditGridSetting">{{$t('otherWords.viewSetting')}}</div>
            </div>
            <span class="operate right" slot="reference"
              ><i class="el-icon-more"></i
            ></span>
          </el-popover>
        </div>
        <div class="outputList" v-if="info.id ? showDetail : true">
          <OutputList
            :request="receiverRequest"
            title="Receiver"
            :selectVal="selectOutputR"
            @callback="outputCallback"
            :rLiveIsspInfo="rLiveIsspInfo"
            :eventInfo="eventInfo"
            :searchType="searchOutputTypeLabel"
            :disabled="isReuse?false : info.type == 'view' ? true : false"
            :cancelSelectedR="cancelSelectedR"
            @changeReceiverSetting="changeReceiverSetting"
          />
          <div class="mediaOutPutListTitle">
            <span class="title" @click="changeCaret">
              <i class="icon el-icon-caret-bottom" v-if="showCaret"></i>
              <i class="icon el-icon-caret-right" v-else></i>
              <span>{{$t('leftPaneOutPut.ipOut')}}</span>
            </span>
          <div id="mediaOutputList" class="scrollbar"></div>
          </div>
          <ReceiverSetting
            v-if="receiverSetting.show"
            :info="receiverSetting"
            @closePopup="closeReceiverSetting"
            @setIsspUrl="controlLiveIssp"
            :eventInfo="eventInfo"
            :rLiveIsspInfo="rLiveIsspInfo"
          ></ReceiverSetting>
        </div>
      </div>
      <el-divider></el-divider>
      <!-- output format 区域 -->
      <div class="title outputFormat" v-show="receiverType != '1'">
        <span class="label">{{$t('eventList.outputFormat')}}</span>
        <el-select
          v-model="producerSettingsDto.format"
          placeholder="Select Output Format"
          popper-class="black"
          :disabled="eventInfo.status == 4 || eventInfo.status == 2 ? true : false"
          class="formatOutput"
          :popper-append-to-body="false"
        >
          <el-option
            v-for="value in formats"
            :key="value"
            :label="value"
            :value="value"
          >
          </el-option>
        </el-select>
      </div>
      <!-- elastic ip 区域 -->
      <div class="title elasticIP" v-show="receiverType != '1'">
        <span class="leftItem">
          <el-tooltip effect="light" placement="top" popper-class="toolTipsItem">
            <div slot="content" v-if="hasElasticIp">{{$t('otherWords.contactSupport')}}</div>
            <div slot="content" v-else>{{$t('otherWords.elasticIpExplain')}}</div>
            <i class="el-icon-question"></i>
          </el-tooltip>
          <span class="text">{{$t('otherWords.elasticIp')}}</span>
          <el-checkbox v-model="isShowElasticIP" :disabled="hasElasticIp || editElasticIP || eventInfo.status == 4"></el-checkbox>
        </span>
        <span class="rightItem" v-if="!isShowElasticIP">
          <span class="verticalLine"></span>
           <span class="label">{{$t('eventList.region')}}</span>
           <el-select
            v-model="producerSettingsDto.region"
            :placeholder="$t('otherWords.selectRegion')"
            popper-class="black"
            :disabled="
            inputType == 'file' ||
            (eventInfo.status && eventInfo.status == 4)
              ? true
              : false
          "
          class="regions"
          :popper-append-to-body="false"
        >
          <el-option
            v-for="item in regionList"
            :key="item.value"
            :label="item.name | showRegion"
            :value="item.value"
          >
          </el-option>
           </el-select>
        </span>
        <!-- elasticIP显示 -->
        <span class="rightItem" v-else>
          <span class="verticalLine"></span>
           <span class="label">{{$t('otherWords.selectIp')}}</span>
           <el-select
            v-model="producerSettingsDto.elasticIp"
            :placeholder="$t('otherWords.pleaseSelect')"
            popper-class="black"
            :disabled="
            inputType == 'file' || (hasElasticIp || editElasticIP) || eventInfo.status == 4
              ? true
              : false
          "
          class="regions"
          @change="showTips"
          :popper-append-to-body="false"
        >
          <el-option
            v-for="item in elasticIpList"
            :key="item.value.id"
            :label="item.elasticIpAndRegions"
            :value="item.value.staticIp"
            :disabled="item.disabled"
          >
          <span style="float: left; color: #555661" v-if="item.value.occupied">{{ item.elasticIpAndRegions }}</span>
          <span style="float: left; color: #F1F1F1" v-else>{{ item.elasticIpAndRegions }}</span>
          <el-tooltip class="item" effect="light" placement="top" popper-class="toolTipsItem" v-if="item.value.occupied">
             <div slot="content" v-if="item.value.occupiedInfo.type === 'rc'">The IP is occupied by {{'"' + item.value.occupiedInfo.name + '"'}}.</div>
             <div slot="content" v-else>{{$t('otherWords.ipOccupiedByProducer')}} {{'"' + item.value.occupiedInfo.name + '"'}} {{$t('otherWords.ofProducer')}}</div>
             <span style="float: right; color: #555661; font-size: 13px">occupied</span>
           </el-tooltip>
          </el-option>
           </el-select>
        </span>
      </div>
      <!-- remark 区域 -->
      <div class="title remark">
        <span class="label">{{$t('eventList.remark')}}</span>
        <el-input
          v-model="description"
          type="textarea"
          :autosize="{ minRows: 1, maxRows: 5 }"
          :placeholder="$t('eventList.addDescription')"
        ></el-input>
      </div>
      <!-- more advance 区域 -->
      <div class="title">
        <span class="label">{{$t('eventList.advanced')}}</span>
         <a
          class="myBtn"
          @click="showAdvanced"
          href="javascript:void(0);"
          >{{$t('eventList.advancedSettings')}}</a
        >
      </div>
      <el-divider></el-divider>
      <!-- 添加 group 区域 -->
      <div class="title groups">
        <div>
          <span class="label"><i class="require">*</i>{{$t('eventList.group')}}</span>
          <a
            class="myBtn"
            @click="addGroup"
            href="javascript:void(0);"
            v-if="partyList.length < Config.eventMaxParty && (info.type != 'view' || isReuse)"
            >{{$t('eventList.addNew')}}</a
          >
        </div>
        <div class="pointer inviteBox" v-if="info.type != 'creat'">
          <span class="f_15" @click="inviteInfo.show = true">{{ invitedInfo | showJoinModel }}</span><i class="el-icon-arrow-down"></i>
        </div>
      </div>
      <!-- 添加 group 区域 -->
      <div class="title addGroup">
        <div class="groupItem" v-for="(val, key) in partyList" :key="key">
          <span class="index">No.{{ key + 1 }}</span>
          <el-input
            v-model="val.title"
            :placeholder="$t('eventList.addGroupName')"
            :maxlength="64"
            :disabled="isReuse?false:info.type == 'view' ? true : false"
          ></el-input>
          <i
            class="el-icon-delete"
            @click="deleteFn(key)"
            v-if="info.type != 'view' || isReuse"
          ></i>
          <el-divider
            direction="vertical"
            v-if="val.partyCode && (info.type != 'view' || isReuse)"
          ></el-divider>
          <el-tooltip
            class="item"
            effect="dark"
            :content="$t('eventList.copyGroupLink')"
            placement="top"
          >
            <span
              class="link el-icon-connection"
              v-if="val.partyCode && (eventInfo.status <= 4 || isReuse)"
              v-clipboard:copy="copyLink(val, 'partyCode')"
              v-clipboard:success="onCopy"
              v-clipboard:error="onError"
            ></span>
          </el-tooltip>
        </div>
      </div>
    </div>
    <!-- 显示 group link 的区域 -->
    <div class="content groupLink" v-if="showGroupLink">
      <div class="title addGroup">
        <div class="groupItem" v-for="(val, key) in partyList" :key="key">
          <div v-if="val.title">
            <span class="index">No.{{ key + 1 }}</span>
            <el-tooltip
              class="item"
              effect="dark"
              placement="top-start"
              :visible-arrow="false"
            >
            <el-input
              v-model="val.title"
              :placeholder="$t('eventList.addGroupName')"
              :maxlength="64"
              :readonly="true"
            ></el-input>
            <div slot="content">{{$t('otherWords.partyId')}}:{{ val.partyCode }}</div>
            </el-tooltip>
            <el-tooltip
              class="item"
              effect="dark"
              :content="$t('eventList.copyGroupLink')"
              placement="top"
            >
              <span
                class="link el-icon-connection"
                v-clipboard:copy="copyLink(val, 'partyCode')"
                v-clipboard:success="onCopy"
                v-clipboard:error="onError"
              ></span>
            </el-tooltip>
          </div>
        </div>
      </div>
    </div>
    <!-- 弹框底部按钮区域 -->
    <span slot="footer" class="dialog-footer">
      <div class="pointer inviteBox left" v-if="showGroupLink" @click="inviteInfo.show = true">
        <span class="f_15">{{ invitedInfo | showJoinModel }}</span><i class="el-icon-arrow-down"></i>
      </div>
      <a
        href="javascript:void(0);"
        class="cancel"
        v-if="!showGroupLink"
        @click="done"
        >{{$t('leftPane.cancel')}}</a
      >
      <span v-if="producerSettingsDto.elasticIp && info.type == 'view' && !isReuse">
      <el-button class="unBindIPed" v-if="!isUnbind" disabled>Release IP</el-button>
      <el-tooltip class="item" effect="light" content="Click to make the Elastic IP idle." placement="top" popper-class="toolTipsItem" v-else>
       <el-button @click="unBindElasticIP" class="unBindIP">Release IP</el-button>
      </el-tooltip>
      </span>
      <el-button
        href="javascript:void(0);"
        class="save"
        v-if="!showGroupLink && info.type == 'view' && !isReuse"
        @click="reuseEvent"
      >
        {{$t('eventList.reuse')}}
      </el-button>
      <el-button
        v-if="!showGroupLink && (info.type != 'view' || isReuse)"
        class="save"
        :loading="createLoading"
        @click="save"
        v-repeatClick
      >{{$t('leftPaneOutPut.save')}}</el-button>
      <a
        href="javascript:void(0);"
        class="save"
        v-if="showGroupLink"
        @click="done"
      >
        {{$t('eventList.doneN')}}
      </a>
    </span>
    <CreateFailed v-if="createFailedInfo.show" :info="createFailedInfo"></CreateFailed>
    <OutputSetting v-if="viewSetting.type === 'G' && viewSetting.show" @closeDialog="closeGridSettingDialog" :gridInfo="showCreateDialog ? basicSetting : viewSetting"></OutputSetting>
    <ReceiverConflict v-if="receiverConflictInfo.show" :info="receiverConflictInfo" :receiverConflictErrorCode="receiverConflictErrorCode" @hideCheckedReceiver="hideCheckedReceiver"></ReceiverConflict>
    <SourceConflict  v-if="sourceConflictInfo.show" :info="sourceConflictInfo"></SourceConflict>
  </el-dialog>
 </div>
  <AdvancedSettings
    ref="advanceSetting"
    :receiverType ="receiverType"
    :showDone="showDone"
    :backupReceiverPeerId="backupReceiverPeerId"
    :receiverBackupBtnVal="receiverBackupBtnVal"
    :advanceSettings="advanceSettings"
    :eventInfo="eventInfo"
    :qualityFeature = "qualityFeature"
    v-if="advancedSettingsInfo.show"
    :eventTypeInfo="info"
    :info="advancedSettingsInfo"
    @changeConfig="changeConfig"
    @showFoot="showFoot"
    >
  </AdvancedSettings>
  <RtmpPush
    v-if="rtmpPushInfo.show"
    :rtmpBackType="rtmpBackType"
    :changeRtmpType = "changeRtmpType"
    :eventInfo="eventInfo"
    :eventTypeInfo="info"
    :info="rtmpPushInfo"
    @showFootRtmp="showFoot"
  />
  <RtmpPushBackUp
    v-if="rtmpPushInfoBackup.show"
    :rtmpBackType="rtmpBackType"
    :changeRtmpType = "changeRtmpType"
    :eventInfo="eventInfo"
    :eventTypeInfo="info"
    :info="rtmpPushInfoBackup"
    @showFootRtmpBackup="showFoot"
  />
  <Invited
    :partyCode="eventInfo.partyCode || invitedInfo.partyCode"
    :inviteInfo="inviteInfo"
    :invitedInfo="invitedInfo"
    @callback="dealInviteEvent"
  />
  </div>
</template>
<script>
import { mapState } from 'vuex'
import SelectScroll from '@/components/selectScroll'
import utils from '@/assets/js/utils'
import CreateFailed from './createEventFailed'
import SelectScrollFile from './selectScrollFile'
import SelectMenu from '@/components/selectMenu'
import OutputList from './outputList'
import OutputSetting from '../dialog/outputSetting'
import ReceiverSetting from '../dialog/receiverSetting.vue'
import i18n from '@/lang/index.js'
import Bus from '@/assets/js/bus.js'
import ReceiverConflict from './recieverConflict.vue'
import SourceConflict from './sourceConflictInfo.vue'
import AdvancedSettings from './advancedSettings.vue'
import RtmpPush from './rtmpPush.vue'
import RtmpPushBackUp from './rtmpPushBackup.vue'
import checkRFeature from '@/components/common/checkRFeature.vue'
import Invited from '@/components/common/invited.vue'
export default {
  components: {
    SelectScroll,
    CreateFailed,
    SelectScrollFile,
    SelectMenu,
    OutputList,
    OutputSetting,
    ReceiverSetting,
    ReceiverConflict,
    SourceConflict,
    AdvancedSettings,
    RtmpPush,
    RtmpPushBackUp,
    checkRFeature,
    Invited
  },
  props: {
    selectInput: {
      type: Object,
      default: () => {
        return {}
      }
    },
    selectOutput: {
      type: Array,
      default: () => {
        return []
      }
    },
    info: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {
      qualityFeature: true,
      loading: false,
      backupLoading: false,
      rtmpBackType: '',
      backType: 'backup',
      changeRtmpType: '',
      showDone: false,
      domain: '',
      advanceSettings: {},
      formats: [],
      inputSourcesRecord: '',
      showAdvanceds: true,
      scrollS: {
        loading: false
      },
      scrolNumber: '',
      visible: false,
      rList: [],
      backupRList: [],
      coordinationUrl: `${window.location.protocol}//${document.domain}/commentator/coordination?id=${this.info.id}`,
      disabledMessage: '',
      disabledBackMessage: '',
      disabledBackInputSlotMessage: '',
      disabledInputSlotMessage: '',
      disableInputSlot: [],
      disableBackInputSlot: [],
      isReuse: false,
      searchOutputTypeLabel: 'Receiver',
      searchOutputType: 1,
      searchOutput: '',
      receiverSetting: {
        show: false
      },
      producerSettingsDto: {
        replayApp: 0,
        pgmRecording: 1,
        rps: 0,
        outputDelayTime: 0,
        outputDelay: 0,
        delay: 2,
        region: '',
        format: '1080I5994',
        elasticIp: '',
        inputSourcesRecord: ''
      },
      inputPreviewSetting: {
        bitrate: '2000K',
        resolution: '1280x720',
        frameRate: '30'
      },
      finalPgmPreviewSetting: {
        bitrate: '2000K',
        resolution: '1280x720',
        frameRate: '30'
      },
      advancedSetting: {
        quality: '360p'
      },
      viewSetting: {
        show: false,
        id: '',
        type: '',
        auth: '',
        origin: 'producer'
      },
      backupTypeList: [],
      backupType: 'vfb',
      purpose: 1,
      backupBtnVal: false,
      receiverBackupBtnVal: false,
      inputType: 'vfb',
      selectOutputR: [],
      selectOutputIP: [],
      selectInputRecord: {},
      selectBackupRecord: {},
      startDatePickerOptions: {
        disabledDate: (time) => {
          const nowDate = utils.formatTimeStartEnd(new Date().getTime(), true)
          // time当前日期的零点返回，但是now按钮返回的是当前时间，统一按0点时间去比较
          time = utils.formatTimeStartEnd(new Date(time).getTime(), true)
          const endTime = utils.formatTimeStartEnd(this.endTime, true)
          return (endTime && time > endTime) || time < nowDate
        }
      },
      endDatePickerOptions: {
        disabledDate: (time) => {
          const nowDate = utils.formatTimeStartEnd(new Date().getTime(), true)
          const startTime = utils.formatTimeStartEnd(this.startTime, true)
          // time当前日期的零点返回，但是now按钮返回的是当前时间，统一按0点时间去比较
          time = utils.formatTimeStartEnd(new Date(time).getTime(), true)
          return (startTime && time < startTime) || time < nowDate
        }
      },
      createFailedInfo: {
        show: false
      },
      durationTime: [
        {
          time: 30,
          unit: 'min'
        }, {
          time: 1,
          unit: 'hour'
        }, {
          time: 2,
          unit: 'hour'
        }, {
          time: 3,
          unit: 'hour'
        }
      ],
      duration: 30,
      durationType: {
        time: 30,
        unit: 'min'
      },
      isStartNow: false,
      isEndNow: false,
      dialogTitle: 'Create an event',
      showGroupLink: false,
      show: true,
      title: '',
      time: [],
      startTime: '',
      endTime: '',
      timeControl: true,
      sourceType: this.type == 'file' ? 2 : 1,
      regionList: [],
      description: '',
      audioMixer: '',
      partyList: [],
      eventInfo: {},
      rLiveIsspInfo: {},
      fileInfo: {
        id: this.selectInput.type == 'file' ? this.selectInput.id : '',
        name: '',
        region: ''
      },
      backupFileInfo: {},
      requestSource: {
        rtmp: {
          url: '/commentator-backend/receiver/rtmp',
          params: {}
        },
        sdi: {
          url: '/commentator-backend/receiver/getLocalSDI',
          params: {}
        },
        slot: {
          url: '/commentator-backend/production/slots',
          params: {}
        },
        pp: {
          url: '/commentator-backend/user/source/input/pp/page',
          params: {
            pageNum: 1,
            pageSize: 20,
            sourceType: null
          },
          name: '',
          label: 'sourceName',
          value: 'id'
        },
        cc: {
          name: '',
          url: '/commentator-backend/user/source/output/receiver/page',
          params: {
            condition: '',
            living: true,
            noCamera: true,
            offline: true,
            online: true,
            pack: true,
            pageNum: 1,
            pageSize: 20
          }
        },
        vfb: {
          url: '/commentator-backend/device/pageDevice',
          params: {
            pageNum: 1,
            pageSize: 20,
            condition: '',
            living: true,
            online: true,
            offline: true,
            noCamera: true,
            gridSdi: true,
            gridPack: true
          },
          name: ''
        }
      },
      sourceTypePamrs: {
        vfb: {
          label: 'TVU Transceiver - Grid',
          value: ''
        },
        file: {
          label: 'Upload files',
          value: ''
        },
        pgm: {
          label: 'PP PGM',
          value: ''
        },
        slot: {
          label: 'PP slot',
          value: ''
        },
        ext: {
          label: 'EXT IP Source',
          value: 2
        },
        pack: {
          label: 'Pack',
          value: 4
        },
        anywhere: {
          label: 'Anywhere',
          value: 6
        },
        grid: {
          label: 'Grid',
          value: 5
        }
      },
      requestInfo: {},
      requestBackupInfo: {},
      backupRequestInfo: {},
      receiverRequest: {
        url: '/commentator-backend/user/source/output/receiver/page',
        params: {
          pageNum: 1,
          pageSize: 20,
          condition: '',
          living: true,
          online: true,
          offline: true,
          noCamera: true,
          receiver: true
        }
      },
      ipOutRequest: {
        url: `/commentator-backend/user/source/output/ipOut/list/${this.info.id}`,
        id: 'id'
      },
      ipOutTemplate: {
        url: '/commentator-backend/output/template/page',
        id: 'id',
        params: {
          pageNum: 1,
          pageSize: 20,
          name: ''
        }
      },
      showDetail: false,
      inputTypeList: [],
      showEditIpout: true,
      showCreateDialog: false,
      basicSetting: {
        auth: 'edit',
        id: '',
        type: 'G'
      },
      receiverConflictErrorCode: '',
      receiverConflictInfo: {
        show: false,
        info: {}
      },
      advancedSettingsInfo: {
        show: false,
        info: {}
      },
      rtmpPushInfo: {
        show: false,
        info: {}
      },
      rtmpPushInfoBackup: {
        show: false,
        info: {}
      },
      sourceConflictInfo: {
        show: false,
        info: {}
      },
      cancelSelectedR: [],
      showCaret: true,
      isShowElasticIP: false,
      elasticIpList: [],
      hasElasticIp: false,
      editElasticIP: false,
      isUnbind: false,
      selectIsspId: '',
      createLoading: false,
      // inputBitrate: 2,
      receiverPeerId: '',
      backupReceiverPeerId: '',
      receiverOutputName: '',
      backupReceiverOutputName: '',
      receiverType: 0,
      receiverInputType: '',
      backupReceiverInputType: '',
      rTypeList: [
        {
          val: 0,
          label: 'cloudReceiver'
        },
        {
          val: 1,
          label: 'tvuTransceiver'
        }
      ],
      typeList: [
        {
          label: 'SDI IN',
          val: 'sdi'
        }
      ],
      scroll: {
        onScroll: this.getReceiverList,
        loading: true,
        noMore: false,
        directive: '.el-select-dropdown__wrap'
      },
      backupScroll: {
        onScroll: this.getBackupReceiverList,
        loading: true,
        noMore: false,
        directive: '.el-select-dropdown__wrap'
      },
      getRListParams: {
        pageNum: 1,
        pageSize: 100,
        condition: '',
        living: true,
        online: true,
        offline: true,
        noCamera: true,
        receiver: true,
        version: 7.8,
        versionCompare: 1
      },
      getBackupRListParams: {
        pageNum: 1,
        pageSize: 100,
        condition: '',
        living: true,
        online: true,
        offline: true,
        noCamera: true,
        receiver: true,
        version: 7.8,
        versionCompare: 1
      },
      getRListTimer: null,
      getBackupRListTimer: null,
      eventRid: '',
      defaultGridText: 'Default Grid',
      inviteInfo: {
        show: false,
        title: 'invite.getLink',
        save: 'invite.saveInvite',
        hideCancel: true,
        hideSave: true
      },
      invitedInfo: {}
    }
  },
  watch: {
    scrolNumber: {
      handler (newName, oldName) {
        if (newName != oldName) {
          this.visible = false;
        }
      }
    },
    inputType: {
      handler: function (val) {
        if (val === 'file') {
          this.$set(this.disableBackInputSlot, '0', 's5')
          this.disabledBackInputSlotMessage = this.$t('otherWords.fileAndSlotOnlyOne')
        } else if (val === 'slot' && this.selectInputRecord.slot) {
          this.$set(this.disableBackInputSlot, '0', this.selectInputRecord.slot.id)
          this.disabledBackInputSlotMessage = ''
        } else {
          this.disableBackInputSlot = []
          this.disabledBackInputSlotMessage = ''
        }
        this.backupTypeList.forEach((v) => {
          v.options.forEach((vals, index) => {
            this.$set(v.options, index, { ...vals, disabled: false })
            if (vals.value == val && val == 'file') {
              this.$set(v.options, index, { ...vals, disabled: true })
            }
            if (vals.value == val && val == 'pgm') {
              this.$set(v.options, index, { ...vals, disabled: true })
            }
            // 用户在输入源中切换至其他源，然后再切回至slot5时，禁用掉备用输入的上传文件
            if (val === 'slot' && this.selectInputRecord.slot && this.selectInputRecord.slot.id === 's5' && vals.value === 'file') {
              this.$set(v.options, index, { ...vals, disabled: true })
            }
          })
        })
      },
      immediate: true
    },
    backupType: {
      handler: function (val) {
        // 用户在备用输入中选择了上传文件，禁用掉输入源中的slot5
        if (val === 'file') {
          this.$set(this.disableInputSlot, '0', 's5')
          this.disabledInputSlotMessage = this.$t('otherWords.fileAndSlotOnlyOne')
        } else if (val === 'slot' && this.selectBackupRecord.slot) {
          this.$set(this.disableInputSlot, '0', this.selectBackupRecord.slot.id)
          this.disabledInputSlotMessage = ''
        } else {
          this.disableInputSlot = []
          this.disabledInputSlotMessage = ''
        }
        this.inputTypeList.forEach((v) => {
          v.options.forEach((vals, index) => {
            this.$set(v.options, index, { ...vals, disabled: false })
            // 用户在备用输入中选择了上传文件，禁用掉输入源中的上传文件
            if (vals.value == val && val == 'file') {
              this.$set(v.options, index, { ...vals, disabled: true })
            }
            if (vals.value == val && val == 'pgm') {
              this.$set(v.options, index, { ...vals, disabled: true })
            }
            // 用户在备用输入源中切换至其他源，然后再切回至slot5时，禁用掉输入源中的上传文件
            if (val === 'slot' && this.selectBackupRecord.slot && this.selectBackupRecord.slot.id === 's5' && vals.value === 'file') {
              this.$set(v.options, index, { ...vals, disabled: true })
            }
          })
        })
      },
      immediate: true
    }
  },
  computed: {
    ...mapState({
      State: (state) => state,
      Config: (state) => state.config,
      initInputTypeList: (state) => state.sourceType,
      mediaInitParams: (state) => state.mediaInitParams
    }),
    showActiveBackground () {
      let showBg = false
      if (this.receiverType == 1) {
        if (this.purpose == 1 && this.receiverBackupBtnVal) {
          showBg = true
        }
      } else {
        if (this.purpose == 1 && this.backupBtnVal) {
          showBg = true
        }
      }
      return showBg
    }
  },
  filters: {
    showJoinModel (info) {
      const value = info.inviteMode
      if (value === 0) {
        return i18n.t('invite.anyoneJoin')
      } else if (value === 1) {
        return i18n.t('invite.onlyUserJoin')
      } else if (value === 3) {
        return i18n.t('invite.onlyByPasscode')
      } else {
        return i18n.t('invite.anyoneJoin')
      }
    },
    showRegion (name) {
      if (!name) return ''
      if (name === 'Asia Pacific (Seoul)') {
        return i18n.t('eventRegions.seoul')
      } else if (name === 'Asia Pacific (Mumbai)') {
        return i18n.t('eventRegions.mumbai')
      } else if (name === 'South America (São Paulo)' || name === 'South America (SÃ£o Paulo)') {
        return i18n.t('eventRegions.paulo')
      } else if (name === 'Europe (Paris)') {
        return i18n.t('eventRegions.parise')
      } else if (name === 'Europe (Frankfurt)') {
        return i18n.t('eventRegions.frankfurt')
      } else if (name === 'Asia Pacific (Tokyo)') {
        return i18n.t('eventRegions.tokyo')
      } else if (name === 'US East (N. Virginia)') {
        return i18n.t('eventRegions.virginia')
      } else if (name === 'Asia Pacific (Sydney)') {
        return i18n.t('eventRegions.sydney')
      } else if (name === 'US West (Oregon)') {
        return i18n.t('eventRegions.oregon')
      } else if (name === 'Middle East (Bahrain)') {
        return i18n.t('eventRegions.bahrain')
      } else {
        return name
      }
    },
    showTimeUnit (unit) {
      if (!unit) return ''
      if (unit === 'min') {
        return i18n.t('otherWords.min')
      } else if (unit === 'hour') {
        return i18n.t('otherWords.hour')
      } else {
        return unit
      }
    }
  },
  mounted () {
    window.addEventListener('scroll', this.handleScroll, true)
  },
  created () {
    this.getFormat()
    this.inputTypeList = JSON.parse(JSON.stringify(this.initInputTypeList))
    this.producerSettingsDto.region = localStorage.getItem('region')
      ? localStorage.getItem('region')
      : ''
    this.getRegionList()

    const backupList = JSON.parse(JSON.stringify(this.inputTypeList))
    this.backupTypeList = backupList && backupList.map(v => {
      const item = v.options.filter(val => {
        return val.value != 'pgm'
      })
      return {
        ...v, options: item
      }
    })
    if (this.info.id) {
      this.getEventInfo()
    } else {
      // 设置初始值（暂时设置，后续等后台接口进行定义字段）
      this.inputType = this.selectInput.type
      this.selectInputRecord[this.inputType] = {
        id: this.selectInput.id,
        name: this.selectInput.name
      }

      this.getRequest('input')
      this.requestInfo.name = this.selectInput.name
      this.backupRequestInfo.name = this.selectInput.name
      this.getRequest('backup')
      this.selectOutput.forEach((v) => {
        if (v.type == 'Receiver' && v.items) {
          this.selectOutputR = v.items.map((val) => {
            return { id: val.peerId, name: val.name }
          })
        }
        if (v.type == 'IPout' && v.items) {
          this.selectOutputIP = v.items.map((val) => {
            const { id, name, type } = val
            return { id, name, type }
          })
        }
      })
    }
    // 显示对话框标题
    if (this.info.type === 'view') {
      this.dialogTitle = this.$t('eventList.viewAnEvent')
    } else if (this.info.type === 'edit') {
      this.dialogTitle = this.$t('eventList.editEvent')
    } else {
      this.dialogTitle = this.$t('eventList.createEvent')
    }
    Bus.$on('eventInfoUpdate', (data) => {
      if (data.clientId != this.State.clientId) {
        const { id } = data
        // 在此更新用户在coordinationRoom的编辑事件
        this.getEventInfo(id)
      }
    })
    if (!this.info.id) {
      this.getMediaOutPutList()
    }
    Bus.$emit('changeToReceiver')
    if (this.info.type == 'creat') {
      this.getElasticIp()
    }
    this.getQualityFeature()
    this.getRArray('init')
    this.getBackupRArray('init')
  },
  methods: {
    getQualityFeature () {
      this.$http.get('/commentator-backend/userSetting/findByKey/quality')
        .then(res => {
          if (res.data.errorCode === '0x0') {
            this.qualityFeature = res.data.result === 'true'
          }
        }).catch(error => {
          console.log(error)
        })
    },
    dealInviteEvent (info) {
      const { type, val } = info
      if (type === 'changeModel') {
        this.invitedInfo.inviteMode = val
      }
    },
    clickBackupReceiverPeerId (item) {
      if (this.receiverPeerId === item.peerId) {
        this.$message.error(`${item.name} ` + this.$t('otherWords.onlySelectedOnce'))
      }
    },
    clickReceiverPeerId (item) {
      if (this.backupReceiverPeerId === item.peerId && this.receiverBackupBtnVal) {
        this.$message.error(`${item.name} ` + this.$t('otherWords.onlySelectedOnce'))
      }
    },
    getFormat () {
      this.$http.get(`https://${this.State.config.urlInfo.gateway}/newppapi/user/available-features`)
        .then(res => {
          if (res.data.errorCode === '0x0') {
            this.advanceSettings = res.data.result
            this.formats = res.data.result.format.options
          }
        }).catch(error => {
          console.log(error)
        })
    },
    showFoot (obj, type) {
      this.showAdvanceds = true
      if (type == 'rtmp') {
        this.$set(this.selectInputRecord, this.inputType, obj)
      } else if (type == 'rtmpBackUp') {
        this.$set(this.selectBackupRecord, this.backupType, obj)
      } else if (type == 'advancedSettings') {
        // 获取checkfeature相关状态
        this.getFeatureInfo()
      }
      // 重新加载media对应的ipout组件
      this.getMediaOutPutList()
    },
    changeConfig (obj) {
      this.producerSettingsDto.rps = obj.checkedRps
      this.producerSettingsDto.pgmRecording = obj.checkedPgmRecording
      this.producerSettingsDto.replayApp = obj.checkedReplayApp
      this.producerSettingsDto.outputDelayTime = obj.outPutBufferValue
      this.producerSettingsDto.outputDelay = obj.checkedOutPutBuffer
      this.producerSettingsDto.cloudRecord = obj.checkedInputSourceRecording
      this.producerSettingsDto.recordSources = obj.cloudRecordChecked.toString()
      this.producerSettingsDto.delay = obj.bitrateValue

      // 比特率，码率，分辨率
      this.inputPreviewSetting.bitrate = obj.mainWindowBitrate.toString() + 'K'
      this.inputPreviewSetting.resolution = obj.mainWindowResolution
      this.inputPreviewSetting.frameRate = obj.mainWindowRate

      this.finalPgmPreviewSetting.bitrate = obj.mainWindowBitrate.toString() + 'K'
      this.finalPgmPreviewSetting.resolution = obj.mainWindowResolution
      this.finalPgmPreviewSetting.frameRate = obj.mainWindowRate

      this.advancedSetting.quality = obj.quality
    },
    showAdvanced () {
      // this.info.show = false
      this.advancedSettingsInfo.show = true
      this.showAdvanceds = false
      // this.$emit('showAavanced')
    },
    openDialog () {
      this.$emit('open')
    },
    handleScroll (e) {
      this.scrolNumber = e.target.scrollTop;
    },
    showGridSetting () {
      this.visible = !this.visible
      const number = -(Math.random().toFixed(5) * 100000)
      const obj = {
        show: true,
        type: 'ISSP',
        outputTitle: 'Grid Settings',
        id: this.State.mediaInitParams.addAccountId,
        targetId: number + '',
        customTag: 'commentator-default'
      }
      try {
        mediaObj.openOutput(obj)
      } catch (e) {
        console.log('mediaObj is not defined')
      }
    },
    showEditGridSetting () {
      this.visible = !this.visible
      this.viewSetting.id = this.eventInfo.defaultGrid.value.id
      const obj = {
        show: true,
        type: 'ISSP',
        outputTitle: 'Grid Settings',
        id: this.viewSetting.id,
        targetId: '',
        readonly: true,
        customTag: 'commentator-default'
      }
      try {
        mediaObj.openOutput(obj)
      } catch (e) {
        console.log('mediaObj is not defined')
      }
    },
    getBackupReceiverList (type, val) {
      if (type === 'filter') {
        if (this.getBackupRListTimer) clearTimeout(this.getBackupRListTimer)
        this.backupScroll.noMore = false
        this.getBackupRListParams.pageNum = 1
        this.getBackupRListParams.condition = val
        this.getBackupRListTimer = setTimeout(() => {
          this.getBackupRArray(type)
        }, 1000)
      } else if (type === 'getList') {
        this.backupLoading = true
        if (val) {
          // 再次选择下拉框默认回到最初位置
          this.getBackupRListParams.condition = ''
          this.getBackupRListParams.pageNum = 1
          this.backupScroll.noMore = false
          this.backupRList = []
          this.getBackupRArray(type)
        } else {
          this.getBackupRListParams.condition = ''
        }
      } else if (type === undefined) {
        this.getBackupRListParams.pageNum++
        this.getBackupRArray(type)
      }
    },
    getBackupRArray (type) {
      if (this.backupScroll.noMore && !type) return
      this.backupScroll.loading = true
      this.$http
        .post('/commentator-backend/user/source/output/receiver/page', this.getBackupRListParams)
        .then(res => {
          const { errorCode } = res.data
          if (errorCode === '0x0') {
            if (type) {
              this.backupRList = res.data.result.list
            } else {
              this.backupRList = this.backupRList.concat(res.data.result.list)
            }
            this.backupScroll.noMore = !res.data.result.hasNextPage
          }
          this.backupScroll.loading = false
          this.backupLoading = false
        })
    },
    // 物理R下拉框出现调用
    getReceiverList (type, val) {
      if (type === 'filter') {
        if (this.getRListTimer) clearTimeout(this.getRListTimer)
        this.scroll.noMore = false
        this.getRListParams.pageNum = 1
        this.getRListParams.condition = val
        this.getRListTimer = setTimeout(() => {
          this.getRArray(type)
        }, 1000)
      } else if (type === 'getList') {
        this.loading = true
        if (val) {
          // 再次选择下拉框默认回到最初位置
          this.getRListParams.condition = ''
          this.getRListParams.pageNum = 1
          this.scroll.noMore = false
          this.rList = []
          this.getRArray(type)
        } else {
          this.getRListParams.condition = ''
        }
      } else if (type === undefined) {
        this.getRListParams.pageNum++
        this.getRArray(type)
      }
    },
    getRArray (type) {
      if (this.scroll.noMore && !type) return
      this.scroll.loading = true
      this.$http
        .post('/commentator-backend/user/source/output/receiver/page', this.getRListParams)
        .then(res => {
          const { errorCode } = res.data
          if (errorCode === '0x0') {
            if (type) {
              this.rList = res.data.result.list
            } else {
              this.rList = this.rList.concat(res.data.result.list)
            }
            this.scroll.noMore = !res.data.result.hasNextPage
          }
          this.scroll.loading = false
          this.loading = false
        })
    },
    getNextList () {
      this.$refs.selectScroll.getNextPage()
    },
    getSourceInfo (val, type) {
      this.changeInputType('sdi')
      if (type === 'main') {
        this.requestInfo.url = this.requestInfo.url + `/${this.receiverPeerId}`
      } else {
        this.backupRequestInfo.url = this.backupRequestInfo.url + `/${this.backupReceiverPeerId}`
      }
    },
    async defaultSet (val) {
      // 切换物理R和pp时清空默认值
      if (this.$refs.selectScroll) {
        this.$refs.selectScroll.setSelectSourceDefault()
      }
      this.fileInfo.id = ''
      if (!val) {
        this.inputType = 'vfb'
        this.receiverPeerId = ''
        this.receiverInputType = ''
        this.backupReceiverInputType = ''
        this.purpose = 1
        this.receiverBackupBtnVal = false
        const request = this.requestSource.vfb
        this.requestInfo = JSON.parse(JSON.stringify(request))
        this.backupRequestInfo = {}
      } else {
        this.backupBtnVal = false
        this.purpose = 1
        this.receiverInputType = 'sdi'
        this.backupReceiverInputType = 'sdi'
        const request = this.requestSource.sdi
        this.requestInfo = JSON.parse(JSON.stringify(request))
        this.backupRequestInfo = {}
      }
      if (this.$refs.receiverCheckFeature) {
        this.$refs.receiverCheckFeature.setDefault()
      }
      if (this.$refs.backupReceiverCheckFeature) {
        this.$refs.backupReceiverCheckFeature.setDefault()
      }
    },
    async getRList (val, key) {
      const isMainSelect = key === 'main'
      this.changeInputType('sdi', '', key)
      if (isMainSelect) {
        this.requestInfo.url = this.requestInfo.url + `/${this.receiverPeerId}`
      } else {
        this.backupRequestInfo.url = this.backupRequestInfo.url + `/${this.backupReceiverPeerId}`
      }
      const hadFeature = isMainSelect ? await this.$refs.receiverCheckFeature.checkRFeature(this.receiverPeerId) : await this.$refs.backupReceiverCheckFeature.checkRFeature(this.backupReceiverPeerId)
      if (hadFeature) {
        if (isMainSelect) {
          this.rList.forEach(item => {
            if (item.peerId == val) {
              this.receiverOutputName = item.name
            }
          })
        } else {
          this.backupRList.forEach(item => {
            if (item.peerId == val) {
              this.backupReceiverOutputName = item.name
            }
          })
        }
      } else {
        isMainSelect ? this.receiverOutputName = '' : this.backupReceiverOutputName = ''
      }
      if (isMainSelect) {
        if (this.$refs.selectScroll) {
          this.$refs.selectScroll.setSelectSourceDefault()
        }
        if (this.selectInputRecord[this.inputType]) {
          this.selectInputRecord[this.inputType].id = ''
          this.selectInputRecord[this.inputType].name = ''
        }
      } else {
        if (this.$refs.backupSelectScroll) {
          this.$refs.backupSelectScroll.setSelectSourceDefault()
        }
        if (this.selectInputRecord.backupReceiver) {
          this.selectInputRecord.backupReceiver.id = ''
          this.selectInputRecord.backupReceiver.name = ''
        }
      }
    },
    changeReceiverSetting (val, info) {
      this.receiverSetting.show = val
      this.receiverSetting.info = info
    },
    closeReceiverSetting () {
      this.receiverSetting.show = false
    },
    reuseEvent () {
      this.isReuse = true
      this.startTime = ''
      this.endTime = ''
      this.audioMixer = ''
      this.eventInfo.ppUrl = ''
      if (this.producerSettingsDto && this.producerSettingsDto.elasticIp && !this.isUnbind) {
        this.editElasticIP = false
      }
    },
    changeTime () {
      if (this.durationType.unit == 'min') {
        if (this.duration > 100 * 60) {
          this.duration = 100 * 60
        }
        if (this.duration < 10) {
          this.duration = 10
        }
      }
      if (this.durationType.unit == 'hour') {
        if (this.duration > 100) {
          this.duration = 100
        }
        if (this.duration < 1) {
          this.duration = 1
        }
      }
    },
    changeBackupBtn () {
      // 取消勾选backup清除数据
      if (!this.receiverBackupBtnVal) {
        this.backupReceiverPeerId = ''
      }
      if (!this.requestBackupInfo.url && this.backupBtnVal) {
        this.getRequest('backup')
      }
    },
    applyInputType () {
      if (this.eventInfo.mainWindowType == this.purpose) {
        return
      }
      this.$http
        .post(
          `/commentator-backend/event/v2/input/switch/${this.info.id}/${this.purpose}?clientId=${this.State.clientId}`
        )
        .then((res) => {
          if (res.data.errorCode == '0x0') {
            this.$message.success(this.$t('eventList.switchInputSuccess'))
            this.eventInfo.mainWindowType = this.purpose
          }
        })
    },
    applyBkInputType () {
      if (this.eventInfo.mainWindowType == this.purpose) {
        return
      }
      this.$http
        .post(
          `/commentator-backend/event/v2/input/switch/${this.info.id}/${this.purpose}?clientId=${this.State.clientId}&platform=1`
        )
        .then((res) => {
          if (res.data.errorCode == '0x0') {
            this.$message.success(this.$t('eventList.switchInputSuccess'))
            this.eventInfo.mainWindowType = this.purpose
          }
        })
    },
    searchOutputFn () {
      if (this.searchOutputType == 1) {
        this.searchOutputTypeLabel = 'Receiver' + Math.random()
        this.receiverRequest.params.pageNum = 1
        this.receiverRequest.params.condition = this.searchOutput
      } else {
        this.searchOutputTypeLabel = 'IP OUT' + Math.random()
        try {
          mediaObj.updataSocial({
            nickName: this.searchOutput
          })
          this.showCaret = true
        } catch (e) {
          console.log('mediaObj is not defined')
        }
      }
    },
    hidePopup () {
      if (this.$refs.popoverGrid) {
        this.$refs.popoverGrid.doClose() // 关闭popover
      }
    },
    closeGridSettingDialog () {
      this.viewSetting.show = false
      this.showCreateDialog = false
    },
    selectSourceType (val) {
      if (this.info.type == 'view' && !this.isReuse) return
      this.purpose = val
    },
    outputCallback (obj) {
      const { id, name, outputType } = obj
      const params = {
        id,
        name,
        type: outputType
      }
      if (obj.type == 'add') {
        if (obj.output == 'Receiver') {
          this.selectOutputR.push(params)
        }
        if (obj.output == 'IP OUT') {
          this.selectOutputIP.push(params)
        }
      } else if (obj.deleteType) {
        this.cancelSelectedR = []
      } else {
        if (obj.output == 'Receiver') {
          this.selectOutputR = this.selectOutputR.filter((v) => v.id != obj.id)
        }
        if (obj.output == 'IP OUT') {
          this.selectOutputIP = this.selectOutputIP.filter(
            (v) => v.id != obj.id
          )
        }
      }
      console.info(548, obj)
    },
    changeInputType (val, type, key) {
      this.rtmpBackType = type
      if (type == 'backup') {
        this.backupType = val
        if (val == 'rtmp') {
          // 显示rtmp弹窗,隐藏create弹窗
          this.rtmpPushInfoBackup.show = true
          this.showAdvanceds = false
        }
      } else {
        if (val != 'sdi') {
          this.receiverPeerId = ''
          this.backupReceiverPeerId = ''
        }
        this.inputType = val
        if (val == 'file') {
          this.backupTypeList.forEach((v) => {
            v.options.forEach((val, index) => {
              if (val.value == 'file') {
                this.$set(val, index, { ...val, disabled: true })
              }
            })
          })
        }
        if (val == 'rtmp') {
          // 显示rtmp弹窗,隐藏create弹窗
          this.rtmpPushInfo.show = true
          this.showAdvanceds = false
        }
      }
      if (val != 'file') {
        this.getRequest(type, key)
      } else {
        this.producerSettingsDto.region = this.fileInfo.region || localStorage.getItem('region')
      }
    },
    // 显示rtmp,隐藏create弹窗
    showRtmp (val) {
      this.rtmpBackType = val
      this.rtmpPushInfo.show = true
      this.showAdvanceds = false
    },
    showRtmpBackUp (val) {
      this.rtmpBackType = val
      this.rtmpPushInfoBackup.show = true
      this.showAdvanceds = false
    },
    getRequest (type, key) {
      // 获取请求url和参数
      let request = {}
      let sourceType = null

      if (type == 'backup') {
        sourceType = this.backupType
      } else {
        sourceType = this.inputType
      }
      if (sourceType == 'vfb') {
        request = this.requestSource.vfb
      } else if (sourceType == 'ext' || sourceType == 'grid') {
        request = this.requestSource.pp
      } else if (sourceType == 'pack' || sourceType == 'anywhere') {
        request = this.requestSource.cc
      } else if (sourceType == 'slot') {
        request = this.requestSource.slot
      } else if (sourceType == 'sdi') {
        request = this.requestSource.sdi
      } else if (sourceType == 'rtmp') {
        request = this.requestSource.rtmp
      }

      if (type == 'backup') {
        this.requestBackupInfo = JSON.parse(JSON.stringify(request))
        this.requestBackupInfo.id = this.selectBackupRecord[this.backupType]
          ? this.selectBackupRecord[this.backupType].id
          : ''
        if (
          this.sourceTypePamrs[this.backupType] &&
          this.sourceTypePamrs[this.backupType].value
        ) {
          this.requestBackupInfo.params.sourceType = this.sourceTypePamrs[
            this.backupType
          ].value
        }
      } else {
        if (this.inputType == 'sdi') {
          if (key === 'main') {
            this.requestInfo = JSON.parse(JSON.stringify(request))
            this.requestInfo.id = this.selectInputRecord[this.inputType] ? this.selectInputRecord[this.inputType].id : ''
            if (this.sourceTypePamrs[this.inputType] && this.sourceTypePamrs[this.inputType].value) {
              this.requestInfo.params.sourceType = this.sourceTypePamrs[
                this.inputType
              ].value
            }
          } else {
            this.backupRequestInfo = JSON.parse(JSON.stringify(request))
            this.backupRequestInfo.id = this.selectInputRecord.backupReceiver ? this.selectInputRecord.backupReceiver.id : ''
            if (this.sourceTypePamrs[this.inputType] && this.sourceTypePamrs[this.inputType].value) {
              this.backupRequestInfo.params.sourceType = this.sourceTypePamrs[
                this.inputType
              ].value
            }
          }
        } else {
          this.requestInfo = JSON.parse(JSON.stringify(request))
          this.requestInfo.id = this.selectInputRecord[this.inputType] ? this.selectInputRecord[this.inputType].id : ''
          if (this.sourceTypePamrs[this.inputType] && this.sourceTypePamrs[this.inputType].value) {
            this.requestInfo.params.sourceType = this.sourceTypePamrs[
              this.inputType
            ].value
          }
        }
      }
    },
    resetTime (tag) {
      if (!this.isReuse && (this.info.type == 'view' || this.info.type == 'edit')) {
        return
      }
      if (tag == 'now') {
        this.timeControl = false
      } else {
        this.timeControl = true
        this.startTime = this.info.id ? this.startTime : ''
        this.endTime = this.info.id ? this.endTime : ''
      }
    },
    changeDuration (val) {
      this.durationType = val
      this.duration = val.time
    },
    verifyTime (startTime) {
      let btn = true
      if (this.endTime && this.startTime && this.endTime - this.startTime < 0) {
        this.$message.error(this.$t('otherWords.endTimeGreater'))
        btn = false
      }
      if (btn && this.endTime && this.startTime && this.endTime - this.startTime < 10 * 60 * 1000) {
        this.$message.error(this.$t('eventList.eventTimeTips'))
        btn = false
      }
      if (startTime < new Date().getTime()) {
        this.startTime = new Date().getTime()
      }
      return btn
    },
    changeStartTime (startTime) {
      this.verifyTime(startTime)
    },
    changeEndTime (val) {
      const timeStr = typeof val === 'object' ? val.getTime() : val
      // 如果结束时间前于当前时间
      if (timeStr <= new Date().getTime()) {
        this.endTime = new Date().getTime() + 60 * 60 * 1000
      }
      let startTime = 0
      if (this.startTime && this.startTime != '') {
        startTime =
          typeof this.startTime === 'number'
            ? this.startTime
            : this.startTime.getTime()
      }
      if (this.endTime <= startTime) {
        this.endTime = startTime + 60 * 60 * 1000
      }
      this.verifyTime()
    },
    async selectInputChange (val, type, backupReceiver) {
      if (type == 'backup') {
        this.$set(this.selectBackupRecord, this.backupType, val)
        if (this.backupType == 'file') {
          this.backupFileInfo = val
        }
        // 去除selectInputRecord里面和用户所选择的slot值相同的属性
        if (this.selectInputRecord && this.selectInputRecord.slot) {
          if (this.selectInputRecord.slot.id === val.id) {
            delete this.selectInputRecord.slot
          }
        }
        // 禁止用户在输入源中选择和备用输入中一样的slot值
        this.$set(this.disableInputSlot, '0', val.id)
        // this.$set(this.disableInput, '0', val.id)
        // 如果用户在备用输入中选择了slot5,则禁用输入源中的上传文件
        if (val.id === 's5') {
          this.disabledInputFile(true)
          this.disabledMessage = this.$t('otherWords.fileAndSlotOnlyOne')
        } else {
          this.disabledInputFile()
          this.disabledMessage = ''
        }
        // 如果用户选择了备用输入上传文件中的具体某一项，同样禁用掉输入源中的s5
        if (this.backupType === 'file') {
          this.$set(this.disableInputSlot, '0', 's5')
          this.disabledInputFile(true)
        } else if (this.backupType === 'slot') {
          this.$set(this.disableInputSlot, '0', val.id)
        } else if (this.backupType != 'vfb' && this.backupType != 'pack') {
          this.disableInputSlot = []
        }
      } else {
        if (backupReceiver) {
          this.$set(this.selectInputRecord, 'backupReceiver', val)
        } else {
          this.$set(this.selectInputRecord, this.inputType, val)
        }
        if (this.inputType == 'file') {
          this.fileInfo = val
        }
        // 去除selectBackupRecord里面和用户所选择的slot值相同的属性
        if (this.selectBackupRecord && this.selectBackupRecord.slot) {
          if (this.selectBackupRecord.slot.id === val.id) {
            delete this.selectBackupRecord.slot
          }
        }
        // 禁止用户在备用输入中选择和输入源中一样的slot值
        this.$set(this.disableBackInputSlot, '0', val.id)
        // 用户在输入源中选择了slot5,禁用backupIn的选择文件
        if (val.id === 's5') {
          this.disabledBackUpFile(true)
          this.disabledBackMessage = this.$t('otherWords.fileAndSlotOnlyOne')
        } else {
          // 用户在输入源中选择的不是slot5，则取消禁用选择文件
          this.disabledBackUpFile()
          this.disabledBackMessage = ''
        }
        // 如果用户选择了输入源上传文件中的具体某一项，同样禁用掉输入源中的s5
        if (this.inputType === 'file') {
          this.$set(this.disableBackInputSlot, '0', 's5')
          // 禁用上传文件
          this.disabledBackUpFile(true)
          await this.getFileRegion(val)
        } else if (this.inputType === 'slot') {
          this.$set(this.disableBackInputSlot, '0', val.id)
        } else if (this.inputType != 'vfb' && this.inputType != 'pack') {
          this.disableBackInputSlot = []
        }
      }
    },
    getFileRegion (val) {
      const protocol = window.location.protocol
      const url = `${protocol}//${this.State.config.urlInfo.gateway}/drive`
      this.$http
        .get(`${url}/file/get/${val.id}`)
        .then((res) => {
          if (res.data.errorCode === '0x0') {
            this.producerSettingsDto.region = res.data.result.region
            this.fileInfo.region = res.data.result.region
          }
        })
    },
    getRegionList (val) {
      this.$http
        .post('/commentator-backend/event/listRegion', {
          language: 'en',
          condition: val
        })
        .then((res) => {
          if (res.data.errorCode == '0x0') {
            this.regionList = res.data.result
          }
        })
    },
    getEventInfo (id) {
      if (!this.info.id) return
      const eventId = id || this.info.id
      this.scrollS.loading = true
      this.$http
        .post(`/commentator-backend/event/v2/${eventId}`)
        .then(async (res) => {
          this.showDetail = true
          this.getMediaOutPutList()
          if (res.data.errorCode == '0x0') {
            const result = res.data.result
            this.eventInfo = res.data.result
            this.eventRid = res.data.result.rid
            if (result.partyExtendInfoVO) {
              this.invitedInfo = result.partyExtendInfoVO
            }
            // 用于实时更新mainwindow结束时间
            this.$emit('updateEndTime', this.eventInfo.endTime)
            // 判断是否需要编辑ipout
            if (this.eventInfo.status >= 4) {
              this.showEditIpout = false
            }
            this.eventInfo.receivers.forEach(item => {
              this.rLiveIsspInfo[item.id] = item.pushLiveUrl
            })
            const {
              title,
              startTime,
              endTime,
              description,
              eventParties,
              audioMixTaskId,
              inputList,
              mainWindowType,
              producerSettingsDto,
              receivers,
              productionId,
              advancedSetting,
              inputPreviewSetting,
              finalPgmPreviewSetting
            } = this.eventInfo
            this.title = title
            this.startTime = startTime
            this.endTime = endTime
            this.producerSettingsDto = producerSettingsDto || this.producerSettingsDto
            // 等待eventInfo获取后，查询ip数据再做对比
            await this.getElasticIp()
            this.scrollS.loading = false
            this.inputPreviewSetting = inputPreviewSetting || this.inputPreviewSetting
            this.finalPgmPreviewSetting = finalPgmPreviewSetting || this.finalPgmPreviewSetting
            // 比特率，码率，分辨率
            this.State.advanceSettingInfo.mainWindowBitrate = Number(this.inputPreviewSetting.bitrate.replace('K', ''))
            this.State.advanceSettingInfo.mainWindowResolution = this.inputPreviewSetting.resolution
            this.State.advanceSettingInfo.mainWindowRate = this.inputPreviewSetting.frameRate

            this.State.advanceSettingInfo.mainWindowBitrate = Number(this.finalPgmPreviewSetting.bitrate.replace('K', ''))
            this.State.advanceSettingInfo.mainWindowResolution = this.finalPgmPreviewSetting.resolution
            this.State.advanceSettingInfo.mainWindowRate = this.finalPgmPreviewSetting.frameRate

            this.State.advanceSettingInfo.bitrateValue = this.producerSettingsDto.delay
            this.State.advanceSettingInfo.checkedRps = this.producerSettingsDto.rps
            this.State.advanceSettingInfo.checkedPgmRecording = this.producerSettingsDto.pgmRecording
            this.State.advanceSettingInfo.checkedReplayApp = this.producerSettingsDto.replayApp
            this.State.advanceSettingInfo.checkedOutPutBuffer = this.producerSettingsDto.outputDelay
            this.State.advanceSettingInfo.outPutBufferValue = this.producerSettingsDto.outputDelayTime
            this.State.advanceSettingInfo.checkedInputSourceRecording = this.producerSettingsDto.cloudRecord
            if (advancedSetting) {
              this.State.advanceSettingInfo.advancedSetting = advancedSetting
              this.qualityFeature = true
            } else {
              this.qualityFeature = false
            }

            if (res.data.result.ppDomainUrl) {
              this.domain = res.data.result.ppDomainUrl.split('//')[1]
              this.$store.commit('setDomain', this.domain)
            }
            if (this.producerSettingsDto.recordSources) {
              this.State.advanceSettingInfo.cloudRecordChecked = this.producerSettingsDto.recordSources.split(',')
            }
            if (this.eventInfo.status == 4) {
              try {
                mediaObj.rId = this.eventInfo.rid
              } catch (e) {
                console.log('mediaObj is not defined')
              }
            } else {
              try {
                mediaObj.rId = ''
              } catch (e) {
                console.log('mediaObj is not defined')
              }
            }
            if (producerSettingsDto && producerSettingsDto.elasticIp) {
              this.isShowElasticIP = true
              this.editElasticIP = true
            }
            this.description = description
            // 拼接audioMixerUrl
            if (this.Config.urlInfo.audioMixerUrl && audioMixTaskId) {
              this.audioMixer = this.Config.urlInfo.audioMixerUrl + '/' + audioMixTaskId
            }
            // this.audioMixer = audioMixUrl // 更新audioMixUrl
            // 拼接ProducerUrl(ppUrl)
            if (this.Config.urlInfo.producerStudioUrl && productionId) {
              this.eventInfo.ppUrl = this.Config.urlInfo.producerStudioUrl + productionId
            }
            this.partyList = eventParties ? JSON.parse(JSON.stringify(eventParties)) : []
            this.selectOutputR = this.eventInfo.receivers || []
            this.$store.state.mediaInitParams.selectedOutput = this.eventInfo.templates || []
            this.$store.state.mediaInitParams.selectedOutput.forEach(info => {
              receivers.forEach(item1 => {
                if (item1.liveOutputId == info.id) {
                  this.$set(info, 'disabled', true)
                }
              })
            })
            let btn = false
            inputList &&
              inputList.forEach((val) => {
                const { purpose, type, value } = val
                if (purpose == 1) {
                  btn = true
                  this.inputType = type
                  if (type == 'file') {
                    this.changeRtmpType = ''
                    this.fileInfo.name = value.name
                    this.fileInfo.id = value.id
                  } else if (type == 'rtmp') {
                    // rtmp弹窗数据
                    this.selectInputRecord[this.inputType] = value
                    this.changeRtmpType = 'rtmp'
                    this.getRequest(type)
                    this.State.rtmpName = value.name
                    this.State.serverValue = value.server
                    this.State.keyValue = value.key
                  } else {
                    this.changeRtmpType = ''
                    this.selectInputRecord[this.inputType] = value
                    this.getRequest(type)
                    this.requestInfo.name = value ? value.name : null
                    this.backupRequestInfo.name = value ? value.name : null
                  }
                }
                if (purpose == 2) {
                  // 如果是 sdi 需要同步 inputType
                  if (type === 'sdi') {
                    this.inputType = type
                  }
                  if (type == 'file') {
                    this.changeRtmpType = ''
                    this.backupFileInfo.name = value.name
                    this.backupFileInfo.id = value.id
                  }
                  if (type != 'pgm' && type != 'rtmp' && type != 'sdi') {
                    this.changeRtmpType = ''
                    this.backupType = type
                    this.selectBackupRecord[type] = value
                    this.receiverBackupBtnVal = true
                    this.backupBtnVal = true
                    this.purpose = mainWindowType
                    this.getRequest('backup')
                    this.requestBackupInfo.name = value ? value.name : null
                  } else if (type == 'rtmp') {
                    this.backupType = type
                    // backup的对勾
                    this.receiverBackupBtnVal = true
                    this.backupBtnVal = true
                    // rtmp弹窗数据
                    this.selectBackupRecord[this.backupType] = value
                    this.changeRtmpType = 'rtmp'
                    this.getRequest('backup')
                    this.State.rtmpNameBackup = value.name
                    this.State.serverValueBackup = value.server
                    this.State.keyValueBackup = value.key
                  }
                }
                // 为了 sdi in 添加的
                if (this.inputType === 'sdi') {
                  btn = true
                  this.inputType = type
                  this.receiverType = 1
                  this.receiverInputType = 'sdi'
                  this.purpose = mainWindowType
                  if (purpose == 1) {
                    this.receiverPeerId = value.rid
                    this.selectInputRecord[this.inputType] = value
                    this.receiverOutputName = value.receiverName
                    this.getRequest(type, 'main')
                    this.requestInfo.name = value ? value.name : null
                    this.requestInfo.url = this.requestInfo.url + `/${this.eventInfo.rid}`
                  } else {
                    this.getRequest(type)
                    this.selectInputRecord.backupReceiver = value
                    this.receiverBackupBtnVal = true
                    this.backupReceiverInputType = type
                    this.backupRequestInfo.name = value ? value.name : null
                    this.backupReceiverPeerId = value.rid
                    this.backupReceiverOutputName = value.receiverName
                    this.backupRequestInfo.url = this.backupRequestInfo.url + `/${value.rid}`
                  }
                }
              })
            if (!btn) {
              this.getRequest('input')
            }
            this.getFeatureInfo()
          }
        })
    },
    // 获取checkfeature相关状态
    getFeatureInfo () {
      this.$nextTick(() => {
        if (this.receiverBackupBtnVal) {
          if (this.$refs.receiverCheckFeature && this.receiverPeerId) {
            this.$refs.receiverCheckFeature.checkRFeature(this.receiverPeerId)
          }
          if (this.$refs.backupReceiverCheckFeature && this.backupReceiverPeerId) {
            this.$refs.backupReceiverCheckFeature.checkRFeature(this.backupReceiverPeerId)
          }
        } else {
          if (this.$refs.receiverCheckFeature && this.receiverPeerId) {
            this.$refs.receiverCheckFeature.checkRFeature(this.receiverPeerId)
          }
        }
      })
    },
    addGroup () {
      this.partyList.push({ partyCode: '', title: '' })
    },
    deleteFn (key) {
      this.partyList.splice(key, 1)
    },
    copyLink (val, type) {
      if (type == 'partyCode') {
        const domain = window.location.host
        return (
          this.$t('otherWords.copyLinkTips') +
          'https://' +
          domain +
          '/commentator?join_id=' +
          val.partyCode +
          '\n\n' +
          'Party ID: ' +
          this.$options.filters.returnStrByInfo(val.partyCode, 4)
        )
        // const joinLink = this.Config.urlInfo.joinLink
        // return (
        //   this.$t('otherWords.copyLinkTips') +
        //   joinLink +
        //   '?join_id=' +
        //   val.partyCode +
        //   `${apiEnv ? '&env=' + apiEnv : ''}` +
        //   '\n\n' +
        //   'Party ID: ' +
        //   this.$options.filters.returnStrByInfo(val.partyCode, 4)
        // )
      } else {
        return val
      }
    },
    onCopy () {
      this.hidePopup()
      this.$message({
        message: this.$t('otherWords.copySuccess'),
        type: 'success'
      })
    },
    onError () {
      this.$message({
        message: 'Copy failed',
        type: 'error'
      })
    },
    done () {
      this.info.show = false
      if (this.info.show == false && this.advancedSettingsInfo.show == false) {
        this.State.advanceSettingInfo = {
          bitrateValue: 2,
          mainWindowBitrate: 2000,
          mainWindowResolution: '1280x720',
          mainWindowRate: '30',
          checkedRps: 0,
          checkedPgmRecording: 1,
          checkedReplayApp: 0,
          checkedOutPutBuffer: 0,
          outPutBufferValue: 2,
          checkedInputSourceRecording: 0,
          cloudRecordChecked: [],
          advancedSetting: {
            quality: '360p'
          }
        }
      }
      if (this.info.show == false && (this.rtmpPushInfo.show == false || this.rtmpPushInfoBackup.show == false)) {
        this.State.rtmpName = ''
        this.State.serverValue = ''
        this.State.keyValue = ''
        this.State.rtmpNameBackup = ''
        this.State.serverValueBackup = ''
        this.State.keyValueBackup = ''
      }
      this.$emit('closeDialog')
    },
    getInputOutputData (isspId) {
      const eventInputDto = []
      const eventOutputDto = []
      // 组装input数据
      if ((this.selectInputRecord[this.inputType] && this.selectInputRecord[this.inputType].id) || this.inputType == 'pgm') {
        if (this.inputType === 'sdi') {
          const selectReceiverInfo = this.rList.filter(item => {
            return item.peerId == this.receiverPeerId
          })
          eventInputDto.push({
            purpose: 1,
            type: this.inputType,
            value: { ...this.selectInputRecord[this.inputType], rid: this.receiverPeerId, receiverName: selectReceiverInfo[0].name }
          })
          // 增加发送 backup receiver 数据
          if (this.receiverBackupBtnVal && this.selectInputRecord.backupReceiver && this.selectInputRecord.backupReceiver.id) {
            const selectBkReceiverInfo = this.backupRList.filter(item => {
              return item.peerId == this.backupReceiverPeerId
            })
            eventInputDto.push({
              purpose: 2,
              type: this.inputType,
              value: { ...this.selectInputRecord.backupReceiver, rid: this.backupReceiverPeerId, receiverName: selectBkReceiverInfo[0].name }
            })
          }
        } else {
          eventInputDto.push({
            purpose: 1,
            type: this.inputType,
            value: this.selectInputRecord[this.inputType]
          })
        }
      }
      // backUp
      if (this.backupBtnVal && (this.selectBackupRecord[this.backupType] || this.backupType == 'pgm')) {
        if (!eventInputDto.some(item => item.type === 'pgm')) {
          eventInputDto.push({
            purpose: 2,
            type: this.backupType,
            value: this.selectBackupRecord[this.backupType]
          })
        }
      }
      if (this.selectOutputR.length || this.$store.state.mediaInitParams.selectedOutput.length) {
        this.selectOutputR.forEach((v) => {
          isspId ? v.liveOutputId = isspId : v.liveOutputId = ''
          eventOutputDto.push({
            type: 'receiver',
            value: v
          })
        })
        this.$store.state.mediaInitParams.selectedOutput.forEach(value => {
          value.name = value.nickName
          eventOutputDto.push({
            type: 'ip_out',
            value
          })
        })
      }

      return { eventInputDto, eventOutputDto }
    },
    controlLiveIssp (isspId) {
      if (isspId === 'defaultGrid') return
      this.selectIsspId = isspId
    },
    async save () {
      // 清除advanceSetting数据
      this.State.advanceSettingInfo = {
        bitrateValue: 2,
        mainWindowBitrate: 2000,
        mainWindowResolution: '1280x720',
        mainWindowRate: '30',
        checkedRps: 0,
        checkedPgmRecording: 1,
        checkedReplayApp: 0,
        checkedOutPutBuffer: 0,
        outPutBufferValue: 2,
        checkedInputSourceRecording: 0,
        cloudRecordChecked: [],
        advancedSetting: {
          quality: '360p'
        }
      }
      // 获取组装的input、output数据
      const { eventInputDto, eventOutputDto } = this.getInputOutputData(this.selectIsspId)
      const _this = this
      console.info(
        this.selectInputRecord,
        this.selectOutputR,
        this.selectOutputIP,
        this.producerSettingsDto,
        eventInputDto,
        eventInputDto.filter((v) => v.purpose == 1)
      )
      if (this.inputType === 'sdi') {
        if (
          !_this.partyList.filter((v) => v.title).length || !_this.title ||
          (!_this.startTime && _this.timeControl) ||
          (!_this.endTime && _this.timeControl) ||
          !eventInputDto.filter((v) => v.purpose == 1).length
        ) {
          this.$message.error(this.$t('eventList.createEventTips'))
          return
        }
      } else {
        const purposeOneItems = eventInputDto.filter((v) => v.purpose == 1)
        const purposeOneItemsBackup = eventInputDto.filter((v) => v.purpose == 2)
        const rtmpItems = purposeOneItems.filter((v) => v.type == 'rtmp')
        const rtmpItemsBackup = purposeOneItemsBackup.filter((v) => v.type == 'rtmp')
        if (
          (_this.isShowElasticIP && !_this.producerSettingsDto.elasticIp) ||
          !_this.title ||
          !_this.producerSettingsDto.region ||
          !_this.partyList.filter((v) => v.title).length ||
          (!_this.startTime && _this.timeControl) ||
          (!_this.endTime && _this.timeControl) ||
          !eventInputDto.length ||
          !eventInputDto.filter((v) => v.purpose == 1).length ||
          (rtmpItems.some((v) => !v.value.name || !v.value.key)) ||
          (rtmpItemsBackup.some((v) => !v.value.name || !v.value.key))
        ) {
          this.$message.error(this.$t('eventList.createEventTips'))
          return
        }
      }
      // 清除rtmp数据
      this.State.rtmpName = ''
      this.State.serverValue = ''
      this.State.keyValue = ''
      this.State.rtmpNameBackup = ''
      this.State.serverValueBackup = ''
      this.State.keyValueBackup = ''
      // 验证时间
      if (!this.verifyTime()) return
      // 获取当前服务的时间
      if (!this.timeControl) {
        await this.$http
          .post('/commentator-backend/event/v2/system/time')
          .then((res) => {
            if (res.data.errorCode == '0x0') {
              if (this.info.type === 'creat' || this.isReuse) {
                this.startTime = res.data.result
              }
              let unitTime = 60 * 1000
              if (this.durationType.unit == 'hour') {
                unitTime = 60 * 60 * 1000
              }
              this.endTime = this.startTime + parseInt(this.duration) * unitTime
              // this.endTime = !(this.durationType - 1)
              //   ? this.startTime + 30 * 60 * 1000
              //   : this.startTime + (this.durationType - 1) * 60 * 60 * 1000
            }
          })
      }
      if (this.receiverType == '1') {
        const { replayApp, pgmRecording, rps, outputDelayTime, outputDelay, ...updatedProducerSettingsDto } = this.producerSettingsDto;
        this.producerSettingsDto = updatedProducerSettingsDto;
      }
      const params = {
        startTime: this.startTime,
        endTime: this.endTime,
        producerSettingsDto: this.producerSettingsDto,
        inputPreviewSetting: this.inputPreviewSetting,
        finalPgmPreviewSetting: this.finalPgmPreviewSetting,
        title: this.title,
        description: this.description,
        record: 1,
        eventInputDto,
        eventOutputDto,
        mainWindowType: (this.backupBtnVal && this.receiverType != '1') || (this.receiverBackupBtnVal && this.receiverType == '1') ? this.purpose : 1,
        platform: this.receiverPeerId ? 1 : 0,
        rid: this.receiverPeerId
      }
      if (this.qualityFeature) {
        params.advancedSetting = this.advancedSetting
      }
      if (!this.info.id) {
        if (this.createLoading) return
        this.createLoading = true
        const array = this.partyList.filter((v) => v.title)
        if (array) {
          params.partyTitles = array.map((v) => v.title)
        }
        if (this.State.mediaInitParams.addAccountId) {
          const obj = {
            type: 'ip_out',
            value: {
              id: this.State.mediaInitParams.addAccountId,
              type: 'G'
            }
          }
          eventOutputDto.push(obj)
        }
        this.$http
          .post('/commentator-backend/event/v2/create', params, { errorTips: false })
          .then((res) => {
            if (res.data.errorCode == '0x0') {
              this.showGroupLink = true
              this.dialogTitle = this.$t('eventList.groupLink')
              this.partyList = res.data.result.eventPartyList
              this.invitedInfo = {
                inviteMode: 0,
                partyCode: res.data.result.parentPartyCode

              }
              this.$emit('callback', { type: 'create' })
              // 调用父组件上面的websocket方法
              // this.$parent.sendWebsocketMessage(true);
              // 创建事件之后跳转至第一页
              this.$message({
                dangerouslyUseHTMLString: true,
                message:
                  this.$t('eventList.createEventSuccess') + `&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<strong><span style='text-decoration:underline;font-weight:900; cursor:pointer'>${this.$t('eventList.viewEvent')}</span></strong>`,
                type: 'success'
              }).$el.querySelector('span').onclick = () => {
                this.$emit('callback', { type: 'pagination' })
              }
            } else if (res.data.errorCode == '82302008') {
              this.$message.error(this.$t('eventList.eventRemaining'))
            } else {
              if (res.data.errorCode == '82301008') {
                // this.createFailedInfo.show = true
                this.receiverConflictErrorCode = res.data.errorCode
                this.receiverConflictInfo.show = true
                this.receiverConflictInfo.data = res.data.result
              } else if (res.data.errorCode == '82301017') {
                this.receiverConflictErrorCode = res.data.errorCode
                this.receiverConflictInfo.show = true
                this.receiverConflictInfo.data = res.data.result
              } else if (res.data.errorCode == '82301009') {
                this.sourceConflictInfo.show = true
                this.sourceConflictInfo.data = res.data.result
              } else {
                this.$message.error(res.data.errorInfo)
              }
            }
            this.createLoading = false
          })
      } else {
        params.rid = this.eventRid
        params.eventParties = this.partyList.filter((v) => v.title)
        let url = `/commentator-backend/event/v2/update/${this.info.id}`
        if (this.isReuse) {
          url = `/commentator-backend/event/v2/reuse/${this.info.id}`
          if (!this.isShowElasticIP) {
            this.producerSettingsDto.elasticIp = ''
          }
        }
        this.$http.post(url, params, { errorTips: false }).then((res) => {
          if (res.data.errorCode == '0x0') {
            this.showGroupLink = true
            this.dialogTitle = this.$t('eventList.groupLink')
            this.partyList = res.data.result.eventPartyList
            this.$emit('callback', { type: 'update' })
            // 调用父组件的websocket方法
            // this.$parent.sendWebsocketMessage(true);
          } else {
            if (res.data.errorCode == '82301008') {
              // this.createFailedInfo.show = true
              // this.createFailedInfo.data = res.data.result
              this.receiverConflictErrorCode = res.data.errorCode
              this.receiverConflictInfo.show = true
              this.receiverConflictInfo.data = res.data.result
            } else if (res.data.errorCode == '82301017') {
              this.receiverConflictErrorCode = res.data.errorCode
              this.receiverConflictInfo.show = true
              this.receiverConflictInfo.data = res.data.result
            } else if (res.data.errorCode == '82301009') {
              this.sourceConflictInfo.show = true
              this.sourceConflictInfo.data = res.data.result
            } else {
              this.$message.error(res.data.errorInfo)
            }
          }
        })
      }
    },
    // 禁用备用输入的上传文件
    disabledBackUpFile (isDisabled) {
      if (isDisabled) {
        this.backupTypeList.forEach((v) => {
          v.options.forEach((vals, index) => {
            if (vals.value == 'file') {
              this.$set(v.options, index, { ...vals, disabled: true })
            }
          })
        })
      } else {
      // 取消禁用上传文件
        this.backupTypeList.forEach((v) => {
          v.options.forEach((vals, index) => {
            this.$set(v.options, index, { ...vals, disabled: false })
          })
        })
      }
    },
    // 禁用输入源的上传文件
    disabledInputFile (isDisabled) {
      // 禁用上传文件
      if (isDisabled) {
        this.inputTypeList.forEach((v) => {
          v.options.forEach((vals, index) => {
            if (vals.value === 'file') {
              this.$set(v.options, index, { ...vals, disabled: true })
            }
          })
        })
      } else {
      // 取消禁用上传文件
        this.inputTypeList.forEach((v) => {
          v.options.forEach((vals, index) => {
            this.$set(v.options, index, { ...vals, disabled: false })
          })
        })
      }
    },
    hideCheckedReceiver (values) {
      values.forEach(item => {
        this.selectOutputR.forEach(value => {
          if (value.id === item.receiverId) {
            this.selectOutputR.splice(this.selectOutputR.indexOf(value), 1)
            this.cancelSelectedR.push(value)
          }
        })
      })
    },
    // hideCheckedSource (values) {
    //   values.forEach(item => {
    //     this.selectOutputIP.forEach(value => {
    //       if (value.id === item.receiverId) {
    //         this.selectOutputIP.splice(this.selectOutputIP.indexOf(value), 1)
    //         this.cancelSelectedR.push(value)
    //       }
    //     })
    //   })
    // },
    getMediaOutPutList () {
      const obj = {
        type: 'OutputSetting',
        changeType: 'showOrHide',
        domId: 'mediaOutputList'
      }
      try {
        mediaObj.rId = ''
        mediaObj.shareToggle = true
        SM.__proto__.JumpMediaJs(obj)
      } catch (e) {
        console.log('mediaObj is not defined')
      }
    },
    changeCaret () {
      this.showCaret = !this.showCaret
      if (this.showCaret) {
        const obj = {
          type: 'OutputSetting',
          changeType: 'showOrHide',
          domId: 'mediaOutputList'
        }
        try {
          mediaObj.shareToggle = true
          SM.__proto__.JumpMediaJs(obj)
        } catch (e) {
          console.log('mediaObj is not defined')
        }
      }
      if (!this.showCaret) {
        const mediaOutputList = document.getElementById('mediaOutputList').querySelector('.mediaOutputBox')
        const outPutSetting = document.getElementById('mediaOutputList').querySelector('.outPutSetting')
        if (outPutSetting) {
          outPutSetting.style.display = 'none'
        }
        if (mediaOutputList) {
          mediaOutputList.style.height = 0 + 'px'
        }
      }
    },
    async getElasticIp () {
      try {
        const { data } = await this.$http.get('/commentator-backend/user/findAllElasticIps')
        if (data.result.length) {
          data.result.forEach(item => {
            item.elasticIpAndRegions = item.staticIp + '    (' + item.regionName + ')'
            item.value = { ...item }
            if (item.occupied) {
              item.disabled = true
            }
          })
          this.elasticIpList = data.result
          this.judgeIsUnBindIp()
        } else {
          // 用户没有选择elasticIp的权限，禁用掉checkBox
          this.hasElasticIp = true
        }
      } catch (err) {
        console.log(err);
      }
    },
    showTips () {
      if (!this.elasticIpList.some(item => item.value.occupied === false)) {
        this.$message.error('There is no available IP, please release it to use.')
      }
    },
    async unBindElasticIP () {
      try {
        await this.$http.post(`/commentator-backend/event/v2/ip/unbind/${this.info.id}`)
        this.isUnbind = false
        // 与勾选框联动 由弹性ip变为地区的select
        this.isShowElasticIP = false
        // 解绑后更新列表数据 去除disabled状态
        this.getElasticIp()
      } catch (err) {
        this.isUnbind = true
        console.log(err);
      }
    },
    judgeIsUnBindIp () {
      const item = this.elasticIpList.find(item => item.staticIp === this.producerSettingsDto.elasticIp)
      if (item && item.occupied && item.occupiedInfo.name === this.eventInfo.title) {
        this.isUnbind = true
      }
    }
  }
}
</script>
<style lang="less" scoped>
.el-dialog__wrapper.showGroup {
  .inviteBox {
    display: flex;
    align-items: center;
    padding: 8px 15px;
    border-radius: 5px;
    margin-top: -7px;
    .el-icon-arrow-down {
      margin-left: 4px;
    }
  }
}
.thisblack-bgc{
  background-color: rgba(0, 0, 0, 0.5);
  overflow-y: hidden;
  &.showGroup{
    padding-top:15vh;
    /deep/.el-dialog__footer {
      padding: 10px 20px 32px;
    }
  }
  &.noShowGroup{
    display:flex;
  }
}
.defaultContent{
  margin-left: 4px;
}
.checkrSucess{
  margin-top:3px;
}
 .icon-Subtract{
  font-size:12px !important;
}
.el-icon-arrow-right,.el-icon-arrow-down{
  margin-top: 2px;
  margin-left:-4px;
}
.tipSuceess{
  margin-left:10px;
  div:not(.hardWareWarningMessage){
    display:flex;
    margin-top:3px;
  }
  div {
    justify-content:space-between;
  }
  .hardWareWarningContent{
    margin-left:-4px;
    background: #FF9A241A;
    border-radius: 2px;
    p{
      padding-left:4px;
      word-break: break-all;
    }
  }
}
.tip {
  div{
      display:flex;
      margin-top:3px;
      justify-content:space-between;
    }
  .onlineError,.singleRnote,.hardWareError{
    margin-left:7px;
  }
  .receiverVersionError{
    cursor:pointer;
  }
  .receiverVersionErrorContent,.ipAndPortErrorContent,.hardWareErrorContent,.funtionErrorContent{
    margin-left:-4px;
    background: rgba(246, 68, 90, 0.1);
    border-radius: 2px;
    p{
      padding-left:4px;
      word-break: break-all;
    }
  }
}
.el-icon-success{
  color:#33AB4F;
}
.el-icon-error{
  color:#FA5555;
}
.el-icon-warning{
  color: #FFB524;
}
/deep/.el-dialog {
  background: #28282e;
  margin:auto !important;
  .icon-help2{
    color: #909090;
  }
  .el-icon-loading{
    color: #03897B;
  }
  .el-dialog__title {
    color: #fff;
  }

  .el-dialog__close {
    color: #fff;
  }
  .dialog-footer {
    a {
      color: #999;
      margin-left: 30px;
    }
    .save {
      color: #fff;
      padding: 3px 17px 5px;
      background: #03897b;
      border-radius: 5px;
      &.disabled {
        color: #999;
        cursor: not-allowed;
      }
    }
    .el-button.save {
      border: none;
      padding: 8px 8px;
      margin-left: 30px;
    }
    .unBindIP {
      color: #fff;
      padding: 3px 17px 5px;
      background: #03897b;
      border-radius: 5px;
      border: none;
      width: 96px;
      height: 30px;
      margin-left: 32px;
    }
    .unBindIPed {
      color: #909090;
      padding: 3px 17px 5px;
      background: #454549;
      border-radius: 5px;
      border: none;
      width: 96px;
      height: 30px;
      margin-left: 32px;
      span {
        cursor: text;
      }
    }
  }
  .el-dialog__body {
    color: #fff;
    font-size: 16px;
    max-height: calc(100vh - 300px);
    overflow-y: auto;
    &::-webkit-scrollbar {
      /*滚动条整体样式*/
      width: 6px; /*高宽分别对应横竖滚动条的尺寸*/
      height: 6px;
    }
    &::-webkit-scrollbar-thumb {
      /*滚动条里面小方块*/
      border-radius: 10px;
      background: rgba(255, 255, 255, 0.3);
    }
    &::-webkit-scrollbar-track {
      /*滚动条里面轨道*/
      border-radius: 10px;
      background: none;
    }
    input:disabled {
      color:#909090 !important;
    }
    .el-input {
      width: 460px;
    }
    .el-input__inner {
      // fixme
      // background: #333339;
      border-radius: 4px;
      border: none;
      color: #fff;
    }
    .el-textarea {
      width: 460px;
      .el-textarea__inner {
        color: #fff;
        height: 40px;
        line-height: 29px;
        border-radius: 5px;
        background: #333339;
        border: none;
        border-radius: 5px;
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
          'Oxygen', 'Ubuntu', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
          sans-serif;
      }
    }
    .el-input--prefix {
      .el-icon-time {
        display: none;
      }
      .el-input__inner {
        padding-left: 15px;
      }
    }
    .el-input--suffix {
      .el-input__inner {
        padding-right: 30px;
      }
      .el-input__suffix{
        display: flex;
        align-items: center;
      }
    }
    .sign {
      margin: 0 12px;
    }
    .el-date-editor {
      width: 215px;
      .el-range-input {
        background: none;
        color: #fff;
      }
      .el-range-separator {
        color: #fff;
      }
    }
  }
  .content {
    .inputType {
      .el-select-dropdown__wrap{
        max-height:293px;
      }
      .type-select-box {
        color: red;
        .el-select {
          .el-input__inner {
            height: 40px;
            line-height: 40px;
          }
          .el-select-dropdown {
            top: 38px !important;
          }
        }
      }
    }
    .el-divider {
      background-color: #333339;
    }
    .receiverBox, .backupReceiverBox{
      padding: 13px 0 3px 0;
      border-radius: 8px;
      &.active {
        background: rgb(0 0 0 / 20%);
      }
    }
    .receiverBox.active {
      margin-bottom: 10px;
      &.receiverBackup {
        margin-bottom: 0;
      }
    }
    .title {
      margin-bottom: 10px;
      position: relative;
      &.output {
        display: flex;
      }
      .select {
        .icon {
          position: absolute;
          top: 13px;
          right: 13px;
        }
      }
      .el-dropdown-link {
        color: #fff;
        .notAllowed {
          cursor: not-allowed;
          color: #909090;
        }
      }
      /deep/ .el-switch {
        .el-switch__core {
          border-color: #b8babc !important;
          background-color: #b8babc !important;
        }
        &.is-checked {
          .el-switch__core {
            border-color: #03897b !important;
            background-color: #03897b !important;
          }
        }
      }
      .set_now_icon {
        cursor: pointer;
        color: #03897b;
        border-bottom: 1px solid#03897b;
        // margin-left: 20px;
        &.notAllowed {
          cursor: not-allowed;
          color: #909090;
          border-bottom: 1px solid #909090;
        }
      }
      &.duration_box {
        .duration {
          margin-left: 188px;
        }
        .unit {
          display:inline-block;
          margin-left:-51px;
          // position: absolute;
          // top: 9px;
          // right: 30px;
          color: #fff;
        }
        .duration_input {
          position: unset;
          display: inline-block;
          width: 100px;
          // margin-right: 23px;
          // padding-right: 23px;
          .el-input__inner {
            padding-right: 40px;
          }
        }
      }
      .receiverType {
        display: inline-block;
        width: 224px;
        margin-right: 10px;
        .el-select {
          display: block;
          width:100%;
           .el-select__caret:first-child::before {
             content: "\e6e1";
              }
              .is-focus {
            .el-select__caret:first-child {
             transform: rotateZ(0deg);
               }
            }
          .el-select-dropdown {
            position: absolute !important;
            top: 38px !important;
            left: 0 !important;
          }
          .el-select-dropdown__item {
            &.is-disabled {
               color: #999;
            &:hover {
               background: none !important;
              }
            }
          }
          .el-input {
            width: 100%;
            .el-input__inner {
              background-color: #333339;
            }
          }
        }
      }
      .backupReceiver {
        width: 458px;
        display: inline-block;
        margin-right: 10px;
        .el-select {
          display: block;
          width:100%;
           .el-select__caret:first-child::before {
             content: "\e6e1";
              }
              .is-focus {
            .el-select__caret:first-child {
             transform: rotateZ(0deg);
               }
            }
          .el-select-dropdown {
            position: absolute !important;
            top: 38px !important;
            left: 0 !important;
          }
          .el-select-dropdown__item {
            &.is-disabled {
               color: #999;
            &:hover {
               background: none !important;
              }
            }
          }
          .el-input {
            width: 100%;
            .el-input__inner {
              background-color: #333339;
            }
          }
        }
      }
      .inputType {
        display: inline-block;
        width: 224px;
        margin-right: 10px;
        position: relative;
        .el-select {
          display: block;
          width:100%;
           .el-select-dropdown {
            position: absolute !important;
            top: 38px !important;
            left: 0 !important;
          }
        }
        &.width330 {
          width: 435px;
        }
        .el-input {
          width: 100%;
        }
      }
      .inputList {
        display: inline-block;
        width: 224px;
        position: relative;
        .el-input {
          width: 100%;
        }
        .select {
          width: 100%;
          .el-select-dropdown {
            position: absolute !important;
            top: 38px !important;
            left: 0 !important;
            max-width: 315px;
          }
        }
      }
      .sdiOutput, .receiverName {
        line-height: 40px;
        background: #333339;
        height: 40px;
        width: 224px;
        color: #909090;
        font-size: 14px;
        margin-right: 10px;
        text-indent: 15px;
      }
      .selectedNum {
        color: #03897b;
      }
      .addGrid {
        position: absolute;
        top: -2px;
        color: #fff;
        cursor: pointer;
        font-size: 30px;
        font-weight: bold;
      }
      .grid {
        margin: 0 30px 0 110px;
        padding: 0 20px;
        margin-bottom: 10px;
        background: rgba(0, 0, 0, 0.15);
        border-radius: 4px;
        border: 1px solid #333339;
        line-height: 50px;
        .name {
          color: #03897b;
          margin-right: 20px;
          max-width: 180px;
        }
        .url {
          max-width: 330px;
          color: #909090;
        }
        .operate {
          cursor: pointer;
        }
      }
      .outputList {
        padding-left: 110px;
        .icon {
          margin-right: 5px;
          cursor: pointer;
        }
        .mediaOutPutListTitle {
          .title {
           .icon {
            font-size: 20px;
           }
           cursor: pointer;
          }
        }
        #mediaOutputList {
          // padding-bottom: 10px !important;
          padding-right: 30px;
          .mediaOutputBox {
            height: 175px;
            .scrollAuto {
              padding-left: 25px;
            }
            .el-loading-mask {
              background-color: transparent!important;
            }
          }
          .outPutSetting {
            .scrollAuto::-webkit-scrollbar {
              /*滚动条整体样式*/
              width: 6px !important; /*高宽分别对应横竖滚动条的尺寸*/
              height: 6px !important;
            }
            .scrollAuto::-webkit-scrollbar-thumb {
              /*滚动条里面小方块*/
              border-radius: 10px !important;
              background: rgba(255, 255, 255, 0.3)  !important;
            }

            .scrollAuto::-webkit-scrollbar-track {
              /*滚动条里面轨道*/
              border-radius: 10px !important;
              background: none !important;
            }
            .OutputList {
              padding-bottom: 0 !important;
              .OutputItem {
                border: none;
                .name {
                  margin-left: -40px;
                }
              }
            }
          }
          .OtherChannels {
            position: fixed;
            top: 63px !important;
            left: 50% !important;
            transform: translateX(365px);
            background-color: #28282e;
            // height: 674px;
            overflow-y: scroll;
            .outputExtra {
              .outputInfoBox {
                  max-height: calc(100vh - 306px);
                  min-height: none;
                  .el-select-dropdown {
                    position: absolute!important;
                    top: 40px!important;
                    left: 0!important;
                  }
              }
            }
            .scrollAuto::-webkit-scrollbar-thumb {
              background: rgba(255, 255, 255, 0.3);
            }
            &::-webkit-scrollbar {
             width: 6px; /*高宽分别对应横竖滚动条的尺寸*/
             height: 6px;
             }
            &::-webkit-scrollbar-thumb {
             /*滚动条里面小方块*/
             border-radius: 10px;
             background: rgba(255, 255, 255, 0.3);
            }
            &::-webkit-scrollbar-track {
             /*滚动条里面轨道*/
             border-radius: 10px;
             background: none;
            }
          }
          .AddOutput {
            position: inherit !important;
            .Add {
              position: absolute;
              top: 10px;
              left: 207px;
              opacity: 0;
              width: 37px;
              height: 24px;
            }
            .ChannelList {
              top:38px;
              left: 213px;
              background-color: #333339;
               &::-webkit-scrollbar {
                /*滚动条整体样式*/
                width: 6px; /*高宽分别对应横竖滚动条的尺寸*/
                height: 6px;
                background: none !important;
              }
              &::-webkit-scrollbar-thumb {
                /*滚动条里面小方块*/
                  border-radius: 10px;
                  background: rgba(255, 255, 255, 0.3);
              }

             &::-webkit-scrollbar-track {
              /*滚动条里面轨道*/
              border-radius: 10px;
              background: none;
            }

              hr {
                display: none;
              }
            }
          }
        }
      }
      .backup, .receiverBackup {
        display: inline-block;
        margin-left: 4px;
        .el-checkbox {
          &.is-checked {
            .el-checkbox__label {
              color: #03897b;
            }
          }
          &.is-disabled {
            .el-checkbox__label {
              color: #909090;
            }
          }
          .el-checkbox__label {
            padding-left: 5px;
          }
          .el-checkbox__input {
            &.is-checked {
              .el-checkbox__inner {
                background: #03897b;
                border: 2px solid #03897b;
                &::after {
                  left: 3px;
                }
              }
            }
            &.is-disabled {
              .el-checkbox__inner {
                background: #909090;
                border: 2px solid #909090;
                &::after {
                  left: 3px;
                }
              }
            }
            .el-checkbox__inner {
              background: none;
              border: 2px solid #666;
            }
          }
        }
      }
      .backupBtn {
        margin-right: 10px;
        padding: 3px 16px 6px;
        background: #4e4e4e;
        border-radius: 5px;
        cursor: pointer;
        color: #999;
        &.active {
          background: #03897b;
          color: #fff;
          &.disabled {
            background: #026056;
            color: #BAC0BF;
          }
        }
        &.applyBtn {
          margin-left: 150px;
          background: #f6445a;
          color: #fff;
        }
        &.disabled {
          cursor: not-allowed;
        }
      }
      //fixme
      .eventName,.duration_input , .el-date-editor, .inputType, .inputList, .search, .slider, .formatOutput, .regions{
        .el-input__inner {
          background-color: #333339;
        }
      }
      &.bitrateBox {
        display: flex;
        align-items: center;
        .slider {
          display: flex;
          align-items: center;
          top: 0;
          .bitInput {
            width: 55px;
            margin-left: 18px;
            .el-input__inner {
              padding: 0 8px;
              height: 36px;
              line-height: 36px;
              text-align: center;
            }
          }
        }
      }
    }
    .ppRBackup {
      &.active {
        padding: 10px 0;
        margin-bottom: 0;
        background: rgb(0 0 0 / 20%);
      }
    }
    .elasticIP {
      .regions {
        .el-input {
          width: 337px;
          height: 40px;
        }
      }
      .leftItem {
        text-align: right;
        .el-icon-question {
          margin-right: 5px;
        }
        .text {
          margin-right: 13px;
        }
        .el-checkbox__inner {
          background-color: #555661;
          border: none;
        }
      }
      .rightItem {
        margin-left: 29px;
        .verticalLine {
          display: inline-block;
          width: 2px;
          height: 32px;
          background-color: #333339;
          margin-right: 42px;
          transform: translateY(9px);
        }
       .label {
         width: 65px;
        }
      }
    }
    .label {
      display: inline-block;
      width: 125px;
      text-align: right;
      line-height: 40px;
      padding-right: 10px;
      .require {
        color: #f6445a;
        margin-right: 5px;
      }
    }
    .myBtn {
      color: #03897b;
      cursor: pointer;
      text-decoration: underline;
    }
    .groups{
      display: flex;
      justify-content: space-between;
      align-items: center;
      .tips {
        font-size: 14px;
        color: #555661;
      }
      .inviteBox {
        display: flex;
        align-items: center;
        padding: 8px 15px;
        border-radius: 5px;
        margin-top: -7px;
        .el-icon-arrow-down {
          margin-top: 3px;
          margin-left: 4px;
        }
      }
    }
    .serviceUrl {
      background: #222227;
      padding: 10px 0;
      border-radius: 10px;
      margin-bottom: 10px;
    }
    .mixer {
      margin-top: 10px;
      position: relative;
      .audio {
        margin: 5px 0 5px 57px;
      }
      .link {
        position: absolute;
        top: 48px;
        right: 148px;
      }
      .url {
        padding: 0 10px;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 380px;
        margin-top: 15px;
        margin-right: 15px;
        margin-left: 26px;
        height: 40px;
        line-height: 40px;
        background: rgba(0, 0, 0, 0.15);
        border-radius: 4px;
        border: 1px solid #333339;
        a {
          color: #4a90e2;
          text-decoration: underline;
          // background: #010101;
          // border: none;
          // border-radius: 5px;
        }
      }
    }
    .groupItem {
      margin-bottom: 10px;
      .el-input {
        width: 360px;
        margin-right: 20px;
      }
      .el-divider {
        margin: 0 20px;
        width: 2px;
        height: 32px;
        background: #333339;
      }
      .el-input__inner {
        color: #fff;
        background: #333339;
        border: none;
        border-radius: 5px;
      }
      i {
        cursor: pointer;
        font-size: 20px;
      }
      .index {
        display: inline-block;
        width: 115px;
        text-align: right;
        margin-right: 5px;
      }
    }
    /deep/.el-input {
      &.is-disabled {
        .el-input__inner {
          // background-color: #F5F7FA;
          color: #999;
          cursor: not-allowed;
        }
      }
    }
    .slider {
      display: inline-block;
      width: 275px;
      position: relative;
      top: 13px;
      .el-slider {
        .el-slider__runway {
          width: 150px;
          height: 4px;
          background: #585b5d;
        }
        .el-slider__button {
          border: none;
          background-color: #03897b;
        }
        .el-slider__bar {
          background-color: #03897b;
          height: 4px;
        }
        .el-slider__input {
          width: 100px;
          .el-input {
            width: 70px;
            .el-input__inner {
              padding: 0 15px;
              text-align: left;
            }
          }
          .el-input-number__decrease,
          .el-input-number__increase {
            display: none;
          }
        }
      }
      &:after {
        content: 's';
        position: absolute;
        top: 50%;
        right: 45px;
        transform: translateY(-50%);
        background: #333339;
        height: 36px;
        line-height: 33px;
        padding: 0 5px;
        border-radius: 0 4px 4px 0;
        font-size: 16px;
      }
    }
    .gray {
      color: #6d6d6d;
      font-size: 12px;
      position: absolute;
      width: 270px;
      right: 25px;
      word-break: break-word;
    }
    .search {
      float: right;
      display: inline-block;
      .input-with-select {
        width: 100%;
      }
      .el-input-group__prepend {
        border: none;
        background: transparent;
        .el-select{
           margin: -8px -20px!important;
        }
        .el-input {
          width: 100px;
          .el-input__inner {
            border-top-left-radius: 16px;
            border-bottom-left-radius: 16px;
            border-top-right-radius: 0px;
            border-bottom-right-radius: 0px;
            background: #333339 !important;
            color: #fff !important;
            width: 100px;
            padding: 0 15px;
            &:hover {
              background: #333339;
            }
          }
        }
      }
      .el-input__inner {
        width: 150px;
        height: 36px;
        line-height: 36px;
        border-top-right-radius: 16px;
        border-bottom-right-radius: 16px;
        padding: 0 38px 0 15px;
      }
      .el-input-group__append {
        padding: 0 10px;
        position: relative;
        left: -36px;
        border-radius: 19px;
        background: none;
        border: none;
        cursor: pointer;
      }
    }
    .eventId {
      padding-left: 60px;
      span {
        cursor: pointer;
      }
    }
  }
  .link {
    cursor: pointer;
    border-radius: 5px;
    color: #fff;
    font-size: 20px;
    position: relative;
    &:hover {
      &:after {
        content: '';
        display: block;
        width: 40px;
        height: 40px;
        background: #ffffff;
        border-radius: 50%;
        opacity: 0.04;
        position: absolute;
        top: -11px;
        left: -9px;
      }
    }
  }
  .groupLink {
    .groupItem {
      padding-left: 20px;
    }
  }
  .el-dialog__footer {
    span {
      cursor: pointer;
    }
  }
}
</style>
