<template>
  <div class="mainwindow"  ref="topMainWindow" :class="{doubleContentLayout: checkList.length === 2, tripleContentLayout: checkList.length === 3}">
    <div class="top-title" ref="topTitle">
      <span>
        <i class="iconfont icon-universal_interviewer"></i>{{$t('coordinationRoom.mainWindow')}}
      </span>
      <el-popover
        placement="bottom"
        :title="$t('coordinationRoom.groupDisplay')"
        width="200"
        trigger="click"
        popper-class="groupPopver"
      >
        <span slot="reference" class="pointer">
          <i class="iconfont icon-Vector"></i>
          <span class="groupTitle">{{ $t('coordinationRoom.groupDisplay') }}</span>
        </span>
        <div class="groupContent">
          <el-checkbox-group v-model="groupDisplayList" @change="changeGroupPreview">
             <el-checkbox v-for="item in groupList" :key="item.partyCode" :label="item.title" :disabled="item.title == 'Multi-Hosts'">
              {{ item.title }}
             </el-checkbox>
          </el-checkbox-group>
        </div>
      </el-popover>
    </div>
    <div class="content mainwindowContent scrollbar" :style="{height:'calc(100% - ' + State.topTitle +'px)'}">
      <div class="topBtn">
        <el-button  v-if="talkAllGroup" @click="talkToAllGroups" type="primary" icon="iconfont icon-privatechat"
        :class="{active: talkAllGroup}"
          >{{$t('coordinationRoom.talkToAllGroups')}}</el-button
        >
        <el-button v-else @click="talkToAllGroups" type="primary" icon="iconfont icon-privatechatOff"
          >{{$t('coordinationRoom.talkToAllGroups')}}</el-button
        >
      </div>
      <div class="previewCheckBox">
        <div class="leftContent">
          <el-tooltip class="item" effect="dark" content="The selected sources will be previewed below." placement="top-start">
            <p class="title">{{$t('coordinationRoom.previewSourceType')}}:</p>
          </el-tooltip>
          <el-checkbox-group v-model="checkList" @change="changePreview">
            <el-checkbox label="main" :disabled="disabledItems.main">Main Input</el-checkbox>
            <el-checkbox v-show="allSourceLists.backup" label="backup" :disabled="disabledItems.backup">Backup Input</el-checkbox>
            <el-checkbox label="pgm" :disabled="disabledItems.pgm" v-if="!disabledItems.pgm">Main Output</el-checkbox>
            <el-tooltip class="item" effect="dark" :content="$t('coordinationRoom.unavailableSource')" placement="top-start" v-else>
            <el-checkbox label="pgm" :disabled="disabledItems.pgm">Main Output</el-checkbox>
            </el-tooltip>
            <el-checkbox v-show="allSourceLists.backupPgm" label="backupPgm" :disabled="disabledItems.backupPgm">Backup Output</el-checkbox>
          </el-checkbox-group>
        </div>
        <!--mainwindow右侧-->
        <div class="rightContent">
        <!--设置弹窗-->
        <el-popover
          popper-class="setting"
          placement="bottom"
          width="410"
          trigger="click"
          effect="dark"
          v-model="visible">
          <div class="settingClose">
           <el-button @click="visible = false" type='text' >
              <i class="el-icon-close"></i>
            </el-button>
          </div>
          <div class="clearfix">
           <div class="clearfixText"> <span>{{$t('coordinationRoom.AudioChannelSetting')}}</span></div>
          </div>
          <div class="loading" v-if="setLoading">
            <i class="el-icon-loading"></i>
          </div>
          <div v-else class="switchContent">
            <div class="switchTitle">
              <span class="verticalLine left"> <i class="iconfont icon-verticalLine"></i></span>
              <span class="left" >{{$t('coordinationRoom.forHostAndCommon')}}</span>
            </div>
            <!-- input audio pair-->
            <div class="MainInput" v-for="(item, index) in inputAudioTracks" :key="item.purpose">
              <div class="MainTop">
                <span class="left" >{{$t(`coordinationRoom.${item.label}`)}}</span>
              </div>
              <div class="channel">
                <el-radio-group  @change="(val) => { channelChange(val, index, 'input') }" v-model="item.value">
                  <el-radio v-for="val in item.channel" :key="val" :label="val">{{$t('coordinationRoom.stereo')}} {{val}}</el-radio>
                </el-radio-group>
              </div>
            </div>
            <div  class="hostTitle">
              <span class="verticalLine left"> <i class="iconfont icon-verticalLine"></i></span>
              <span class="left" >{{$t('coordinationRoom.forHost')}}</span>
            </div>
            <!-- PGM OUT audio pair-->
            <div class="PgmOut" v-for="item in pgmAudioTracks" :key="item.purpose">
              <div class="MainTop">
                <span class="left">{{$t(`coordinationRoom.${item.label}`)}}</span>
              </div>
              <div class="channel">
                <el-radio-group v-model="item.value">
                  <el-radio v-for="val in item.channel" :key="val" :label="val">{{$t('coordinationRoom.stereo')}} {{val}}</el-radio>
                </el-radio-group>
              </div>
            </div>
          </div>

          <div class="settingFoot">
            <el-button size="mini" type="text" v-show="!showDoneButton" @click="visible = false">{{$t('leftPane.cancel')}}</el-button>
            <el-button type="primary" size="mini" @click="saveAudio()">{{showDoneButton ? $t('leftPaneOutPut.done') :$t('leftPaneOutPut.save')}}</el-button>
          </div>

          <el-tooltip   slot="reference" effect="dark" :content="$t('coordinationRoom.settingName')" placement="top">
            <i @click="setShow" class="el-icon-setting" ></i>
          </el-tooltip>

        </el-popover>
        </div>

      </div>
      <div
        class="contentLayout"
        :class="{doubleContentLayout: checkList.length === 2, tripleContentLayout: checkList.length === 3, fourContentPalyout: checkList.length === 4}"
      >
      <div class="source multipleSource" v-for="val in  previewLists" :key="val.type">
        <div class="title overflow" :title="val.name">{{val.type | showType(eventInfo)}}: {{ val.name }}</div>
        <div class="videoBox clearfix">
          <div class="videoContent">
            <AttendUser
              :ref="`attendUser${val.rtilCode}`"
              class="videoArea"
              :remoteNetWorks="remoteNetWork[val.rtilCode]"
              :singChannel="channels[val.rtilCode]"
              :userInfo="userInfo"
              :rtc="rtc"
              :title="val.type"
              :userNewFullScreen="true"
              :showOperateBox="false"
              :pgmMute="!vfbSpeakerInfo[val.rtilCode]"
              :videoControl="channels[val.rtilCode] && channels[val.rtilCode].id ? true : false"
              @callback="controlVideoMute"
            >
              <div class="defaultBox" v-if="outputRtilCode && !(channels[val.rtilCode] && channels[val.rtilCode].id) && eventInfo.status == 4">
                <LoadingIcon />
              </div>
              <div class="defaultBox" v-if="!outputRtilCode && !(channels[val.rtilCode] && channels[val.rtilCode].id) && eventInfo.status < 4">
                <div class="text">
                  {{$t('coordinationParticipant.theEventTimeIs')}} {{ eventInfo.startTime | FormatTime('YYYY-MM-DD HH:mm') }} -
                  {{ eventInfo.endTime | FormatTime('YYYY-MM-DD HH:mm') }}
                </div>
              </div>
            </AttendUser>
          </div>
          <Volume :rtilCode="(channels[val.rtilCode] && channels[val.rtilCode].id) ? channels[val.rtilCode].id : ''" :height="volumeHeight"></Volume>
        </div>
      </div>
      </div>
    <MainWindowInfo v-if="eventInfo.id" :eventInfo="eventInfo" @callback="updateMainWindowType"/>
    </div>
  </div>
</template>
<script>
import MainWindowInfo from './mainWindowInfo'
import AttendUser from '@/components/common/attendUser'
import LoadingIcon from '@/components/common/loadingIcon'
import Volume from '@/components/common/volume.vue'
import { mapState } from 'vuex'
import Bus from '@/assets/js/bus.js'
import $ from 'jquery'
import i18n from '@/lang/index.js'
export default {
  components: {
    AttendUser,
    MainWindowInfo,
    LoadingIcon,
    Volume
  },
  props: {
    partyCode: String,
    eventInfo: Object,
    talkAllGroup: Boolean,
    coordinationInfo: {
      type: Object,
      default: () => {
        return {}
      }
    },
    changeMainWindowLayout: {
      type: Number,
      default: 0
    },
    devicesInfo: {
      type: Object,
      default: () => {
        return {}
      }
    },
    userInfo: {
      type: Object,
      default: () => {
        return {}
      }
    },
    outputRtilCode: {
      type: [String, Number],
      default: ''
    },
    allSourceLists: {
      type: Object,
      default: () => {
        return {}
      }
    },
    previewAudioList: {
      type: Array,
      default: () => {
        return []
      }
    },
    channels: {
      type: Object,
      default: () => {
        return {}
      }
    },
    rtc: {
      type: Object,
      default: () => {
        return {}
      }
    },
    groups: {
      type: Array,
      default: () => {
        return []
      }
    },
    vfbSpeakerInfo: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  computed: {
    ...mapState({
      State: state => state,
      logoGray: (state) => state.logoGray,
      controlMutes: (state) => state.controlMutes,
      GroupDisplayList: state => state.groupDisplayList
    })
  },
  data () {
    return {
      remoteNetWork: {},
      setLoading: false,
      loadingShow: true,
      showDoneButton: false,
      objBackUp: {},
      inputAudioTracks: [],
      pgmAudioTracks: [],
      purposeName: {
        1: 'main',
        2: 'backup',
        100: 'pgm',
        101: 'backupPgm'
      },
      MainUpaudioChannel: true,
      sourceAudioChannels: [],
      inputInfos: [],
      BackupAudioType: 0,
      MainAudioType: 0,
      visible: false,
      dialogVisible: false,
      eventName: '',
      currentVideo: '',
      isOnAir: false,
      volumeHeight: 0,
      changeOnair: false,
      changeAllGroup: false,
      disabledItems: {
        backup: false,
        main: false,
        pgm: false,
        backupPgm: false
      },
      checkList: [],
      previewLists: [],
      groupDisplayList: [],
      groupList: []
    }
  },
  mounted () {
    this.State.topTitle = this.$refs.topTitle.offsetHeight + 22
    this.State.MainWindow = this.State.leftSource - this.State.topTitle - 22
  },
  async created () {
    const _this = this
    this.$nextTick(() => {
      this.State.topMainWindow = _this.$refs.topMainWindow.offsetHeight
    })
    Bus.$on('setMainWindowUnlinkQuality', (payload) => {
      if (!payload.type) return
      this.$set(this.remoteNetWork, payload.id, {
        id: payload.id,
        uplinkNetworkQuality: payload.uplinkNetworkQuality,
        downlinkNetworkQuality: payload.downlinkNetworkQuality
      })
    })
    // get event source name
    this.showInputSource()
    Bus.$on('inputChanged', data => {
      let settingInfo = localStorage.getItem('settingInfo')
      if (settingInfo) {
        settingInfo = JSON.parse(settingInfo)
        Object.keys(settingInfo).forEach(key => {
          // 有值才根据 local 里面的值更改 enable
          if (key != 'partyCode' && data[key] && settingInfo[key]) {
            data[key].enable = settingInfo[key].enable
          }
        })
      }
      this.changeInputSourceInfo(data)
    })
    // 初始化不显示group时mainWindow区域居中
    if (this.groupDisplayList.length === 0 || this.groupDisplayList.length === 1) {
      this.$emit('changeMainBoxLayout', 'center')
    }
  },
  filters: {
    showType (value, eventInfo) {
      if (!value) return ''
      if (value === 'main') {
        return i18n.t('coordinationRoom.mainInput')
      } else if (value === 'backup') {
        return i18n.t('coordinationRoom.backUpInput')
      } else if (value === 'pgm') {
        if (eventInfo.rid && !eventInfo.rid.includes('ffffff')) {
          return i18n.t('coordinationRoom.pgm')
        } else {
          return i18n.t('coordinationRoom.PgmOutInput')
        }
      } else if (value === 'backupPgm') {
        return i18n.t('coordinationRoom.backupPgm')
      } else {
        return value
      }
    }
  },
  watch: {
    'eventInfo.inputList': {
      handler (newValue) {
        this.showInputSource()
      }
    },
    groupDisplayList: {
      handler (value) {
        if (value.length === 0 || value.length === 1) {
          this.$emit('changeMainBoxLayout', 'center')
        } else {
          this.$emit('changeMainBoxLayout', '')
        }
      }
    },
    previewLists: {
      handler (values) {
        // 计算mainWindow下方盒子的百分比
        this.$nextTick(() => {
          this.State.topMainWindow = this.$refs.topMainWindow.offsetHeight
          this.State.BoomMainWindow = this.State.leftSource - this.State.topMainWindow
          this.State.BoomMainWindows = ((this.State.BoomMainWindow / this.State.leftSource) * 100 - 1) + '%'
        })
        if (values.length === 3) {
          Bus.$emit('changeMainWindowHeight')
        } else {
          Bus.$emit('cancelChange')
        }
      }
    },
    changeMainWindowLayout: {
      handler (value) {
        this.$nextTick(() => {
          // this.changeLayout()
        })
      }
    },
    userInfo: {
      handler (value) {
        let hasHostParty = false
        let localData = localStorage.getItem('groupDisplayList')
        localData = JSON.parse(localData)
        this.groupList = this.groups.concat()
        this.groupList.forEach(item => {
          if (item.partyCode == this.userInfo.realPartyCode) hasHostParty = true
          // 初始化数据
          if (localData) {
            if (!this.groupDisplayList.includes(item.title) && localData[item.title] !== false) {
              this.groupDisplayList.push(item.title)
              this.GroupDisplayList.push(item.title)
            }
          } else {
            if (!this.groupDisplayList.includes(item.title)) {
              this.groupDisplayList.push(item.title)
              this.GroupDisplayList.push(item.title)
            }
          }
        })
        if (!hasHostParty) {
          this.groupList.push({
            partyCode: this.userInfo.realPartyCode,
            title: 'Multi-Hosts'
          })
          if (localData) {
            if (!this.groupDisplayList.includes('Multi-Hosts') && localData['Multi-Hosts'] !== false) {
              this.groupDisplayList.push('Multi-Hosts')
              this.GroupDisplayList.push('Multi-Hosts')
            }
          } else {
            this.groupDisplayList.push('Multi-Hosts')
            this.GroupDisplayList.push('Multi-Hosts')
          }
        }
        const obj = {}
        const partycodeObj = {}
        this.groupList.forEach(item => {
          obj[item.title] = this.groupDisplayList.includes(item.title)
          partycodeObj[item.partyCode] = this.groupDisplayList.includes(item.title)
        })
        localStorage.setItem('groupDisplayList', JSON.stringify(obj))
        localStorage.setItem('groupCodeDisplayList', JSON.stringify(partycodeObj))
      }
    },
    coordinationInfo: {
      handler (value) {
        if (value.onAir) {
          this.changeOnair = true
        } else {
          this.changeOnair = false
        }
      },
      deep: true
    }
  },
  methods: {
    setSpeaker () {
      this.$nextTick(() => {
        this.previewLists.forEach(item => {
          if (this.$refs[`attendUser${item.rtilCode}`][0] && item.rtilCode) {
            this.$refs[`attendUser${item.rtilCode}`][0].setSpeakerAttendUser()
          }
        })
      })
    },
    showOnAir (val) {
      let showIt = false
      if (
        this.coordinationInfo.onAir &&
        (this.eventInfo.mainWindowType === 1 && val.purpose === 1 || this.eventInfo.mainWindowType === 2 && val.purpose === 2)
      ) {
        showIt = true
      }
      return showIt
    },
    changeGroupPreview (val) {
      this.State.groupDisplayList = val
      const obj = {}
      const partycodeObj = {}
      // 把取消勾选的保存上，为了方便勾选回去
      this.groupList.forEach(item => {
        obj[item.title] = val.includes(item.title)
        partycodeObj[item.partyCode] = val.includes(item.title)
      })
      localStorage.setItem('groupDisplayList', JSON.stringify(obj))
      localStorage.setItem('groupCodeDisplayList', JSON.stringify(partycodeObj))
    },
    updateMainWindowType (type) {
      this.eventInfo.mainWindowType = type
    },
    channelChange (val, index) {
      this.inputAudioTracks[index].trackIds = [val]
    },
    saveAudio () {
      if (this.showDoneButton) return
      this.visible = false
      const audioTracks = JSON.parse(JSON.stringify(this.inputAudioTracks))
      if (audioTracks.length == 0) return
      const params = {
        eventId: this.eventInfo.id,
        audioTracks: audioTracks
      };
      this.$http
        .post('/commentator-backend/eventPreview/updateAudioTrack', params, { errorTips: false })
        .then((res) => {
          if (res.data.errorCode === '0x0') {
            this.$message.success(this.$t('HostCommon.operateSucces'))
            const localInfo = JSON.parse(localStorage.getItem('localInfo'))
            this.pgmAudioTracks.forEach(item => {
              const localIndex = Number(localInfo[item.label].selectAudioPairIndex)
              if (localIndex != item.value) {
                localInfo[item.label].selectAudioPairIndex = item.value
                localStorage.setItem('localInfo', JSON.stringify(localInfo))
                this.$emit('callback', {
                  type: 'changeVfbAudioTrack',
                  vfbType: item.label,
                  val: item.value
                })
              }
            })
          } else {
            this.$message.error(res.data.errorInfo)
          }
        });
    },
    // 展示声道数量（Main in 和BackUpin）
    setShow () {
      this.setLoading = true
      this.$http.get(`/commentator-backend/eventPreview/getAudioTrack/${this.eventInfo.id}`, {}, { errorTips: false })
        .then(res => {
          if (res.data.errorCode === '0x0') {
            let flag = true // 用来判断是否所有 channel 都是一对声道
            const audioChannelList = []
            const pgmAudioChannelList = []
            const result = res.data.result
            this.sourceAudioChannels = result.sourceAudioChannels
            this.inputInfos = result.previewInfos
            this.inputInfos.forEach(item => {
              let sharedMemoryName = ''
              // pgm 本地处理
              if (item.purpose != 100 && item.purpose != 101) {
                const obj = {}
                obj.purpose = item.purpose
                obj.trackIds = [item.audioTracksArray[0]]
                obj.label = this.purposeName[item.purpose]
                obj.value = item.audioTracksArray[0]
                sharedMemoryName = item.sharedMemoryName
                this.sourceAudioChannels.forEach(item1 => {
                  if (item1.Name == sharedMemoryName) {
                    obj.channel = (item1.AudioChannelCount) / 2
                    if (obj.channel != 1) flag = false
                  }
                })
                audioChannelList.push(obj)
              } else {
                const localInfo = JSON.parse(localStorage.getItem('localInfo'))
                const hasSelectIndex = Number(localInfo[this.purposeName[item.purpose]].selectAudioPairIndex)
                const obj = {}
                obj.purpose = item.purpose
                obj.value = hasSelectIndex
                obj.label = this.purposeName[item.purpose]
                sharedMemoryName = item.sharedMemoryName
                this.sourceAudioChannels.forEach(item1 => {
                  if (item1.Name == sharedMemoryName) {
                    obj.channel = (item1.AudioChannelCount) / 2
                  }
                })
                pgmAudioChannelList.push(obj)
              }
            })
            this.setLoading = false
            if (flag) {
              this.showDoneButton = true
            }
            this.inputAudioTracks = audioChannelList.sort((item1, item2) => {
              return item1.purpose - item2.purpose
            })
            this.pgmAudioTracks = pgmAudioChannelList.sort((item1, item2) => {
              return item1.purpose - item2.purpose
            })
          } else {
            // this.visible = false
            // this.$message.error(res.data.errorInfo)
          }
        })
    },
    computedWidth () {
      // 计算mainwindow的视频区域的大小\
      const mainwindowContent = $('.mainwindowContent').height()
      const videoMaxHeight = mainwindowContent - 145
      const videoWidth = $('.videoBox').width()
      if (videoWidth * (9 / 16) > videoMaxHeight) {
        const width = (16 / 9) * videoMaxHeight
        $('.videoContent').css({ width: width - 45 })
      } else {
        $('.videoContent').css({ width: 'calc(100% - 45px)' })
      }
    },
    changeAir () {
      this.loadingShow = false
      const params = {
        enable: this.coordinationInfo.onAir == 1 ? 0 : 1,
        eventId: this.eventInfo.id,
        operateType: 'onAir',
        parentPartyCode: this.eventInfo.partyCode
      }
      setTimeout(() => {
        this.$http.post(`${this.State.config.urlInfo.partyline}/partyline-backend/coordination/noLogin/setup`, params).then(res => {
          if (res.data.errorCode == '0x0') {
            this.$emit('upDateOnAir', params.enable)
          }
          this.loadingShow = true
        }).catch(() => {
          this.changeOnair = false
        })
      }, 1000)
    },
    showInputSource () {
      this.eventInfo.inputList &&
      this.eventInfo.inputList.forEach((val) => {
        if (val.purpose == this.eventInfo.mainWindowType) {
          if (val.type == 'pgm') {
            this.eventName = 'PGM'
          } else {
            this.eventName = val.value ? val.value.name : ''
          }
        }
      })
    },
    changePreview (selectedSources) {
      const previewListsTypes = []
      this.previewLists.forEach(value => {
        previewListsTypes.push(value.type)
      })
      const settingInfo = localStorage.getItem('settingInfo') ? JSON.parse(localStorage.getItem('settingInfo')) : {}
      const localInfo = localStorage.getItem('localInfo') ? JSON.parse(localStorage.getItem('localInfo')) : {}
      if (selectedSources.length > this.previewLists.length) {
        // 此时需要向previewLists数组里面增加元素，增加一个previewLists不存在的元素
        const addOnes = selectedSources.filter(item => !previewListsTypes.includes(item))
        addOnes.forEach(key => {
          if (this.allSourceLists[key]) {
            this.previewLists.push({ ...this.allSourceLists[key], type: key })
            this.rtc.subscribe(this.allSourceLists[key].rtilCode, 'all')
            if (key === 'pgm' || key === 'backupPgm') {
              // 因为 pgm, backupPgm 的音频可以选择所以需要添加额外订阅当前音频的
              let localVideoKey = localInfo[key].audioValue
              if (localVideoKey) {
                localVideoKey = JSON.parse(localVideoKey)
                this.rtc.subscribe(localVideoKey, 'audio')
              }
            }
            settingInfo[key].enable = true
            localStorage.setItem('settingInfo', JSON.stringify(settingInfo))
          }
        })
      } else if (selectedSources.length < this.previewLists.length) {
        // 此时需要删除previewLists数组里面的元素
        const removeOnes = previewListsTypes.filter(value => !selectedSources.includes(value))
        removeOnes.forEach(key => {
          const targetItem = this.previewLists.find(item => item.type === key)
          this.previewLists.splice(this.previewLists.findIndex(value => value.type === key), 1)
          this.rtc.unsubscribe(targetItem.rtilCode, 'all')
          if (key === 'pgm' || key === 'backupPgm') {
            // 因为 pgm, backupPgm 的音频可以选择所以需要添加额外取消订阅当前音频的
            if (
              this.channels[targetItem.rtilCode] &&
              this.channels[targetItem.rtilCode].audioTrack &&
              this.channels[targetItem.rtilCode].audioTrack._uintId != targetItem.rtilCode
            ) {
              this.rtc.unsubscribe(this.channels[targetItem.rtilCode].audioTrack._uintId, 'audio')
            }
          }
          settingInfo[key].enable = false
          localStorage.setItem('settingInfo', JSON.stringify(settingInfo))
        })
      }
      this.previewLists = this.previewLists.sort((item1, item2) => {
        return item1.purpose - item2.purpose
      })
      const updateParams = {
        enableMainIn: selectedSources.includes('main'),
        enableBackUp: selectedSources.includes('backup'),
        enablePGM: selectedSources.includes('pgm'),
        enableBackupPGM: selectedSources.includes('backupPgm')
      }
      // 勾选更改speaker
      this.previewLists.forEach(value => {
        if (selectedSources.includes(value.type)) {
          this.setSpeaker()
        }
      })
      this.updateMainWindowInfo(updateParams)
    },
    changeLayout () {
      this.$nextTick(() => {
        this.computedWidth()
      })
      if (this.previewLists.length === 3) {
        this.$nextTick(() => {
          // 获取三个视频区域包裹的盒子的最大宽高
          const contentLayoutWidth = $('.contentLayout').width()
          const contentLayoutHeight = $('.contentLayout').height()
          // 第一个视频盒子的最大高度
          const firstVideoHeight = contentLayoutHeight * 0.58
          const videoCons = document.querySelector('.contentLayout').querySelectorAll('.videoContent')
          videoCons[0].style.height = (firstVideoHeight - 30) + 'px'
          videoCons[0].style.width = firstVideoHeight * 16 / 9 + 'px'
          // 获取第一个视频区域盒子的宽度
          const firstVideoWidth = videoCons[0].offsetWidth
          // 设置第二个和第三个视频区域盒子的宽高
          const volumeBoxs = document.querySelector('.contentLayout').querySelectorAll('.volumeBox')
          for (let i = 0; i < videoCons.length; i++) {
            if (i >= 1) {
              videoCons[i].style.width = (firstVideoWidth * 0.45) + 'px'
              videoCons[i].style.height = (videoCons[1].offsetWidth) * 9 / 16 + 'px'
              videoCons[i].style.maxWidth = contentLayoutWidth * 0.5 + 'px'
            }
          }
          // 更改音量柱的高度
          const videoAreas = document.querySelector('.contentLayout').querySelectorAll('.videoArea')
          const videoTitle = document.querySelector('.contentLayout').querySelectorAll('.title')
          for (let i = 0; i < videoTitle.length; i++) {
            if (i >= 1) {
              videoTitle[i].style.maxWidth = videoCons[1].offsetWidth + 'px'
            }
          }
          volumeBoxs[0].style.height = videoAreas[0].offsetHeight - 4 + 'px'
          const smallVideo = videoAreas[1].offsetHeight
          for (let i = 0; i < volumeBoxs.length; i++) {
            if (i >= 1) {
              volumeBoxs[i].style.height = smallVideo - 4 + 'px'
            }
          }
        })
      } else if (this.previewLists.length === 2) {
        this.$nextTick(() => {
          const videoContents = document.querySelector('.contentLayout').querySelectorAll('.videoContent')
          const volumeBox = document.querySelector('.contentLayout').querySelectorAll('.volumeBox')
          for (let i = 0; i < videoContents.length; i++) {
            videoContents[i].style = null
          }
          const videoContentHeight = videoContents[1].offsetWidth * 9 / 16
          for (let i = 0; i < volumeBox.length; i++) {
            // 小视频流音量柱的高度
            volumeBox[i].style.height = (videoContentHeight - 4) + 'px'
          }
        })
      } else {
        this.$nextTick(() => {
          const videoContents = document.querySelector('.contentLayout').querySelector('.videoContent')
          const volumeBox = document.querySelector('.contentLayout').querySelector('.volumeBox')
          if (videoContents) {
            const videoContentHeight = videoContents.scrollWidth * 9 / 16
            // 小视频流音量柱的高度
            volumeBox.style.height = (videoContentHeight - 4) + 'px'
          }
        })
      }
    },
    async updateMainWindowInfo (params) {
      await this.$http.post(`/commentator-backend/coordination/update/${this.eventInfo.id}`, params)
    },
    changeInputSourceInfo (resultObj) {
      this.previewLists = []
      this.checkList = []
      this.disabledItems = {
        backup: false,
        main: false,
        pgm: false,
        backupPgm: false
      }
      Object.keys(resultObj).forEach(key => {
        if (!resultObj[key] && typeof resultObj[key] === 'object') {
          // 将item项禁用掉
          this.disabledItems[key] = true
        }
        if (resultObj[key] && resultObj[key].enable) {
          this.previewLists.push({ ...resultObj[key], type: key })
          this.checkList.push(key)
        }
      })
      this.previewLists = this.previewLists.sort((item1, item2) => {
        return item1.purpose - item2.purpose
      })
      const settingInfo = localStorage.getItem('settingInfo')
      // 如果没有存过则认为是第一进入
      if (!this.checkList.length && !settingInfo) {
        this.checkList.push('main')
      }
    },
    talkToAllGroups () {
      if (this.changeAllGroup) return
      // 记录状态
      this.changeAllGroup = true
      // const params = {
      //   enable: this.talkAllGroup == 1 ? 0 : 1,
      //   eventId: this.eventInfo.id,
      //   operateType: 'room',
      //   parentPartyCode: this.eventInfo.partyCode
      // }
      const params = [
        {
          key: 'talkToAllGroup',
          notify: true,
          value: this.talkAllGroup ? 0 : 1,
          annotation: this.eventInfo.partyCode,
          mark: this.userInfo.rtilCode + '',
          initiator: this.userInfo.rtilCode
        }
      ]
      this.$emit('changeTalkAllGroups', this.talkAllGroup)
      this.$http.post(`${this.State.config.urlInfo.partyline}/partyline-backend/behavior/noLogin/setList`, params).then(res => {
        this.changeAllGroup = false
        if (res.data.errorCode == '0x0') {
          // this.$emit('changeTalkAllGroups')
        }
      }).catch(() => {
        this.changeAllGroup = false
      })
    },
    controlVideoMute ({ type, val, id, isControlMute }) {
      if (type === 'fullScreen') {
        this.State.fullScreen = val
        if (val) {
          document.documentElement.requestFullscreen()
        } else {
          if (document.fullscreenElement) {
            document.exitFullscreen()
          }
        }
      } else if (type === 'muteAudio') {
        this.$set(this.controlMutes, id, isControlMute)
        const vfbInfo = localStorage.getItem('vfbInfo') ? JSON.parse(localStorage.getItem('vfbInfo')) : {}
        isControlMute ? vfbInfo[id] = true : vfbInfo[id] = false
        this.$emit('callback', { type: 'changeVfbInfo', id, val: isControlMute })
        localStorage.setItem('vfbInfo', JSON.stringify(vfbInfo))
      }
    }
  },
  beforeDestroy () {
    this.rtc && this.rtc.leave()
  }
}
</script>
<style lang="less" scoped>
.channel {
  /deep/.el-radio {
    margin-right: 20px;
    margin-bottom: 10px;
    &.is-checked {
      .el-radio__label {
        color: #03897B;
      }
      .el-radio__inner {
        border-color:#03897B;
        background: #03897B;
      }
    }
  }
}
.mainwindow {
    // height: auto;
    height:100%;
  &.doubleContentLayout {
    // height: auto;
    height:100%;
  }
  &.tripleContentLayout {
    // height: auto;
    height:100%;
  }
  .top-title {
    font-size: 18px;
    font-weight: bold;
    color: #f1f1f1;
    padding: 14px 0;
    display: flex;
    justify-content: space-between;
    i {
      color: #999;
      margin-right: 5px;
      font-size: 20px;
      position: relative;
      top: -2px;
    }
    .groupTitle {
      font-size: 14px;
      font-weight: normal;
    }
  }
  .content {
    background: rgba(40, 40, 46, 0.6);
    padding: 10px;
    overflow-y:auto;
    padding-top: 12px;
    .previewCheckBox {
      width: 100%;
      height: 48px;
      line-height: 48px;
      background-color: #28282E;
      padding: 0 16px;
      box-sizing: border-box;
      .leftContent {
        float: left;
        display: flex;
        justify-content: flex-start;
        height: 100%;
        width: calc(100% - 100px);
        .title {
          min-width: 140px;
          font-size: 14px;
          color: #909090;
        }
        /deep/ .el-checkbox-group {
           display: flex;
           justify-content: flex-start;
           width: calc(100% - 48px);
          .el-checkbox {
            margin-right: 5%;
            .el-checkbox__inner {
              vertical-align: middle;
              width: 16px;
              height: 16px;
              background-color: #6D6D6D;
              border-color: #6D6D6D;
              border-radius: 2px;
            }
            .el-checkbox__label {
                vertical-align: inherit;
              }
            .el-checkbox__input {
                vertical-align: text-bottom;
              }
          }
          .is-checked {
              .el-checkbox__label {
                color: #F1F1F1;
                vertical-align: inherit;
              }
          }
        }
      }
      .rightContent {
        margin-left:-60px;
        float: right;
        .el-icon-setting:hover{
          color:#03897B;
        }
        .el-button--mini.is-round{
          padding:2px 12px;
          // color:#FFF !important;
        }
        .onAir {
          color: #909090;
          margin-left: 54px;
          font-size: 14px;
          margin-right: 5px;
        }
      }
    }
    .topBtn {
      text-align: center;
      margin-bottom: 8px;
      margin-top: 5px;
      /deep/.el-button {
        width: 320px;
        height: 30px;
        line-height: 0;
        background: #F6445A;
        box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.5);
        border-radius: 4px;
        color: #f1f1f1;
        border: 1px solid #F6445A;
        i {
          margin-right: 5px;
        }
        &.active {
          border: 1px solid #33AB4F;
          background: #33AB4F;
        }
      }
    }
    .source {
      text-align: center;
      width: 90%;
      margin: 0 auto;
      .title {
        text-align: left;
        font-size: 16px;
        max-width: 100%;
        margin-bottom: 2px;
      }
      .videoBox {
        margin-bottom: 12px;
        position: relative;
        display: flex;
        justify-content: center;
        width: 100%;
        .videoContent {
          float: left;
          width: 100%;
        }
        .defaultBox {
          height: 100%;
          width: 100%;
          background: #28282e;
        }
        .volumeBox {
          float: left;
          width: 23px;
          display: flex;
          justify-content: center;
          canvas {
            margin-right: 2px;
          }
        }
        canvas {
          height: 100%;
          width: 100%;
          position: absolute;
          top: 0;
          left: 0;
        }
        .icon-fold {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          font-size: 40px;
          cursor: pointer;
          z-index: 100;
        }
      }
      .el-button {
        width: 320px;
        height: 40px;
        border: 1px solid #454549;
        background: #454549;
        border-radius: 4px;
        margin-bottom: 12px;
        color: #909090;
        &.active {
          border: 1px solid #f6445a;
          background: #f6445a;
          color: #fff;
        }
      }
    }
    .doubleContentLayout {
      width: 100%;
      display: flex;
      justify-content: space-between;
      .source {
        width: 50%;
        margin: 0 0;
      }
      .videoBox {
        width: 100%;
      }
      .videoContent {
        width: calc(100% - 23px);
      }
    }
    .tripleContentLayout {
      min-width: 442px;
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      position: relative;
      width: 100%;
      .videoBox {
        margin-bottom: 0px;
      }
      .multipleSource:nth-child(1) {
        width: 100%;
        margin-bottom: 10px;
      }
      .multipleSource:nth-child(2), .multipleSource:nth-child(3) {
        width: 50%;
      }
    }
    .fourContentPalyout {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      .source {
        width: 50%;
      }
    }
    /deep/.defaultBox {
      .text {
        position: absolute;
        // width: 405px;
        width: 100%;
        color: #F1F1F1;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
}
</style>
