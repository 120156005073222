<template>
  <div class="group-box" :style="{width}">
    <div class="top-title overflow">
      <i class="iconfont icon-Group"></i>
      <span>Multi-Hosts</span>
      <span v-if="Object.keys(this.channels).length">({{ Object.keys(this.channels).length }})</span>
      <span v-show="false">{{party.partyCode}}</span>
      <!-- <el-tooltip
        class="item"
        effect="dark"
        :content="$t('eventList.copyGroupLink')"
        placement="top"
      >
        <span
          style="color:#f1f1f1; margin-left:8px;cursor: pointer;"
          class="link el-icon-connection"
        ></span>
      </el-tooltip> -->
    </div>
    <div class="content-box">
      <div class="top-btn">
        <el-button
          v-if="privateWithGroup"
          :class="{ active: privateWithGroup }"
          type="primary"
          icon="iconfont icon-privatechat"
          @click="setPrivate('group', userInfo.rtilCode)"
          >{{$t('coordinationRoom.talkToGroup')}}</el-button
        >
        <el-button
          v-else
          type="primary"
          icon="iconfont icon-privatechatOff"
          @click="setPrivate('group', userInfo.rtilCode)"
          >{{$t('coordinationRoom.talkToGroup')}}</el-button
        >
      </div>
      <div class="managerList scrollbar">
        <!-- 为了让 host 自己可以在最前面所以多写一遍 -->
        <div class="managerLi" v-if="JSON.stringify(userInfo) != '{}'">
          <!-- host 自己 -->
          <div class="liLeft host">
            <span class="email" :title="loginInfo.email">{{ loginInfo.firstName ? loginInfo.firstName : loginInfo.email.slice(0, 1) }}</span>
            <span v-if="!editName" class="name">{{ nickNameList[userInfo.rtilCode] ? nickNameList[userInfo.rtilCode] : 'user_' + userInfo.rtilCode}}(me)</span>
            <span class="rename" v-show="!editName" @click="controlRenameInput"><i class="iconfont icon-Edit"></i><span>{{ $t('leftPane.rename') }}</span></span>
            <el-input ref="renameInput" class="inputName" v-model="inputName" v-if="editName" @keyup.enter.native="saveName" @blur="saveName" size="mini"></el-input>
          </div>
        </div>
        <!-- 其他 host -->
        <div class="managerLi" v-for="item in channels" :key="item.id" v-show="item.id != userInfo.rtilCode">
          <div class="liLeft">
            <span class="email" :title="loginInfo.email">{{ loginInfo.firstName ? loginInfo.firstName : loginInfo.email.slice(0, 1) }}</span>
            <span class="name">{{ nickNameList[item.id] ? nickNameList[item.id]: 'user_' + item.id }}</span>
          </div>
          <div class="liRight" v-if="userInfo.rtilCode != item.id">
            <div class="rightLeft">
              <i
                class="micphone"
                v-if="!muteAudioIcon(item.id)"
                :title="volume.level ? parseInt(volume.level) : ''"
              >
                <MicPhone :level="volume.level" />
              </i>
              <svg
                class="muteAudioImg"
                aria-hidden="true"
                v-if="muteAudioIcon(item.id)"
              >
                <use xlink:href="#icon-universal_micoff"></use>
              </svg>
            </div>
            <el-divider class="line" direction="vertical"></el-divider>
            <div class="rightRight" @click="setPrivate('member', item.id)">
              <div class="private" :class="{active: managePrivate[item.id]}">
                <i v-if="managePrivate[item.id]" class="iconfont icon-privatechat"></i>
                <i v-else class="iconfont icon-privatechatOff"></i>
              </div>
            </div>
          </div>
          <AttendUser
            :ref="`attendUser${item.id}`"
            class="hide"
            :title="item.id + ''"
            :nickName="nickNameList[item.id]"
            :singChannel="item"
            :userInfo="userInfo"
            :rtc="rtc"
            :isPip="true"
          >
          </AttendUser>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import AttendUser from '@/components/common/attendUser'
import MicPhone from '@/components/common/micphone.vue'
export default {
  components: {
    AttendUser,
    MicPhone
  },
  props: {
    width: String,
    groupInfo: Object,
    muteAudioSelf: Boolean,
    extraParty: [String, Number],
    party: {
      type: Object,
      default: () => {
        return {}
      }
    },
    channels: {
      type: Object,
      default: () => {
        return {}
      }
    },
    muteAudio: {
      type: Boolean,
      default: false
    },
    blockAudio: {
      type: Boolean,
      default: false
    },
    volume: {
      type: Object,
      default: function () {
        return {
          level: 0
        }
      }
    },
    userInfo: {
      type: Object,
      default: function () {
        return {}
      }
    },
    nickNameList: {
      type: Object,
      default: function () {
        return {}
      }
    },
    rtc: {
      type: Object,
      default: function () {
        return {}
      }
    },
    managePrivate: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  data () {
    return {
      editName: false,
      inputName: '',
      privateWithGroup: false
    }
  },
  computed: {
    ...mapState({
      State: state => state,
      loginInfo: state => state.loginInfo
    })
  },
  watch: {
    muteAudioSelf: function (val) {
      if (this.rtc) {
        Object.keys(this.managePrivate).forEach((id) => {
          // 推流 私聊开 麦克风关的
          if (this.managePrivate[id] && !val) {
            if (this.rtc._hasPublishAudio) return
            this.rtc.publish(['audio'])
          } else {
            if (!this.rtc._hasPublishAudio) return
            this.rtc.unpublish(['audio'])
          }
        })
      }
    },
    userInfo: {
      handler (val) {
        if (val.behavior && val.behavior.talkToCoordGroup) this.privateWithGroup = val.behavior.talkToCoordGroup == '1'
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    // host之间私聊的speaker处理逻辑
    setSpeaker () {
      Object.keys(this.channels).forEach((id) => {
        if (this.$refs[`attendUser${id}`][0] && id) {
          this.$refs[`attendUser${id}`][0].setSpeakerAttendUser()
        }
      })
    },
    controlRenameInput () {
      this.editName = !this.editName
      if (this.editName) {
        this.$nextTick(() => {
          this.inputName = this.nickNameList[this.userInfo.rtilCode] ? this.nickNameList[this.userInfo.rtilCode] : 'user_' + this.userInfo.rtilCode
          this.$refs.renameInput.$refs.input.focus()
          this.$refs.renameInput.$refs.input.select()
        })
      }
    },
    setPrivate (type, rtilCode) {
      // pushtalk 分为两种可点击地方，发送参数分别不同
      const params = {
        eventId: this.$route.query.id,
        parentPartyCode: this.extraParty
      }
      if (type === 'group') {
        const params = [
          {
            key: 'talkToCoordGroup',
            notify: true,
            value: this.privateWithGroup ? 0 : 1,
            annotation: this.userInfo.realPartyCode,
            mark: this.userInfo.rtilCode + ''
          }
        ]
        this.$emit('callback', { type, val: params[0].value })
        this.privateWithGroup = params[0].value
        this.$http
          .post(`${this.State.config.urlInfo.partyline}/partyline-backend/behavior/noLogin/setList`, params)
          .then(res => {
            if (res.data.errorCode == '0x0') {
              this.privateWithGroup = params[0].value
              this.pushHostAudio()
            } else {
              this.privateWithGroup = !params[0].value
              this.$emit('callback', { type, val: this.privateWithGroup })
            }
          })
          .catch(() => {
            this.privateWithGroup = !params[0].value
            this.$emit('callback', { type, val: this.privateWithGroup })
          })
      } else {
        params.enable = this.managePrivate[rtilCode] ? 0 : 1
        params.operateType = 'member'
        params.memberSettings = {
          rtilCode,
          subPartyCode: this.extraParty,
          subOperateType: 'talk',
          starterRtilCode: this.userInfo.rtilCode
        }
        this.$emit('callback', { type, val: params.enable == 1, rtilCode })
        this.$http
          .post(`${this.State.config.urlInfo.partyline}/partyline-backend/coordination/noLogin/setup`, params)
          .then(res => {
            if (res.data.errorCode === '0x0') {
              this.pushHostAudio()
            } else {
              this.$emit('callback', { type, val: this.managePrivate[rtilCode], rtilCode })
            }
          })
          .catch(() => {
            this.$emit('callback', { type, val: this.managePrivate[rtilCode], rtilCode })
          })
      }
    },
    pushHostAudio () {
      if (!this.rtc._hasPublishAudio && !this.muteAudioSelf) {
        this.rtc.publish(['audio'])
      }
    },
    muteAudioIcon (rtilCode) {
      if (!rtilCode) return
      let flag = false
      if (!this.channels[rtilCode].audioTrack) {
        flag = true
      }
      return flag
    },
    saveName () {
      if (!this.inputName) {
        this.$refs.renameInput.$refs.input.focus()
        this.inputName = this.nickNameList[this.userInfo.rtilCode] ? this.nickNameList[this.userInfo.rtilCode] : 'user_' + this.userInfo.rtilCode
        this.editName = false
        this.$message.warning(this.$t('coordinationRoom.nameEmpty'))
        return
      }
      const params = [
        {
          mark: this.userInfo.rtilCode + '',
          annotation: this.userInfo.realPartyCode,
          key: 'nickName',
          notify: true,
          value: this.inputName.replace(/(^\s*)|(\s*$)/g, ''), // 去掉首尾空格
          initiator: this.userInfo.rtilCode
        }
      ]
      this.$http
        .post(`${this.State.config.urlInfo.partyline}/partyline-backend/behavior/noLogin/setList`, params)
        .then((res) => {

        })
      this.editName = false
    }
  }
}
</script>
<style lang="less" scpoed>
.group-box {
  height: 100%;
  min-width: 18.5vw;
  margin-right: 12px;
  display: inline-block;
  .top-title {
    font-size: 18px;
    font-weight: bold;
    color: #f1f1f1;
    padding: 14px 0;
    max-width: 100%;
    i {
      color: #999;
      margin-right: 5px;
      font-size: 20px;
      position: relative;
      top: -2px;
    }
    span {
      color: #666;
      font-size: 16px;
    }
  }
  .content-box {
    background: rgba(40, 40, 46, 0.6);
    height: calc(100% - 95px);
    padding: 20px 0;
    .top-btn {
      text-align: center;
      margin-bottom: 20px;
      &.hidden {
        visibility: hidden;
      }
      .el-button {
        width: 180px;
        height: 32px;
        background: #F6445A;
        box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.5);
        border-radius: 4px;
        color: #f1f1f1;
        border: 1px solid #F6445A;
        padding: 8px 20px;
        margin-right: 19px;
        &.active {
          border: 1px solid #33AB4F;
          background: #33AB4F;
        }
        i {
          margin-right: 5px;
        }
      }
    }
  }
  .managerList {
    overflow-y: auto;
    padding: 0 10px;
    height: calc(100% - 50px);
    .managerLi {
      display: flex;
      align-items: center;
      padding: 16px 0;
      justify-content: space-between;
      border-bottom: 2px solid #262626;
      &:last-child {
        border: none;
      }
      .liLeft {
        display: flex;
        align-items: center;
        &.host:hover {
          .name {
            display: none;
          }
          .rename {
            display: inline-block;
          }
        }
        .email {
          display: flex;
          height: 28px;
          width: 28px;
          background: #03897B;
          border-radius: 50%;
          align-items: center;
          justify-content: center;
          margin-right: 6px;
          flex: none;
        }
        .name {
          height: 24px;
        }
        .rename {
          display: none;
          .icon-Edit {
            color: #03897b;
            font-size: 18px;
            margin-right: 2px;
          }
        }
        .inputName {
          .el-input__inner {
            color: #fff;
            background: #333339;
            border-color: #333339;
          }
        }
      }
      .liRight {
        display: flex;
        align-items: center;
        .line {
          height: 24px;
          margin: 0 20px;
          background: #444444;
        }
        .rightLeft {
          display: flex;
          align-items: center;
          .muteAudioImg {
            width: 20px;
            height: 20px;
          }
        }
        .rightRight {
          .private {
            width: 16px;
            display: flex;
            align-items: center;
            cursor: pointer;
            padding: 8px;
            border-radius: 5px;
            background: #F6445A;
            box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
            &.active {
              background: #33AB4F;
              box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
            }
          }
        }
      }
    }
  }
}
</style>
