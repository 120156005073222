<template>
  <div class="group-box">
    <div class="top-title" :class="{hide: !showTitle}">
      <i class="el-icon-bell"></i><span @click="showMetadata()">{{ title | showTitle}}</span>
    </div>
    <div class="content-box">
      <!-- 为了main window单独处理 -->
      <div v-if="modeKey === 'mainWindow'">
        <ul class="pip scrollbar" >
          <li class="clearfix mainWindow">
            <!-- <el-tooltip
              class="item"
              effect="dark"
              :content="$t('coordinationRoom.onAirEnabled')"
              placement="top">
              <div class="onAirText"  v-show="!muteAudioObj[userInfo.rtilCode] && onAirObj[userInfo.rtilCode] && !talkbackInfo[userInfo.rtilCode]">{{$t('coordinationRoom.onAir')}}</div>
            </el-tooltip> -->
            <slot></slot>
            <div class="metaData" v-if="showReceiveMeta">
              <p><span>timeCode - {{ metaDataTimeCode? metaDataTimeCode : false }}</span></p>
              <p><span>R timeStamp - {{ metaDateTimeStamp }}</span></p>
              <p><span>web timeStamp - {{ webDateTimeStamp }}</span></p>
              <p><span>time difference - {{ metaDateTimeStamp ? new Date(webDateTimeStamp) - new Date(metaDateTimeStamp + '') : '' }}</span></p>
            </div>
            <div class="box" ref="group-video-box">
              <AttendUser
                ref="attendUser"
                :rtc="rtc"
                :onAir="onAirObj[userInfo.rtilCode]&& !talkbackInfo[userInfo.rtilCode]"
                :isTalkBack="talkbackInfo[userInfo.rtilCode]"
                :coordRoomStatus="false"
                :remoteNetWorks="remoteNetWork[channels.id]"
                :showOperateBox="false"
                :title="channels.id + ''"
                :singChannel="channels"
                :pgmMute="pgmMute"
                :userInfo="userInfo"
                :userNewFullScreen="userNewFullScreen"
                :operateList="operation"
                @callback="attendCallback"
                :nickName="nickNameInfo[channels.id]"
                :volume="voiceList[channels.id] || {}"
                :modeKey="modeKey"
              >
                <div class="defaultText" v-if="!channels.id">
                  <p v-if="eventStartTime===0">
                  {{$t('coordinationParticipant.gettingReady')}}
                  </p>
                  <p v-else>
                  {{$t('coordinationParticipant.theEventTimeIs')}} {{ eventStartTime }} - {{ eventEndTime }}
                  </p>
                </div>
              </AttendUser>
            </div>
            <Volume :rtilCode="channels.id" :height="volumeHeight"></Volume>
          </li>
        </ul>
      </div>
      <ul class="pip scrollbar pipList" v-else>
        <li class="clearfix" v-if="!modeKey">
          <div class="pip-title" :class="{hide: !showTitle}">{{ nickNameInfo[meInfo.id] }}</div>
          <div class="box" ref="group-video-box">
            <AttendUser
              class="is-local"
              title="Me"
              :privateInfo="privateInfo[userInfo.rtilCode]"
              :nickName="nickNameInfo[meInfo.id]"
              :singChannel="meInfo"
              :rtc="rtc"
              :volume="voiceList[userInfo.rtilCode] || {}"
              :userInfo="userInfo"
              :userNewFullScreen="userNewFullScreen"
              :isTalkback=talkbackInfo[meInfo.id]
              :talkBackType=talkbackType[meInfo.id]
              :isMemeberPrivate=isMemeberPrivate[meInfo.id]
              :video="videoObj[meInfo.id] || videoSelf"
              :muteAudio="muteAudioObj[meInfo.id] || muteAudioSelf"
              :operateList="operation"
              :showMuteIcons="false"
              :isPip="true"
              :isPrivate="isPrivate"
              @callback="attendCallback"
            >
              <div class="streamBox" id="local_stream"></div>
            </AttendUser>
          </div>
          <Volume :rtilCode="userInfo.rtilCode" :height="volumeHeight"></Volume>
        </li>
        <li v-for="val in channels" :key="val.id" class="clearfix" v-if="val.id">
          <div class="pip-title" :class="{hide: !showTitle}">{{ nickNameInfo[val.id] }}</div>
          <div class="box">
            <AttendUser
              :ref="`attender${val.id}`"
              :rtc="rtc"
              :remoteNetWorks="remoteNetWork[val.id]"
              :privateInfo="privateInfo[val.id]"
              :title="val.id + ''"
              :nickName="nickNameInfo[val.id]"
              :singChannel="val"
              :userInfo="userInfo"
              :video="videoObj[val.id]"
              :muteAudio="muteAudioObj[val.id]"
              :volume="voiceList[val.id] || {}"
              :operateList="operation"
              :userNewFullScreen="userNewFullScreen"
              :isMemeberPrivate=isMemeberPrivate[val.id]
              :isTalkback=talkbackInfo[val.id]
              :talkBackType=talkbackType[val.id]
              :isPrivate="isPrivate"
              :isPip="true"
              @callback="attendCallback"
            >
            </AttendUser>
          </div>
          <Volume :rtilCode="val.id" :height="volumeHeight"></Volume>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import AttendUser from '@/components/common/attendUser'
import Volume from '@/components/common/volume.vue'
import { mapState } from 'vuex'
import i18n from '@/lang/index.js'
import Bus from '@/assets/js/bus.js'
const moment = require('moment')
export default {
  components: {
    AttendUser,
    Volume
  },
  props: {
    metaDataTimeCode: [String, Number],
    metaDateTimeStamp: [String, Number],
    privateInfo: {
      type: Object,
      default: function () {
        return {}
      }
    },
    isMemeberPrivate: {
      type: Object,
      default: function () {
        return {}
      }
    },
    title: String,
    modeKey: String,
    videoSelf: Boolean,
    muteAudioSelf: Boolean,
    rtc: {
      type: Object,
      default: function () {
        return {}
      }
    },
    meInfo: {
      type: Object,
      default: function () {
        return {}
      }
    },
    userInfo: {
      type: Object,
      default: function () {
        return {}
      }
    },
    channels: {
      type: Object,
      default: function () {
        return {}
      }
    },
    videoObj: {
      type: Object,
      default: function () {
        return {}
      }
    },
    allPairStatusInfo: {
      type: Object,
      default: function () {
        return {}
      }
    },
    amStatusInfo: {
      type: Object,
      default: function () {
        return {}
      }
    },
    muteAudioObj: {
      type: Object,
      default: function () {
        return {}
      }
    },
    voiceList: {
      type: Object,
      default: function () {
        return {}
      }
    },
    isPrivate: {
      type: Boolean,
      default: false
    },
    nickNameInfo: {
      type: Object,
      default: function () {
        return {}
      }
    },
    showTitle: {
      type: Boolean,
      default: true
    },
    userNewFullScreen: {
      type: Boolean,
      default: false
    },
    pgmMute: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState({
      eventInfo: (state) => state.eventInfo
    })
  },
  watch: {
    eventInfo (info) {
      const { startTime, endTime } = info
      this.eventStartTime = moment(startTime).format('YYYY-MM-DD HH:mm:ss')
      this.eventEndTime = moment(endTime).format('YYYY-MM-DD HH:mm:ss')
    },
    metaDateTimeStamp (val) {
      if (val) {
        this.webDateTimeStamp = this.getFullTimestamp(new Date().getTime())
      }
    }
  },
  data () {
    return {
      singeleStatusInfo: {},
      onAirObj: {},
      remoteNetWork: {},
      showReceiveMeta: false,
      volumeHeight: 50,
      eventStartTime: 0,
      eventEndTime: 0,
      operation: [],
      talkbackInfo: {},
      talkbackType: {},
      webDateTimeStamp: ''
    }
  },
  async created () {
    Bus.$on('setUnlinkQuality', (payload) => {
      if (!payload.type) return
      this.$set(this.remoteNetWork, payload.id, {
        id: payload.id,
        uplinkNetworkQuality: payload.uplinkNetworkQuality,
        downlinkNetworkQuality: payload.downlinkNetworkQuality
      })
    })
    Bus.$on('controlMetaData', val => {
      this.showReceiveMeta = val
    })
    window.addEventListener('resize', this.computedWidth)
    this.$nextTick(() => {
      this.computedWidth()
    })
    Bus.$on('GrapqQLDataChange', (val) => {
      // 单个pair的状态(ws推送更新的)
      val.onUpdatedPair.inputDatas && val.onUpdatedPair.inputDatas.forEach((itemInput) => {
        if (itemInput.ID.includes('Commentator')) {
          const parts = itemInput.ID.split('_');
          const rtilCode = parts[2];
          if (itemInput.audioStreams.length != 0) {
            itemInput.audioStreams[0].channelDatas && itemInput.audioStreams[0].channelDatas.forEach((itemChannel) => {
              if (itemChannel.ID == '0') {
                this.amStatusInfo[rtilCode] = {
                  ...itemChannel
                }
                // 处理单个pair状态
                this.singeleStatusInfo[val.onUpdatedPair.ID] = {
                  ...this.amStatusInfo
                }
              }
            })
          }
        }
      })
      this.dealStatus(false, val)
    })
    Bus.$on('setBehaviors', (v) => {
      const behaviors = v.behaviors
      behaviors.forEach((val) => {
        if ((val.key == 'callAll' || val.key == 'callHost' || val.key == 'callPip') && val.initiator == this.userInfo.realPartyCode) {
          this.$set(this.talkbackInfo, val.mark, val.value == 'true')
          this.$set(this.talkbackType, val.mark, val.key)
        }
        if (val.key == 'camera') {
          this.videoObj[val.mark] = val.value === 'true'
        }
      })
    })
  },
  methods: {
    // 处理onAir状态
    dealStatus (initStatus, val) {
      if (!initStatus) {
        // 将ws的变化同步到初始化时候所有pair状态
        Object.keys(this.allPairStatusInfo).forEach((ID) => {
          // 改变当前pair的状态
          if (ID == val.onUpdatedPair.ID) {
            this.allPairStatusInfo[ID] = this.singeleStatusInfo[ID]
          }
        })
      }
      // onAir状态设置
      Object.values(this.allPairStatusInfo).forEach(subMap => {
        Object.keys(subMap).forEach(rtilCode => {
          this.$set(this.onAirObj, rtilCode, false);
        })
      })

      Object.keys(this.allPairStatusInfo).forEach(key => {
        const subMap = this.allPairStatusInfo[key];
        Object.keys(subMap).forEach(rtilCode => {
          if (subMap[rtilCode].output && !subMap[rtilCode].mute && !this.muteAudioObj[rtilCode]) {
            this.$set(this.onAirObj, rtilCode, true);
          }
        })
      })
    },
    setSpeaker () {
      if (this.$refs.attendUser) {
        this.$refs.attendUser.setSpeakerAttendUser()
      }
      // Object.keys(this.channels).forEach((id) => {
      //   if (this.$refs[`attender${id}`]) {
      //     this.$refs[`attender${id}`][0].setSpeakerAttendUser()
      //   }
      // })
    },
    getFullTimestamp (timeStamp) {
      if (!timeStamp) return ''
      const pad = (n, s = 2) => (`${new Array(s).fill(0)}${n}`).slice(-s);
      const d = new Date(timeStamp)
      return `${pad(d.getFullYear(), 4)}-${pad(d.getMonth() + 1)}-${pad(d.getDate())} ${pad(d.getHours())}:${pad(d.getMinutes())}:${pad(d.getSeconds())}.${pad(d.getMilliseconds(), 3)}`;
    },
    computedWidth () {
      setTimeout(() => {
        const height = this.$refs['group-video-box'] && this.$refs['group-video-box'].clientHeight
        this.volumeHeight = height
      }, 500)
    },
    showMetadata () {
      this.$emit('showMetaDataInfo')
    },
    attendCallback ({ type, id, val, isControlMute }) {
      if (type === 'muteVideo' || type === 'muteAudio') {
        this.setOperate({ type, id, val, isControlMute })
      } else if (type === 'fullScreen') {
        this.$emit('callback', { type: 'fullscreen', val })
      }
    },
    setOperate ({ type, id, val, isControlMute }) {
      // 把 mute pgm 的操作存在本地 localstorage
      if (this.modeKey === 'mainWindow' && this.channels.id == id) {
        this.$emit('callback', { type: 'savePgmMute', val, isControlMute })
      }
      // mian window 和不是自己都不要控制
      if (this.modeKey || id != this.userInfo.rtilCode) return
      if (type === 'muteVideo') {
        // 先操作然后在改值
        this.videoSelf
          ? this.rtc.setEnabled(true, 'video')
          : this.rtc.setEnabled(false, 'video')
        this.$emit('callback', 'muteVideo')
      }
      if (type === 'muteAudio') {
        this.muteAudioSelf
          ? this.rtc.setEnabled(false, 'audio')
          : this.rtc.setEnabled(true, 'audio')
        this.$emit('callback', 'muteAudio')
      }
    }
  },
  filters: {
    showTitle (title) {
      if (!title) return ''
      if (title === 'Main window') {
        return i18n.t('coordinationRoom.mainWindow')
      } else if (title === 'Talent Commentator') {
        return i18n.t('coordinationParticipant.talentCommentator')
      } else {
        return title
      }
    }
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.computedWidth)
  }
}
</script>
<style lang="less" scoped>
.group-box {
  height: 100%;
  width: 40%;
  margin-right: 8px;
  .content-box {
    background: #1c1c20;
    height: calc(100% - 95px);
    padding: 20px 0;
  }
  .pip {
    height: calc(100% - 45px);
    overflow-y: auto;
    padding: 0 10px;
    .pip-title {
      color: #909090;
      margin-bottom: 5px;
    }
    li {
      margin-bottom: 15px;
      position: relative;
      .box {
        width: calc(100% - 55px);
        float: left;
      }
      .volumeBox {
        float: left;
        height: 100%;
      }
      .private {
        cursor: pointer;
        position: absolute;
        top: 50%;
        right: -14px;
        transform: translate(-50%, -50%);
        padding: 5px 8px;
        border-radius: 5px;
        background: #27272a;
        box-shadow: 0px 2px 4px 0px rgb(0 0 0 / 50%);
        margin-top: 10px;
        &.active {
          background: #f6445a;
          box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
        }
      }
      &.mainWindow {
        width: 92%;
        transform: translateX(6%);
        .onAirText {
          position: absolute;
          z-index: 11111;
          cursor:default;
          background: #F6445A;
          padding: 6px;
          border-radius: 4px;
          top: 8px;
          left:8px;
          font-size: 12px;
        }
      }
    }
  }
  .pipList {
    height: calc(100vh - 180px);
    overflow-y: auto;
  }
  .top-title {
    font-size: 18px;
    font-weight: bold;
    color: #f1f1f1;
    padding: 15px 0;
    i {
      color: #999;
      margin-right: 5px;
    }
  }
  .top-btn {
    text-align: center;
    margin-bottom: 20px;
    .el-button {
      width: 180px;
      height: 32px;
      background: #28282e;
      box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.5);
      border-radius: 4px;
      color: #f1f1f1;
      border: 1px solid #28282e;
      padding: 8px 20px;
      &.active {
        border: 1px solid #f6445a;
        background: #f6445a;
      }
    }
  }
  /deep/.streamBox {
    height: 100%;
    width: 100%;
  }
  .content {
    height: calc(100% - 75px);
    background: #28282e;
    padding: 10px;
  }
}
</style>
