<template>
  <div class="group-box" :style="{width}">
    <div class="top-title overflow">
      <i class="iconfont icon-Group"></i>
      {{ party.title}}
      <span v-if="Object.keys(this.channels).length">({{ Object.keys(this.channels).length }})</span>
      <span v-show="false">{{party.partyCode}}</span>
        <el-tooltip
        class="item"
        effect="dark"
        :content="$t('eventList.copyGroupLink')"
        placement="top"
        >
        <span
          style="color:#f1f1f1; margin-left:8px;cursor: pointer;"
          class="link el-icon-connection"
          v-clipboard:copy="copyLink(party.partyCode, 'partyCode')"
          v-clipboard:success="onCopy"
          v-clipboard:error="onError"
        ></span>
      </el-tooltip>
    </div>
    <div class="content-box">
      <div class="top-btn">
        <el-button
          v-if="privateWithGroup"
          :class="{ active: privateWithGroup }"
          type="primary"
          icon="iconfont icon-privatechat"
          @click="setPrivate('group')"
          >{{$t('coordinationRoom.talkToGroup')}}</el-button
        >
        <el-button
          v-else
          type="primary"
          icon="iconfont icon-privatechatOff"
          @click="setPrivate('group')"
          >{{$t('coordinationRoom.talkToGroup')}}</el-button
        >
      </div>
      <ul class="pip scrollbar">
        <li class="clearfix" v-show="false">
          <div class="box">
            <AttendUser
              class="is-local"
              title="Me"
              nickName=""
              :singChannel="meInfo"
              :volume="voiceList[userInfo.rtilCode] || {}"
              :userInfo="userInfo"
            >
              <div class="streamBox" id="local_stream1"></div>
            </AttendUser>
          </div>
          <Volume :rtilCode="userInfo.rtilCode" :height="volumeHeight"></Volume>
          <div class="private active">
            <i class="iconfont icon-privatechat"></i>
          </div>
        </li>
        <li
          v-for="(val, key, index) in channels"
          :key="val.id"
          class="clearfix"
        >
          <div class="pip-title">
            Source {{ index + 1 > 10 ? index + 1 : `0${index + 1}` }}
          </div>
          <div class="box group-video-box">
            <AttendUser
              :title="val.id + ''"
              :remoteNetWorks="remoteNetWork[val.id]"
              :nickName="nickNameInfo[val.id]"
              :singChannel="val"
              :groupPartyCode ="val.channel"
              :userInfo="userInfo"
              :video="videoObj[val.id]"
              :coordRoomStatus="true"
              :muteAudio="!micObj[val.id]"
              :onAir="onAirObj[val.id] && micObj[val.id]"
              :notJoinR="notJoinR[val.id]"
              :isTalkBack="talkbackInfo[val.id]"
              :talkType="talkbackType[val.id]"
              :blockVideo="blockVideoObj[val.id]"
              :blockAudio="blockAudioObj[val.id]"
              :volume="voiceList[val.id] || {}"
              :rtc="rtc"
              :showVideoIcon="val.channel != extraParty"
              :operateList="operation"
              :tagName="tagNameInfo[val.id]"
              :loading="loadingObj[val.id]"
              @reJoinR= "reJoinR(val.id)"
              @callback="attendCallback"
              :userNewFullScreen="userNewFullScreen"
              :isPip="true"
            >
            </AttendUser>
          </div>
          <Volume :rtilCode="val.id" :height="volumeHeight"></Volume>
          <div class="buttons" :style="{ height: volumeHeight + 'px' }">
              <div
                class="private"
                :class="{ active: privateInfo[val.id] }"
                @click="setPrivate('pip', val.id)"
              >
                <i v-if="privateInfo[val.id]" class="iconfont icon-privatechat" ></i>
                <i v-else class="iconfont icon-privatechatOff" ></i>
              </div>
          </div>
        </li>
      </ul>
    </div>
    <!-- 添加tag name -->
    <tag-name
      v-if="setTagName.show"
      :info="setTagName"
      @saveTagName="saveTagName"
    ></tag-name>
  </div>
</template>
<script>
import AgoraClient from '@/assets/js/rtc-client-ng'
import AttendUser from '@/components/common/attendUser'
import Volume from '@/components/common/volume.vue'
import { audioContextObj } from '@/assets/js/audioContext'
import $ from 'jquery'
import Bus from '@/assets/js/bus.js'
import TagName from '@/components/common/tagName'
import { mapState } from 'vuex'
export default {
  components: {
    AttendUser,
    Volume,
    TagName
  },
  props: {
    muteAudioSelf: Boolean,
    partyListInfo: {
      type: Object,
      default: () => {}
    },
    party: Object,
    width: String,
    clickTalkAllGroups: Number,
    talkAllGroup: Boolean,
    eventInfo: Object,
    groupInfo: Object,
    extraParty: [String, Number],
    realPartyListInfo: {
      type: Object,
      default: () => {}
    },
    allPairStatusInfo: {
      type: Object,
      default: function () {
        return {}
      }
    },
    amStatusInfo: {
      type: Object,
      default: function () {
        return {}
      }
    },
    allUserInfo: {
      type: Object,
      default: function () {
        return {}
      }
    },
    userNewFullScreen: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState({
      Config: (state) => state.config,
      State: state => state,
      UserInfo: (state) => state.userInfo
    })
  },
  watch: {
    muteAudioSelf: function (val) {
      if (this.rtc) {
        Object.keys(this.privateInfo).forEach((id) => {
          // 推流 私聊开 麦克风关的
          if (this.privateInfo[id] && !val) {
            if (this.rtc._hasPublishAudio) return
            this.rtc.publish(['audio'])
          } else {
            if (!this.rtc._hasPublishAudio) return
            this.rtc.unpublish(['audio'])
          }
        })
      }
    },
    clickTalkAllGroups: function () {
      this.setPrivate('all')
    },
    async realPartyListInfo (value) {
      if (this.rtc) this.rtc.leave()
      this.rtc = new AgoraClient(this.callBack)
      // 需要先获取设备列表，并判断之前选择过的设备列表是否还存在，不存在则选择默认的
      this.devicesInfo = await this.rtc.getDevice()
      this.userInfo = value
      this.rtilCode = value.rtilCode
      this.recordDeviceStatus() // 用来记录是否有摄像头或者block
      this.joinChannel(value)
      // 获取pip状态
      console.log(this.groupInfo.groupMemberSettingsMap, 9896)
      if (this.groupInfo.groupMemberSettingsMap) {
        const _this = this
        Object.keys(this.groupInfo.groupMemberSettingsMap).forEach(rtilCode => {
          _this.groupInfo.groupMemberSettingsMap[rtilCode].forEach(info => {
            if (info.rtilCode == this.userInfo.rtilCode && info.talk == 1) {
              _this.$set(_this.privateInfo, rtilCode, info.talk == 1)
              _this.$set(_this.talkbackInfo, rtilCode, info.talkBack == 1)
            }
          })
        })
      }
    },
    'State.groupDisplayList': function (newValue, lastValue) {
      let currentGroup = null
      if (newValue.length > lastValue.length) {
        currentGroup = newValue.filter(title => {
          return !lastValue.includes(title)
        })
      } else {
        currentGroup = lastValue.filter(title => {
          return !newValue.includes(title)
        })
      }
      // 根据最新需求改为隐藏组时取消订阅视频音频，显示时在恢复订阅视频，音频则根据之前记录的数据订阅
      if (this.rtc && currentGroup == this.party.title) {
        Object.keys(this.channels).forEach(rtilCode => {
          if (newValue.includes(this.party.title)) {
            this.rtc.subscribe(rtilCode, 'video')
            // 在判断订阅音频
            if (this.talbackCodeList[currentGroup]) {
              this.talbackCodeList[currentGroup].forEach(rtilCode => {
                this.rtc.subscribe(rtilCode, 'audio')
              })
            }
            // 显示 group 时，需判断是否发送流
            if (this.privateWithGroup) {
              if (this.rtc._hasPublishAudio) return
              this.rtc.publish(['audio'])
            } else {
              Object.keys(this.privateInfo).forEach(rtilCode => {
                if (this.privateInfo[rtilCode] && !this.rtc._hasPublishAudio) {
                  this.rtc.publish(['audio'])
                }
              })
            }
          } else {
            // 隐藏组时取消订阅音视频
            if (this.channels[rtilCode]) {
              if (this.channels[rtilCode].videoTrack) {
                this.rtc.unsubscribe(rtilCode, 'video')
              }
              if (this.channels[rtilCode].audioTrack) {
                this.rtc.unsubscribe(rtilCode, 'audio')
              }
            }
            // 还要根据当前是否 talk to group 或者有和 commentator talk 来判断是否取消或者发送 当前 group host 的流
            if (this.privateWithGroup) {
              if (!this.rtc._hasPublishAudio) return
              this.rtc.unpublish(['audio'])
            } else {
              Object.keys(this.privateInfo).forEach(rtilCode => {
                if (this.privateInfo[rtilCode] && this.rtc._hasPublishAudio) {
                  this.rtc.unpublish(['audio'])
                }
              })
            }
          }
        })
      }
    }
  },
  data () {
    return {
      loadingObj: {},
      remoteNetWork: {},
      talkbackType: {},
      onAirObj: {},
      notJoinR: {},
      singeleStatusInfo: {},
      talkBackType: '',
      audioStatus: '',
      rtcLoaded: false,
      blockVideoObj: {},
      blockAudioObj: {},
      volumeHeight: 120,
      rtc: null,
      devicesInfo: null,
      rtilCode: null,
      meInfo: {},
      userInfo: {},
      channels: {},
      nickNameInfo: {},
      videoObj: {},
      micObj: {},
      // muteAudioObj: {},
      voiceList: {},
      operation: ['fullScreen', 'rename', 'removeParticipant'],
      privateInfo: {},
      setTagName: {
        show: false,
        tagName: ''
      },
      tagNameInfo: {},
      talkbackInfo: {},
      changeAllGroup: false,
      privateWithGroup: false,
      talbackCodeList: {}, // 用来记录隐藏的 group 的 talkback，根据新需求隐藏的 group 不接收 talkback
      rtilCodeInfo: {} // 用来记录每个加入进来的与会者的麦克风，摄像头等状态
    }
  },
  async created () {
    window.addEventListener('resize', this.computedWidth)
    Bus.$on('GrapqQLDataChange', (val) => {
      // 单个pair的状态(ws推送更新的)
      val.onUpdatedPair.inputDatas && val.onUpdatedPair.inputDatas.forEach((itemInput) => {
        if (itemInput.ID.includes('Commentator')) {
          const parts = itemInput.ID.split('_');
          const rtilCode = parts[2];
          if (itemInput.audioStreams.length != 0) {
            itemInput.audioStreams[0].channelDatas && itemInput.audioStreams[0].channelDatas.forEach((itemChannel) => {
              if (itemChannel.ID == '0') {
                this.amStatusInfo[rtilCode] = {
                  ...itemChannel
                }
                this.singeleStatusInfo[val.onUpdatedPair.ID] = {
                  ...this.amStatusInfo
                }
              }
            })
          }
        }
      })
      this.dealStatus(false, val)
    })
    Bus.$on('setBehaviors', (v) => {
      const behaviors = v.behaviors
      behaviors.forEach((val) => {
        if (val.key == 'audioStatus') {
          this.audioStatus = val.value
        }
      })
      behaviors.forEach(async (val) => {
        if (val.key == 'nickName') {
          this.$set(this.nickNameInfo, val.mark, val.value)
        }
        if (val.key == 'tagName') {
          this.$set(this.tagNameInfo, val.mark, val.value)
        }
        if ((val.key == 'callAll' || val.key == 'callHost' || val.key == 'callPip') && val.initiator == this.party.partyCode) {
          this.$set(this.talkbackInfo, val.mark, val.value == 'true')
          this.$set(this.talkbackType, val.mark, val.key)

          // talkBack
          // 1. 先隐藏组然后发起 talkback， 这时应不接收流并记录下来数据为了后面显示组恢复订阅数据
          // 2. 隐藏组发起 talkback， 过后恢复组，这时需要订阅之前的数据
          // 3. 正在 talkback 时隐藏组需要取消订阅
          // 4. 正在 talkback 时隐藏组稍后恢复，则需要先取消组然后再订阅组
          if (val.key == 'callAll') {
            this.talkBackType = 'callAll'
            if (val.value == 'true') {
              // 判断当前 talkback 的组是否有被隐藏, 如果隐藏则不订阅 audio
              if (this.State.groupDisplayList.includes(this.party.title)) {
                await this.rtc.subscribe(val.mark, 'audio')
                if (this.rtc._remotePublishUser[val.mark] && this.rtc._remotePublishUser[val.mark]._audioTrack) {
                  this.rtc._remotePublishUser[val.mark]._audioTrack.setPlaybackDevice(localStorage.getItem('speakerId'))
                  this.rtc._remotePublishUser[val.mark]._audioTrack.play()
                }
              }
              // 记录没订阅的音频数据方便显示组的时候订阅回去
              if (!this.talbackCodeList[this.party.title]) {
                this.talbackCodeList[this.party.title] = []
              }
              this.talbackCodeList[this.party.title].push(val.mark)
            } else {
              this.talkBackType = ''
              this.rtc.unsubscribe(val.mark, 'audio')
              // 如果有删除之前保存的数据
              if (this.talbackCodeList[this.party.title]) {
                this.talbackCodeList[this.party.title] = this.talbackCodeList[this.party.title].filter(rtilCode => {
                  return rtilCode != val.mark
                })
                if (!this.talbackCodeList[this.party.title].length) {
                  this.$delete(this.talbackCodeList, this.party.title)
                }
              }
            }
          } else if (val.key == 'callHost') {
            // callHost
            this.talkBackType = 'callHost'
            if (val.value == 'true') {
              // 判断当前 talkback 的组是否有被隐藏, 如果隐藏则不订阅 audio
              if (this.State.groupDisplayList.includes(this.party.title)) {
                await this.rtc.subscribe(val.mark, 'audio')
                if (this.rtc._remotePublishUser[val.mark] && this.rtc._remotePublishUser[val.mark]._audioTrack) {
                  this.rtc._remotePublishUser[val.mark]._audioTrack.setPlaybackDevice(localStorage.getItem('speakerId'))
                  this.rtc._remotePublishUser[val.mark]._audioTrack.play()
                }
              }
              // 记录没订阅的音频数据方便显示组的时候订阅回去
              if (!this.talbackCodeList[this.party.title]) {
                this.talbackCodeList[this.party.title] = []
              }
              this.talbackCodeList[this.party.title].push(val.mark)
            } else {
              this.talkBackType = ''
              this.rtc.unsubscribe(val.mark, 'audio')
              // 如果有删除之前保存的数据
              if (this.talbackCodeList[this.party.title]) {
                this.talbackCodeList[this.party.title] = this.talbackCodeList[this.party.title].filter(rtilCode => {
                  return rtilCode != val.mark
                })
                if (!this.talbackCodeList[this.party.title].length) {
                  this.$delete(this.talbackCodeList, this.party.title)
                }
              }
            }
          } else if (val.key == 'callPip') {
            // callPip
            this.talkBackType = 'callPip'
            if (val.value == 'true') {
              this.noCallBackRtilCode = val.mark
              this.rtc.unsubscribe(val.mark, 'audio')
            }
          } else {
            this.talkBackType = ''
          }
        }
        if (val.key == 'camera') {
          this.videoObj[val.mark] = val.value === 'true'
        }
        if (val.key == 'mic') {
          this.micObj[val.mark] = val.value === 'true'
        }
      })
    })
    Bus.$on('controlBehavior', v => {
      const { rtilCode, behavior } = v
      const { key, value } = behavior
      if (key == 'nickName' && this.nickNameInfo[rtilCode]) {
        this.nickNameInfo[rtilCode] = value
      }
    })
  },
  methods: {
    reJoinR (rtilCode) {
      const params = {
        partyCode: this.party.partyCode,
        rtilCode: rtilCode
      }
      this.$set(this.loadingObj, rtilCode, true);
      this.$http
        .post('/commentator-backend/commentator/party/addMixAudio', params)
        .then(async (res) => {
          this.$emit('getNewAmStatusInfo', rtilCode)
          if (res.data.errorCode != '0x0') {
            this.$message.error(res.data.errorInfo)
          }
        })
    },
    // 处理onAir状态
    dealStatus (initStatus, val, reJoinRtilCode) {
      if (initStatus) {
        // 检查是否加入到R
        this.$nextTick(() => {
          this.channels && Object.keys(this.channels).forEach(rtilcode => {
            if (!this.amStatusInfo[rtilcode]) {
              this.$set(this.notJoinR, rtilcode, true)
            } else {
              this.$set(this.notJoinR, rtilcode, false)
            }
          })
        })
        if (reJoinRtilCode) {
          this.$set(this.loadingObj, reJoinRtilCode, false)
        }
      }

      // onAir状态设置
      Object.values(initStatus
        ? this.allPairStatusInfo
        : this.singeleStatusInfo).forEach(subMap => {
        Object.keys(subMap).forEach(rtilCode => {
          this.$set(this.onAirObj, rtilCode, false);
        })
      })

      Object.keys(initStatus
        ? this.allPairStatusInfo
        : this.singeleStatusInfo).forEach(key => {
        const subMap = initStatus
          ? this.allPairStatusInfo[key]
          : this.singeleStatusInfo[key];
        Object.keys(subMap).forEach(rtilCode => {
          if (subMap[rtilCode].output && !subMap[rtilCode].mute) {
            this.$set(this.onAirObj, rtilCode, true);
          }
        })
      })
    },
    async joinChannel (data) {
      const { appId, tokenCode, rtilCode, realPartyCode, videoQualities } = data
      let hasPushTalk = false
      // 根据 userinfo 里面的 subPartyBehavior 给 tallgroup 赋值
      if (this.allUserInfo.subPartyBehavior && this.allUserInfo.subPartyBehavior[this.party.partyCode]) {
        this.privateWithGroup = this.allUserInfo.subPartyBehavior[this.party.partyCode].talkToGroup == '1'
      }
      if (this.allUserInfo.behavior && this.allUserInfo.behavior.talkToAllGroup) {
        this.privateWithGroup = this.allUserInfo.behavior.talkToAllGroup == '1'
      }
      const groupMemberSettingsMap = this.groupInfo.groupMemberSettingsMap
      // 如果当前的列表中有 host pusktalk 的则需要发流
      if (groupMemberSettingsMap) {
        Object.keys(groupMemberSettingsMap).forEach(rtilCode => {
          if (groupMemberSettingsMap[rtilCode].length) {
            groupMemberSettingsMap[rtilCode].forEach(info => {
              if (info.rtilCode == this.userInfo.rtilCode && info.talk) {
                hasPushTalk = true
              }
            })
          }
        })
      }
      const hasDispalyGroup = this.State.groupDisplayList.includes(this.party.title)
      const params = {
        appId,
        channel: realPartyCode + '',
        token: tokenCode,
        uid: rtilCode,
        codec: 'h264',
        videoConfig: videoQualities,
        cameraId: this.devicesInfo.cameraId,
        microphoneId: this.devicesInfo.microphoneId,
        video: false,
        audio: !!this.devicesInfo.microphoneId,
        publishVideo: false,
        publishAudio: (!!this.privateWithGroup || hasPushTalk) && !this.muteAudioSelf && hasDispalyGroup,
        domId: 'local_stream1',
        subscribeRemote: false, // 默认不订阅流
        publish: !!this.privateWithGroup || hasPushTalk, // 默认不publish
        getRemoteNetworks: true
      }
      const uid = await this.rtc.join(params)
      if (!uid) return
      this.State.rtcLoaded = true
      // Me设置私聊功能，使用到
      this.meInfo = {
        id: this.userInfo.rtilCode,
        channel: this.userInfo.realPartyCode,
        videoTrack: this.rtc._videoTrack,
        audioTrack: this.rtc._audioTrack
      }
    },
    setUnlinkQuality (payload) {
      if (!payload.type) return
      this.$set(this.remoteNetWork, payload.id, {
        id: payload.id,
        uplinkNetworkQuality: payload.uplinkNetworkQuality,
        downlinkNetworkQuality: payload.downlinkNetworkQuality
      })
    },
    copyLink (val, type) {
      if (type == 'partyCode') {
        const domain = window.location.host
        return (
          this.$t('otherWords.copyLinkTips') +
          'https://' +
          domain +
          '/commentator?join_id=' +
          val +
          '\n\n' +
          'Party ID: ' +
          this.$options.filters.returnStrByInfo(val, 4)
        )
      } else {
        return val
      }
    },
    onCopy () {
      this.$message({
        message: this.$t('otherWords.copySuccess'),
        type: 'success'
      })
    },
    onError () {
      this.$message({
        message: 'Copy failed',
        type: 'error'
      })
    },
    async callBack ({ action, payload }) {
      const { changeDevice } = payload
      if (action != 'volumeList' && action != 'receiveMetadata' && action != 'unlinkQuality') {
        console.info(139, action, payload)
      }
      switch (action) {
        case 'user-joined':
          this.userJoin(payload)
          break
        case 'unlinkQuality':
          this.setUnlinkQuality(payload)
          break
        case 'user-left':
          this.userLeave(payload)
          break
        case 'subscribe-track':
          this.subscribeTrack(payload)
          break
        case 'unsubscribe-track':
          if (payload.type == 'audio') {
            this.$set(this.channels, payload.id, {
              ...this.channels[payload.id],
              audioTrack: null
            })
          }
          break
        case 'user-published':
        case 'user-unpublished':
          this.userPublished(action, payload)
          break
        case 'volumeList':
          payload.forEach((volume) => {
            this.$set(this.voiceList, volume.uid, volume)
          })
          break
        case 'micChange':
          this.$emit('micChange', changeDevice)
          break
        case 'playbackChange':
          this.$emit('playbackChange', changeDevice)
          break
        case 'connection-state-change':
          // 代表有人重复加入
          if (payload.reason === 'UID_BANNED') {
            this.$emit('callback', { type: 'showRepeatPopup' })
          }
          break
      }
    },
    callBackChangeDevice (obj) {
      this.rtc.setDevice(obj.deviceId, 'audio', 'needLocalAudioTrack')
    },
    async getNickName (id) {
      await this.$http
        .post(`${this.State.config.urlInfo.partyline}/partyline-backend/behavior/noLogin/getList`, {
          originRtilCode: this.userInfo.rtilCode,
          annotation: this.userInfo.joinCode,
          keys: [
            'nickName',
            'camera',
            'mic',
            'blockAudio',
            'blockVideo',
            'tagName',
            'talkback',
            'mixAudio'
          ],
          marks: [id]
        })
        .then((res) => {
          if (res.data.errorCode === '0x0') {
            const result = res.data.result[id]
            this.rtilCodeInfo[id] = result
            if (!this.nickNameInfo[id]) {
              this.$set(this.nickNameInfo, id, result.nickName)
            }
            this.$set(this.tagNameInfo, id, result.tagName)
            this.$set(this.videoObj, id, result.camera !== 'false')
            this.$set(this.micObj, id, result.mic !== 'false')
            this.$set(this.blockVideoObj, id, result.blockVideo === 'true')
            this.$set(this.blockAudioObj, id, result.blockAudio === 'true')
          }
        })
    },
    userJoin (payload) {
      if (this.privateWithGroup) {
        this.$set(this.privateInfo, payload.id, true)
      }
      //  else {
      //   this.$set(this.privateInfo, payload.id, false)
      // }

      this.$http
        .post(`${this.State.config.urlInfo.partyline}/partyline-backend/member/noLogin/queryByRtilCode/${payload.id}`)
        .then((res) => {
          if (res.data.errorCode === '0x0') {
            const result = res.data.result
            const accountId = result.accountId.toLowerCase()

            this.getNickName(payload.id)

            if (result.deviceType === 'T' || result.deviceType === 'R') {
              if (!this.nickNameInfo[payload.id]) {
                this.$set(this.nickNameInfo, payload.id, result.deviceName)
              }
            }

            if (result.deviceType !== 'R' && !this.userInfo.managerRtils.includes(payload.id)) {
              let obj = Object.assign({}, this.channels[payload.id])
              obj = {
                ...obj,
                ...payload,
                tid: accountId
              }
              this.$set(this.channels, payload.id, obj)
            }
            this.$nextTick(() => {
              this.computedWidth()
            })
          }
        })
    },
    userPublished (action, payload) {
      this.muteAudioOrVideo(action, payload)
      if (action == 'user-published') {
        const advancedSetting = this.eventInfo.advancedSetting
        if (advancedSetting && advancedSetting.quality === '1080p') {
          this.rtc._client.setRemoteVideoStreamType(payload.id, 1)
        }
        if (payload.mediaType == 'video') {
          this.rtc.subscribe(payload.id, 'video')
        }
        if (payload.mediaType == 'audio') {
          // 更改是为了刷新若host开着私聊，不订阅与会者的流
          // 获取是否隐藏组的codeList
          let localData = localStorage.getItem('groupCodeDisplayList')
          localData = JSON.parse(localData)
          if (this.talkbackInfo[payload.id] && localData[payload.channel]) {
            console.info(3622)
            this.rtc.subscribe(payload.id, 'audio')
          }
        }
      } else {
        if (payload.mediaType == 'audio') {
          const _this = this
          Object.keys(_this.channels).forEach(rtilCode => {
            const info = _this.channels[rtilCode]
            if (info.id == payload.id) {
              info.audioTrack = null
            }
          })
        }
      }
    },
    muteAudioOrVideo (action, payload) {
      const { id } = payload
      if (action === 'user-unpublished') {
        if (payload.mediaType === 'video') {
          this.$set(this.videoObj, id, false)
        } else {
          // this.$set(this.muteAudioObj, id, true)
        }
      } else {
        if (payload.mediaType === 'video') {
          // 因为与会者关闭摄像头也会发黑流所以先判断实际的状态是否是开启状态
          if (this.rtilCodeInfo[id] && this.rtilCodeInfo[id].camera !== 'false') {
            this.$set(this.videoObj, id, true)
          }
        } else {
          // this.$delete(this.muteAudioObj, id)
        }
      }
    },
    subscribeTrack (payload) {
      try {
        // 创建可以播放的mediastream
        const { id, videoTrack, audioTrack } = payload
        console.info(362, id, videoTrack, audioTrack)

        // 音量柱的显示
        const media = new MediaStream()
        if (videoTrack) {
          media.addTrack(videoTrack._mediaStreamTrack)
        }
        if (audioTrack) {
          media.addTrack(audioTrack._mediaStreamTrack)
          audioContextObj.audioTrack(media, id)
          setTimeout(() => {
            audioContextObj.resumeAudio(id)
          }, 1000)
        }
        // 列表流的显示
        const obj = Object.assign({}, this.channels[payload.id])
        obj.id = id
        obj.videoTrack = videoTrack
        obj.audioTrack = audioTrack
        if (this.talkBackType == 'callPip') {
          this.rtc.unsubscribe(this.noCallBackRtilCode, 'audio')
          // }
        }
        this.$set(this.channels, payload.id, obj)
        this.$nextTick(() => {
          this.computedWidth()
        })
      } catch (error) {
        console.info(error)
      }
    },
    userLeave (payload) {
      const { id } = payload
      this.$delete(this.channels, id)
      this.$set(this.videoObj, payload.id, false)
      this.$delete(this.rtilCodeInfo, id)
    },
    computedWidth () {
      // 使用Vue的$nextTick来确保DOM更新完成
      this.$nextTick(() => {
        // 使用requestAnimationFrame确保执行时机是正确的
        requestAnimationFrame(() => {
          const height = $('.group-video-box').height();
          // 在这里执行额外的检查以确保获取的高度是有效的
          if (height !== null && height !== 0) {
            this.volumeHeight = height;
          } else {
            // 设置时间延迟来确保DOM确实已更新，并在之后重试获取高度
            setTimeout(this.computedWidth, 10);
          }
        });
      });
    },
    setPrivate (type, rtil) {
      // 点击则更改状态，不等待接口返回
      if (type == 'pip') {
        const params = {
          enable: this.privateInfo[rtil] ? 0 : 1,
          eventId: this.$route.query.id,
          memberSettings: {
            rtilCode: rtil,
            subPartyCode: this.party.partyCode,
            subOperateType: 'talk',
            starterRtilCode: this.userInfo.rtilCode
          },
          operateType: 'member',
          parentPartyCode: this.extraParty
        }
        if (!this.privateInfo[rtil]) {
          this.$set(this.privateInfo, rtil, true)
        } else {
          this.$set(this.privateInfo, rtil, false)
        }
        this.$http.post(`${this.State.config.urlInfo.partyline}/partyline-backend/coordination/noLogin/setup`, params).then(res => {
          if (res.data.errorCode == '0x0') {
            if (this.privateInfo[rtil]) {
              if (!this.talkbackInfo[rtil]) {
                this.rtc.unsubscribe(rtil, 'audio')
              }
            }
            if (!this.muteAudioSelf) {
              this.publishHost()
            }
          }
        })
      }
      if (type == 'group') {
        const params = [
          {
            key: 'talkToGroup',
            notify: true,
            value: this.privateWithGroup ? 0 : 1,
            annotation: this.party.partyCode,
            mark: this.userInfo.rtilCode + '',
            parentPartyCode: this.eventInfo.partyCode
          }
        ]
        // 点击则更改状态，不等待接口返回
        if (this.privateWithGroup) {
          this.privateWithGroup = false
          Object.keys(this.channels).forEach((id) => {
            this.$set(this.privateInfo, id, false)
          })
        } else {
          this.privateWithGroup = true
          Object.keys(this.channels).forEach((id) => {
            this.$set(this.privateInfo, id, true)
          })
        }
        this.$http.post(`${this.State.config.urlInfo.partyline}/partyline-backend/behavior/noLogin/setList`, params).then(res => {
          if (res.data.errorCode == '0x0') {
            if (this.privateWithGroup) {
              Object.keys(this.channels).forEach((id) => {
                if (this.privateInfo[id] && !this.talkbackInfo[id]) {
                  this.rtc.unsubscribe(id, 'audio')
                }
              })
            }
            if (!this.muteAudioSelf) {
              this.publishHost()
            }
          }
        }).catch(() => {
        })
      }

      if (type == 'all') {
        if (this.talkAllGroup) {
          this.privateWithGroup = true
          Object.keys(this.channels).forEach((id) => {
            if (!this.privateInfo[id]) {
              // 打开host页面的私聊但是未开与会者私聊，则不订阅对应那个rtill的流
              // this.rtc.subscribe(id, 'audio')
              this.$set(this.privateInfo, id, true)
            }
          })
        } else {
          this.privateWithGroup = false
          Object.keys(this.channels).forEach((id) => {
            if (this.privateInfo[id]) {
              if (!this.talkbackInfo[id]) {
                this.rtc.unsubscribe(id, 'audio')
              }
              this.$set(this.privateInfo, id, false)
            }
          })
        }
        if (!this.muteAudioSelf) {
          this.publishHost()
        }
      }
    },
    publishHost () {
      // 判断host是否publish/unpublish自己的video
      if (this.privateWithGroup) {
        if (this.rtc._hasPublishAudio) return
        this.rtc.publish(['audio'])
      } else {
        let btn = false
        Object.keys(this.privateInfo).forEach((id) => {
          // 并且麦克风是开着的再publish 添加 this.channels[id] 是为了防止脏数据
          if (this.privateInfo[id] && !this.muteAudioSelf && this.channels[id]) {
            btn = true
          }
        })
        if (btn) {
          if (this.rtc._hasPublishAudio) return
          this.rtc.publish(['audio'])
        } else {
          if (!this.rtc._hasPublishAudio) return
          this.rtc.unpublish(['audio'])
        }
      }
    },
    attendCallback (obj) {
      const { type, val } = obj
      if (type == 'editTagName') {
        this.editTagName(obj)
      }
      if (type === 'fullScreen') {
        this.$emit('callback', { type: 'fullscreen', val })
      }
    },
    editTagName (obj) {
      this.setTagName.show = true
      this.setTagName.originRtilCode = this.userInfo.rtilCode
      this.setTagName.targetRtilCode = obj.id
      this.setTagName.partyCode = this.userInfo.joinCode
      this.setTagName.tagName = this.tagNameInfo[obj.id]
    },
    saveTagName (obj) {
      this.$set(this.tagNameInfo, obj.rtilCode, obj.tagName)
    },
    async recordDeviceStatus () {
      const data = [
        {
          mark: this.userInfo.rtilCode + '',
          annotation: this.userInfo.joinCode,
          key: 'blockVideo',
          value: !this.devicesInfo.cameraId,
          initiator: this.userInfo.rtilCode + ''
        },
        {
          mark: this.userInfo.rtilCode + '',
          annotation: this.userInfo.joinCode,
          key: 'blockAudio',
          value: !this.devicesInfo.microphoneId,
          initiator: this.userInfo.rtilCode + ''
        }
      ]
      await this.$http.post(`${this.State.config.urlInfo.partyline}/partyline-backend/behavior/noLogin/setList`, data)
    }
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.computedWidth)
    this.rtc && this.rtc.leave()
  }
}
</script>
<style lang="less" scoped>
.lds-ripple {
  display: inline-block;
  position: relative;
  width: 54px;
  height: 54px;
  z-index: 10;
  // i {
  //   font-size: 30px;
  //   color: #f6445a;
  //   position: absolute;
  //   top: 50%;
  //   left: 50%;
  //   transform: translate(-50%, -50%);
  // }
    .icon-toolbartalkbackon {
      font-size: 28px;
      color: #33AB4F;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    .icon-talkHostOff{
      font-size: 20px;
      color: #33AB4F;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    .icon-talkPipOff{
      font-size: 20px;
      color: #33AB4F;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
}
.lds-ripple div {
  position: absolute;
  border: 4px solid #33AB4F;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1.5s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
  animation-delay: -1s;
}
@keyframes lds-ripple {
  0% {
    top: 22px;
    left: 22px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 44px;
    height: 44px;
    opacity: 0;
  }
}
.group-box {
  height: 100%;
  width: 18.5vw;
  // min-width: 335px;
  margin-right: 12px;
  display: inline-block;
  .content-box {
    background: rgba(40, 40, 46, 0.6);
    height: calc(100% - 95px);
    padding: 20px 0;
  }
  .pip {
    height: calc(100% - 50px);
    overflow-y: auto;
    padding: 0 10px;
    .pip-title {
      color: #909090;
      margin-bottom: 5px;
    }
    /deep/.el-button {
        background: #28282e;
        box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.5);
        border-radius: 4px;
        color: #f1f1f1;
        border: 1px solid #28282e;
        padding: 8px 20px;
      &.active {
        border: 1px solid #f6445a;
        background: #f6445a;
      }
      i {
        margin-right: 5px;
      }
     }
    li {
      margin-bottom: 15px;
      position: relative;
      .box {
        width: calc(100% - 75px);
        float: left;
      }
      .volumeBox {
        float: left;
        width: 22px;
        height: 100%;
      }
      .buttons {
        display: flex;
        width: 55px;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 50%;
        right: -7px;
        transform: translateY(-50%);
        margin-top: 13px;
        div {
          margin-bottom: 10px;
          &:last-child {
            margin-bottom: 0;
          }
        }
        .private {
          width: 16px;
          cursor: pointer;
          padding-top: 3px;
          padding-bottom: 5px;
          padding-left: 8px;
          padding-right: 8px;
          border-radius: 5px;
          background: #F6445A;
          box-shadow: 0px 2px 4px 0px rgb(0 0 0 / 50%);
          margin-top: 10px;
          &.active {
            background: #33AB4F;
            box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
          }
        }
      }
    }
  }
  .top-title {
    font-size: 18px;
    font-weight: bold;
    color: #f1f1f1;
    padding: 14px 0;
    max-width: 100%;
    i {
      color: #999;
      margin-right: 5px;
      font-size: 20px;
      position: relative;
      top: -2px;
    }
    span {
      color: #666;
      font-size: 16px;
    }
  }
  .top-btn {
    text-align: center;
    margin-bottom: 20px;
    &.hidden {
      visibility: hidden;
    }
    /deep/.el-button {
      width: 180px;
      height: 32px;
      background: #F6445A;
      box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.5);
      border-radius: 4px;
      color: #f1f1f1;
      border: 1px solid #F6445A;
      padding: 8px 20px;
      margin-right: 19px;
      &.active {
        border: 1px solid #33AB4F;
        background: #33AB4F;
      }
      i {
        margin-right: 5px;
      }
    }
  }

  /deep/.streamBox {
    height: 100%;
    width: 100%;
  }
  .content {
    height: calc(100% - 75px);
    background: #28282e;
    padding: 10px;
  }
}
</style>
