<template>
  <div class="joinModule">
    <!-- <div style="color: #000;">{{ content }}</div> -->
    <div id="weixinUI" v-if="isWeixin">
      <div id="step1">
        <p>1.{{$t('joinPartyWays.clickMenu')}}</p>
        <img :src="sjUrl" />
      </div>
      <div id="step2">
        <p>2.{{$t('joinPartyWays.openWays')}}.</p>
      </div>
    </div>
    <div id="otherUI" v-else>
      <!-- v-if="this.isMac && !this.isIos && !this.isIpad" -->
      <div v-if="(this.isMac || this.isWindows) && !this.isIos && !isAndroid">
      <p  class="h1text">
        {{$t('joinPartyWays.joinByBrowser')}}
      </p>
      <a
        class="AccessBtn pointer"
        @click="joinParty"
        >{{$t('joinPartyWays.accessByBrowser')}}</a
      >
      <el-divider
        ><i>{{$t('joinPartyWays.or')}}</i></el-divider
      >
      </div>
      <p class="center h1text">{{$t('joinPartyWays.installAnyWhere')}}</p>
      <p class="h3text">
        {{$t('joinPartyWays.openAppJoinPartyLine')}}
      </p>
      <a id="otherJoin" @click="joinAnywhereParty"
        >{{$t('joinPartyWays.joinPartyLine')}}</a>
      <p class="h1text">{{$t('joinPartyWays.noApplication')}}</p>
      <p class="h3text">
       {{$t('joinPartyWays.toInstallAnyWhere')}}
      </p>
      <a id="downloadBtn" :href="downloadUrl">{{$t('joinPartyWays.downLoadFromStore')}}</a>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
  data () {
    return {
      isWeixin: navigator.userAgent.includes('MicroMessenger'),
      isSafariBrowser:
        /Safari/.test(navigator.userAgent) &&
        !/Chrome/.test(navigator.userAgent),
      isChrome: /Chrome/.test(navigator.userAgent),
      isAndroid: navigator.userAgent.includes('Android'),
      isIos:
        navigator.userAgent.includes('iPhone') ||
        navigator.userAgent.includes('iOS'),
      needRed: !window.location.href.includes('join_id='),
      lang: navigator.language,
      textMac:
        'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_4) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/13.1 Safari/605.1.15',
      // const isMac = /Macintosh|Mac OS X/i.test(navigator.userAgent);
      isMac: false,
      isIpad: false,
      content: navigator.userAgent,
      macOsXIndex: 0,
      // const macVersion = navigator.userAgent.slice(macOsXIndex + 9, macOsXIndex + 16).replaceAll('_', '.');
      macVersion: '',
      joinPartyurl: '',
      sjUrl: require('@/assets/img/sj.png'),
      hxAndrUrl: '',
      ontouch: 'ontouchend' in document,
      env: '',
      downloadTime: null,
      isWindows: navigator.userAgent.includes('Windows')
    }
  },
  created () {
    String.prototype.replaceAll = function (s1, s2) {
      return this.replace(new RegExp(s1, 'gm'), s2)
    }
    this.isMac = /Macintosh|Mac OS X/i.test(navigator.userAgent)
    this.isIpad = navigator.userAgent.includes('iPad')
    this.macOsXIndex = navigator.userAgent.indexOf('Mac OS X')
    this.macVersion = navigator.userAgent
      .slice(this.macOsXIndex + 9, this.macOsXIndex + 16)
      .replaceAll('_', '.')

    sessionStorage.setItem(this.$route.query.join_id, true)
  },
  computed: {
    ...mapState({
      Config: state => state.config,
      State: state => state
    }),
    // 根据语言浏览器版本来添加不同的URL
    downloadUrl () {
      let url = ''
      if (this.isIos || this.isIpad) {
        if (this.lang === 'zh-CN') {
          url =
            'itms-apps://itunes.apple.com/cn/app/tvu-anywhere-pro/id883099579'
        } else {
          url =
            'itms-apps://itunes.apple.com/us/app/tvu-anywhere-pro/id883099579'
        }
      }
      // android
      if (this.isAndroid) {
        url =
          'https://play.google.com/store/apps/details?id=com.tvunetworks.android.anywhere'
      }
      // Mac
      if (this.isMac && !this.isIos && !this.isIpad) {
        if (this.lang === 'zh-CN') {
          url = 'itms-apps://itunes.apple.com/cn/app/tvu-anywhere/id943316643'
        } else {
          url = 'itms-apps://itunes.apple.com/us/app/tvu-anywhere/id943316643'
        }
      }

      // windows
      if (this.isWindows) {
        console.info('windows')
        url = ''
      }

      return url
    }
  },
  mounted () {
    // const isHaveEnviroment = window.location.href.includes('env')
    // if (isHaveEnviroment) {
    //   const index = window.location.href.lastIndexOf('=')
    //   this.env = this.$route.query.env
    // } else {
    //   this.env = ''
    // }
    this.env = this.Config.apiEnv
    this.judgeUrl()
    document.addEventListener('visibilitychange', () => {
      // 如果页面隐藏，推测打开scheme成功，清除下载任务
      if (document.hidden || document.webkitHidden) {
        clearTimeout(this.downloadTime)
      }
    })
    window.addEventListener('pagehide', () => {
      clearTimeout(this.downloadTime)
    })
  },
  methods: {
    checkInstallApp (url) {
      window.location = url
      if (this.isMac) return // mac下的safari
      this.downloadTime = setTimeout(() => {
        if (this.downloadUrl) {
          window.location = this.downloadUrl
        } else {
          console.info('暂无下载地址')
        }
      }, 1500)
    },

    joinAnywhereParty () {
      this.checkInstallApp(this.joinPartyurl)
    },
    judgeUrl () {
      let url = ''
      let domain = window.location.host
      if (domain.indexOf('.tvunetworks.com') > -1) {
        domain = 'www.tvunetworks.com'
      } else {
        domain = 'www.tvunetworks.cn'
      }
      if (this.needRed) {
        const index = window.location.href.indexOf('/j/')
        if (this.isIos || this.isIpad) {
          url = `https://${domain}/partyline?join_id=` + window.location.href.slice(index + 2) + `${this.env ? '&env=' + this.env : ''}`
        }
        // mac
        if (this.isMac) {
          if (!this.ontouch) {
            url = `anywheremac://${domain}/partyline?` + window.location.href.slice(index + 2) + `${this.env ? '&env=' + this.env : ''}`
          }
        }
        // Android
        if (this.isAndroid) {
          url = window.location.origin + '/partyline?' + window.location.href.slice(index + 2) + `${this.env ? '&env=' + this.env : ''}`
          this.hxAndrUrl = `anywhere://${domain}/partyline?join_id=` + window.location.href.slice(index + 2) + `${this.env ? '&env=' + this.env : ''}`
        }

        // windows
        if (this.isWindows && !this.ontouch) {
          url = `anywherewin://${domain}/partyline?` + window.location.href.slice(index + 2) + `${this.env ? '&env=' + this.env : ''}`
        }
      } else {
        const joinIndex = window.location.href.indexOf('join_id=')
        if (this.isIos || this.isIpad) {
          url = `https://${domain}/partyline?` + window.location.href.slice(joinIndex) + `${this.env ? '&env=' + this.env : ''}`
        }
        if (this.isMac) {
          if (!this.ontouch) {
            url = `anywheremac://${domain}/partyline?` + window.location.href.slice(joinIndex) + `${this.env ? '&env=' + this.env : ''}`
          }
        }
        if (this.isAndroid) {
          url = `anywhere://${domain}/partyline?` + window.location.href.slice(joinIndex) + `${this.env ? '&env=' + this.env : ''}`
          this.hxAndrUrl = `anywhere://${domain}/partyline?` + window.location.href.slice(joinIndex) + `${this.env ? '&env=' + this.env : ''}`
        }
        // windows
        if (this.isWindows && !this.ontouch) {
          url = `anywherewin://${domain}/partyline?` + window.location.href.slice(joinIndex) + `${this.env ? '&env=' + this.env : ''}`
        }
      }
      this.joinPartyurl = url + '&' + Math.random()

      // if (!(this.isMac && !this.isIos && !this.isIpad)) {
      //   if (this.isAndroid) {
      //     window.location = this.hxAndrUrl + '&' + Math.random()
      //   } else {
      //     alert(1111)
      //     this.checkInstallApp(`${url}&${Math.random()}`)
      //   }
      // }
    },
    joinParty () {
      const id = this.$route.query.join_id
      const query = {
        join_id: id
      }
      sessionStorage.setItem('enterPartyType', 'web')
      this.$router.push({
        name: 'participate',
        query
      })
    }
  }
}
</script>
<style scoped lang="less">
#step1 {
  background: #ed7e2f;
  height: 110px;
  text-align: center;
  line-height: 110px;
  font-size: 25px;
}
#step1 p {
  color: #fff;
}
#step1 img {
  position: fixed;
  right: 8px;
  top: 5px;
  width: 50px;
}
#step2 {
  text-align: center;
  font-size: 17px;
  margin-top: 200px;
}
#step3 {
  padding: 10px 15px;
  background: #33ab4f;
  border-radius: 3px;
  color: #fff;
  cursor: pointer;
  text-decoration: none;
}
#step3Box {
  text-align: center;
  margin-top: 3px;
}
#userAgent {
  display: block;
}
#otherUI {
  font-size: 14px;
  padding-top: 20px;
  text-align: center;
  display: block;
  width: 90%;
  margin: 0 auto;
  a {
    width: 50%;
    background: #33ab4f;
    color: #fff;
    outline: none;
    border: 0;
    padding: 8px;
    border-radius: 3px;
    display: inline-block;
    font-size: 16px;
    text-align: center;
    text-decoration: none;
    margin-bottom: 40px;
    cursor: pointer;
  }
  .h1text {
    font-size: 20px;
    font-weight: 500;
    color: rgba(84, 85, 96, 1);
  }
  .h3text {
    font-size: 14px;
    font-weight: 500;
    color: rgba(155, 155, 155, 1);
    margin-bottom: 20px;
  }
  .line {
    border-bottom: 1px solid #e1e1e1;
    position: relative;
  }
  .orBox {
    padding: 10px 15px;
    background: 000;
    color: #e1e1e1;
    position: absolute;
  }
  /deep/ .el-divider {
    background-color: #e1e1e1;
    .el-divider__text {
      color: #e1e1e1;
    }
  }
  .AccessBtn {
    margin-top: 20px;
  }
}
</style>
