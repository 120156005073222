<template>
  <el-dialog
    title="Event creation failed"
    :visible.sync="info.show"
    :show-close="false"
    :lock-scroll="false"
    append-to-body
    width="550px"
  >
    <div class="content">
      <p>please modify the event time or Channel</p>
      <p v-for="val in info.data" :key="val.id">
        The receiver has been assigned to "{{ val.title }}" between
        {{ val.startTime | FormatTime('YYYY-MM-DD HH:mm') }} and
        {{ val.endTime | FormatTime('YYYY-MM-DD HH:mm') }}, created by
        {{ val.email }}
      </p>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="info.show = false">Done</el-button>
    </span>
  </el-dialog>
</template>
<script>
export default {
  props: {
    info: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {}
  },
  methods: {}
}
</script>
<style lang="less" scoped>
/deep/.el-dialog {
  background: #28282e;
  .el-dialog__title {
    color: #fff;
  }
  .el-button {
    background: none;
    border: none;
    color: #999;
    font-size: 16px;
  }
  .el-button--primary {
    color: #03897b;
  }
}
.content {
  p {
    margin-bottom: 10px;
    color: #b7b7b7;
    word-break: break-word;
  }
}
</style>
