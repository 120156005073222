<template>
  <div class="removePage">
    <h1><img :src="homeLogo"/><span>{{homeTitle}}</span></h1>
    <p class="title">{{ title }}</p>
    <div v-if="isNeedPassCode">
      <el-input v-model="password" :placeholder="$t('addWords.enterPassWord')"></el-input>
    </div>
    <div class="bottomContent">
      <p>{{ content }}</p>
    </div>
    <el-button v-if="type != 'noCommonpartyPermission'" @click="jump" class="right">{{ buttonText }}</el-button>
  </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
  data () {
    return {
      title: '',
      content: '',
      password: '',
      partyCode: '',
      type: '',
      buttonText: this.$t('HostCommon.backHome'),
      isNeedPassCode: false
    }
  },
  computed: {
    ...mapState({
      State: state => state,
      homeLogo: state => state.homeLogo,
      homeTitle: state => state.homeTitle
    })
  },
  created () {
    this.State.newPageLoading = false
    document.getElementsByClassName('contentBox')[0].style.height = '100vh'
    const { type, partyCode } = this.$route.query
    this.type = type
    this.partyCode = partyCode
    if (type == 'disableParty') {
      this.title = this.$t('coordinationParticipant.unableJoin')
      this.content = this.$t('coordinationParticipant.partyDisabled')
    } else if (type === 'leavrParty') {
      this.title = this.$t('leaveParty.leave')
      this.content = this.$t('leaveParty.removeFromParty')
    } else if (type === 'noCommonpartyPermission') {
      this.title = this.$t('HostCommon.noPermission')
      this.content = this.$t('HostCommon.accountNotAllowed')
    } else if (type === 'needPassCode') {
      this.isNeedPassCode = true
      this.buttonText = this.$t('HostCommon.joinParty')
      this.title = this.$t('HostCommon.participantPassCode')
      this.content = this.$t('HostCommon.caseSensitive')
    }
  },
  methods: {
    jump () {
      if (this.isNeedPassCode) {
        this.judgePassword()
      } else {
        const userServerUrl = this.State.config.urlInfo.userservice
        window.location.href = `${userServerUrl}/?url=${location.protocol}//${location.host}/commentator?join_id=${this.partyCode}&module=commentator&byLogin=true&serviceName=Commentator`
      }
    },
    judgePassword () {
      const haveHistoryMem = localStorage.getItem('particiapntMemberId')
      const params = {
        partyCode: this.partyCode,
        invitePassCode: this.password,
        memberId: haveHistoryMem
      }
      this.$http
        .post(`${this.State.config.urlInfo.partyline}/partyline-backend/partyExtendInfo/noLogin/checkInvite`, params, { errorTips: false })
        .then(res => {
          if (res.data.errorCode === '0x0') {
            this.$router.push({
              name: 'preview',
              query: {
                id: this.partyCode
              },
              params: { password: this.password }
            })
          } else {
            this.$message.error(this.$t('HostCommon.incorrectPassWord'))
          }
        })
    }
  }
}
</script>
<style lang="less" scoped>
.removePage {
  width: 440px;
  background:#FBFBFB;
  border-radius:8px;
  border:2px solid #F2F2F2;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  padding: 20px 45px;
  .bottomContent{
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;
    p {
      display: inline-block;
      margin-bottom: 0;
    }
  }
  h1 {
    text-align: center;
    margin-bottom: 40px;
    img {
      width: 28px;
      height: 28px;
      margin-right: 10px;
    }
    span {
      font-size:24px;
      color:rgba(51,171,79,1);
      line-height:28px;
      background:linear-gradient(233deg, rgba(180,236,81,1) 0%, rgba(66,147,33,1) 100%), linear-gradient(270deg, rgba(3,161,118,1) 0%, rgba(113,186,42,1) 59%, rgba(208,217,0,1) 100%);
      -webkit-background-clip:text;
      -webkit-text-fill-color:transparent;
      position: relative;
      top: -5px;
    }
  }
  p {
    font-size: 16px;
    color: #555661;
    margin-bottom: 40px;
  }
  .title {
    font-weight: bold;
    font-size: 18px;
    color: #28282E;
  }
  .el-button {
      border: 0;
      background:linear-gradient(270deg,rgba(3,161,118,1) 0%,rgba(113,186,42,1) 59%,rgba(208,217,0,1) 100%);
      color: #fff;
      padding: 9px 15px;
      border-radius: 4px;
  }
  /deep/ .el-input__inner {
    background: none;
    border: 2px solid #33aa50;
    width: 60%;
    height: 40px;
    color: #454549;
    margin-bottom: 5px;
  }
}
</style>
