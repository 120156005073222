<template>
  <div id="receiverControl">
    <head class="head">
      <div class="headLeft">
        <svg class="icon-a-TVUlogo_left logoSvg" aria-hidden="true">
          <use :xlink:href="logoSvg"></use>
        </svg>
        <span class="logoText">{{ State.logoTitle }}</span>
      </div>
      <div class="headRight">
        <span class="userEmail">{{ State.loginInfo.userName ? State.loginInfo.userName.slice(0,1) : '' }}</span>
      </div>
    </head>
    <div class="eventList scrollbar">
      <div class="title">Event List</div>
      <!-- @node-expand="joinParty"
      @node-collapse="leaveParty" -->
      <el-tree
        class="eventTree"
        :data="eventList"
        default-expand-all
        @node-click="handleNodeClick"
      >
        <span class="custom-tree-node" slot-scope="{ node, data }">
          <div class="treeLi" :class="{ w_33: node.level == 3 }">
            <span class="name">{{ data.title }}</span>
            <span v-if="node.level == 3" class="status">[ P: <i :class="returnIcon('participant', data)"></i> <span v-for="info in data.rList" :key="info.rtilCode">R: <i :class="returnIcon('receiver', data, info)" :title="info.rtilCode"></i></span> ]</span>
            <el-button v-if="node.level == 3" size="small" @click="addToReceiver(node, data)">Add to Receiver</el-button>
            <el-button v-if="node.level == 3" size="small" @click="removeFromReceiver(node, data)">Remove from Receiver</el-button>
          </div>
        </span>
      </el-tree>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import AgoraClient from '@/assets/js/rtc-client-ng'
export default {
  data () {
    return {
      logoSvg: '',
      eventList: [],
      activeNames: ['1'],
      sendParams: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
        sid: '',
        rid: '',
        startTime: '',
        endTime: '',
        title: '',
        description: '',
        partyCode: '',
        status: [],
        timer: null,
        userId: '',
        needCheckReceiverOccupied: true
      },
      defaultProps: {
        children: 'children',
        label: 'title'
      },
      rtc: {},
      nickNameInfo: {},
      getReceiverStatusTimer: {},
      partyRtilCodeList: {} // 用来记录每个 party 里面加进来的人的  rtilCode
    }
  },
  computed: {
    ...mapState({
      State: state => state
    })
  },
  created () {
    this.State.newPageLoading = false
    this.logoSvg = `#${this.State.commonLogo}`
    this.getEventList()
  },
  methods: {
    getEventList () {
      this.sendParams.userId = this.State.loginInfo.id
      this.$http
        .post('/commentator-backend/event/v2/page', this.sendParams)
        .then(res => {
          if (res.data.errorCode === '0x0') {
            const list = res.data.result.list
            this.assemblyData(list)
          }
        })
    },
    assemblyData (list) {
      if (!list || !list.length) return
      list = list.filter(item => {
        return item.status === 4
      })
      list.forEach(item => {
        item.children = item.eventParties
        item.eventParties.forEach(party => {
          party.children = []
        })
      });
      this.eventList = list
      this.eventList.forEach(party => {
        this.joinParty(party)
        setTimeout(() => {
          this.getReceiverStatus(party.id)
        }, 4000)
        this.getReceiverStatusTimer[party.id] = setInterval(() => {
          this.getReceiverStatus(party.id)
        }, 10000)
      })
    },
    joinParty (data, node) {
      // if (node.level != 1) return
      data.children.forEach(partyInfo => {
        const params = {
          memberId: Math.random(),
          partyCode: partyInfo.partyCode,
          role: 0,
          type: 0
        }
        this.$http
          .post(`${this.State.config.urlInfo.partyline}/partyline-backend/party/v2/noLogin/agoraTokenGenerate`, params)
          .then(async (res) => {
            if (res.data.errorCode === '0x0') {
              const result = res.data.result
              this.joinChannel(result)
            }
          })
      })
    },
    joinChannel (result) {
      const { appId, tokenCode, rtilCode, realPartyCode, videoQualities } = result
      this.rtc[realPartyCode] = new AgoraClient(this.callBack)
      const params = {
        appId,
        channel: realPartyCode + '',
        token: tokenCode,
        uid: rtilCode,
        codec: 'h264',
        video: false,
        audio: false,
        videoConfig: videoQualities,
        publish: false
      }
      this.rtc[realPartyCode].join(params)
    },
    callBack ({ action, payload }) {
      if (action != 'volumeList' && action != 'receiveMetadata') {
        console.log(action, payload)
      }
      switch (action) {
        case 'user-joined':
          this.userJoin(payload)
          break
        case 'user-left':
          this.userLeave(payload)
          break
      }
    },
    userJoin (payload, byReceiverStatus) {
      const { channel, id } = payload
      // 通过 ws 推送 r 的数据不走下面逻辑
      if (!byReceiverStatus) {
        if (!this.partyRtilCodeList[channel]) this.partyRtilCodeList[channel] = []
        if (!this.partyRtilCodeList[channel].includes(id)) {
          this.partyRtilCodeList[channel].push(id)
        }
      }
      // 根据当前的 partyCode 去 eventList 里面找主 partyCode
      let joinPartyCode = ''
      this.eventList.forEach(item => {
        item.children.forEach(partyInfo => {
          if (partyInfo.partyCode == channel) {
            joinPartyCode = item.parentPartyCode
          }
        })
      })
      if (!joinPartyCode) return
      const params = {
        annotation: joinPartyCode,
        keys: [
          'nickName'
        ],
        marks: [id]
      }
      this.$http
        .post(`${this.State.config.urlInfo.partyline}/partyline-backend/behavior/noLogin/getList`, params)
        .then((res) => {
          if (res.data.errorCode === '0x0') {
            const result = res.data.result
            if (!this.nickNameInfo[id]) {
              this.$set(this.nickNameInfo, id, result[id].nickName)
            }
            this.eventList.forEach(item => {
              item.children.forEach(partyInfo => {
                if (partyInfo.partyCode == channel) {
                  let addToList = true
                  partyInfo.children.forEach(info => {
                    if (info.rtilCode == id) {
                      addToList = false
                      info.title = result[id].nickName
                    }
                  })
                  if (addToList) {
                    partyInfo.children.push({
                      rtilCode: Number(id),
                      title: result[id].nickName,
                      partyCode: channel
                    })
                  }
                }
              })
            })
            // 为了页面渲染一次
            this.eventList = JSON.parse(JSON.stringify(this.eventList))
          }
        })
    },
    userLeave (payload) {
      const { channel, id } = payload
      this.partyRtilCodeList[channel] = this.partyRtilCodeList[channel].filter(rtilCode => {
        return rtilCode != id
      })
      this.eventList.forEach(item => {
        item.children.forEach(partyInfo => {
          if (partyInfo.partyCode == channel) {
            partyInfo.children = partyInfo.children.filter(item => {
              return item.rtilCode != id
            })
          }
        })
      })
    },
    leaveParty (data, node) {
      // if (node.level != 1) return
      data.children.forEach(partyInfo => {
        if (this.rtc[partyInfo.partyCode]) {
          this.rtc[partyInfo.partyCode].leave()
          this.$delete(this.rtc, partyInfo.partyCode)
        }
      })
    },
    getReceiverStatus (eventId) {
      this.$http
        .get(`/commentator-backend/receiver/getAudioMixStatus/${eventId}`)
        .then(async (res) => {
          if (res.data.errorCode === '0x0') {
            const result = res.data.result
            if (JSON.stringify(result) === '{}') {
              Object.keys(this.getReceiverStatusTimer).forEach(eventId => {
                if (this.getReceiverStatusTimer[eventId]) clearInterval(this.getReceiverStatusTimer[eventId])
              })
              this.partyRtilCodeList = {}
              this.eventList = []
            } else {
              Object.keys(result).forEach(rid => {
                result[rid].forEach(info => {
                  // 把在 R 上的数据显示出来
                  this.eventList.forEach(item => {
                    if (item.id == eventId) {
                      item.children.forEach(party => {
                        if (party.partyCode == info.partyCode) {
                          let addToList = true
                          party.children.forEach(partyInfo => {
                            partyInfo.rList = result
                            if (partyInfo.rtilCode == info.rtilCode) {
                              addToList = false
                            }
                          })
                          if (addToList) {
                            party.children.push({
                              rtilCode: info.rtilCode,
                              title: this.nickNameInfo[info.rtilCode],
                              partyCode: info.partyCode
                            })
                            this.userJoin({
                              channel: info.partyCode,
                              id: Number(info.rtilCode)
                            }, 'byReceiverStatus')
                          }
                        }
                      })
                    }
                  })
                })
              })
              console.log(11111, JSON.parse(JSON.stringify(this.eventList)))
              this.eventList = JSON.parse(JSON.stringify(this.eventList))
            }
          }
        })
    },
    addToReceiver (node, data) {
      const eventId = node.parent.parent.data.id
      if (this.getReceiverStatusTimer[eventId]) clearInterval(this.getReceiverStatusTimer[eventId])
      this.getReceiverStatusTimer[eventId] = setInterval(() => {
        this.getReceiverStatus(eventId)
      }, 5000)
      const params = {
        partyCode: data.partyCode,
        rtilCode: data.rtilCode
      }
      this.$http
        .post('/commentator-backend/commentator/party/addMixAudio', params)
        .then(async (res) => {
          if (res.data.errorCode === '0x0') {
            // 需要添加 eventList 里面的数据
            this.eventList.forEach(event => {
              if (event.id == eventId) {
                event.children.forEach(partyInfo => {
                  if (partyInfo.partyCode == data.partyCode) {
                    partyInfo.children.forEach(participantInfo => {
                      participantInfo.rList && Object.keys(participantInfo.rList).forEach(rid => {
                        participantInfo.rList[rid].push(params)
                      })
                    })
                  }
                })
              }
            })
            this.eventList = JSON.parse(JSON.stringify(this.eventList))
          }
        })
    },
    removeFromReceiver (node, data) {
      const eventId = node.parent.parent.data.id
      if (this.getReceiverStatusTimer[eventId]) clearInterval(this.getReceiverStatusTimer[eventId])
      this.getReceiverStatusTimer[eventId] = setInterval(() => {
        this.getReceiverStatus(eventId)
      }, 5000)
      const params = {
        partyCode: data.partyCode,
        rtilCode: data.rtilCode
      }
      this.$http
        .post('/commentator-backend/commentator/party/removeMixAudio', params)
        .then(async (res) => {
          if (res.data.errorCode === '0x0') {
            // 需要删除 eventList 里面的数据
            this.eventList.forEach(event => {
              if (event.id == eventId) {
                event.children.forEach(partyInfo => {
                  if (partyInfo.partyCode == data.partyCode) {
                    partyInfo.children.forEach(participantInfo => {
                      Object.keys(participantInfo.rList).forEach(rid => {
                        participantInfo.rList[rid] = participantInfo.rList[rid].filter(item => {
                          return item.rtilCode != data.rtilCode
                        })
                      })
                    })
                  }
                })
              }
            })
            // 如果 partyRtilCodeList[partyCode] 里面也没有的话则移除这条数据
            if (this.partyRtilCodeList[data.partyCode] && !this.partyRtilCodeList[data.partyCode].includes(Number(data.rtilCode))) {
              this.eventList.forEach(item => {
                item.children.forEach(party => {
                  if (party.partyCode == data.partyCode) {
                    party.children = party.children.filter(info => {
                      return info.rtilCode != data.rtilCode
                    })
                  }
                })
              })
            } else {
              this.eventList = JSON.parse(JSON.stringify(this.eventList))
            }
          }
        })
    },
    returnIcon (type, data, info) {
      const { partyCode, rtilCode } = data
      if (type === 'participant') {
        if (this.partyRtilCodeList[partyCode] && this.partyRtilCodeList[partyCode].includes(Number(rtilCode))) {
          return 'el-icon-check'
        } else {
          return 'el-icon-close'
        }
      } else {
        let iconClass = 'el-icon-close'
        info.forEach(partyInfo => {
          if (partyInfo.partyCode == partyCode && rtilCode == partyInfo.rtilCode) {
            iconClass = 'el-icon-check'
          }
        })
        return iconClass
      }
    },
    handleNodeClick () {

    },
    handleChange () {

    }
  }
}
</script>
<style lang="less" scoped>
#receiverControl {
  margin-top: -5px;
  .head {
    height: 62px;
    background: #000;
    color: #f1f1f1;
    padding: 0 16px;
    border-bottom: 2px solid #28282e;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .headLeft {
      display: flex;
      align-items: center;
      .logoSvg {
        width: 32px;
        height: 32px;
        margin-right: 16px;
        float: left;
      }
      .logoText {
        font-weight: 700;
        font-size: 16px;
        line-height: 18px;
        color: #DBDBDB;
      }
    }
    .userEmail {
      font-size: 16px;
      width: 28px;
      float: right;
      height: 28px;
      border-radius: 50%;
      line-height: 28px;
      background: #03897b;
      text-align: center;
      cursor: pointer;
    }
  }
  .eventList {
    margin-top: 20px;
    overflow-y: scroll;
    height: calc(100vh - 84px);
    .title {
      font-size: 18px;
      font-weight: bold;
      padding-left: 18px;
      margin-bottom: 15px;
    }
    .el-tree.eventTree {
      padding: 0 12px;
      color: #03897b;
      background: transparent;
      /deep/.el-tree-node {
        &:focus>.el-tree-node__content {
          background: #28282e;
        }
      }
      /deep/.el-tree-node__content {
        height: 40px;
        &:hover {
          background-color: #28282e;
        }
        .treeLi {
          .name {
            margin-right: 10px;
          }
          .status {
            margin-right: 10px;
          }
          .el-button {
            background: #33AB4F;
            border: 1px solid #33AB4F;
            color: #f1f1f1;
            &:hover {
              background: #34964b;
            }
          }
        }
      }
    }
  }
}
</style>
