<template>
  <el-dialog
    :title="$t('eventList.groupLink')"
    :visible.sync="info.show"
    :lock-scroll="false"
    :close-on-click-modal="false"
    width="720px"
    append-to-body
    class="thisblack-bgc groupList"
  >
    <div class="content groupLink">
      <div class="title addGroup">
        <div class="groupItem" v-for="(val, key) in info.partyList" :key="key">
          <div v-if="val.title">
            <span class="index">No.{{ key + 1 }}</span>
            <el-tooltip
              class="item"
              effect="dark"
              placement="top-start"
              :visible-arrow="false"
            >
            <el-input
              v-model="val.title"
              :placeholder="$t('eventList.addGroupName')"
              :maxlength="64"
              :readonly="true"
            ></el-input>
            <div slot="content">{{$t('otherWords.partyId')}}:{{ val.partyCode }}</div>
            </el-tooltip>
            <el-tooltip
              class="item"
              effect="dark"
              :content="$t('eventList.copyGroupLink')"
              placement="top"
            >
              <span
                class="link el-icon-connection"
                v-clipboard:copy="copyLink(val, 'partyCode')"
                v-clipboard:success="onCopy"
                v-clipboard:error="onError"
              ></span>
            </el-tooltip>
          </div>
        </div>
      </div>
      <invited
        :inviteInfo="inviteInfo"
        :partyCode="info.joinCode"
        :invitedInfo="info.invitedInfo"
        @callback="dealInviteEvent"
      ></invited>
    </div>
    <span slot="footer" class="dialog-footer">
      <div class="pointer inviteBox left" @click="inviteInfo.show = true">
        <span class="f_15">{{ info.invitedInfo | showJoinModel }}</span><i class="el-icon-arrow-down"></i>
      </div>
      <a
        href="javascript:void(0);"
        class="save"
        @click="done"
        >{{$t('eventList.doneN')}}</a
      >
    </span>
  </el-dialog>
</template>
<script>
import { mapState } from 'vuex'
import invited from '@/components/common/invited.vue'
export default {
  props: {
    info: Object
  },
  components: {
    invited
  },
  data () {
    return {
      show: true,
      inviteInfo: {
        show: false,
        title: 'invite.getLink',
        save: 'invite.saveInvite',
        hideCancel: true,
        hideSave: true
      }
    }
  },
  computed: {
    ...mapState({
      Config: (state) => state.config
    })
  },
  methods: {
    copyLink (val, type) {
      if (type == 'partyCode') {
        const domain = window.location.host
        return (
          this.$t('otherWords.copyLinkTips') +
          'https://' +
          domain +
          '/commentator?join_id=' +
          val.partyCode +
          '\n\n' +
          'Party ID: ' +
          this.$options.filters.returnStrByInfo(val.partyCode, 4)
        )
      } else {
        return val
      }
    },
    onCopy () {
      this.$message({
        message: this.$t('otherWords.copySuccess'),
        type: 'success'
      })
    },
    onError () {
      this.$message({
        message: 'Copy failed',
        type: 'error'
      })
    },
    done () {
      this.info.show = false
    },
    dealInviteEvent (info) {
      const { type, val } = info
      if (type === 'changeModel') {
        this.info.invitedInfo.inviteMode = val
      }
    }
  },
  filters: {
    showJoinModel (info) {
      const value = info.inviteMode
      if (value === 0) {
        return i18n.t('invite.anyoneJoin')
      } else if (value === 1) {
        return i18n.t('invite.onlyUserJoin')
      } else if (value === 3) {
        return i18n.t('invite.onlyByPasscode')
      } else {
        return i18n.t('invite.anyoneJoin')
      }
    }
  }
}
</script>
<style lang="less" scoped>
.thisblack-bgc.groupList{
  background-color: rgba(0, 0, 0, 0.5);
  overflow-y: hidden;
  /deep/.el-dialog__footer {
    padding: 10px 20px 32px;
    .inviteBox {
      display: flex;
      align-items: center;
      padding: 8px 15px;
      border-radius: 5px;
      margin-top: -7px;
      .el-icon-arrow-down {
        margin-top: 2px;
        margin-left: 4px;
      }
    }
  }
}
/deep/.el-dialog {
  background: #28282e;
  .el-dialog__title {
    color: #fff;
  }
  .el-dialog__close {
    color: #fff;
  }
  .dialog-footer {
    a {
      color: #999;
      margin-left: 30px;
    }
    .save {
      color: #fff;
      padding: 3px 17px 5px;
      background: #03897b;
      border-radius: 5px;
      &.disabled {
        color: #999;
        cursor: not-allowed;
      }
    }
  }

  .el-dialog__body {
    color: #fff;
    font-size: 16px;
    max-height: 500px;
    overflow-y: auto;
    &::-webkit-scrollbar {
      /*滚动条整体样式*/
      width: 6px; /*高宽分别对应横竖滚动条的尺寸*/
      height: 6px;
    }
    &::-webkit-scrollbar-thumb {
      /*滚动条里面小方块*/
      border-radius: 10px;
      background: rgba(255, 255, 255, 0.3);
    }
    &::-webkit-scrollbar-track {
      /*滚动条里面轨道*/
      border-radius: 10px;
      background: none;
    }
    input:disabled {
      color:#909090 !important;
    }
    .el-input {
      width: 460px;
    }
    .el-input__inner {
      background: #333339;
      border-radius: 4px;
      border: none;
      color: #fff;
    }
    .el-textarea {
      width: 460px;
      .el-textarea__inner {
        color: #fff;
        height: 40px;
        line-height: 29px;
        border-radius: 5px;
        background: #333339;
        border: none;
        border-radius: 5px;
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
          'Oxygen', 'Ubuntu', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
          sans-serif;
      }
    }
    .el-input--prefix {
      .el-icon-time {
        display: none;
      }
      .el-input__inner {
        padding-left: 15px;
      }
    }
    .el-input--suffix {
      .el-input__inner {
        padding-right: 30px;
      }
    }
    .sign {
      margin: 0 12px;
    }
    .el-date-editor {
      width: 215px;
      .el-range-input {
        background: none;
        color: #fff;
      }
      .el-range-separator {
        color: #fff;
      }
    }
  }
  .content {
    .inputType {
      .type-select-box {
        color: red;
        .el-select {
          .el-input__inner {
            height: 40px;
            line-height: 40px;
          }
          .el-select-dropdown {
            top: 38px !important;
          }
        }
      }
    }
    .el-divider {
      background-color: #333339;
    }
    .title {
      margin-bottom: 10px;
      position: relative;
      .select {
        .icon {
          position: absolute;
          top: 13px;
          right: 13px;
        }
      }
      .el-dropdown-link {
        color: #fff;
        .notAllowed {
          cursor: not-allowed;
          color: #909090;
        }
      }
      /deep/ .el-switch {
        .el-switch__core {
          border-color: #b8babc !important;
          background-color: #b8babc !important;
        }
        &.is-checked {
          .el-switch__core {
            border-color: #03897b !important;
            background-color: #03897b !important;
          }
        }
      }
      .set_now_icon {
        cursor: pointer;
        color: #03897b;
        border-bottom: 1px solid#03897b;
        margin-left: 20px;
        &.notAllowed {
          cursor: not-allowed;
          color: #909090;
          border-bottom: 1px solid #909090;
        }
      }
      &.duration_box {
        .duration {
          margin-left: 188px;
        }
        .unit {
          position: absolute;
          top: 9px;
          right: 30px;
          color: #fff;
        }
        .duration_input {
          display: inline-block;
          width: 100px;
          // margin-right: 23px;
          padding-right: 23px;
          .el-input__inner {
            padding-right: 40px;
          }
        }
      }
      .inputType {
        display: inline-block;
        width: 190px;
        margin-right: 10px;
        &.width330 {
          width: 435px;
        }
        .el-input {
          width: 100%;
        }
      }
      .inputList {
        display: inline-block;
        width: 235px;
        position: relative;
        .el-input {
          width: 100%;
        }
        .select {
          width: 100%;
          .el-select-dropdown {
            position: absolute !important;
            top: 38px !important;
            left: 0 !important;
          }
        }
      }
      .selectedNum {
        color: #03897b;
      }
      .grid {
        margin: 0 30px 0 110px;
        padding: 0 20px;
        margin-bottom: 10px;
        background: rgba(0, 0, 0, 0.15);
        border-radius: 4px;
        border: 1px solid #333339;
        line-height: 50px;
        .name {
          color: #03897b;
          margin-right: 10px;
          max-width: 180px;
        }
        .url {
          max-width: 200px;
        }
        .operate {
          cursor: pointer;
        }
      }
      .outputList {
        padding-left: 110px;
        .icon {
          margin-right: 5px;
          cursor: pointer;
        }
      }
      .backup {
        position: absolute;
        top: 9px;
        right: 37px;
        .el-checkbox {
          &.is-checked {
            .el-checkbox__label {
              color: #03897b;
            }
          }
          &.is-disabled {
            .el-checkbox__label {
              color: #909090;
            }
          }
          .el-checkbox__label {
            padding-left: 5px;
          }
          .el-checkbox__input {
            &.is-checked {
              .el-checkbox__inner {
                background: #03897b;
                border: 2px solid #03897b;
                &::after {
                  left: 3px;
                }
              }
            }
            &.is-disabled {
              .el-checkbox__inner {
                background: #909090;
                border: 2px solid #909090;
                &::after {
                  left: 3px;
                }
              }
            }
            .el-checkbox__inner {
              background: none;
              border: 2px solid #666;
            }
          }
        }
      }
      .backupBtn {
        margin-right: 10px;
        padding: 3px 16px 6px;
        background: #4e4e4e;
        border-radius: 5px;
        cursor: pointer;
        color: #999;
        &.active {
          background: #03897b;
          color: #fff;
          &.disabled {
            background: #026056;
            color: #BAC0BF;
          }
        }
        &.applyBtn {
          margin-left: 150px;
          background: #f6445a;
          color: #fff;
        }
        &.disabled {
          cursor: not-allowed;
        }
      }
    }
    .label {
      display: inline-block;
      width: 110px;
      text-align: right;
      line-height: 40px;
      padding-right: 10px;
      .require {
        color: #f6445a;
        margin-right: 5px;
      }
    }
    .btn {
      color: #03897b;
      cursor: pointer;
      text-decoration: underline;
    }
    .tips {
      font-size: 14px;
      color: #555661;
      float: right;
      position: relative;
      top: 9px;
    }
    .mixer {
      margin-top: 25px;
      position: relative;
      .audio {
        margin: 5px 0 5px 57px;
      }
      .link {
        position: absolute;
        top: 48px;
        right: 148px;
      }
      .url {
        padding: 0 10px;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 380px;
        margin-top: 15px;
        margin-right: 15px;
        margin-left: 26px;
        height: 40px;
        line-height: 40px;
        background: rgba(0, 0, 0, 0.15);
        border-radius: 4px;
        border: 1px solid #333339;
        a {
          color: #4a90e2;
          text-decoration: underline;
        }
      }
    }
    .groupItem {
      margin-bottom: 10px;
      .el-input {
        width: 360px;
        margin-right: 20px;
      }
      .el-divider {
        margin: 0 20px;
        width: 2px;
        height: 32px;
        background: #333339;
      }
      .el-input__inner {
        color: #fff;
        background: #333339;
        border: none;
        border-radius: 5px;
      }
      i {
        cursor: pointer;
        font-size: 20px;
      }
      .index {
        display: inline-block;
        width: 115px;
        text-align: right;
        margin-right: 5px;
      }
    }
    /deep/.el-input {
      &.is-disabled {
        .el-input__inner {
          color: #999;
          cursor: not-allowed;
        }
      }
    }
    .slider {
      display: inline-block;
      width: 275px;
      position: relative;
      top: 13px;
      .el-slider {
        .el-slider__runway {
          width: 150px;
          height: 4px;
          background: #585b5d;
        }
        .el-slider__button {
          border: none;
          background-color: #03897b;
        }
        .el-slider__bar {
          background-color: #03897b;
          height: 4px;
        }
        .el-slider__input {
          width: 100px;
          .el-input {
            width: 70px;
            .el-input__inner {
              padding: 0 15px;
              text-align: left;
            }
          }
          .el-input-number__decrease,
          .el-input-number__increase {
            display: none;
          }
        }
      }
      &:after {
        content: 's';
        position: absolute;
        top: 6px;
        right: 44px;
      }
    }
    .gray {
      color: #6d6d6d;
      font-size: 14px;
    }
    .search {
      float: right;
      display: inline-block;
      .input-with-select {
        width: 100%;
      }
      .el-input-group__prepend {
        border: none;
        background: transparent;
        .el-input {
          width: 100px;
          .el-input__inner {
            border-top-left-radius: 16px;
            border-bottom-left-radius: 16px;
            border-top-right-radius: 0px;
            border-bottom-right-radius: 0px;
            background: #333339 !important;
            color: #fff !important;
            width: 100px;
            padding: 0 15px;
            &:hover {
              background: #333339;
            }
          }
        }
      }
      .el-input__inner {
        width: 150px;
        height: 36px;
        line-height: 36px;
        border-top-right-radius: 16px;
        border-bottom-right-radius: 16px;
        padding: 0 38px 0 15px;
      }
      .el-input-group__append {
        padding: 0 10px;
        position: relative;
        left: -36px;
        border-radius: 19px;
        background: none;
        border: none;
        cursor: pointer;
      }
    }
    .eventId {
      padding-left: 60px;
      span {
        cursor: pointer;
      }
    }
  }
  .link {
    cursor: pointer;
    border-radius: 5px;
    color: #fff;
    font-size: 20px;
    position: relative;
    &:hover {
      &:after {
        content: '';
        display: block;
        width: 40px;
        height: 40px;
        background: #ffffff;
        border-radius: 50%;
        opacity: 0.04;
        position: absolute;
        top: -11px;
        left: -9px;
      }
    }
  }
  .groupLink {
    .groupItem {
      padding-left: 20px;
    }
  }
  .el-dialog__footer {
    span {
      cursor: pointer;
    }
  }
}
</style>
