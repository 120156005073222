<template>
  <el-dialog
    :title="$t('leftPane.extIpSourceSetting')"
    :visible.sync="info.show"
    :lock-scroll="false"
    width="760px"
  >
    <div class="content">
      <div class="title">
        <span class="label">{{$t('leftPane.sourceName')}} </span>
        <el-input
          v-model="params.sourceName"
          :placeholder="$t('leftPane.lessThanWords')"
          maxlength="100"
        ></el-input>
      </div>
      <div class="title">
        <span class="label">URL </span>
        <el-input
          :placeholder="placeholder"
          v-model="params.source"
          class="input-with-select"
        >
          <el-select
            v-model="sourceType"
            slot="prepend"
            placeholder=""
            popper-class="input-select"
            @change="changeType"
          >
            <el-option label="Generic Ext" value="Ext"></el-option>
            <el-option label="Youtube" value="YouTube" v-if="showYoutube"></el-option>
            <el-option label="ProMPEG(RTP+FEC)" value="RTP"></el-option>
          </el-select>
        </el-input>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="save">{{$t('leftPaneOutPut.save')}}</el-button>
    </span>
  </el-dialog>
</template>
<script>
export default {
  props: {
    info: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {
      params: {
        sourceName: '',
        source: '',
        sourceType: 2
      },
      sourceType: 'Ext',
      urlPlaceholder: {
        Ext: 'http(s)/rtmp(s)/issp/mms/hls/udp/ftp/...',
        YouTube: 'https://',
        RTP: 'rtp://'
      },
      placeholder: '',
      showYoutube: !document.domain.includes('.cn')
    }
  },
  created () {
    this.placeholder = this.urlPlaceholder[this.params.sourceType]
  },
  methods: {
    save () {
      if (!this.params.sourceName) {
        this.$message.error(this.$t('otherWords.nameIsRequired'))
        return
      }
      if (!this.params.source) {
        this.$message.error(this.$t('otherWords.urlIsRequired'))
        return
      }
      if (this.sourceType == 'Ext') {
        delete this.params.sourceParams
      } else {
        if (
          !this.params.source.startsWith(this.urlPlaceholder[this.sourceType])
        ) {
          this.$message.error(
            this.$t('otherWords.urlValidTips') + ` ${
              this.urlPlaceholder[this.sourceType]
            }`
          )
          return
        }
        this.params.sourceParams = {
          extType: this.sourceType
        }
      }
      this.$http
        .post('/commentator-backend/user/source/input/pp/create', this.params)
        .then((res) => {
          if (res.data.errorCode == '0x0') {
            this.$message.success(this.$t('otherWords.addExtIpSourceSuccess'))
            this.info.show = false
            this.$emit('callback', { type: 'addExt' })
          }
        })
    },
    changeType () {
      this.params.source = ''
      this.placeholder = this.urlPlaceholder[this.sourceType]
    }
  }
}
</script>
<style lang="less" scoped>
/deep/.el-dialog {
  background: #28282e;
  .el-dialog__title {
    color: #fff;
  }
  .el-button {
    background: #03897b;
    border: #03897b;
    color: #999;
    font-size: 16px;
  }
  .el-button--primary {
    color: #fff;
  }
  .el-icon-close {
    color: #fff;
  }
  .el-dialog__body {
    color: #fff;
    font-size: 16px;
  }
}
.content {
  .title {
    .label {
      line-height: 30px;
    }
  }
  p {
    margin-bottom: 10px;
    color: #b4b4b4;
  }
  /deep/.el-input__inner {
    background: #333339;
    border: 1px solid #333339;
    height: 48px;
    line-height: 48px;
    color: #fff;
    &::-webkit-input-placeholder {
      color: #555661;
    }
  }
  /deep/.input-with-select {
    .el-input__inner {
      color: #fff;
      background: #333339;
      border-left: 1px solid #585b5d;
      height: 48px;
      line-height: 48px;
      &:hover {
        color: #fff;
      }
    }
    .el-input-group__prepend {
      width: 195px;
      background: #333339;
      border: 1px solid #333339;
      .el-input__inner {
        border-left: 1px solid transparent;
      }
    }
  }
}
</style>
