<template>
  <div class="header" v-show="!State.fullScreen">
    <tvu-top-header
      :tvuLogo="false"
      projectName="Remote Commentator"
      :globalFunList="returnGlobalFunList"
      :multiAccountList="multiAccountList"
      :userAuthUrl="Config.urlInfo.userAuthUrl"
      theme="dark"
      :versionServiceNameList="serviceNameList"
      :serviceVesion="[{
        serviceName: 'Remote Commentator',
        frontendVersion: fontendVersion,
        backendVersion: backendVersion,
        tagName: $store.state.config.applicationName
      }]"
      @callback="topHeaderCallback">
      <template #top-header-project-name >
        <div class="left">
          <el-tooltip class="item" effect="dark" placement="bottom">
            <!-- 版本号显示内容 -->
            <div slot="content">
              <div class="CommentatorVersion">Commentator(ENV: {{ $store.state.config.applicationName }}):<br />Frontend: {{ fontendVersion }}<br />Backend: {{ backendVersion }}</div>
            </div>
            <div>
              <svg class="icon-a-TVUlogo_left logoSvg" @click="showMetadata()" aria-hidden="true">
                <use :xlink:href="logoSvg"></use>
              </svg>
            </div>
          </el-tooltip>
          <span class="logoText">{{$route.name === 'usage'? State.logoTitle+' / Usage': State.logoTitle }}</span>
        </div>
      </template>
      <!--中间-->
      <template #top-header-middle v-if="$route.name != 'usage'">
        <div class="middle">
          <!-- 显示网络状况 -->
          <el-popover  popper-class="netWorkTips" effect="dark" placement="bottom-start" :visible-arrow="false">
            <div>
              <h6>{{$t('personalInfo.bandWidth')}}</h6>
              <p class="upLink">
                <i class="el-icon-top" :class="colorUp"></i>
                <span :class="colorUp">{{$t(`personalInfo.${netWork.upLinkNetWork}`)}}</span>
              </p>
              <p class="downLink">
                <i class="el-icon-bottom" :class="colorDown"></i>
                <span :class="colorDown">{{$t(`personalInfo.${netWork.downLinkNetWork}`)}}</span>
              </p>
            </div>
            <NetWorkQuality slot="reference" @callback="showNetWork"></NetWorkQuality>
          </el-popover>
          <div  class="timeCount" v-if="eventStartTime===0">
            <span>{{ $t('coordinationParticipant.gettingReady') }}</span>
          </div>
          <div class="timeCount" v-else>
            <el-tooltip effect="dark" :content="urlText" placement="top">
              <span class="m_r_20 ">{{ urlTextInfo }} [{{ eventGroupNames }}]</span>
            </el-tooltip>
            <span>{{ eventStartTime }} - </span>
            <span>{{ eventEndTime }}</span>
          </div>
        </div>
      </template>
      <!--右侧-->
      <template #right-app-module v-if="!firstWord">
        <div class="loginBox">
          <div class="noLoginLang" v-if="!firstWord && State.switchLang" @click="changeParticipantLanguage" style="cursor: pointer;">
            <img src="@/assets/img/en.png" v-if="$i18n.locale === 'en'" />
            <img src="@/assets/img/zh.png" v-else-if="$i18n.locale === 'zh'" />
            <img src="@/assets/img/en.png" v-else />
          </div>
          <el-button class="right loginButton" v-if="!firstWord" @click="login"
            >{{$t('personalInfo.login')}}/{{$t('personalInfo.register')}}</el-button
          >
        </div>
      </template>
      <!-- 自定义系统级别账号下拉的中间模块 -->
      <template #account-center-module>
        <div class="language" v-if="State.switchLang">
          <div class="left">
            {{$t('personalInfo.lang')}}
          </div>
          <div class="right">
              <span
                :class="{ zhColor: $i18n.locale === 'zh'}"
                @click="changeLanguage('en')"
                >EN</span
              >
              <span class="second">/</span>
              <span
                :class="{ zhColor: $i18n.locale !== 'zh' }"
                @click="changeLanguage('zh')"
                >中文</span
              >
          </div>
        </div>
      </template>
    </tvu-top-header>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import Cookie from 'js-cookie'
import Bus from '@/assets/js/bus.js'
import NetWorkQuality from '@/components/common/netWorkQuality'
const moment = require('moment')
export default {
  components: {
    NetWorkQuality
  },
  data () {
    return {
      serviceNameList: [],
      multiAccountList: [],
      netWork: {
        upLinkNetWork: 'Good',
        downLinkNetWork: 'Good'
      },
      eventGroupName: '',
      userId: '',
      copyUrl: '',
      eventStartTime: 0,
      eventEndTime: 0,
      fontendVersion: '',
      env: JSON.parse(localStorage.getItem('partylineCommentatorConfig')).apiEnv,
      backendVersion: '',
      showMetadataInfo: false,
      logoSvg: ''
    }
  },
  computed: {
    ...mapState({
      State: (state) => state,
      Config: (state) => state.config,
      userInfo: (state) => state.loginInfo,
      websocket: (state) => state.socket,
      eventInfo: (state) => state.eventInfo
    }),
    returnGlobalFunList () {
      let array = []
      if (this.firstWord && this.$route.name != 'usage') {
        array = ['account', 'helpCenter']
      } else if (this.$route.name === 'usage') {
        array = ['waffle', 'account', 'helpCenter']
      }
      return array
    },
    colorUp () {
      if (!this.netWork.upLinkNetWork) return ''
      if (this.netWork.upLinkNetWork === 'Good') {
        return 'colorGreen'
      } else if (this.netWork.upLinkNetWork === 'Mid') {
        return 'colorOrange'
      } else if (this.netWork.upLinkNetWork === 'Poor') {
        return 'colorRed'
      } else {
        return 'colorGreen'
      }
    },
    colorDown () {
      if (!this.netWork.downLinkNetWork) return ''
      if (this.netWork.downLinkNetWork === 'Good') {
        return 'colorGreen'
      } else if (this.netWork.downLinkNetWork === 'Mid') {
        return 'colorOrange'
      } else if (this.netWork.downLinkNetWork === 'Poor') {
        return 'colorRed'
      } else {
        return 'colorGreen'
      }
    },
    firstWord () {
      const userInfo = this.userInfo.firstName || this.userInfo.email || ''
      if (userInfo[0]) {
        return userInfo.substring(0, 1)
      }
      return ''
    },
    getName () {
      const userInfo = this.userInfo
      if (userInfo.firstName && userInfo.lastName) {
        return userInfo.firstName + ' ' + userInfo.lastName
      } else if (userInfo.userName) {
        return userInfo.userName
      } else {
        return userInfo.email ? userInfo.email.split('@')[0] : ''
      }
    },
    eventGroupNames () {
      if (this.eventInfo.eventParties) {
        this.eventInfo.eventParties.forEach((item, index) => {
          if (item.partyCode == this.State.userInfo.realPartyCode) {
            this.eventGroupName = item.title
          }
        })
      }
      return this.eventGroupName
    },
    urlText () {
      const partyCode = this.State.userInfo.realPartyCode
      if (!partyCode) return
      let _str = 'Party ID: '
      _str += this.$options.filters.returnStrByInfo(partyCode, 4)
      return _str
    },
    urlTextInfo () {
      const partyCode = this.State.userInfo.realPartyCode
      return this.eventInfo.title
        ? this.eventInfo.title
        : this.$options.filters.returnStrByInfo(partyCode, 4)
    },
    urlAddress () {
      const domain = window.location.host
      const partyCode = this.State.userInfo.realPartyCode
      if (partyCode) {
        return (
          this.$t('otherWords.copyLinkTips') +
          'https://' +
          domain +
          '/partyline?join_id=' +
          this.State.userInfo.realPartyCode +
          `${this.env ? '&env=' + this.env : ''}` +
          '\n\n' +
          'Party ID: ' +
          this.$options.filters.returnStrByInfo(
            this.State.userInfo.realPartyCode,
            4
          )
        )
      }
      return ''
    }
  },
  async created () {
    this.userId = await this.$store.dispatch('userInfo')
    // 显示前端版本号
    let frontVersion = localStorage.getItem('partylineCommentatorVersion')
    if (frontVersion.includes('_')) {
      frontVersion = frontVersion.substring(0, frontVersion.indexOf('_'))
    }
    this.fontendVersion = 'Front ' + frontVersion
    // 获取后端版本号
    await this.$store.dispatch('getBackEndVersion')
    let backVersion = localStorage.getItem('backendVersion')
    if (backVersion && backVersion.includes('_')) {
      backVersion = backVersion.substring(0, backVersion.indexOf('_'))
    }
    this.backendVersion = 'Backend ' + backVersion
  },
  mounted () {
    this.logoSvg = `#${this.State.commonLogo}`
  },
  watch: {
    eventInfo (info) {
      const { startTime, endTime } = info
      this.eventStartTime = moment(startTime).format('YYYY-MM-DD HH:mm:ss')
      this.eventEndTime = moment(endTime).format('YYYY-MM-DD HH:mm:ss')
    }
  },
  methods: {
    topHeaderCallback (info) {
      const { type } = info
      if (type === 'signOut') {
        this.logout()
      }
    },
    showNetWork (netWork) {
      this.netWork.upLinkNetWork = netWork.upLinkNetWork
      this.netWork.downLinkNetWork = netWork.downLinkNetWork
    },
    showMetadata () {
      this.showMetadataInfo = !this.showMetadataInfo
      Bus.$emit('controlMetaData', this.showMetadataInfo)
    },
    changeLanguage (lang) {
      Cookie.set('userLanguage', lang, { domain: '.tvunetworks.com' })
      this.$i18n.locale = lang
      this.State.browserLanguage = lang
    },
    changeParticipantLanguage () {
      if (this.$i18n.locale === 'zh' || this.$i18n.locale === 'zh-CN') {
        Cookie.set('userLanguage', 'en', { domain: '.tvunetworks.com' })
        this.$i18n.locale = 'en'
        this.State.browserLanguage = 'en'
      } else if (this.$i18n.locale === 'en') {
        Cookie.set('userLanguage', 'zh', { domain: '.tvunetworks.com' })
        this.$i18n.locale = 'zh'
        this.State.browserLanguage = 'zh'
      }
    },
    login () {
      const userServerUrl = this.Config.urlInfo.userservice
      window.location.href = `${userServerUrl}/?url=${location.protocol}//${location.host}/commentator?join_id=${this.State.userInfo.realPartyCode}&module=commentator&byLogin=true&serviceName=Commentator`
    },
    logout () {
      let domain = '.tvunetworks.com'
      if (!location.host.includes(domain)) {
        domain = '.tvunetworks.cn'
      }
      this.$http.get('/commentator-backend/user/logout').then((res) => {
        if (res.data.errorCode == '0x0') {
          localStorage.removeItem('commentatorSession')
          window.location.href = `${this.Config.urlInfo.userservice}/?url=${location.protocol}//${location.host}/commentator&serviceName=${this.Config.serverName}`
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
.header {
    margin-bottom: 5px;
  .left {
    display: flex;
    align-items: center;
    .el-tooltip {
      display: flex;
      align-items: center;
    }
    .logoSvg {
      width: 32px;
      height: 32px;
      margin-right: 16px;
    }
    .logoText {
      font-weight: 700;
      font-size: 16px;
      line-height: 18px;
      color: #DBDBDB;
    }
  }
  .middle {
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    .copyBtn {
      cursor: pointer;
      font-size: 16px;
      font-weight: 700;
      color: #f1f1f1;
      text-shadow: 0 1px 3px rgb(0 0 0 / 20%);
      -webkit-text-fill-color: transparent;
      background: linear-gradient(202deg, #b4ec51, #0c6);
      -webkit-background-clip: text;
      margin-left: 20px;
    }
  }
  .loginBox {
    display: flex;
    align-items: center;
    .noLoginLang {
      display: flex;
      // height: 32px;
      margin-right: 24px;
    }
  }
  .language {
    width: 100%;
    height: 22px;
    margin-top: 15px;
    .left {
      float: left;
      margin-left: 24px;
      font-size: 16px;
      font-weight: bold;
      color: #f1f1f1;
    }

    .right {
      margin-right: 4px;
      width: 64px;
      text-align: end;
      float: right;
      font-size: 16px;
      font-weight: 500;
      color: #03897b;

      .second {
        font-weight: 500;
        color: #F1F1F1;
      }

      .zhColor {
        color: #f1f1f1 !important;
        cursor: pointer;
      }
    }
  }
  // 头像区域
  .login_imag {
    position: absolute;
    top: 0px;
    right: 85px;
  }
  ::v-deep .loginButton.el-button {
    background: transparent;
    color: #00d000;
    border: 2px solid #555661;
    span {
      display: inline-block;
      width: 91px;
    }
  }
  .top {
    margin-top: -13px;
    font-size: 12px;
  }
  .buttom {
    margin-top: -36px;
    .unit {
      font-size: 12px;
      color: #909090;
    }
    .time {
      font-size: 18px;
      color: #ffffff;
      font-weight: 700;
    }
  }
  .name {
    width: 32px;
    float: right;
    height: 32px;
    border-radius: 32px;
    line-height: 32px;
    margin-top: 14px;
    background: rgba(3, 137, 123, 1);
    margin-right: 24px;
    text-align: center;
    cursor: pointer;
  }
  .timeCount{
    display: inline-block;
  }
}
</style>
